export const SUPPORT_MESSAGES = {
	SUPPORT: "Suporte",
	WHATSAPP: "WhatsApp",
	
	SUPPORT_EMAIL: "E-mail de suporte",
	SUPPORT_EMAIL_PLACEHOLDER: "Digite o e-mail de suporte",

	WPP_NUMBER: "WhatsApp de suporte",
	WPP_NUMBER_PLACEHOLDER: "+55 21 01234-5678",

	SAVE_BTN: "Salvar",
	SEND_TEST_BTN: "Testar envio",
	SAVE_FEEDBACK: "Salvo com sucesso!",

	WPP_FEEDBACK: "O contato de Whatsapp do suporte foi configurado e salvo com sucesso!",
	SUPPORT_FEEDBACK: "O e-mail do suporte foi configurado e salvo com sucesso!",

	EMAIL_ERROR: "Digite um e-mail válido.",
	SUBJECT_ERROR: "Campo obrigatório.",

	WPP_ERROR: "Digite um número válido.",
	SENDING_EMAIL: "Enviando e-mail...",

	AWS_HELP: "Não se esqueça de configurar o seu domínio para enviar e-mails com o Amazon SES. Faça o login no console e acesse: Amazon SES > Configuration > Verified Identities ",

	EMAIL_SENT: "E-mail enviado com sucesso!",
	EMAIL_SENT_DESCRIPTION1: "Enviamos o e-mail para: " ,
	EMAIL_SENT_DESCRIPTION2: ". Verifique sua caixa de entrada.",
	EMAIL_NOT_SENT: "Não foi possível enviar o e-mail.",
	EMAIL_NOT_SENT_DESCRIPTION: "Verifique se o domínio foi validado no console da AWS e se as configurações de SMTP estão corretas.",
	
	FROM_EMAIL_PLACEHOLDER: "Digite o e-mail de remetente",
	SEND: "Enviar",
};