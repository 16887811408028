import React from "react";
import { COLORS } from "../../../../../../assets";
import { MainButton } from "../../../../../../components/Buttons";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { ModalContainer, ModalContent } from "./styles";

interface Props {
    isOpen: boolean;
    onDeleteRequest: () => void;
    onCancel: () => void;
}

const DeleteUserModal = (props: Props) => {

	const { currentTheme } = useTheme();

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<h4>{MESSAGES.PT_BR.USER_MESSAGES.DELETE_MODAL_TITLE}</h4>
				<p>{MESSAGES.PT_BR.USER_MESSAGES.DELETE_MODAL_DESCRIPTION_A} 
					<strong>{MESSAGES.PT_BR.USER_MESSAGES.DELETE_MODAL_DESCRIPTION_B}</strong>
					{MESSAGES.PT_BR.USER_MESSAGES.DELETE_MODAL_DESCRIPTION_C}
					<strong>{MESSAGES.PT_BR.USER_MESSAGES.DELETE_MODAL_DESCRIPTION_D}</strong>
					{MESSAGES.PT_BR.USER_MESSAGES.DELETE_MODAL_DESCRIPTION_E}
				</p>
				<div>
					<MainButton 
						enabled 
						onClick={props.onCancel} 
						text={MESSAGES.PT_BR.USER_MESSAGES.DELETE_MODAL_CANCEL_BUTTON} 
						darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON} 
						lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}/>
					<MainButton 
						enabled 
						onClick={props.onDeleteRequest}
						text={MESSAGES.PT_BR.USER_MESSAGES.DELETE_MODAL_CONFIRM_BUTTON} 
						darkColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON} 
						lightColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}/>
				</div>
			</ModalContent>
		</ModalContainer>
	);
};

export default DeleteUserModal;
