import React from "react";
import { useTheme } from "../../../contexts/theme";
import { Card, ColorContainer, Container } from "./styles";
import { ColorDotSelection } from "../../../components";
import { THEME_TYPES } from "../../../constants";
import { MESSAGES } from "../../../constants/messages";

const Error404: React.FC = () => {
	const { currentTheme } = useTheme();

	return (
		<Container>
			<ColorContainer>
				<ColorDotSelection/>
			</ColorContainer>
			<Card theme={currentTheme} >
				<img src={currentTheme.type === THEME_TYPES.DARK ? "/images/logoDark.png" : "/images/logo.png"} alt="Technos LOGO" />
				<hr />
				<div>
					<h1>404</h1>
					<p>{MESSAGES.PT_BR.ERROR.ERROR_404}</p>
				</div>
			</Card>
		</Container>
	);
};

export default Error404;