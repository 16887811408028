import styled from "styled-components";

export interface IContainerProps {
    screenWidth?: number;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
`;

export const HeaderButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const BackButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    min-width: 100px;

    h4 {
        color: ${(props) => props.theme.primaryColor};
        font-size: 18px;
    }

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;

export const DateSelectorContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const TotalButton = styled.div`
    width: 100px;
    margin-top: 0;
    margin-right: 10px;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.backgroundCard};
    border-radius: 16px;
    padding-left: 10px;
    margin-top: 20px;
    z-index: 1;

    h2 {
        color: ${(props) => props.theme.textColor};
        margin-top: 16px;
        margin-left: 16px;
    }
`;

export const HeaderCounterContainer = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;

    h2 {
        color: ${(props) => props.theme.textColor};
    }
`;

const getWfItems = (props: IContainerProps) => {
	if (props.screenWidth && props.screenWidth < 600) {
		return "repeat(1, 1fr)";
	} else if (props.screenWidth && props.screenWidth < 900) {
		return "repeat(2, 1fr)";
	} else if (props.screenWidth && props.screenWidth < 1200) {
		return "repeat(3, 1fr)";
	} else if (props.screenWidth && props.screenWidth < 1500) {
		return "repeat(4, 1fr)";
	} else {
		return "repeat(5, 1fr)";
	}
};

export const ShowResultsContainer = styled.div<IContainerProps>`
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
    grid-template-columns: ${getWfItems};
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 10px;
    box-sizing: border-box;
`;

export const WatchFaceCard = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.backgroundCardSecondary};
    height: 240px;
    width: 200px;
    border-radius: 16px;
    align-items: center;
    justify-content: center;

    img {
        height: 170px;
        width: 140px;
        border-radius: 16px;
        object-fit: cover;
    }

    p {
        color: ${(props) => props.theme.textColor};
        font-size: 14px;
        margin-top: 10px;
        font-family: "Normal";
    }

    h6 {
        color: ${(props) => props.theme.textColor};
        font-size: 14px;
        margin-top: 2px;
    }
`;
