import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { TextIconButton } from "../../../../components/Buttons";
import { useTheme } from "../../../../contexts/theme";
import { AddWorkoutModal, AddWorkoutVideoModal, ShowWorkoutVideos, WorkoutSectionItem } from "./components";
import { Container, DroppableArea, FakeBtn, ResultContainer, RowContainer, TrainingHeader } from "./styles";
import { ReactComponent as PlusIcon } from "../../../../assets/images/plusIcon.svg";
import { ReactComponent as DragEnabledIcon } from "../../../../assets/images/dragEnabledIcon.svg";
import { ReactComponent as DragDisabledIcon } from "../../../../assets/images/dragDisabledIcon.svg";
import { Loader } from "../../../../components";
import { MESSAGES } from "../../../../constants/messages";
import { useTraining } from "../../../../contexts/training";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { WorkoutLevel, WorkoutSection } from "../../../../types/Training";
import { BiArrowBack } from "react-icons/bi";
import { toast } from "react-hot-toast";
import { useWindowDimensions } from "../../../../hooks";
import { SCREEN_SIZE } from "../../../../constants";


const Workout = () => {

	const { currentTheme } = useTheme();
	const { selectedApp } = useMobileApp();
	const [workouts, setWorkouts] = useState<WorkoutSection[] | null>(null);
	const { getWorkouts, saveNewOrder } = useTraining();
	const [addWorkoutModalVisible, setAddWorkoutModalVisible] = useState<boolean>(false);
	const [addWorkoutVideoModalVisible, setAddWorkoutVideoModalVisible] = useState<boolean>(false);
	const { width } = useWindowDimensions();
	const nextValidIndex = useRef<number>(0);

	const [selectedWorkout, setSelectedWorkout] = useState<WorkoutSection | null>(null);
	const [isDragDisabled, setIsDragDisabled] = useState<boolean>(true);

	useEffect(() => {
		refreshWorkouts();
	}, []);

	const refreshWorkouts = () => {
		getWorkouts(selectedApp?.appCode ?? 0)
			.then((response) => {
				setWorkouts(response?.sort((a, b) => a.index - b.index) ?? []);
				nextValidIndex.current = response?.length + 1 ?? 1;

				if (selectedWorkout) {
					const workout = response?.find((workout) => workout.id === selectedWorkout.id);
					if (workout) {
						setSelectedWorkout(workout);
					} else {
						setSelectedWorkout(null);
					}
				}
			}).catch((error) => {
				setWorkouts([]);
				nextValidIndex.current = 1;
				console.error(error);
			});
	};


	const handleOnDragEnd = (result: DropResult) => {
		if (!result.destination || workouts === null) return;
		let items = Array.from(workouts);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		items.forEach((item, index) => {
			item.index = index + 1;
		});

		items = items.sort((a, b) => a.index - b.index);

		setWorkouts(items ?? []);

		const t = toast.loading(MESSAGES.PT_BR.TRAINING_MESSAGES.LOADING);
		saveNewOrder(selectedApp.appCode, items)
			.then(() => {
				toast.success(MESSAGES.PT_BR.TRAINING_MESSAGES.EDIT_SUCCESS);
			}).catch(() => {
				toast.error(MESSAGES.PT_BR.TRAINING_MESSAGES.EDIT_ERROR);
			}).finally(() => {
				toast.dismiss(t);
			});
	};


	const getContent = () => {
		if (selectedWorkout) {
			return (
				<>
					{addWorkoutVideoModalVisible &&
						<AddWorkoutVideoModal currentSection={selectedWorkout} onClose={() => { setAddWorkoutVideoModalVisible(false); refreshWorkouts(); }} />
					}
					<TrainingHeader>
						<RowContainer>
							<BiArrowBack color={currentTheme.textColor} fontSize="2em" style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => setSelectedWorkout(null)} />
							<h1>{selectedWorkout.name}</h1>
						</RowContainer>
						<TextIconButton
							text={MESSAGES.PT_BR.TRAINING_MESSAGES.NEW_WORKOUT_VIDEO}
							onClick={() => setAddWorkoutVideoModalVisible(true)}
							svgImage={<PlusIcon style={{ "width": "100%", "height": "100%" }} />}
							enabled
						/>
					</TrainingHeader>
					<ResultContainer>
						{workouts != null &&
							<>
								<ShowWorkoutVideos type={WorkoutLevel.BEGINNER} selectedWorkout={selectedWorkout} onReloadRequested={() => refreshWorkouts()} />
								<ShowWorkoutVideos type={WorkoutLevel.INTERMEDIATE} selectedWorkout={selectedWorkout} onReloadRequested={() => refreshWorkouts()} />
								<ShowWorkoutVideos type={WorkoutLevel.ADVANCED} selectedWorkout={selectedWorkout} onReloadRequested={() => refreshWorkouts()} />
							</>}
					</ResultContainer>
				</>
			);
		}

		return (
			<>
				{addWorkoutModalVisible &&
					<AddWorkoutModal nextValidIndex={nextValidIndex.current} onClose={() => { setAddWorkoutModalVisible(false); refreshWorkouts(); }} />
				}
				<TrainingHeader>
					<h1>{MESSAGES.PT_BR.TRAINING_MESSAGES.WORKOUTS_TITLE}</h1>
					{width > SCREEN_SIZE.DESKTOP &&
						<FakeBtn onClick={() => setIsDragDisabled(!isDragDisabled)}>
							<div>
								{isDragDisabled ? <DragDisabledIcon /> : <DragEnabledIcon />}
							</div>
							<p>{isDragDisabled ? MESSAGES.PT_BR.FAQ_MESSAGES.DRAG_DISABLED : MESSAGES.PT_BR.FAQ_MESSAGES.DRAG_ENABLED}</p>
						</FakeBtn>
					}
					<TextIconButton
						text={MESSAGES.PT_BR.TRAINING_MESSAGES.NEW_WORKOUT}
						onClick={() => { setAddWorkoutModalVisible(true); }}
						svgImage={<PlusIcon style={{ "width": "100%", "height": "100%" }} />}
						enabled
					/>
				</TrainingHeader>
				{workouts === null ?
					<Loader /> :
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId="characters">
							{(provided) => (
								<DroppableArea {...provided.droppableProps} ref={provided.innerRef} onDragEnter={() => console.log("Entrei")}>
									{workouts?.map((item, index) => {
										return (
											<Draggable key={index} draggableId={item.index + ""} index={index} isDragDisabled={isDragDisabled} >
												{(provided) => (
													<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
														<WorkoutSectionItem
															currentWorkout={item}
															onSelectedWorkout={(workout) => setSelectedWorkout(workout)}
															onReloadRequested={() => refreshWorkouts()}
														/>
													</div>
												)}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</DroppableArea>
							)}
						</Droppable>
					</DragDropContext>
				}
			</>
		);
	};

	return (
		<Container>
			{getContent()}
		</Container>
	);
};

export default Workout;