import React, { useEffect, useState } from "react";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { useTheme } from "../../../../../../contexts/theme";
import { MESSAGES } from "../../../../../../constants/messages";
import { MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { Tutorial } from "../../../../../../types/Tutorial";
import { useTutorial } from "../../../../../../contexts/tutorials";
import toast from "react-hot-toast";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { useDevice } from "../../../../../../contexts/device";

interface ModalProps {
	isOpen: boolean;
	onCancel: (status) => void;
	onVideoUpdated: () => void;
	currentVideo: Tutorial | null;
}

const EditVideoModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();
	const { updateTutorial } = useTutorial();
	const { selectedApp } = useMobileApp();
	const { currentDevice } = useDevice();
	const [videoTitle, setVideoTitle] = useState<string>(props.currentVideo?.title ?? "");
	const [titleError, setTitleError] = useState<string>("");
	const [videoLink, setVideoLink] = useState<string>(props.currentVideo?.link || "");
	const [videoLinkError, setVideoLinkError] = useState<string>("");

	useEffect(() => {
		setVideoTitle(props.currentVideo?.title || "");
		setVideoLink(props.currentVideo?.link || "");
		setTitleError("");
		setVideoLinkError("");
	}, [props.isOpen]);

	const handleEditVideo = () => {
		let allRight = true;
		if (videoTitle === "") {
			setTitleError(MESSAGES.PT_BR.TUTORIALS.MODAL.TITLE_ERROR);
			allRight = false;
		} else {
			setTitleError("");
		}

		if (videoLink === "") {
			setVideoLinkError(MESSAGES.PT_BR.TUTORIALS.MODAL.LINK_ERROR);
			allRight = false;
		} else {
			setVideoLinkError("");
		}

		if (allRight) {
			const updatedVideo = {
				title: videoTitle,
				link: videoLink,
			} as Tutorial;

			const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
			updateTutorial(updatedVideo, selectedApp?.appCode, currentDevice?.slug || "")
				.then(() => {
					props.onVideoUpdated();
					props.onCancel(
						{
							title: MESSAGES.PT_BR.TUTORIALS.MODAL.SUCCESS_FEEDBACK,
							description: MESSAGES.PT_BR.TUTORIALS.MODAL.SUCCESS_DESCRIPTION,
							success: true,
							isOpen: true,
						});
					setVideoTitle("");
					setVideoLink("");
				})
				.catch((error) => {
					toast.error(error);
				}).finally(() => {
					toast.dismiss(t);
				});
		}
	};

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.TUTORIALS.MODAL.EDIT_TITLE}</h1>
					<div>
						<CloseIcon onClick={() => props.onCancel({ isOpen: false, title: "", description: "", success: false })} />
					</div>
				</ModalHeader>
				<ModalBody>
					<MainInput
						type="text"
						placeholder={MESSAGES.PT_BR.TUTORIALS.MODAL.TITLE_PLACEHOLDER}
						value={videoTitle}
						errorText={titleError}
						onChange={(value) => { setVideoTitle(value); }}
						title={MESSAGES.PT_BR.TUTORIALS.MODAL.TITLE}
						disabled
					/>
					<MainInput
						type="text"
						placeholder={MESSAGES.PT_BR.TUTORIALS.MODAL.LINK_PLACEHOLDER}
						value={videoLink}
						errorText={videoLinkError}
						onChange={(value) => { setVideoLink(value); }}
						title={MESSAGES.PT_BR.TUTORIALS.MODAL.LINK}
					/>
				</ModalBody>
				<ModalFooter theme={currentTheme}>
					<MainButton
						enabled
						onClick={() => handleEditVideo()}
						text={MESSAGES.PT_BR.TUTORIALS.MODAL.SAVE_BTN}
					/>
				</ModalFooter>
			</ModalContent>
		</ModalContainer>
	);

};

export default EditVideoModal;