import styled from "styled-components";

interface ModalProps {
	isOpen: boolean,
}

export const ModalContainer = styled.div<ModalProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	opacity: ${props => props.isOpen ? 1 : 0};
	visibility: ${props => props.isOpen ? "visible" : "hidden"};
	transition: visibility 0s, opacity 0.3s linear;
`;


export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 30%;
	height: 40%;
	min-width: 400px;
	background-color: ${props => props.theme.backgroundCard};
	border-radius: 10px;
	padding: 20px;
	box-sizing: border-box;
`;

export const ModalHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	h3 {
		font-size: 28px;
		padding: 10px;
		text-align: center;
		font-weight: 500;
		line-height: 30px;
			color: ${props => props.theme.secondaryColor};
	}

	div {
		&:hover {
				cursor: pointer;
		}
	}
`;

export const ModalBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;

	p {
		font-size: 18px;
		font-weight: 200;
		text-align: center;
		margin-bottom: 20px;
		color: ${props => props.theme.textColor};
		font-family: "Normal";
	}

	div {
		
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		h5 {
			margin-left: 20px;
		}

		button {
			width: 50%;
			border-radius: 10px;
			text-align: center;
			font-size: 16px;
			margin: 0;
		}
	}
`;






