import React, { createContext, useContext, useState } from "react";
import { Api } from "../services";

interface ITermsContextData {
	getPrivacy: (appCode: number) => Promise<string>;
	getUseTerms: (appCode: number) => Promise<string>;
	getConsentTerms: (appCode: number) => Promise<string>;
	savePrivacy: (htmlPage: string, updateText: string, appCode: number) => Promise<void>;
	saveUseTerms: (htmlPage: string, updateText: string, appCode: number) => Promise<void>;
	saveConsentTerms: (htmlPage: string, updateText: string, appCode: number) => Promise<void>;
}

interface TermsProviderProps {
	children: React.ReactNode;
}

export const TermsContext = createContext({} as ITermsContextData);

const TermsProvider: React.FC<TermsProviderProps> = ({ children }) => {

	const [termsVersion, setTermsVersion] = useState(0);

	const getPrivacy = async (appCode: number) => {
		const termsPromise = Api.Terms.getPrivacy(appCode);
		const versionPromise = Api.Terms.getTermsVersion(appCode);
		try {
			const values_1 = await Promise.all<[Promise<string>, Promise<number>]>([termsPromise, versionPromise]);
			setTermsVersion(values_1[1]);
			return values_1[0];
		} catch (err) {
			return Promise.reject(err);
		}
	};

	const getUseTerms = async (appCode: number) => {
		const termsPromise = Api.Terms.getUseTerms(appCode);
		const versionPromise = Api.Terms.getTermsVersion(appCode);
		try {
			const values_1 = await Promise.all<[Promise<string>, Promise<number>]>([termsPromise, versionPromise]);
			setTermsVersion(values_1[1]);
			return values_1[0];
		} catch (err) {
			return Promise.reject(err);
		}
	};

	const getConsentTerms = async (appCode: number) => {
		const termsPromise = Api.Terms.getConsentTerms(appCode);
		const versionPromise = Api.Terms.getTermsVersion(appCode);
		try {
			const values_1 = await Promise.all<[Promise<string>, Promise<number>]>([termsPromise, versionPromise]);
			setTermsVersion(values_1[1]);
			return values_1[0];
		} catch (err) {
			return Promise.reject(err);
		}
	};

	const savePrivacy = async (htmlPage: string, updateText: string , appCode: number) => {
		const termsPromise = Api.Terms.savePrivacy(htmlPage, appCode);
		const versionPromise = Api.Terms.saveTermsVersion(termsVersion + 1, updateText, appCode);
		try {
			await Promise.all<[Promise<void>, Promise<void>]>([termsPromise, versionPromise]);
			setTermsVersion(termsVersion + 1);
			return;
		} catch (err) {
			return Promise.reject(err);
		}
	};

	const saveUseTerms = async (htmlPage: string, updateText: string , appCode: number) => {
		const termsPromise = Api.Terms.saveUseTerms(htmlPage, appCode);
		const versionPromise = Api.Terms.saveTermsVersion(termsVersion + 1, updateText, appCode);
		try {
			await Promise.all<[Promise<void>, Promise<void>]>([termsPromise, versionPromise]);
			setTermsVersion(termsVersion + 1);
			return;
		} catch (err) {
			return Promise.reject(err);
		}
	};

	const saveConsentTerms = async (htmlPage: string, updateText: string ,appCode: number) => {
		const termsPromise = Api.Terms.saveConsentTerms(htmlPage, appCode);
		const versionPromise = Api.Terms.saveTermsVersion(termsVersion + 1, updateText, appCode);
		try {
			await Promise.all<[Promise<void>, Promise<void>]>([termsPromise, versionPromise]);
			setTermsVersion(termsVersion + 1);
			return;
		} catch (err) {
			return Promise.reject(err);
		}
	};


	return (
		<TermsContext.Provider
			value={{
				getPrivacy,
				getUseTerms,
				getConsentTerms,
				savePrivacy,
				saveUseTerms,
				saveConsentTerms
			}}>
			{children}
		</TermsContext.Provider>
	);
};

const useTerms = () => {
	const context = useContext(TermsContext);

	return context;
};

export { TermsProvider, useTerms };
