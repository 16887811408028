import React from "react";
import { useTheme } from "../../../../../../contexts/theme";
import { Exercise } from "../../../../../../types/Exercise";
import { TableHeader, TableItem } from "./components";
import { Line, LineTr, ResultTableContainer } from "./styles";

interface TableProps {
	result: Exercise[];
	onDeleteRequested: (customExercise: Exercise) => void;
	onEditRequested: (customExercise: Exercise) => void;
}

const ResultTable = (props: TableProps) => {

	const { currentTheme } = useTheme();

	return (
		<ResultTableContainer>
			<table>
				<thead>
					<TableHeader />
				</thead>
				<tbody>
					{
						props.result.map((currentExercise, index) => (
							<React.Fragment key={index}>
								<TableItem
									customExercise={currentExercise}
									key={index} onDeleteRequested={props.onDeleteRequested}
									onEditRequested={props.onEditRequested} 
								/>
								<LineTr theme={currentTheme}>
									<td colSpan={5}>
										<Line theme={currentTheme} />
									</td>
								</LineTr>
							</React.Fragment>
						))
					}
				</tbody>
			</table>
		</ResultTableContainer>
	);
};

export default ResultTable;