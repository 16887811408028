import styled from "styled-components";
import { Theme } from "../../../../../../../types/Theme";

interface ModalProps {
    isOpen: boolean,
    theme: Theme,
}

export const ModalContainer = styled.div<ModalProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    opacity: ${props => props.isOpen ? 1 : 0};
    visibility: ${props => props.isOpen? "visible" : "hidden"};
    transition: visibility 0s, opacity 0.3s linear;

`;

interface ContentProps {
    theme: Theme,
}

export const ModalContent = styled.div<ContentProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    min-width: 400px;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
`;

export const ModalHeader = styled.div<ContentProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h1 {
        font-size: 28px;
        padding: 10px;
        text-align: center;
        font-weight: 600;
        line-height: 30px;
        color: ${props => props.theme.primaryColor};
    }

    div {
        &:hover {
            cursor: pointer;
        }
    }
`;

export const ModalBody = styled.div<ContentProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;

    button {
        width: 50%;
        background-color: ${props => props.theme.primaryColor};
        border-radius: 8px;
    }
`;






