import styled from "styled-components";
import { COLORS } from "../../../assets";

interface CheckBoxContainerProps {
    hasSubOptions?: boolean;
}


export const CheckBoxContainer = styled.div<CheckBoxContainerProps>`
    flex-direction: column;
    margin: 5px;
    height: 20px;
    margin-bottom: ${props => props.hasSubOptions ? "30px" : 0};

    label {
        font-family: "normal";
        accent-color: ${COLORS.DEFAULT_COLORS.FIRST_BUTTON};
        color: ${props => props.theme.textColor};

        input {
            margin-right: 5px;
            background-color: red;
        }
    }
`;

export const SubOptionContainer = styled.div`
    display: flex;
    margin-left: 20px;
    margin-top: 5px;
    flex-direction: row;

    label {
        margin-left: 10px;
    }
`;