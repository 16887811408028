import React, { useState } from "react";
import { Container, DropdownItem, OpenContainer, SpacingContainer, StackContainer } from "./styles";
import { IoIosArrowDown, IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { useTheme } from "../../../../contexts/theme";
import ColorDotSelection from "../../../ColorDotSelection";
import { MESSAGES } from "../../../../constants/messages";
import { MdOutlineSettings } from "react-icons/md";
import { Feature } from "../../../../types/Feature";
import { canSee } from "../../../../services/auth/PermissionGate";
import { useAdminUser } from "../../../../contexts/adminUser";

interface TextInputProps {
	items: Feature[];
	title: string;
	onClick: (value: string) => void;
}

const ConfigDropdown = (props: TextInputProps) => {

	const { currentTheme } = useTheme();
	const [isOpen, setIsOpen] = useState(false);
	const {currentUser} = useAdminUser();

	return (
		<StackContainer onMouseLeave={() => { setIsOpen(false); }}>
			<Container onClick={() => setIsOpen(!isOpen)}>
				<div>
					<MdOutlineSettings color={currentTheme.textColor} fontSize="2.5em" />
				</div>
				<p>{props.title}</p>
				<div>
					{isOpen ?
						<IoIosArrowUp color={currentTheme.textColor} fontSize="2.0em" /> :
						<IoIosArrowDown color={currentTheme.textColor} fontSize="2.0em" />
					}
				</div>
			</Container>
			{isOpen &&
				<SpacingContainer>
					<OpenContainer>
						<DropdownItem enabled>
							<div>
								<p>{MESSAGES.PT_BR.CONFIG.THEME}</p>
								<ColorDotSelection />
							</div>
						</DropdownItem>

						{props.items.map((item, index) => {
							return (
								<DropdownItem
									key={index}
									enabled={!item.feature || canSee(currentUser.role, item.feature)}
									onClick={() => {
										if(!item.feature || canSee(currentUser.role, item.feature)){
											setIsOpen(false);
											props.onClick(props.items[index].name);
										}
									}}>
									<div>
										<p>{item.name}</p>
										<IoIosArrowForward color={currentTheme.textColor} fontSize="1.5em" />
									</div>
								</DropdownItem>
							);
						})}
					</OpenContainer>
				</SpacingContainer>
			}
		</StackContainer>
	);
};

export default ConfigDropdown;