import React from "react";
import { useTheme } from "../../../../../../contexts/theme";
import { Tutorial } from "../../../../../../types/Tutorial";
import { TableHeader, TableItem } from "./components";
import { Line, LineTr, ResultTableContainer } from "./styles";

interface TableProps {
	result: Tutorial[];
	onEditRequested: (tutorial: Tutorial) => void;
	onDeleteRequested: (tutorial: Tutorial) => void;
}

const ResultTable = (props: TableProps) => {

	const { currentTheme } = useTheme();

	return (
		<ResultTableContainer>
			<table>
				<thead>
					<TableHeader />
				</thead>
				<tbody>
					{
						props.result.map((currentTutorial, index) => (
							<>
								<TableItem tutorial={currentTutorial} key={index} onDeleteRequested={props.onDeleteRequested} onEditRequested={props.onEditRequested} />
								<LineTr theme={currentTheme}>
									<td colSpan={5}>
										<Line theme={currentTheme}/>
									</td>
								</LineTr>
							</>
						))
					}
				</tbody>
			</table>
		</ResultTableContainer>
	);
};

export default ResultTable;