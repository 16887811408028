import React from "react";
import { useTheme } from "../../contexts/theme";
import { Device } from "../../types/Device";
import { Container, DeviceContainer } from "./styles";

interface HorizontalDeviceSelectionProps {
	onSelectionChange: (newSlugList: string[]) => void;
	deviceList: Device[];
	selectedDeviceSlugList: string[];
}


const HorizontalDeviceSelection = (props: HorizontalDeviceSelectionProps) => {

	const { currentTheme } = useTheme();

	const handleDeviceSelected = (device: Device) => {
		let newSlugList = [...props.selectedDeviceSlugList];
		if (newSlugList.find((slug) => slug === device.slug) != undefined) {
			newSlugList = newSlugList.filter((slug) => slug !== device.slug);
		} else {
			newSlugList.push(device.slug);
		}

		props.onSelectionChange(newSlugList);
	};

	return (
		<Container>
			{props.deviceList?.map((device, index) => (
				<DeviceContainer
					key={index}
					theme={currentTheme}
					selected={props.selectedDeviceSlugList.find((slug) => slug === device.slug) != undefined}
					onClick={() => handleDeviceSelected(device)}
				>
					<img src={device.image} alt={device.name} />
					<h1></h1>
					<span>{device.name}</span>
				</DeviceContainer>
			))}
		</Container>
	);
};

export default HorizontalDeviceSelection;