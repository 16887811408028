import { getApi } from ".";
import { SupportMapper } from "../../types/Mapper/SupportMapper";

export const getWppNumber = (appCode: number) => {
	return new Promise<string>((resolve, reject) => {
		getApi(appCode).get("/contacts")
			.then((response) => {
				resolve(response.data?.phone_number);
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const saveWppNumber = (wppNumber: string, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post(`/contacts?phoneNumber=${wppNumber}`)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const getSupportEmail = (appCode: number) => {
	return new Promise<string>((resolve, reject) => {
		getApi(appCode).get("/support")
			.then((response) => {
				resolve(response.data?.support_email);
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const saveSupport = (email: string, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put("/smtp", SupportMapper.fromSupportModelToApi(email))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const sendTestEmail = (fromEmail: string, apiCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(apiCode).post("/support", SupportMapper.getTestEmail(fromEmail)).then(() => {
			resolve();
		}).catch((error) => {
			console.log(error);
			reject(error?.response?.data?.title);
		});
	});
};
