import styled from "styled-components";
import { Theme } from "../../../../../../../../types/Theme";

interface ContainerProps {
	theme: Theme
}

export const TableItemStyled = styled.tr<ContainerProps>`
	td {
		height: 70px;
		color: ${props => props.theme.textColor};
		font-weight: 400;
		font-size: 20px;
		font-family: "Normal";
		text-align: center;
	}
`;

export const ThumbImg = styled.img`
	width: 160px;
	height: 100px;
	border-radius: 5px;
	object-fit: cover;

	&:hover {
		cursor: pointer;
	}
`;

