import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 25px;
`;

export const FaqHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin-top: 2%;

    h1 {
        color: ${props => props.theme.textColor};
    }

    button {
        max-width: 280px;
        height: 50px;
        font-size: 18px;
    }
`;

export const DroppableArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    margin: 20px 10px;
    width: 95%;

    > div {
        display: flex;
        width: 100%;
    }

    
`;

export const RightHeaderInfos = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
        margin-left: 10px;
    }
`;

export const FakeBtn = styled.div`
    display: flex;
    margin-right: 30px;
    color: ${(props) => props.theme.textColor};
    
    &:hover {
        cursor: pointer;
    }
`;