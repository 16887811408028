import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, RowContainer } from "./styles";
import { Exercise } from "../../../../../../types/Exercise";

interface Props {
	isOpen: boolean;
	onCancel: () => void;
	handleNewExercise: (exercise: Exercise, keepAdding: boolean) => void;
}

const AddCustomExerciseModal = (props: Props) => {

	const { currentTheme } = useTheme();
	const [newExercise, setNewExercise] = useState<Exercise>({} as Exercise);
	const [errorField, setErrorField] = useState<string>("");

	const handleNewExercise = (keepAdding: boolean) => {
		if (!newExercise.name || newExercise.name?.length === 0) {
			setErrorField(MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.MANDATORY_FIELD);
			return;
		} else {
			props.handleNewExercise(newExercise, keepAdding);
			resetStates();
		}
	};

	const resetStates = () => {
		setNewExercise({} as Exercise);
		setErrorField("");
	};

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.NEW_EXERCISE}</h1>
					<div>
						<CloseIcon onClick={() => {props.onCancel(); resetStates();}} />
					</div>
				</ModalHeader>
				<ModalBody>
					<RowContainer>
						<MainInput
							type="text"
							title={""}
							value={newExercise.name ?? ""}
							placeholder={MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.NEW_EXERCISE_PLACEHOLDER}
							onChange={(newValue) => setNewExercise({ ...newExercise, name: newValue })}
							errorText={errorField}
						/>
					</RowContainer>
					<ModalFooter theme={currentTheme}>
						<p onClick={() => handleNewExercise(true)}>{MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.KEEP_ADDING}</p>
						<MainButton enabled onClick={() => handleNewExercise(false)} text={MESSAGES.PT_BR.FIRMWARE.ADD_BTN} />
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default AddCustomExerciseModal;
