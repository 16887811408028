import styled from "styled-components";
import { Theme } from "../../../../../../types/Theme";

interface ContainerProps {
    theme: Theme
}

export const SearchContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
    border-radius: 15px;
    background-color: ${props => props.theme.backgroundCard};

   div {
        display: flex;
        width: 1px;
        height: 90%;
        background-color: ${props => props.theme.borderColor};
        border-radius: 15px;
   }
`;

export const SearchInputStyled = styled.input`
    width: 90%;
    height: 100%;
    border: none;
    border-radius: 15px;
    padding: 0 20px;
    font-size: 16px;
    outline: none;
    display: flex;
    background-color: ${props => props.theme.backgroundCard};
    color: ${props => props.theme.textColor};
    font-weight: 600;
    font-family: "Medium";
    ::placeholder {
        color: ${props => props.theme.textColor};
    }
`;

export const SearchButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50px;
    border: none;
    border-radius: 15px;
    background-color: ${props => props.theme.backgroundCard};
    padding: 12px;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
`;


