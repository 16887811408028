
/**
    API Object
    {
        "id": 0,
        "name": "string",
        "slug": "string",
        "createdAt": "2023-02-07T17:55:39.696Z",
        "updatedAt": "2023-02-07T17:55:39.696Z",
        "link_manual": "string",
        "available_features": [
            "string"
        ],
        "image": "string"
    }
 */

import { FirmwareType, GpsType, WatchFaceType } from "../Device";

/**
  APP Object
    {
        name: string;
        slug: string;
        image?: string;
        linkManual?: string;
        availableFeatures: string[];
    }
 */

const fromApiToModel = (device) => {
	return {
		name: device.name,
		slug: device.slug,
		linkManual: device?.link_manual || "",
		availableFeatures: device?.available_features || [],
		image: device?.image || "/images/t_technos.png",
		watchFaceType: getApiWatchFaceType(device?.available_features),
		firmwareType: device?.available_features?.length && getApiFirmwareType(device.available_features),
		gpsType: device?.available_features?.length && getApiGpsType(device.available_features)
	};
};

const getApiWatchFaceType = (availableFeatures) => {
	if (!Array.isArray(availableFeatures) || availableFeatures.length === 0)
		return WatchFaceType.ZIP;

	if (availableFeatures.includes(WatchFaceType.BIN))
		return WatchFaceType.BIN;
	
	if (availableFeatures.includes(WatchFaceType.WATCH))
		return WatchFaceType.WATCH;

	return WatchFaceType.ZIP;
};

const getApiFirmwareType = (available_features) => {
	if (available_features.includes(FirmwareType.BIN)) return FirmwareType.BIN;
	if (available_features.includes(FirmwareType.ZIP)) return FirmwareType.ZIP;
	if (available_features.includes(FirmwareType.SEQUENTIAL)) return FirmwareType.SEQUENTIAL;
	return false;
};

const getApiGpsType = (available_features) => {
	if (available_features.includes(GpsType.AGPS)) return GpsType.AGPS;
	if (available_features.includes(GpsType.EPO)) return GpsType.EPO;
	return GpsType.AGPS;
};

const getAvailableFeatures = (device) => {
	if (device.watchFaceType === WatchFaceType.ZIP){
		device.availableFeatures = device.availableFeatures.filter(item => item !== WatchFaceType.BIN && item !== WatchFaceType.WATCH);
	} else if (device.watchFaceType === WatchFaceType.WATCH) {
		device.availableFeatures = device.availableFeatures.filter(item => item !== WatchFaceType.BIN && item !== WatchFaceType.ZIP);
	} else {
		device.availableFeatures = device.availableFeatures.filter(item => item !== WatchFaceType.ZIP && item !== WatchFaceType.WATCH);
	}

	if(device.firmwareType === FirmwareType.ZIP){
		device.availableFeatures = device.availableFeatures.filter(item => item !== FirmwareType.BIN);
	}else {
		device.availableFeatures = device.availableFeatures.filter(item => item !== FirmwareType.ZIP);
	}

	if(device.gpsType === GpsType.AGPS){
		device.availableFeatures = device.availableFeatures.filter(item => item !== GpsType.EPO);
	}else{
		device.availableFeatures = device.availableFeatures.filter(item => item !== GpsType.AGPS);
	}

	if(!device.availableFeatures.includes(device.watchFaceType)){
		device.availableFeatures.push(device.watchFaceType);
	}

	if(!device.availableFeatures.includes(device.firmwareType)){
		device.availableFeatures.push(device.firmwareType);
	}

	if(!device.availableFeatures.includes(device.gpsType)){
		device.availableFeatures.push(device.gpsType);
	}

	return device.availableFeatures.filter(item => item !== false);
};

const fromModelToApi = (device) => {
	return {
		name: device.name,
		slug: device.slug,
		image: device?.image || "",
		linkManual: device?.linkManual || "",
		availableFeatures: getAvailableFeatures(device),
	};
};

export const DeviceMapper = {
	fromApiToModel,
	fromModelToApi
};
