import { getApi } from ".";
import { SleepMapper } from "../../types/Mapper/SleepMapper";
import { Sleep } from "../../types/Sleep";

export const getSleepScoreList = (appCode: number) => {
	return new Promise<Sleep[]>((resolve, reject) => {
		getApi(appCode).get("/sleep_score_list")
			.then((response) => {
				resolve(response.data.map((apiSleep) => SleepMapper.fromApiToModel(apiSleep) as Sleep));
			}).catch((error) => {
				console.log("Opa, deu erro: ", error);
				reject(error?.response?.data?.title);
			});
	});
};


export const updateSleepScore = (sleepScore: Sleep, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/sleep_score", SleepMapper.fromModelToApi(sleepScore))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log("Opa, deu erro: ", error);
				reject(error?.response?.data?.title);
			});
	});
};