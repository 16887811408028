import React, { useState } from "react";
import { Container, DropdownItem, OpenContainer, SpacingContainer, StackContainer } from "./styles";
import { IoIosArrowDown, IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { useTheme } from "../../../../contexts/theme";
import { StatusModal } from "../../../../components";
import { useAdminUser } from "../../../../contexts/adminUser";
import { Auth } from "../../../../services";
import { MESSAGES } from "../../../../constants/messages";
import { BiUserCircle } from "react-icons/bi";
import { EditProfileModal, EditPasswordModal } from "./components";
import { toast } from "react-hot-toast";
import { useMobileApp } from "../../../../contexts/mobileApp";

interface TextInputProps {
	items: string[];
}

const ProfileDropdown = (props: TextInputProps) => {

	const { currentTheme } = useTheme();
	const { currentUser, logout, updateCurrentUser, updatePassword } = useAdminUser();
	const { selectedApp } = useMobileApp();

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
	const [editPasswordModal, setEditPasswordModal] = useState<boolean>(false);
	const [modalStatus, setModalStatus] = useState({ isOpen: false, title: "", description: "", success: true });

	const onProfileOptionClick = (option: string) => {
		switch (option) {
		case MESSAGES.PT_BR.PROFILE.LOGOUT:
			Auth.doLogout().then(() => logout());
			break;
		case MESSAGES.PT_BR.PROFILE.PROFILE:
			setEditModalVisible(true);
			break;
		}
	};

	const onEditProfileRequested = (newName: string, newPhotoFile: File) => {
		const updatedUser = { ...currentUser, name: newName };

		const t = toast.loading(MESSAGES.PT_BR.USER_MESSAGES.LOADING);
		updateCurrentUser(selectedApp.appCode, updatedUser, newPhotoFile)
			.then(() => {
				setModalStatus({
					isOpen: true,
					title: MESSAGES.PT_BR.USER_MESSAGES.PROFILE_EDIT_FEEDBACK_TITLE,
					description: MESSAGES.PT_BR.USER_MESSAGES.PROFILE_EDIT_FEEDBACK_DESCRIPTION,
					success: true,
				});
			}).catch((err) => {
				console.log(err);
				setModalStatus({
					isOpen: true,
					title: MESSAGES.PT_BR.USER_MESSAGES.PROFILE_EDIT_ERROR,
					description: MESSAGES.PT_BR.USER_MESSAGES.PROFILE_EDIT_ERROR_DESCRIPTION,
					success: true,
				});
			}).finally(() => {
				toast.dismiss(t);
				setTimeout(() => {
					setEditModalVisible(false);
					setModalStatus({ ...modalStatus, isOpen: false });
				}, 3000);
			});
	};

	const onEditPasswordRequested = (oldPassword: string, newPassword: string) => {

		const t = toast.loading(MESSAGES.PT_BR.USER_MESSAGES.LOADING);
		setEditPasswordModal(false);
		updatePassword(selectedApp.appCode, oldPassword, newPassword)
			.then(() => {
				setModalStatus({
					isOpen: true,
					title: MESSAGES.PT_BR.USER_MESSAGES.CHANGE_PASSWORD_FEEDBACK_TITLE,
					description: MESSAGES.PT_BR.USER_MESSAGES.CHANGE_PASSWORD_FEEDBACK_DESCRIPTION,
					success: true,
				});
			}).catch((err) => {
				console.log(err);
				setModalStatus({
					isOpen: true,
					title: MESSAGES.PT_BR.USER_MESSAGES.CHANGE_PASSWORD_ERROR,
					description: MESSAGES.PT_BR.USER_MESSAGES.CHANGE_PASSWORD_ERROR_DESCRIPTION,
					success: false,
				});
			}).finally(() => {
				toast.dismiss(t);
				setTimeout(() => {
					setEditModalVisible(false);
					setEditPasswordModal(false);
					setModalStatus({ ...modalStatus, isOpen: false });
				}, 3000);
			});
	};

	return (
		<StackContainer onMouseLeave={() => setIsOpen(false)}>
			<StatusModal isOpen={modalStatus.isOpen} description={modalStatus.description} success={modalStatus.success} title={modalStatus.title} />
			<EditProfileModal
				user={currentUser}
				isOpen={editModalVisible}
				onCancel={() => setEditModalVisible(false)}
				onEditPassword={() => setEditPasswordModal(true)}
				onEditProfileRequested={onEditProfileRequested}
			/>
			<EditPasswordModal isOpen={editPasswordModal} onCancel={() => setEditPasswordModal(false)} onEditPasswordRequested={onEditPasswordRequested} />
			<Container onClick={() => setIsOpen(!isOpen)} theme={currentTheme}>
				{
					currentUser.photo ?
						<img src={currentUser.photo} /> :
						<BiUserCircle color={currentTheme.textColor} fontSize="3.2em" />
				}
				<div>
					{isOpen ?
						<IoIosArrowUp color={currentTheme.textColor} fontSize="2.0em" /> :
						<IoIosArrowDown color={currentTheme.textColor} fontSize="2.0em" />
					}
				</div>
			</Container>
			{isOpen &&
				<SpacingContainer>
					<OpenContainer theme={currentTheme}>
						{props.items.map((item, index) => {
							return (
								<DropdownItem
									key={index}
									theme={currentTheme}
									onClick={() => {
										setIsOpen(false);
										onProfileOptionClick(props.items[index]);
									}}>
									<div>
										{/* <img src={props.selected?.image ?? technosLogo}/> */}
										<p>{item}</p>
										<IoIosArrowForward color={currentTheme.textColor} fontSize="1.5em" />
									</div>
								</DropdownItem>
							);
						})}
					</OpenContainer>
				</SpacingContainer>
			}
		</StackContainer>
	);
};

export default ProfileDropdown;