import { MESSAGES } from "../constants/messages";

export const emailIsValid = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

export const versionIsValid = (version: string) => {
	const regex = /^\d+(\.\d+)*$/;
	return regex.test(version);
};

export const checkVersionAndSetSateError = (setVersionErrorState: (state: string) => void, version?: string) => {
	if (version && !versionIsValid(version)) {
		setVersionErrorState(MESSAGES.PT_BR.FIRMWARE.INVALID_VERSION);
		return false;
	} else {
		setVersionErrorState("");
		return true;
	}
};

export const isNumber = (value: string) => {
	return String(value).match(/^[0-9]*$/);
};