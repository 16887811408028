import styled from "styled-components";

interface SidebarSmallItemProps {
    enabled: boolean;
}

export const SidebarSmallItemStyled = styled.div<SidebarSmallItemProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px; 

    &:hover {
            cursor: pointer;
            opacity: 0.7;
    }
`;
