import { getApi } from ".";
import { AGPS, EPO } from "../../types/Gps";
import { GpsMapper } from "../../types/Mapper/GpsMapper";

export const getAgpsInfos = (appCode: number, smartWatchSlug: string) => {
	return new Promise<AGPS>((resolve, reject) => {
		getApi(appCode).get("/gps/agps?smartWatchSlug=" + smartWatchSlug)
			.then((response) => {
				return resolve(GpsMapper.fromApiToAgpsModel(response.data));
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const saveAgpsInfos = (agpsInfos: AGPS, appCode: number, smartWatchSlug: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/gps/agps", GpsMapper.fromAgpsModelToApi(agpsInfos, smartWatchSlug))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};


export const getEpoInfos = (appCode: number, smartWatchSlug: string) => {
	return new Promise<EPO>((resolve, reject) => {
		getApi(appCode).get("/gps/epo?smartWatchSlug=" + smartWatchSlug)
			.then((response) => {
				return resolve(GpsMapper.fromApiToEpoModel(response.data));
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const saveEpoInfos = (epoInfos: EPO, appCode: number, smartWatchSlug: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/gps/epo", GpsMapper.fromEpoModelToApi(epoInfos, smartWatchSlug))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};