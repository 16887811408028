import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FEATURES, ROUTES, SCREEN_SIZE } from "../../../constants";
import { MESSAGES } from "../../../constants/messages";
import { useTheme } from "../../../contexts/theme";
import { useMobileApp } from "../../../contexts/mobileApp";
import { AppSelectionDropdown, ConfigDropdown, ProfileDropdown } from "../components";
import { DropdownContainer, HeaderStyled } from "./styles";
import { useDevice } from "../../../contexts/device";
import { AboutModal } from "../components/ConfigDropdown/components";
import { Feature } from "../../../types/Feature";
import { useWindowDimensions } from "../../../hooks";


const configList: Feature[] = [
	{ name: MESSAGES.PT_BR.CONFIG.NEW_DEVICE, feature: FEATURES.DEVICES },
	{ name: MESSAGES.PT_BR.CONFIG.DEVICES, feature: FEATURES.DEVICES },
	{ name: MESSAGES.PT_BR.CONFIG.ADM_USERS, feature: FEATURES.ADMIN_USERS },
	{ name: MESSAGES.PT_BR.CONFIG.ABOUT, feature: null }
];

const profileList: string[] = [MESSAGES.PT_BR.PROFILE.PROFILE, MESSAGES.PT_BR.PROFILE.LOGOUT];

interface Props {
	open: boolean;
}

const MainHeader = (props: Props) => {

	const { currentTheme } = useTheme();
	const { appList, selectedApp, setSelectedApp } = useMobileApp();
	const { setAddDeviceModalVisible, setEditDeviceModalVisible } = useDevice();
	const [aboutModalVisible, setAboutModalVisible] = useState(false);
	const { width } = useWindowDimensions();

	const navigate = useNavigate();
	const location = useLocation();

	const getPageTitle = () => {
		const path = location.pathname;
		if (path.includes(ROUTES.DASHBOARD.MOBILE_USERS)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.MOBILE_USERS];
		} else if (path.includes(ROUTES.DASHBOARD.TERMS_AND_CONDITIONS)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.TERMS_AND_CONDITIONS];
		} else if (path.includes(ROUTES.DASHBOARD.PUSH_NOTIFICATIONS)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.PUSH_NOTIFICATIONS];
		} else if (path.includes(ROUTES.DASHBOARD.LOGO)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.LOGO];
		} else if (path.includes(ROUTES.DASHBOARD.FAQ)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.FAQ];
		} else if (path.includes(ROUTES.DASHBOARD.SUPPORT)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.SUPPORT];
		} else if (path.includes(ROUTES.DASHBOARD.API_KEY)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.API_KEY];
		} else if (path.includes(ROUTES.DASHBOARD.WATCH_FACES)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.WATCH_FACES];
		} else if (path.includes(ROUTES.DASHBOARD.TUTORIALS)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.TUTORIALS];
		} else if (path.includes(ROUTES.DASHBOARD.MANUAL)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.MANUAL];
		} else if (path.includes(ROUTES.DASHBOARD.FIRMWARE)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.FIRMWARE];
		} else if (path.includes(ROUTES.DASHBOARD.TRAINING)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.TRAINING];
		} else if (path.includes(ROUTES.DASHBOARD.CUSTOM_EXERCISE)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.CUSTOM_EXERCISE];
		} else if (path.includes(ROUTES.DASHBOARD.SHARE_EXERCISE)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.SHARE_EXERCISE];
		} else if (path.includes(ROUTES.DASHBOARD.GPS)) {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.GPS];
		} else {
			return MESSAGES.PT_BR.ROUTES_TITLE[ROUTES.DASHBOARD.SLEEP_SCORE];
		}
	};

	const onConfigOptionClick = (option: string) => {
		switch (option) {
		case MESSAGES.PT_BR.CONFIG.ADM_USERS:
			navigate("/" + ROUTES.SETTING + "/" + ROUTES.SETTINGS.ADMIN_USERS);
			break;
		case MESSAGES.PT_BR.CONFIG.NEW_DEVICE:
			setAddDeviceModalVisible(true);
			break;
		case MESSAGES.PT_BR.CONFIG.DEVICES:
			setEditDeviceModalVisible(true);
			break;
		case MESSAGES.PT_BR.CONFIG.ABOUT:
			setAboutModalVisible(true);
			break;
		}
	};

	return (
		<HeaderStyled theme={currentTheme} sideBarOpen={props.open}>
			<AboutModal isOpen={aboutModalVisible} onClose={() => setAboutModalVisible(false)} />

			{
				width > SCREEN_SIZE.DESKTOP &&
				<h1>{getPageTitle()}</h1>
			}
			<DropdownContainer>
				<AppSelectionDropdown
					items={appList}
					onChange={(newDevice) => { setSelectedApp(newDevice); }}
					selected={selectedApp}
				/>
				{
					width > SCREEN_SIZE.DESKTOP &&
					<ConfigDropdown
						title={MESSAGES.PT_BR.CONFIG.TITLE}
						items={configList}
						onClick={(newConfig) => { onConfigOptionClick(newConfig); }}
					/>
				}

				<ProfileDropdown
					items={profileList}
				/>
			</DropdownContainer>

		</HeaderStyled>
	);
};

export default MainHeader;