import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 25px;
`;

export const TrainingHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: blue; */
    width: 95%;
    margin-top: 2%;

    h1 {
        width: 100%;
        color: ${props => props.theme.textColor};
        
    }
    h2 {
        font-size: 30px;
        font-weight: 600;
        color: ${props => props.theme.textColor};
        justify-content: left;
        align-items: right;
        text-align: left;
        padding: 2px;
    } 
    button {
        max-width: 280px;
        height: 50px;
        font-size: 18px;
    }
`;

export const DroppableArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    margin: 20px 10px;
    width: 95%;

    > div {
        display: flex;
        width: 100%;
    }
`;

export const FakeBtn = styled.div`
    display: flex;
    margin-right: 30px;
    color: ${(props) => props.theme.textColor};

    p {
        margin-left: 10px;
    }
    
    &:hover {
        cursor: pointer;
    }
`;

export const TableHeaderStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 95%;
    margin-top: 20px;

    h3 {
        font-size: 20px;
		font-weight: 600;
		color: ${props => props.theme.textColor};
    }
`;
