
/**
 * API Object
 {
  "client_id": "string",
  "client_secret": "string",
  "refresh_token": "string"
}
 */

/**
 * App Object
 interface StravaKeys {
	clientId: string;
	clientSecret: string;
	updateToken: string;
}
 */

const fromStravaModelToApi = (stravaModel) => {
	return {
		client_id: stravaModel.clientId,
		client_secret: stravaModel.clientSecret,
		refresh_token: stravaModel.updateToken,
	};
};

const fromApiToStravaModel = (apiModel) => {
	return {
		clientId: apiModel.client_id,
		clientSecret: apiModel.client_secret,
		updateToken: apiModel.refresh_token,
	};
};

export const ApiKeysMapper = {
	fromStravaModelToApi,
	fromApiToStravaModel,
};