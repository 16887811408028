import { getApi } from ".";
import { FaqArticle, FaqTopic } from "../../types/FaqArticle";
import { FaqMapper } from "../../types/Mapper/FaqMapper";

export const getFaqArticleList = (appCode: number) => {
	return new Promise<FaqArticle[]>((resolve, reject) => {
		getApi(appCode).get("/faqs")
			.then((response) => { 
				resolve(response.data.map((faqApi) => FaqMapper.fromApiToModel(faqApi)));
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const updateFaqTheme = (appCode: number, updatedFaqArticle: FaqArticle) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put(`/faqs/updateFaqTheme?id=${updatedFaqArticle.id}`, FaqMapper.fromFaqArticleModelToApi(updatedFaqArticle))
			.then(() => { 
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const updateFaqTopic = (appCode: number, updatedFaqTopic: FaqTopic, themeIndex: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put(`/faqs/updateFaq?id=${updatedFaqTopic.topicId}`,FaqMapper.fromAppTopicToUpdatedApiObject(updatedFaqTopic, themeIndex))
			.then(() => { 
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const deleteFaqTheme = (appCode: number, faqThemeId: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`/faqs/deleteFaqTheme?id=${faqThemeId}`)
			.then(() => { 
				resolve();
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};

export const addFaqTheme = (appCode: number, name: string, index: number) => {
	return new Promise<number>((resolve, reject) => {
		getApi(appCode).post("/faqs/createFaqTheme", FaqMapper.fromAppThemeToApiTheme(name, index, "", ""))
			.then((response) => { 
				resolve(response.data.id);
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};

export const addFaqTopic = (appCode: number, faqThemeId: number, faqTopic: FaqTopic ) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/faqs/createFaq", FaqMapper.fromAppTopicToApiTopic(faqTopic, faqThemeId))
			.then(() => { 
				resolve();
			}).catch((error) => {
				console.log("Error: ", error);
				reject(error?.response?.data?.title);
			});
	});
};

export const deleteFaqTopic = (appCode: number, faqTopicId: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`/faqs/deleteFaq?id=${faqTopicId}`)
			.then(() => { 
				resolve();
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};