import { getApi } from ".";
import { NotificationsMapper } from "../../types/Mapper/NotificationsMapper";
import { Notification } from "../../types/Notification";

interface iNotificationsPage {
	token: string;
	notifications: Notification[];
}

export const getNotificationList = (appCode: number, page_size: number, page_token: string | null) => {
	const url = page_token ? `/notifications?page_size=${page_size}&page_token=${page_token}` : `/notifications?page_size=${page_size}`;
	return new Promise<iNotificationsPage>((resolve, reject) => {
		getApi(appCode).get(url)
			.then((response) => {
				resolve({token: response.data.page_token, notifications: response.data.notifications.map((notification) => NotificationsMapper.fromApiToModel(notification))});
			}).catch((error) => {
				reject(error.response?.data?.title ?? "Erro ao recuperar notificações");
			});
	});
};


export const sendNotification = (appCode: number, notification: Notification) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/notifications", NotificationsMapper.fromModelToApi(notification), {timeout: 20000})
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log("Erro ao enviar notificação", error);
				reject(error.response?.data?.title ?? "Erro ao enviar notificação");
			});
	});
};