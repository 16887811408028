/* eslint-disable indent */
import styled from "styled-components";
import { Theme } from "../../../types/Theme";

interface ITagInputProps {
  theme: Theme;
  isError?: boolean;
}

export const StackContainer = styled.div<ITagInputProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;

  p {
    width: 100%;
    text-align: right;
    font-family: "Normal";
    padding-right: 10px;
    padding-top: 5px;
    font-size: 14px;
    box-sizing: border-box;
    color: ${(props) => props.theme.textColor};
  }

  p {
    width: 100%;
    text-align: right;
    font-family: "Normal";
    padding-right: 10px;
    padding-top: 5px;
    font-size: 14px;
    box-sizing: border-box;
    color: ${props => props.theme.textColor};
  }
    
  h3 {
    font-family: "Normal";
    padding-left: 10px;
    padding-top: 5px;
    font-size: 15px;
    font-weight: 500;
    color: ${props => props.theme.textColor};
  }

  span {
    color: ${(props) => props.theme.primaryColor};
    font-size: 12px;
    margin-left: 8px;
    margin-top: 5px;
    font-family: "Normal";
  }
`;

interface InfoBoxProps {
  visible: boolean;
}

export const InfoBoxContainer = styled.div<InfoBoxProps>`
  display: ${(props) => (props.visible ? "flex" : "none")};
  /* display: flex; */
  position: absolute;
  margin-top: 130px;
`;

export const TitleContainer = styled.div<ITagInputProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
  width: fit-content;

  h1 {
    font-size: 18px;
    font-weight: 500;
    margin-right: 5px;
    color: ${(props) => props.theme.textColor};
    text-align: center;
  }
`;

export const TextAreaStyled = styled.textarea<ITagInputProps>`
  display: flex;
  align-items: flex-start;
  height: 40px;
  width: 100%;
  background-color: transparent;
  border: ${(props) =>
    props.isError ? `1px solid ${props.theme.primaryColor}` : "none"};
  outline: none;
  color: ${(props) => props.theme.textColor};
  font-size: 16px;
  background-color: ${(props) => props.theme.backgroundCardSecondary};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  resize: none;
  font-family: "Normal";
`;

export const InputStyled = styled.input`
  display: none;
`;

export const InputContainer = styled.div<ITagInputProps>`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.backgroundCardSecondary};
  width: 100%;
  height: 55px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  border: ${(props) =>
    props.isError ? `1px solid ${props.theme.primaryColor}` : "none"};

  &:hover {
    cursor: pointer;
  }
`;

export const FilenameText = styled.h5<ITagInputProps>`
  font-size: 18px;
  text-align: center;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  color: ${(props) => props.theme.textColor};
  padding: 10px;
  font-family: "Normal";
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
`;

export const FilenameContainer = styled.div`
  display: flex;
  height: 25px;
  
  a {
    font-family: "Normal";
    padding-left: 10px;
    padding-top: 5px;
    font-size: 15px;
    font-weight: 500;
  }
`;