import { getApi } from ".";
import { UserMapper } from "../../types/Mapper/UserMapper";
import { MobileUser } from "../../types/MobileUser";

interface iUserPage {
	token: string;
	users: MobileUser[];
}

export const getMobileUsers = (appCode: number, pageToken: string | null, pageSize: number) => {
	return new Promise<iUserPage>((resolve, reject) => {
		let promise = getApi(appCode).get(`/users?page_size=${pageSize}`);
		if (pageToken) {
			promise = getApi(appCode).get(`/users?page_size=${pageSize}&page_token=${pageToken}`);
		}
		promise.then((response) => {
			resolve({ token: response.data.page_token, users: response.data.users.map((user) => UserMapper.fromApiToAppModel(user) as MobileUser) });
		}).catch((error) => {
			console.log("Erro ao recuperar usuários", error);
			reject(error.response?.data?.title);
		});
	});
};

export const getUsersByFilter = (appCode: number, key: string) => {
	return new Promise<MobileUser[]>((resolve, reject) => {
		getApi(appCode).get(`/users?page_size=10&filter=${key}`)
			.then((response) => {
				resolve(response.data.users.map((user) => UserMapper.fromApiToAppModel(user) as MobileUser));
			}).catch((error) => {
				console.log("Erro ao recuperar usuários", error);
				reject(error.response?.data?.title);
			});
	});
};


export const sendResetPasswordEmail = (appCode: number, email: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/user/recover-password", { email })
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log("Erro ao enviar email", error.response?.data?.title);
				reject(error.response?.data?.title);
			});
	});
};


export const sendConfirmationEmail = (appCode: number, email: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/user/confirmations/resend", { email })
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log("Erro ao enviar email", error.response?.data?.title);
				reject(error.response?.data?.title);
			});
	});
};

export const deleteUser = (appCode: number, email: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`/admin/app-users?email=${email}`)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log("Erro ao deletar usuário", error.response?.data?.title);
				reject(error.response?.data?.title);
			});
	});
};