import styled from "styled-components";

interface ContainerProps {
    isDragging: boolean
}
 
export const StackContainer = styled.div<ContainerProps>`
    width: 100%;
    background-color: ${props => props.theme.backgroundCardSecondary};
    box-shadow:  ${props => props.isDragging ? "0px 0px 10px 0px rgba(0,0,0,0.75)" : "none"};
    border-radius: 16px;
    margin-bottom: 10px;
`;
 
export const ItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 104px;
    padding: 10px;
    box-sizing: border-box;
`;
 
export const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    &:hover {
        cursor: pointer;
    }
`;
 
export const WorkoutImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

   img {
        height: 88px;
        width: 88px;
        border-radius: 16px;
        object-fit: cover;
   }
`;

export const TextContainer = styled.div`
    width: 100%;
 
    p {
        color: ${props => props.theme.textColor};
        font-size: 18px;
        font-weight: 520;
        margin-left: 5%;
    }
`;
