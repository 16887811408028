import React, { createContext, useContext } from "react";
import { Api } from "../services";
import { Notification } from "../types/Notification";

interface INotificationContextData {
	getNotificationList: (appCode: number) => Promise<Notification[]>;
	sendNotification: (appCode: number, notification: Notification, image: File) => Promise<void>;
}

interface NotificationProviderProps {
	children: React.ReactNode;
}

export const NotificationContext = createContext({} as INotificationContextData);

const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {

	const PAGE_SIZE = 10;
	const S3_FOLDER = "notifications";

	const getNotificationList = (appCode: number) => {
		return new Promise<Notification[]>((resolve, reject) => {
			Api.Notification.getNotificationList(appCode, PAGE_SIZE, null) //TODO: Paginacao
				.then((response) => {
					resolve(response.notifications);
				}).catch((error) => {
					console.log("error");
					reject(error);
				});
		});
	};

	const sendNotification = (appCode: number, notification: Notification, image: File) => {
		let imagePromise: Promise<string>;
		return new Promise<void>((resolve, reject) => {
			if (image.size) imagePromise = Api.AWS.sendOrUpdateFileToS3(appCode, image, S3_FOLDER);
			Promise.all([imagePromise]).then(urls => {
				if (urls[0]) notification.image = urls[0];
				Api.Notification.sendNotification(appCode, notification)
					.then(() => resolve())
					.catch(apiError => reject(apiError));
			}).catch(s3Error => {
				reject(s3Error);
			});
		});
	};


	return (
		<NotificationContext.Provider
			value={{
				getNotificationList,
				sendNotification
			}}>
			{children}
		</NotificationContext.Provider>
	);
};

const useNotification = () => {
	const context = useContext(NotificationContext);

	return context;
};

export { NotificationProvider, useNotification };
