import styled from "styled-components";
import { THEME_TYPES } from "../../../../../../constants";
import { Theme } from "../../../../../../types/Theme";

interface ContainerProps {
    theme: Theme
}

export const ResultTableContainer = styled.div<ContainerProps>`
   
    width: 100%;
    border-radius: 20px;
    margin-top: 10px;
    background-color: ${props => props.theme.backgroundCard};
    padding-bottom: 20px;

    table {
        padding-left: 2%;
        padding-right: 2%;
        width: 100%;
        margin-bottom: 20px;
    }
`;

export const LineTr = styled.tr<ContainerProps>`

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        hr {
        width: 90%;
        opacity: 0.5;
        border: 1px solid ${props => props.theme.borderColor};
        justify-content: center;
        align-items: center;
    }
    }
`;

export const Line = styled.div<ContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.type === THEME_TYPES.DARK ? "rgba(245, 246, 247, 0.1)" : "#00222222"};
    width: 95%;
    margin-left: 1%;
    height: 1px;
    border-radius: 50px;
`;