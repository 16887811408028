import React, { useEffect, useState } from "react";
import { Loader, StatusModal, DeleteItemModal } from "../../../components";
import { TextIconButton } from "../../../components/Buttons";
import { MESSAGES } from "../../../constants/messages";
import { useAdminUser } from "../../../contexts/adminUser";
import { useTheme } from "../../../contexts/theme";
import { AdminUser, Role } from "../../../types/AdminUser";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusIcon.svg";
import { ResultTable, AddUserModal, EditUserModal } from "./components";
import { MainContainer, CardContainer, Header } from "./styles";
import { updateAdminUser } from "../../../services/api/AdminUser";
import { useMobileApp } from "../../../contexts/mobileApp";
import { toast } from "react-hot-toast";
import { canManage } from "../../../services/auth/PermissionGate";
import { FEATURES } from "../../../constants";

const AdminUsers = () => {

	const { currentTheme } = useTheme();
	const { getAdminUsers, deleteAdminUser, currentUser } = useAdminUser();
	const { selectedApp } = useMobileApp();

	const emptyAdminUser = {
		id: "",
		email: "",
		name: "",
		photo: "",
		token: "",
		role: Role.NONE
	};

	const [adminUsers, setAdminUsers] = useState<AdminUser[] | null>(null);
	const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
	const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
	const [editingdUser, setEditingUser] = useState<AdminUser>(emptyAdminUser); // Guarda usuário sendo editado ou deletado
	const [modalStatus, setModalStatus] = useState({ isOpen: false, title: "", description: "", success: false });

	const reloadList = () => {

		getAdminUsers(selectedApp.appCode)
			.then((users) => {
				if (users) {
					setAdminUsers(users.filter((user) => user.id !== currentUser.id));
				}
			})
			.catch((errorMessage) => {
				console.log("erro request");
				console.log(errorMessage);
			});
	};

	useEffect(() => {
		reloadList();
	}, [addModalVisible,deleteModalVisible, editModalVisible]);

	const openEditUserModal = (user: AdminUser) => {
		setEditingUser(user);
		setEditModalVisible(true);
	};

	const onEditRequested = (user: AdminUser, newRole: string) => {
		updateAdminUser(1, user.id , newRole);
		setModalStatus({
			isOpen: true,
			title: MESSAGES.PT_BR.USER_MESSAGES.ROLE_EDIT_FEEDBACK_TITLE,
			description: MESSAGES.PT_BR.USER_MESSAGES.ROLE_EDIT_FEEDBACK_DESCRIPTION,
			success: true,
		});

		setTimeout(() => {
			setEditModalVisible(false);
			setModalStatus({ ...modalStatus, isOpen: false });
		}, 3000);
	};


	const openDeleteUserModal = (user: AdminUser) => {
		setEditingUser(user);
		setDeleteModalVisible(true);
	};

	const onDeleteRequested = () => {
		const t = toast.loading(MESSAGES.PT_BR.USER_MESSAGES.DELETE_USER_TOAST_LOADING);
		deleteAdminUser(selectedApp.appCode, editingdUser.id)
			.then(() => {
				handleFeedback({
					isOpen: true,
					description: MESSAGES.PT_BR.USER_MESSAGES.DELETE_USER_FEEDBACK_DESCRIPTION,
					success: true,
					title: MESSAGES.PT_BR.USER_MESSAGES.DELETE_USER_FEEDBACK_TITLE,
				});
			}).catch((err) => {
				handleFeedback({
					isOpen: true,
					description: MESSAGES.PT_BR.USER_MESSAGES.DELETE_USER_FEEDBACK_DES_ERROR + " " + err,
					success: false,
					title: MESSAGES.PT_BR.USER_MESSAGES.DELETE_USER_FEEDBACK_ERROR,
				});
			}).finally(() => {
				setDeleteModalVisible(false);
				toast.dismiss(t);
			});
	};

	const showResults = () => {
		if (adminUsers && adminUsers.length > 0) {
			return <ResultTable
				result={adminUsers}
				onClickEdit={openEditUserModal}
				onClickDelete={openDeleteUserModal}
			/>;
		} else if (adminUsers?.length === 0) {
			return (
				<CardContainer theme={currentTheme}>
					<p>Nenhum usuário encontrado</p>
				</CardContainer>
			);
		}
		return (
			<CardContainer theme={currentTheme}>
				<Loader />
			</CardContainer>
		);
	};

	const handleFeedback = (feedback) => {

		if (feedback) {
			setModalStatus(feedback);
			setTimeout(() => {
				setModalStatus({ ...modalStatus, isOpen: false });
			}, 3000);
		}
	};

	return (
		<MainContainer theme={currentTheme}>
			<StatusModal
				isOpen={modalStatus.isOpen}
				description={modalStatus.description}
				success={modalStatus.success}
				title={modalStatus.title}
			/>
			<AddUserModal isOpen={addModalVisible} onCancel={() => setAddModalVisible(false)} onFeedbackRequested={handleFeedback} />
			<EditUserModal isOpen={editModalVisible} onCancel={() => setEditModalVisible(false)} user={editingdUser} onEditRequested={onEditRequested} />
			<DeleteItemModal
				isOpen={deleteModalVisible}
				onCancel={() => setDeleteModalVisible(false)}
				title={MESSAGES.PT_BR.USER_MESSAGES.DELETE_USER_MODAL_TITLE}
				body={MESSAGES.PT_BR.USER_MESSAGES.DELETE_USER_MODAL_DESCRIPTION}
				handleDelete={onDeleteRequested}
			/>
			<Header theme={currentTheme}>
				<h1>{MESSAGES.PT_BR.USER_MESSAGES.WEBADMIN_USERS_HEADER}</h1>
				<TextIconButton
					text={MESSAGES.PT_BR.USER_MESSAGES.NEW_USER}
					onClick={() => setAddModalVisible(true)}
					svgImage={<PlusIcon style={{ "width": "100%", "height": "100%" }} />}
					enabled={canManage(currentUser.role, FEATURES.ADD_ADMIN_USER)}
				/>
			</Header>
			{showResults()}
		</MainContainer>
	);
};

export default AdminUsers;