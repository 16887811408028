import React from "react";
import { useTheme } from "../../../../../../contexts/theme";
import { MobileUser } from "../../../../../../types/MobileUser";
import { TableHeader, TableItem } from "./components";
import { Line, LineTr, ResultTableContainer } from "./styles";

interface TableProps {
	result: MobileUser[];
	onDeleteRequest: (mobileUser: MobileUser) => void;
	onResetPasswordRequest: (mobileUser: MobileUser) => void;
	onSendEmailConfirmationRequest: (mobileUser: MobileUser) => void;
}


const ResultTable = (props: TableProps) => {

	const { currentTheme } = useTheme();

	return (
		<ResultTableContainer theme={currentTheme}>
			<table>
				<thead>
					<TableHeader />
				</thead>
				<tbody>
					{
						props.result.map((user) => (
							<React.Fragment key={user.id}>
								<TableItem
									user={user}
									onDeleteRequest={props.onDeleteRequest}
									onResetPasswordRequest={props.onResetPasswordRequest}
									onSendEmailConfirmationRequest={props.onSendEmailConfirmationRequest}
								/>
								<LineTr theme={currentTheme}>
									<td colSpan={5}>
										<Line theme={currentTheme}/>
									</td>
								</LineTr>
							</React.Fragment>
						))
					}
				</tbody>
			</table>
		</ResultTableContainer>
	);
};

export default ResultTable;