import { createGlobalStyle } from "styled-components";
import { Theme } from "../../types/Theme";

interface IBackgroundStyleProps {
  theme: Theme
}

const GlobalStyle = createGlobalStyle<IBackgroundStyleProps>`
  body {
    background-color: ${props => props.theme.backgroundPage};
  }

`;

export default GlobalStyle;