import styled from "styled-components";

interface ContainerProps {
	canManage: boolean;
}

export const TableItemStyled = styled.tr<ContainerProps>`
	td {
		color: ${props => props.theme.textColor};
		justify-content: center;
		align-items: center;
		text-align: center;
		font-family: "Normal";
		height: 45px;

		> div {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			opacity: ${props => props.canManage ? 1 : 0.5};
		}
	}
`;

export const ImgContainer = styled.div`
	margin-left: 10px;
	margin-right: 10px;
`;
