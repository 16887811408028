import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { COLORS } from "../../../../../../assets";
import { StatusModal } from "../../../../../../components";
import { MainButton } from "../../../../../../components/Buttons";
import { MainInput } from "../../../../../../components/Inputs";
import { MESSAGES } from "../../../../../../constants/messages";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { Container, ContentFooter } from "./styles";
import { EPO } from "../../../../../../types/Gps";
import { useGps } from "../../../../../../contexts/gps";
import { useDevice } from "../../../../../../contexts/device";

const EpoContent = () => {

	const [modalStatus, setModalStatus] = useState({ isOpen: false, title: MESSAGES.PT_BR.GPS.SAVE_FEEDBACK, description: MESSAGES.PT_BR.GPS.EOP_FEEDBACK, success: true });

	const [epoInfos, setEpoInfos] = useState<EPO>({} as EPO);
	const [c3FileError, setC3FileError] = useState<string>("");
	const [g3FileError, setG3FileError] = useState<string>("");
	const [e3FileError, setE3FileError] = useState<string>("");
	const [j3FileError, setJ3FileError] = useState<string>("");
	
	const { getEpoInfos, saveEpoInfos } = useGps();
	const { selectedApp } = useMobileApp();
	const { currentDevice } = useDevice();

	useEffect(() => {
		resetStates();
		getEpoInfos(selectedApp?.appCode ?? 0, currentDevice?.slug ?? "")
			.then((epoInfos) => {
				setEpoInfos(epoInfos);
			}).catch(() => {
				setEpoInfos({
					c3File: "",
					g3File: "",
					e3File: "",
					j3File: ""
				} as EPO);
			});
	}, [selectedApp]);

	const resetStates = () => {
		setC3FileError("");
		setG3FileError("");
		setE3FileError("");
		setJ3FileError("");
		setEpoInfos({} as EPO);
	};

	const handleSaveEpo = () => {
		if(epoInfos == null) return;

		let allRight = true;

		if(epoInfos?.c3File?.length === 0) {
			setC3FileError(MESSAGES.PT_BR.GPS.MANDATORY_FIELD);
			allRight = false;
		}else {
			setC3FileError("");
		}

		if(epoInfos?.g3File?.length === 0) {
			setG3FileError(MESSAGES.PT_BR.GPS.MANDATORY_FIELD);
			allRight = false;
		}else {
			setG3FileError("");
		}

		if(epoInfos?.e3File?.length === 0) {
			setE3FileError(MESSAGES.PT_BR.GPS.MANDATORY_FIELD);
			allRight = false;
		} else {
			setE3FileError("");
		}

		if(epoInfos?.j3File?.length === 0) {
			setJ3FileError(MESSAGES.PT_BR.GPS.MANDATORY_FIELD);
			allRight = false;
		} else {
			setJ3FileError("");
		}

		if(!allRight) return;

		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		
		saveEpoInfos(epoInfos, selectedApp?.appCode ?? 0, currentDevice?.slug ?? "")
			.then(() => {
				setModalStatus({ ...modalStatus, isOpen: true });
			}).catch((error) => {
				if(!error || error.length === 0)
					error = MESSAGES.PT_BR.GPS.GENERIC_ERROR;
				toast.error(error);
			}).finally(() => {
				toast.dismiss(t);
				setTimeout(() => {
					setModalStatus({ ...modalStatus, isOpen: false });
				}, 3000);
			});
	};


	return (
		<Container>
			<StatusModal
				isOpen={modalStatus.isOpen}
				description={modalStatus.description}
				success={modalStatus.success}
				title={modalStatus.title}
			/>
			<MainInput
				title={MESSAGES.PT_BR.GPS.EPO_C3}
				value={epoInfos?.c3File ?? ""}
				onChange={(value) => { setEpoInfos({ ...epoInfos, c3File: value }); }}
				placeholder={MESSAGES.PT_BR.GPS.EPO_C3_PLACEHOLDER}
				type="text"
				style={{ marginBottom: 28 }}
				errorText={c3FileError}
				isLoading={epoInfos?.c3File == undefined}
			/>
			<MainInput
				title={MESSAGES.PT_BR.GPS.EPO_E3}
				value={epoInfos?.e3File ?? ""}
				onChange={(value) => { setEpoInfos({ ...epoInfos, e3File: value }); }}
				placeholder={MESSAGES.PT_BR.GPS.EPO_E3_PLACEHOLDER}
				type="text"
				style={{ marginBottom: 28 }}
				errorText={e3FileError}
				isLoading={epoInfos?.e3File == undefined}
			/>
			<MainInput
				title={MESSAGES.PT_BR.GPS.EPO_G3}
				value={epoInfos?.g3File ?? ""}
				onChange={(value) => { setEpoInfos({ ...epoInfos, g3File: value }); }}
				placeholder={MESSAGES.PT_BR.GPS.EPO_G3_PLACEHOLDER}
				type="text"
				style={{ marginBottom: 28 }}
				errorText={g3FileError}
				isLoading={epoInfos?.g3File == undefined}
			/>
			<MainInput
				title={MESSAGES.PT_BR.GPS.EPO_J3}
				value={epoInfos?.j3File ?? ""}
				onChange={(value) => { setEpoInfos({ ...epoInfos, j3File: value }); }}
				placeholder={MESSAGES.PT_BR.GPS.EPO_J3_PLACEHOLDER}
				type="text"
				errorText={j3FileError}
				isLoading={epoInfos?.j3File == undefined}
			/>
			<ContentFooter>
				<MainButton
					enabled
					onClick={() => { handleSaveEpo(); }}
					text={MESSAGES.PT_BR.GPS.SAVE}
					darkColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
					lightColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
				/>
			</ContentFooter>
		</Container>
	);
};

export default EpoContent;