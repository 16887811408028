import styled from "styled-components";
import { Theme } from "../../../../types/Theme";

export const StackContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

interface ContainerProps {
    theme: Theme
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    flex-direction: row;
    border: 2px solid ${props => props.theme.textColor};
    border-radius: 15px;
    height: 50px;
    width: 180px;
    padding: 0 5px;
    margin: 0 10px;


    &:hover {
        cursor: pointer;
    }

    img {
        height: 30px;
        width: 30px;
        object-fit: cover;
        border-radius: 5px;
    }

    > div {
        display: flex;
        width: 15%;
    }

    p {
        color: ${props => props.theme.textColor};
    }
`;


export const SpacingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: 53px;
    width: 200px;
    padding-top: 10px;
`; 

export const OpenContainer = styled.div <ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.backgroundCard};
    width: 180px;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
`;

export const AddAppItem = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    box-sizing: border-box;

    div {
        display: flex;
        box-sizing: border-box;
        height: 30px;
        width: 30px;
        margin-left: 5px;
    }

    h4 {
        color: ${props => props.theme.textColor};
        font-size: 16px;
        font-weight: 400;
        height: 100%;
        width: 100%;
        margin-left: 10px;
        font-family: "Normal";
    }

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }

`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    box-sizing: border-box;
`;
