const fromSupportModelToApi = (recipientEmail) => {
	return {
		support_email: recipientEmail,
	};
};


const getTestEmail = (fromEmail) => {
	return {
		"subject": "E-mail de teste do webAdmin: " + fromEmail,
		"reply_to": fromEmail,
		"body": "Você está recebendo esse e-mail porque uma solicitação de envio foi feita pelo webAdmin.",
		"metadata": "Metadados do e-mail de teste"
	};
};

export const SupportMapper = {
	fromSupportModelToApi,
	getTestEmail
};
