export interface MobileUser {
    id: number;
    name: string;
    email: string;
    genre?: GENRE;
    emailConfirmedAt?: string;
}

export enum GENRE {
    MALE = "m",
    FEMALE = "f",
    OTHER = "o",
}