import styled from "styled-components";

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: visibility 0s, opacity 0.3s linear;
`;

interface ContentProps {
    hasImage?: boolean,
}

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    min-width: 800px;
    max-width: 1000px;
    height: 500px;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
`;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h1 {
        font-size: 28px;
        padding: 10px;
        text-align: center;
        font-weight: 600;
        line-height: 30px;
        color: ${props => props.theme.primaryColor};
    }

    div {
        &:hover {
            cursor: pointer;
        }
    }
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    flex: 1;
`;

export const ModalFooter = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;

    button {
        width: 25%;
        margin: 0;
    }

    p{
        height: 100%;
        line-height: 50px;
        margin-right: 20px;
        text-decoration: underline;
        color: ${props => props.theme.textColor};

        &:hover {
            cursor: pointer;
        }
    }

`;

export const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    flex: 0.5;
`;

export const FilesContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    div {
        margin-left: 0;
        margin-right: 0;
    }

    h5{
       margin: 15px; 
    }

    h6 {
        font-family: "Normal";
        font-size: 14px;
        margin-top: 10px;
        color: ${props => props.theme.textColor};
    }
`;

export const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0.5;
    justify-content: center;
    align-items: center;
`;

export const ImgContainer = styled.div<ContentProps>`
    display: flex;
    background-color: ${({ theme, hasImage }) => hasImage ? "transparent" : theme.backgroundCardSecondary};
    width: 170px;
    height: 200px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    margin-bottom: 8%;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 16px;
    }
`;

export const ToogleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
`;