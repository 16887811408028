import styled from "styled-components";
import { THEME_TYPES } from "../../../constants";
import { Theme } from "../../../types/Theme";

interface ButtonStyledProps {
    theme?: Theme;
    enabled?: boolean;
    border?: boolean;
    darkColor?: string;
    lightColor?: string;
}

const getBackgroundColor = (props: ButtonStyledProps) => {
	if(props?.darkColor && props.theme?.type == THEME_TYPES.DARK) {
		return props.darkColor;
	}else if(props?.lightColor && props.theme?.type == THEME_TYPES.LIGHT) {
		return props.lightColor;
	}else if(props?.theme?.type == THEME_TYPES.DARK) {
		return props.theme?.primaryColor;
	}else {
		return props.theme?.secondaryColor;
	}
};

const getColor = (props: ButtonStyledProps) => {
	if (props.border) {
		return props.theme?.textColor;
	} else if (props.theme?.type === THEME_TYPES.DARK) {
		return props.theme.textColor;
	} else {
		return props.theme?.secondaryTextColor;
	}
};

export const ButtonStyled = styled.button<ButtonStyledProps>`
    width: 100%;
    height: 50px;
    border: ${props => props.border ? `3px solid ${props.theme?.textColor}` : "none"};
    border-radius: 16px;
    background-color: ${props => getBackgroundColor(props)};
    font-size: 20px;
    color: ${props => getColor(props)};
    margin-top: 4%;
    opacity: ${props => props.enabled ? 1 : 0.5};


    &:hover {
        cursor: ${props => props.enabled ? "pointer" : "default"};
        opacity: ${props => props.enabled ? 0.8 : 0.5};
    }
`;