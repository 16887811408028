export const TRAINING_MESSAGES = {
	WORKOUTS_TITLE: "Treinos",
	NEW_WORKOUT: "Novo treino",
	NEW_WORKOUT_VIDEO: "Nova aula",
	NAME: "Nome",
	NAME_PLACEHOLDER: "Digite o nome",
	ACTIVITY: "Atividade",
	ICON: "Ícone",
	ICON_SELECTED: "Ícone Selecionado",
	ICON_SELECTED_HELP: "Ícone exibido quando o treino atual é selecionado",
	ADD: "Adicionar",
	EDIT_WORKOUT: "Editar treino",
	SAVE: "Salvar",
	MANDATORY_FIELD: "Campo obrigatório",
	LOADING: "Carregando...",
	LOADING_SUCCESS: "Carregado com sucesso!",
	LOADING_ERROR: "Erro ao carregar imagem",

	ADD_SUCCESS: "Adicionado com sucesso!",
	EDIT_SUCCESS: "Salvo com sucesso!",
	EDIT_DESCRIPTION: "A edição foi salva com sucesso!",
	ADD_WORKOUT_DESCRIPTION: "O treino foi adicionada com sucesso!",
	ADD_WORKOUT_VIDEO_DESCRIPTION: "A aula foi adicionada com sucesso!",

	ADD_ERROR: "Erro ao adicionar",
	ADD_ERROR_WORKOUT_DESCRIPTION: "Ocorreu um erro ao adicionar o treino: ",
	ADD_ERROR_WORKOUT_VIDEO_DESCRIPTION: "Ocorreu um erro ao adicionar a aula: ",

	EDIT_ERROR: "Erro ao editar",
	EDIT_ERROR_DESCRIPTION: "Ocorreu um erro ao editar o treino: ",

	DELETE_SECTION_TITLE: "Tem certeza que deseja excluir o treino?",
	DELETE_SECTION_DESCRIPTION: "Caso você exclua o treino, todos as aulas contidas nesse treino também serão excluídas. Caso não queira perder as aulas mude-as de treino antes de concluir a exclusão.",
	DELETE_VIDEO_TITLE: "Tem certeza que deseja excluir a aula?",
	DELETE_VIDEO_DESCRIPTION: "Caso você exclua essa aula, a mesma não poderá ser resgatada.",
	DELETE: "Tenho certeza, excluir.",
	CANCEL: "Não tenho certeza, cancelar.",

	DELETE_SUCCESS: "Excluído com sucesso!",
	DELETE_SUCCESS_DESCRIPTION: "O item foi excluído com sucesso!",
	DELETE_ERROR: "Erro ao excluir",
	DELETE_ERROR_DESCRIPTION: "Ocorreu um erro ao excluir o item: ",

	BEGINNER: "Iniciante",
	INTERMEDIATE: "Intermediário",
	ADVANCED: "Avançado",
	LEVEL_ARRAY: ["Iniciante", "Intermediário", "Avançado"],

	TH_EP: "Ep",
	TH_DESCRIPTION: "Descrição",
	TH_SHOW_ON_APP: "Mostrar no app",
	TH_ACTIONS: "Ações",

	ADD_NEW_WORKOUT_VIDEO: "Adicionar aula",
	LEVEL: "Dificuldade",
	EP: "Episódio",
	DESCRIPTION: "Descrição",
	LINK: "Link do vídeo",
	LINK_PLACEHOLDER: "Cole o link do vídeo",
	THUMBNAIL: "Thumbnail",
	ADD_BTN: "Adicionar",
	KEEP_ADDING: "Adicionar e criar nova aula",

	EDIT_WORKOUT_VIDEO: "Editar aula",
};

