import styled from "styled-components";
import { Theme } from "../../../../../../../../types/Theme";

interface ContainerProps {
    theme: Theme
}

export const TableItemStyled = styled.tr<ContainerProps>`

    td {
        color: ${props => props.theme.textColor};
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: "Normal";

        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;

            button {
                font-size: 14px;
                padding: 2px;
                border-radius: 10px;
                margin-left: 5px;
                margin-right: 5px;
                margin-top: 5px;
                margin-bottom: 5px;
                height: 45px;
                font-weight: 100;
                max-width: 100px;
                line-height: 19px;
            }
        }
    }
`;
