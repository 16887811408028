import React, { useState } from "react";
import { MESSAGES } from "../../../../../constants/messages";
import { ReactComponent as CloseIcon } from "../../../../../assets/images/closeIcon.svg";
import { MainInput } from "../../../../../components/Inputs";
import { MainButton } from "../../../../../components/Buttons";
import Dropdown from "../../../../../components/Dropdown/MainDropdown";
import { AdminUser, Role, RolesList } from "../../../../../types/AdminUser";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader } from "./styles";
import { useAdminUser } from "../../../../../contexts/adminUser";

interface Props {
	user: AdminUser;
	isOpen: boolean;
	onEditRequested: (user: AdminUser, newRole: string) => void;
	onCancel: () => void;
}

const EditUserModal = (props: Props) => {

	const { currentUser } = useAdminUser();
	const [selectedRole, setSelectedRole] = useState<string>("");

	return (
		<ModalContainer isOpen={props.isOpen}>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.USER_MESSAGES.EDIT_USER_MODAL_TITLE}</h1>
					<div>
						<CloseIcon onClick={props.onCancel} />
					</div>
				</ModalHeader>
				<ModalBody>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.USER_MESSAGES.NAME}
						value={props.user.name}
						placeholder={MESSAGES.PT_BR.USER_MESSAGES.NAME_PLACEHOLDER}
						onChange={() => null}
						style={{ marginBottom: "10px" }}
						disabled
					/>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.USER_MESSAGES.EMAIL}
						value={props.user.email}
						placeholder={MESSAGES.PT_BR.USER_MESSAGES.EMAIL_PLACEHOLDER}
						onChange={() => null}
						style={{ marginBottom: "10px" }}
						disabled
					/>
					<Dropdown 
						title={MESSAGES.PT_BR.USER_MESSAGES.ROLE}
						items={ currentUser.role === Role.OWNER ? RolesList : RolesList.filter((role) => role !== Role.OWNER) } //Apenas Owner pode adicionar Owner
						onChange={setSelectedRole}
						selected={selectedRole || props.user.role}
					/>
					<ModalFooter>
						<MainButton enabled onClick={() => {
							props.onEditRequested(props.user, selectedRole);
						}} text={MESSAGES.PT_BR.USER_MESSAGES.SAVE_BTN} />
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default EditUserModal;
