import { getApi } from ".";

export const getPrivacy = (appCode: number) => {
	return new Promise<string>((resolve, reject) => {
		getApi(appCode).get("/privacy_policy")
			.then((response) => {
				resolve(response.data);
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const getUseTerms = (appCode: number) => {
	return new Promise<string>((resolve, reject) => {
		getApi(appCode).get("/term_and_condition")
			.then((response) => {
				resolve(response.data);
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const getConsentTerms = (appCode: number) => {
	return new Promise<string>((resolve, reject) => {
		getApi(appCode).get("/consent_term")
			.then((response) => {
				resolve(response.data);
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const savePrivacy = (htmlPage: string, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		const requestBody = {
			privacyPolicy: htmlPage
		};

		getApi(appCode).post("/privacy_policy_create_update", requestBody)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const saveUseTerms = (htmlPage: string, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		const requestBody = {
			termsConditions: htmlPage
		};

		getApi(appCode).post("/term_and_condition_create_update", requestBody)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const saveConsentTerms = (htmlPage: string, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		const requestBody = {
			consentTerms: htmlPage
		};

		getApi(appCode).post("/consent_term_create_update", requestBody)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const getTermsVersion = (appCode: number) => {
	return new Promise<number>((resolve, reject) => {
		getApi(appCode).get("/legal_infos")
			.then((response) => {
				resolve(Number.parseInt(response.data.version));
			}).catch((error) => {
				console.log("error.response.status: " + error?.response?.status);
				if (error?.response?.status === 404) {
					console.log("Não existe versão de termos cadastrada.");
					resolve(0);
					return;
				}

				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const saveTermsVersion = (version: number, description: string, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		const requestBody = {
			version: version.toString(),
			description: description
		};

		getApi(appCode).post("/legal_infos", requestBody)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};
