import React, { useRef } from "react";
import { ReactComponent as EditIconLight } from "../../../assets/images/light/editIcon.svg";
import { ReactComponent as EditIconDark } from "../../../assets/images/dark/editIcon.svg";
import { useTheme } from "../../../contexts/theme";
import { THEME_TYPES } from "../../../constants";
import { ImgContainer, RoudedBtnContainer, InputStyled } from "./styles";

interface ModalProps {
	onSelectImage: (value: File) => void;
	image?: string;
	withCssImage?: boolean;
	style?: React.CSSProperties;
	imgStyle?: React.CSSProperties;
	editBtnStyle?: React.CSSProperties;
	defaultImageComponent: React.ReactNode;
}

const ImageInputWithPreview = ({ withCssImage = true, ...props }: ModalProps) => {

	const { currentTheme } = useTheme();
	const inputRef = useRef<HTMLInputElement>(null);

	const handleOnChange = () => {
		if(inputRef?.current?.files?.[0]){
			props.onSelectImage(inputRef?.current?.files?.[0]);
		}
	};

	return (
		<ImgContainer theme={currentTheme} style={props.style} withCssImage={withCssImage}>
			<RoudedBtnContainer theme={currentTheme} onClick={() => inputRef?.current?.click()} style={props.editBtnStyle} >
				<InputStyled onChange={handleOnChange} ref={inputRef} type="file" accept="image/png, image/jpeg" />
				{currentTheme.type == THEME_TYPES.DARK ? <EditIconDark /> : <EditIconLight />}
			</RoudedBtnContainer>
			{props.image ?
				<img src={props.image} style={props.imgStyle} />
				:
				props.defaultImageComponent
			}
		</ImgContainer>
	);

};

export default ImageInputWithPreview;