/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import { useTheme } from "../../../contexts/theme";
import { DateInputStyled, InputContainer, StackContainer, TitleContainer } from "./styles";
import { AiOutlineCalendar } from "react-icons/ai";
import moment from "moment";


interface MainInputProps {
    value: string | undefined;
    onChange: (value: string) => void;
    errorText?: string;
    title?: string;
    style?: React.CSSProperties;
}

const DateInput = (props: MainInputProps) => {

	const { currentTheme } = useTheme();

	const handleDateChange = (newDate: string) => {
		const parsedDate = moment(newDate).toISOString();
		props.onChange(parsedDate);
	};

	return (
		<StackContainer theme={currentTheme} style={props.style} >
			{props.title &&
                <TitleContainer theme={currentTheme}>
                	<h1>{props.title}</h1>
                </TitleContainer>
			}
			<InputContainer theme={currentTheme}>
				<DateInputStyled onChange={(newDate) => handleDateChange(newDate.target.value)}
					value={props.value}
					type={"date"} 
					theme={currentTheme}
					min={moment().format("YYYY-MM-DD")}
				/>
				<AiOutlineCalendar color={currentTheme.textColor} size={30} />
			</InputContainer>
		</StackContainer>
	);
};

export default DateInput;