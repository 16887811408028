import React from "react";
import Toggle from "react-toggle";
import { useTheme } from "../../contexts/theme";
import "./styles.css";

interface ToggleProps {
	defaultChecked: boolean;
	onChange: () => void;
	leftLabel?: string;
	rightLabel?: string;
	checked?: boolean;
	disabled?: boolean;
}


const TechnosToggle = (props: ToggleProps) => {
	const { currentTheme } = useTheme();

	return (
		<label className="toggleContainer" style={{marginBottom: "3px", width: "100%", opacity: props?.disabled? 0.5 : 1}}>
			<span style={{marginRight: "10px", color: currentTheme.textColor }}>{props.leftLabel}</span>
			<Toggle
				defaultChecked={props.defaultChecked}
				icons={false}
				onChange={() => !props.disabled && props.onChange()}
				checked={props.checked ?? false}
			/>
			{props.rightLabel && <span style={{marginLeft: "10px", color: currentTheme.textColor }}>{props.rightLabel}</span> }
		</label>
	);
};

export default TechnosToggle;