import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, RowContainer } from "./styles";
import { Exercise } from "../../../../../../types/Exercise";

interface Props {
	isOpen: boolean;
	onCancel: () => void;
    currentExercise: Exercise;
	handleEditExercise: (exercise: Exercise, lastExerciseName: string) => void;
}

const EditCustomExerciseModal = (props: Props) => {

	const { currentTheme } = useTheme();
	const [newExercise, setNewExercise] = useState<Exercise>(props.currentExercise);
	const [errorField, setErrorField] = useState<string>("");
	const lastExerciseName = props.currentExercise.name;

	const handleEditExercise = () => {
		if (!newExercise.name || newExercise.name?.length === 0) {
			setErrorField(MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.MANDATORY_FIELD);
			return;
		} else {
			props.handleEditExercise(newExercise, lastExerciseName);
			resetStates();
		}
	};

	const resetStates = () => {
		setNewExercise({} as Exercise);
		setErrorField("");
	};

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.EDIT_EXERCISE}</h1>
					<div>
						<CloseIcon onClick={() => {props.onCancel(); resetStates();}} />
					</div>
				</ModalHeader>
				<ModalBody>
					<RowContainer>
						<MainInput
							type="text"
							title={""}
							value={newExercise.name ?? ""}
							placeholder={MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.NEW_EXERCISE_PLACEHOLDER}
							onChange={(newValue) => setNewExercise({ ...newExercise, name: newValue })}
							errorText={errorField}
						/>
					</RowContainer>
					<ModalFooter theme={currentTheme}>
						<MainButton enabled onClick={() => handleEditExercise()} text={MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.SAVE} />
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default EditCustomExerciseModal;
