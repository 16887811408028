import React from "react";
import { CheckBoxContainer, SubOptionContainer } from "./styles";

export interface OptionBaseProps {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
}

interface CheckBoxProps extends OptionBaseProps {
    subOptions?: OptionBaseProps[];
}

const MainCheckBox = (props: CheckBoxProps) => {

	return (
		<CheckBoxContainer hasSubOptions={props.subOptions && props.subOptions.length > 0}>
			<label>
				<input type="checkbox" checked={props.checked} onChange={() => props.onChange(!props.checked)} />
				{props.label}
			</label>
			<SubOptionContainer>
				{props.subOptions && props.subOptions.map((subOption, index) => (
					<label key={index}>
						<input type="checkbox" checked={subOption.checked} onChange={() => subOption.onChange(!subOption.checked)} />
						{subOption.label}
					</label>
				))}
			</SubOptionContainer>

		</CheckBoxContainer>
	);
};

export default MainCheckBox;