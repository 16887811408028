import React, { useState } from "react";
import { Container, OpenContainer, SpacingContainer, StackContainer } from "./styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useTheme } from "../../../../contexts/theme";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { MobileApp } from "../../../../types/MobileApp";
import technosLogo from "../../../../assets/images/technosLogo.svg";
import { DropdownItem, NewAppModal, EditAppModal } from "./components";
import { StatusModal } from "../../../../components";
import { MESSAGES } from "../../../../constants/messages";

interface TextInputProps {
	items: MobileApp[];
	selected: MobileApp;
	onChange: (value: MobileApp) => void;
}

const AppSelectionDropdown = (props: TextInputProps) => {

	const { currentTheme } = useTheme();
	const { createNewMobileApp, editMobileApps, deleteMobileApp } = useMobileApp();

	const [isOpen, setIsOpen] = useState(false);
	const [newAppModalVisible, setNewAppModalVisible] = useState(false);
	const [editAppModalVisible, setEditAppModalVisible] = useState(false);
	const [statusModal, setStatusModal] = useState({ isOpen: false, title: "", description: "", success: false });

	const handleCreateApp = (name: string, image: string) => {
		createNewMobileApp(name, image).then(() => {
			setStatusModal({
				isOpen: true,
				title: MESSAGES.PT_BR.APP_MODAL.NEW_APP_FEEDBACK_TITLE,
				description: MESSAGES.PT_BR.APP_MODAL.NEW_APP_FEEDBACK_DESCRIPTION,
				success: true
			});
		});
		
		setTimeout(() => {
			setStatusModal({ ...statusModal, isOpen: false });
			setNewAppModalVisible(false);
		}, 3000);
	};

	const handleEditMobileApps = (apps: MobileApp[]) => {
		editMobileApps(apps);

		setStatusModal({
			isOpen: true,
			title: MESSAGES.PT_BR.APP_MODAL.EDIT_APP_FEEDBACK_TITLE,
			description: MESSAGES.PT_BR.APP_MODAL.EDIT_APP_FEEDBACK_DESCRIPTION,
			success: true
		});

		setTimeout(() => {
			setStatusModal({ ...statusModal, isOpen: false });
			setEditAppModalVisible(false);
		}, 3000);
	};

	const handleDeleteMobileApp = (device: MobileApp, deletingIndex: number) => {
		deleteMobileApp(device, deletingIndex);
		setEditAppModalVisible(false);
	};

	return (
		<StackContainer onMouseLeave={() => setIsOpen(false)}>
			<StatusModal isOpen={statusModal.isOpen} title={statusModal.title} description={statusModal.description} success={statusModal.success} />
			<NewAppModal isOpen={newAppModalVisible} handleCreateApp={handleCreateApp} onCancel={() => setNewAppModalVisible(false)} />
			<EditAppModal isOpen={editAppModalVisible} handleEditMobileApps={handleEditMobileApps} handleDeleteMobileApp={handleDeleteMobileApp} onCancel={() => setEditAppModalVisible(false)} />
			<Container onClick={() => setIsOpen(!isOpen)} theme={currentTheme}>
				<img src={props.selected?.image || technosLogo} />
				<p>{props.selected.name}</p>
				<div>
					{isOpen ?
						<IoIosArrowUp color={currentTheme.textColor} fontSize="1.5em" /> :
						<IoIosArrowDown color={currentTheme.textColor} fontSize="1.5em" />
					}
				</div>
			</Container>
			{isOpen &&
				<SpacingContainer>
					<OpenContainer theme={currentTheme}>
						{props.items.map((item, index) => {
							return (
								<DropdownItem key={index} index={index} item={item} onClick={(selectedApp) => {
									setIsOpen(false);
									props.onChange(selectedApp);
								}} />
							);
						})}
					</OpenContainer>
				</SpacingContainer>
			}

		</StackContainer>
	);
};

export default AppSelectionDropdown;