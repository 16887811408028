import React, { useEffect, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Outlet, useNavigate } from "react-router-dom";
import { DEFAULT_DEVICE, ROUTES } from "../../constants";
import { FEATURES } from "../../constants/features";
import { useDevice } from "../../contexts/device";
import { useMobileApp } from "../../contexts/mobileApp";
import { SecondaryHeader } from "../Header";
import MainHeader from "../Header/MainHeader";
import LeftMenu from "../LeftMenu";
import { Content, MenuStyled } from "./styles";
import { LocalStorage } from "../../services/storage";
import { useAdminUser } from "../../contexts/adminUser";
import { getNextAllowedFeature } from "../../services/auth/PermissionGate";
const IS_OPEN_KEY = "isOpenKey";

interface MenuProps {
	renderAsPartial?: boolean;
	isMainPage?: boolean;
}

const getSideBarPosition = (isMainPage: boolean) => {
	const lastSelectedOption = LocalStorage.getLocalData(IS_OPEN_KEY);
	if (lastSelectedOption) {
		return (JSON.parse(lastSelectedOption));
	}else{
		return isMainPage ?? false;
	}
};

const Menu = (props: MenuProps) => {
	

	const [sidebarOpen, setSidebarOpen] = useState<boolean>(getSideBarPosition(props.isMainPage ?? false));
	const deviceStore = useDevice();
	const appStore = useMobileApp();
	const navigate = useNavigate();
	const lastDevice = useRef(deviceStore.currentDevice);
	const { currentUser } = useAdminUser();

	//Sempre que o app atual mudar, atualiza a lista de dispositivos
	//Quando a lista atualiza, triga o useEffect que navega para a primeira feature disponível
	useEffect(() => {
		if (appStore.selectedApp) {
			deviceStore.getDeviceList(appStore.selectedApp.appCode ?? 0);
		}
	}, [appStore.selectedApp]);

	//Sempre que o dispositivo atual mudar e estiver em uma página de admin, navega para a primeira feature disponível
	useEffect(() => {
		if (deviceStore.currentDevice && deviceStore.currentDevice.availableFeatures.length > 0 && window.location.pathname.includes("/" + ROUTES.ADMIN + "/") && deviceStore.currentDevice !== DEFAULT_DEVICE && lastDevice?.current?.slug !== deviceStore.currentDevice?.slug) {
			const firstFeature = getNextAllowedFeature(deviceStore.currentDevice, currentUser.role);
			Object.keys(FEATURES).find((key) => {
				FEATURES[key] === firstFeature &&
					navigate("/" + ROUTES.ADMIN + "/" + ROUTES.DASHBOARD[key], { replace: true });
			});
		}else if(lastDevice?.current?.slug !== deviceStore.currentDevice?.slug){
			const firstFeature = getNextAllowedFeature(DEFAULT_DEVICE, currentUser.role);
			Object.keys(FEATURES).find((key) => {
				FEATURES[key] === firstFeature &&
					navigate("/" + ROUTES.ADMIN + "/" + ROUTES.DASHBOARD[key], { replace: true });
			});
		}
		lastDevice.current = deviceStore.currentDevice;
	}, [deviceStore.currentDevice]);


	const getFixedComponents = () => {
		if (props.isMainPage) {
			return (
				<>
					<LeftMenu
						sideBarOpen={sidebarOpen}
						onOpen={(isOpen) => {
							setSidebarOpen(isOpen);
							LocalStorage.storeLocalData(IS_OPEN_KEY, JSON.stringify(isOpen));
						}}
					/>
					<MainHeader
						open={sidebarOpen}
					/>
				</>
			);
		}

		return (
			<SecondaryHeader />
		);
	};

	return (
		<MenuStyled>
			{getFixedComponents()}
			<Toaster
				toastOptions={{
					style: {
						fontFamily: "Light"
					},
					success: {
						iconTheme: {
							primary: "green",
							secondary: "white",
						},
					},
				}}
			/>
			<Content sidebarOpen={sidebarOpen} isMainPage={props.isMainPage ?? false}>
				{props.renderAsPartial ? <Outlet /> : null}
			</Content>
		</MenuStyled>
	);
};

export default Menu;