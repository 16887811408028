import { WorkoutSection, WorkoutVideo } from "../../types/Training";
import { getApi } from ".";
import { TrainingVideoMapper } from "../../types/Mapper/TrainingVideoMapper";


export const getWorkouts = (appCode: number) => {
	return new Promise<WorkoutSection[]>((resolve, reject) => {
		getApi(appCode).get("/trainningVideos")
			.then((response) => {
				resolve(response.data.map( currentSection => TrainingVideoMapper.fromApiSectionToWorkoutSection(currentSection) as WorkoutSection ) );
			}).catch((error) => {
				console.log(error);
				reject(error.response?.data?.title);
			});
	});
};


export const saveWorkoutSection = (workoutSection: WorkoutSection, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/trainningVideoSections/createTrainningVideoSection", workoutSection)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error.response?.data?.title);
			});
	});
};


export const updateWorkoutSection = (workoutSection: WorkoutSection, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put(`/trainningVideoSections/updateTrainningVideoSection?id=${workoutSection.id}`, TrainingVideoMapper.fromWorkoutVideoSectionToApi(workoutSection))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error.response?.data?.title);
			});
	});
};


export const deleteWorkoutSection = (workoutSectionId: number, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`/trainningVideoSections/deleteTrainningVideoSection?id=${workoutSectionId}`)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error.response?.data?.title);
			});
	});
};

export const deleteWorkoutVideo = (workoutVideoId: number, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`/trainningVideos/deleteTrainningVideo?id=${workoutVideoId}`)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error.response?.data?.title);
			});
	});
};


export const saveWorkoutVideo = (workoutVideo: WorkoutVideo, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/trainningVideos/createTrainningVideo", TrainingVideoMapper.fromWorkoutVideoToApi(workoutVideo))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error.response?.data?.title);
			});
	});
};


export const updateTrainningVideo = (appCode: number, newWorkoutVideo: WorkoutVideo) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put(`/trainningVideos/updateTrainningVideo?id=${newWorkoutVideo.id}`, TrainingVideoMapper.fromWorkoutVideoToApi(newWorkoutVideo))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};