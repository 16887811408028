import React, { createContext, useContext } from "react";
import { Api } from "../services";
import { WatchFace, WatchFaceSection } from "../types/Watchface";

interface IWatchFaceContextData {
	getWatchFaceListBySection: (appCode: number, deviceSlug: string,  start?: string|null, end?: string|null ) => Promise<WatchFaceSection[] | null>;
	updateWatchFaceSection: (appCode: number, section: WatchFaceSection) => Promise<void>;
	createWatchFaceSection: (newWatchFaceSection: WatchFaceSection, appCode: number, deviceSlug: string) => Promise<void>;
	deleteWatchFaceSection: (appCode: number, itemId: number) => Promise<void>;
	saveNewSortOrder: (appCode: number, sortedWfList: WatchFaceSection[]) => Promise<void>;
	createWatchFace: (appCode: number, newWatchFace: WatchFace, wfImage: File, preview: File, sectionId: number, deviceSlug: string) => Promise<void>;
	deleteWatchFace: (appCode: number, watchFaceId: number) => Promise<void>;
	updateWatchFace: (appCode: number, watchFace: WatchFace, wfImage: File, preview: File, deviceSlug: string) => Promise<void>;
	saveNewWfListSortOrder: (appCode: number, sortedWfList: WatchFace[]) => Promise<void>;
}

interface WatchFaceProviderProps {
	children: React.ReactNode;
}

export const WatchFaceContext = createContext({} as IWatchFaceContextData);

const WatchFaceProvider: React.FC<WatchFaceProviderProps> = ({ children }) => {

	const S3_FOLDER = "watchfaces";
	const _getImagesFolder = (deviceSlug: string) => `${S3_FOLDER}/${deviceSlug}/images`;
	const _getPreviewFolder = (deviceSlug: string) => `${S3_FOLDER}/${deviceSlug}/preview`;

	const createWatchFaceSection = (newWatchFaceSection: WatchFaceSection, appCode: number, deviceSlug: string) => {
		return new Promise<void>((resolve, reject) => {
			Api.WatchFace.createWatchFaceSection(newWatchFaceSection, appCode, deviceSlug)
				.then(() => {
					resolve();
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const getWatchFaceListBySection = (appCode: number, deviceSlug: string,  start: string|null = null, end: string|null = null) => {
		return new Promise<WatchFaceSection[] | null>((resolve, reject) => {
			Api.WatchFace.getWatchFacesBySection(appCode, deviceSlug, start, end)
				.then((wfList) => {
					resolve(wfList);
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const updateWatchFaceSection = (appCode: number, section: WatchFaceSection) => {
		return new Promise<void>((resolve, reject) => {
			Api.WatchFace.updateWatchFaceSection(appCode, section)
				.then(() => {
					resolve();
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const saveNewSortOrder = async (appCode: number, sortedWfList: WatchFaceSection[]) => {

		const tasks = new Array(sortedWfList.length).fill(updateWatchFaceSection);

		for (let i = 0; i < sortedWfList.length; i++) {
			try{
				await tasks[i](appCode, sortedWfList[i]);
			}catch (e) {
				console.log(e);
				return Promise.reject(e);
			}
		}
		return Promise.resolve();
	};

	const saveNewWfListSortOrder = async (appCode: number, sortedWfList: WatchFace[]) => {
		const tasks = new Array(sortedWfList.length).fill(updateWatchFace);
		const emptyFile = new File([], "");

		for (let i = 0; i < sortedWfList.length; i++) {
			try{
				await tasks[i](appCode, sortedWfList[i], emptyFile, emptyFile, "");
			}catch (e) {
				console.log(e);
				return Promise.reject(e);
			}
		}
		return Promise.resolve();
	};

	const deleteWatchFaceSection = (appCode: number, itemId: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.WatchFace.deleteWatchFaceSection(appCode, itemId)
				.then(() => {
					resolve();
				}).catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const deleteWatchFace = (appCode: number, itemId: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.WatchFace.deleteWatchFace(appCode, itemId)
				.then(() => {
					resolve();
				}).catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const createWatchFace = (appCode: number, newWatchFace: WatchFace, wfImage: File, preview: File, sectionId: number, deviceSlug: string) => {
		let wfImagePromise: Promise<string>;
		let previewPromise: Promise<string>;
		return new Promise<void>((resolve, reject) => {
			if (wfImage.size) wfImagePromise = Api.AWS.sendOrUpdateFileToS3(appCode, wfImage, _getImagesFolder(deviceSlug));
			if (preview.size) previewPromise = Api.AWS.sendOrUpdateFileToS3(appCode, preview, _getPreviewFolder(deviceSlug));
			Promise.all([wfImagePromise, previewPromise]).then(([wfImageUrl, previewUrl]) => {
				if (wfImageUrl) newWatchFace.image = wfImageUrl;
				if (previewUrl) newWatchFace.preview = previewUrl;
				resolve(Api.WatchFace.createWatchFace(appCode, newWatchFace, sectionId, deviceSlug));
			}).catch(s3Error => {
				reject(s3Error);
			});
		});
	};

	const updateWatchFace = (appCode: number, watchFace: WatchFace, wfImage: File, preview: File, deviceSlug: string) => {
		let wfImagePromise: Promise<string>;
		let previewPromise: Promise<string>;
		return new Promise<void>((resolve, reject) => {
			if (wfImage.size) wfImagePromise = Api.AWS.sendOrUpdateFileToS3(appCode, wfImage, _getImagesFolder(deviceSlug), watchFace.image);
			if (preview.size) previewPromise = Api.AWS.sendOrUpdateFileToS3(appCode, preview, _getPreviewFolder(deviceSlug), watchFace.preview);
			Promise.all([wfImagePromise, previewPromise]).then(([wfImageUrl, previewUrl]) => {
				if (wfImageUrl) watchFace.image = wfImageUrl;
				if (previewUrl) watchFace.preview = previewUrl;
				resolve(Api.WatchFace.updateWatchFace(appCode, watchFace));
			}).catch(s3Error => {
				reject(s3Error);
			});
		});
	};

	return (
		<WatchFaceContext.Provider
			value={{
				getWatchFaceListBySection,
				updateWatchFaceSection,
				createWatchFaceSection,
				deleteWatchFaceSection,
				saveNewSortOrder,
				createWatchFace,
				deleteWatchFace,
				updateWatchFace,
				saveNewWfListSortOrder
			}}>
			{children}
		</WatchFaceContext.Provider>
	);
};

const useWatchFace = () => {
	const context = useContext(WatchFaceContext);

	return context;
};

export { WatchFaceProvider, useWatchFace };
