import styled from "styled-components";

interface StackProps {
    disabled?: boolean;
}

export const StackContainer = styled.div<StackProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30px;

    &:hover {
        cursor: pointer;
    }
`; 
