import React, { useState } from "react";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader } from "./styles";
import { MESSAGES } from "../../../../../../constants/messages";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";


interface Props {
    onSaveRequested: (legalInfos: string) => void;
    onCancel: () => void;
    isOpen: boolean;
}

const UpdateInfosModal = (props: Props) => {

	const [legalInfos, setLegalInfos] = useState("");
	const [errorText, setErrorText] = useState("");

	return (
		<ModalContainer isOpen={props.isOpen}>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.POLICIES_AND_TERMS.LEGAL_INFOS_MODAL.TITLE}</h1>
					<div>
						<CloseIcon onClick={() => {
							setLegalInfos("");
							setErrorText("");
							props.onCancel();
						}} />
					</div>
				</ModalHeader>
				<ModalBody>
					<p>{MESSAGES.PT_BR.POLICIES_AND_TERMS.LEGAL_INFOS_MODAL.DESCRIPTION}</p>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.POLICIES_AND_TERMS.LEGAL_INFOS_MODAL.DESCRIPTION_INPUT}
						onChange={(newText) => setLegalInfos(newText)}
						placeholder={MESSAGES.PT_BR.POLICIES_AND_TERMS.LEGAL_INFOS_MODAL.DESCRIPTION_PLACEHOLDER}
						value={legalInfos}
						rowsNumber={4}
						errorText={errorText}
					/>
				</ModalBody>
				<ModalFooter >
					<MainButton enabled
						onClick={() => {
							if(legalInfos.length > 0){
								props.onSaveRequested(legalInfos);
								setLegalInfos("");
								setErrorText("");
							}else{
								setErrorText(MESSAGES.PT_BR.POLICIES_AND_TERMS.LEGAL_INFOS_MODAL.MANDATORY_FIELD);
							}
						}}
						text={MESSAGES.PT_BR.POLICIES_AND_TERMS.LEGAL_INFOS_MODAL.UPDATE} />
				</ModalFooter>
			</ModalContent>
		</ModalContainer>
	);
};

export default UpdateInfosModal;