import styled from "styled-components";
import { Theme } from "../../../../../types/Theme";

interface ContainerProps {
	theme: Theme
}

export const ResultTableContainer = styled.div<ContainerProps>`
	width: 100%;
	min-width: 800px;
	margin-top: 20px;
	padding-bottom: 20px;

	table {
		padding-left: 2%;
		padding-right: 2%;
		width: 100%;
		margin-bottom: 20px;
	}
`;

export const LineTr = styled.tr<ContainerProps>`

	div {
		display: flex;
		justify-content: center;
		align-items: center;

		hr {
			width: 100%;
			opacity: 0.5;
			border: 1px solid ${props => props.theme.borderColor};
			justify-content: center;
			align-items: center;
		}
	}
`;

export const Line = styled.div<ContainerProps>`
	width: 100%;
	height: 1px;
	background-color: ${props => props.theme.backgroundCardSurface};

`;