export const SHARE_EXERCISE = {
	TITLE: "Compartilhar exercício",
	EDIT_TITLE: "Editar imagem",
	NEW_BTN: "Nova imagem",
	ADD_BTN: "Adicionar",
	EDIT_BTN: "Salvar",
	PREVIEW: "Preview",
	EXERCISE_TITLE: "Título exercício",
	ACTION: "Ações",
	KEEP_ADDING: "Continuar adicionando",
	DRAG_DISABLED: "Reordenação bloqueada",
	DRAG_ENABLED: "Reordenação desbloqueada",

	NAME: "Exercício",
	NAME_PLACEHOLDER: "Nome do exercício",
	NAME_INFO: "Digite o nome do exercício que está sendo executado na imagem, de preferência, deve-se adicionar exercícios que tenha disponível no relógio",
	IMAGE: "Arquivo da imagem",
	IMAGE_INFO: "O formato da imagem deve ser em .png ou .jpg e a imagem deve ser em alta resolução",
	SHOW_ON_APP: "Mostrar no app",

	GET_ERROR: "Erro ao recuperar a lista de compartilhamento de exercícios",
	
	SAVING: "Salvando...",
	SAVED: "Salvo",
	SAVING_ERROR: "Erro ao salvar",

	DELETING: "Excluindo...",
	DELETED_SUCCESSFULLY: "Excluído",
	DELETING_ERROR: "Erro ao excluir",
	DELETE_TITLE: "Você tem certeza que deseja excluir essa imagem?",
	DELETE_DESCRIPTION: "Assim que prosseguir com a exclusão essa imagem não será mais mostrada no aplicativo do usuário.",
	DELETE_BTN: "Tenho certeza, excluir.",
	CANCEL_BTN: "Não tenho certeza, cancelar.",
};

