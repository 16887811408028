import React, { createContext, useContext } from "react";
import { Api } from "../services";
import { Sleep } from "../types/Sleep";

interface ISleepScoreContextData {
	getSleepScoreList: (appCode: number) => Promise<Sleep[] | null>;
	updateSleepScore: (updatedSleep: Sleep, appCode: number) => Promise<void>;
}

interface SleepScoreProviderProps {
	children: React.ReactNode;
}

export const SleepScoreContext = createContext({} as ISleepScoreContextData);

const SleepScoreProvider: React.FC<SleepScoreProviderProps> = ({ children }) => {

	const getSleepScoreList = ( appCode: number) => {
		return Api.SleepScore.getSleepScoreList(appCode);
	};

	const updateSleepScore = (sleepScore: Sleep, appCode: number) => {
		return Api.SleepScore.updateSleepScore(sleepScore, appCode);
	};

	return (
		<SleepScoreContext.Provider
			value={{
				getSleepScoreList,
				updateSleepScore
			}}>
			{children}
		</SleepScoreContext.Provider>
	);
};

const useSleepScore = () => {
	const context = useContext(SleepScoreContext);

	return context;
};

export { SleepScoreProvider, useSleepScore };
