import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { COLORS } from "../../../../../../assets";
import { StatusModal } from "../../../../../../components";
import { MainButton } from "../../../../../../components/Buttons";
import { InfoInput } from "../../../../../../components/Inputs";
import { MESSAGES } from "../../../../../../constants/messages";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { useSupport } from "../../../../../../contexts/support";
import { useTheme } from "../../../../../../contexts/theme";
import { Helpers } from "../../../../../../helpers";
import { ContentFooter, InfoContainer } from "./styles";
import { SendTestEmailModal } from "./components";

const AWS_URL = "https://sa-east-1.console.aws.amazon.com/ses/home?region=sa-east-1#/verified-identities";

const SupportContent = () => {

	const [email, setEmail] = useState<string | null>(null);
	const [lastSavedEmail, setLastSavedEmail] = useState<string | null>(null);
	const [sendTestEmailModalVisible, setSendTestEmailModalVisible] = useState(false);
	const [emailError, setEmailError] = useState("");
	const [modalStatus, setModalStatus] = useState({ isOpen: false, title: MESSAGES.PT_BR.SUPPORT_MESSAGES.SAVE_FEEDBACK, description: MESSAGES.PT_BR.SUPPORT_MESSAGES.SUPPORT_FEEDBACK, success: true });
	const { getSupportEmail, saveSupport, sendTestEmail } = useSupport();
	const { currentTheme } = useTheme();
	const { selectedApp } = useMobileApp();

	useEffect(() => {
		setEmail(null);
		getSupportEmail(selectedApp?.appCode).then((res) => {
			setEmail(res);
			setLastSavedEmail(res);
		}).catch((err) => {
			setEmail("");
			setLastSavedEmail(null);
			toast.error(err);
		});
	}, [selectedApp]);

	const handleSaveSupport = () => {
		let allRight = true;
		if (Helpers.Validators.emailIsValid(email ?? "")) {
			setEmailError("");
		} else {
			setEmailError(MESSAGES.PT_BR.SUPPORT_MESSAGES.EMAIL_ERROR);
			allRight = false;
		}

		if (allRight) {
			const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
			saveSupport(email ?? "", selectedApp?.appCode)
				.then(() => {
					setLastSavedEmail(email);
					setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.SUPPORT_MESSAGES.SAVE_FEEDBACK, description: MESSAGES.PT_BR.SUPPORT_MESSAGES.SUPPORT_FEEDBACK, success: true });
				}).catch((err) => {
					toast.error(err);
				}).finally(() => {
					toast.dismiss(t);
					setTimeout(() => {
						setModalStatus({ ...modalStatus, isOpen: false });
					}, 3000);
				});
		}
	};

	const handleSendTestEmail = (fromEmail: string) => {
		setSendTestEmailModalVisible(false);
		const t = toast.loading(MESSAGES.PT_BR.SUPPORT_MESSAGES.SENDING_EMAIL);
		if(email){
			sendTestEmail(fromEmail , selectedApp?.appCode).then(() => {
				setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.SUPPORT_MESSAGES.EMAIL_SENT, description: MESSAGES.PT_BR.SUPPORT_MESSAGES.EMAIL_SENT_DESCRIPTION1 + email + MESSAGES.PT_BR.SUPPORT_MESSAGES.EMAIL_SENT_DESCRIPTION2, success: true });
			}).catch((err) => {
				setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.SUPPORT_MESSAGES.EMAIL_NOT_SENT, description: MESSAGES.PT_BR.SUPPORT_MESSAGES.EMAIL_NOT_SENT_DESCRIPTION + err, success: false });
			}).finally(() => {
				setTimeout(() => {
					setModalStatus({ ...modalStatus, isOpen: false });
				}, 3000);
				toast.dismiss(t);
			});
		}else {
			toast.error(MESSAGES.PT_BR.SUPPORT_MESSAGES.EMAIL_ERROR);
		}
	};

	const InfoBox = () => {
		return (
			<InfoContainer theme={currentTheme}>
				<p>{MESSAGES.PT_BR.SUPPORT_MESSAGES.AWS_HELP}</p>
				<p style={{color: "purple", textDecoration: "underline", cursor:"pointer"}} onClick={() => window.location.href = AWS_URL}>Acessar</p>
				<img src={"/images/awsInfos.png"} alt={"Aws help"} />
			</InfoContainer>
		);
	};

	return (
		<div>
			<StatusModal
				isOpen={modalStatus.isOpen}
				description={modalStatus.description}
				success={modalStatus.success}
				title={modalStatus.title}
			/>
			<SendTestEmailModal handleSendEmail={(fromEmail) => handleSendTestEmail(fromEmail)} isOpen={sendTestEmailModalVisible} onCancel={() => setSendTestEmailModalVisible(false)} />
			<InfoInput
				onChange={(newValue) => { setEmail(newValue); setEmailError(""); }}
				placeholder={MESSAGES.PT_BR.SUPPORT_MESSAGES.SUPPORT_EMAIL_PLACEHOLDER}
				value={email ?? ""}
				type="text"
				title={MESSAGES.PT_BR.SUPPORT_MESSAGES.SUPPORT_EMAIL}
				style={{ marginBottom: 28 }}
				errorText={emailError}
				InfoBox={<InfoBox></InfoBox>}
			/>
			<ContentFooter>
				<MainButton
					enabled={lastSavedEmail === email && lastSavedEmail !== null}
					onClick={() => {setSendTestEmailModalVisible(true);}}
					text={MESSAGES.PT_BR.SUPPORT_MESSAGES.SEND_TEST_BTN}
					darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
					lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
				/>
				<h5></h5>
				<MainButton
					enabled
					onClick={() => { handleSaveSupport(); }}
					text={MESSAGES.PT_BR.SUPPORT_MESSAGES.SAVE_BTN}
					darkColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
					lightColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
				/>
			</ContentFooter>
		</div>
	);
};

export default SupportContent;