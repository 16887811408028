import React, { useState } from "react";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { ModalBody, ModalContainer, ModalContent, ModalHeader } from "./styles";
import { MainButton } from "../../../../../../components/Buttons";
import { COLORS } from "../../../../../../assets";
import { WorkoutSection, WorkoutVideo } from "../../../../../../types/Training";
import { useTraining } from "../../../../../../contexts/training";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { StatusModal } from "../../../../../../components";
import { toast } from "react-hot-toast";
import { deleteWorkoutVideo } from "../../../../../../services/api/Training";

interface ModalProps {
	onClose: () => void;
	workoutSection?: WorkoutSection
	workoutVideo?: WorkoutVideo
	type: ModalType
}

export enum ModalType {
	WORKOUT_SECTION = 0,
	WORKOUT_VIDEO = 1
}


const DeleteItemModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();
	const { deleteWorkoutSection } = useTraining();
	const { selectedApp } = useMobileApp();
	const [statusModal, setStatusModal] = useState({ isOpen: false, title: "", description: "", success: false });

	const handleDelete = () => {
		
		if (props.type === ModalType.WORKOUT_SECTION && props.workoutSection) {
			const t = toast.loading(MESSAGES.PT_BR.TRAINING_MESSAGES.LOADING);
			deleteWorkoutSection(props.workoutSection.id, selectedApp.appCode)
				.then(() => {
					setStatusModal({ isOpen: true, title: MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_SUCCESS, description: MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_SUCCESS_DESCRIPTION, success: true });
				}).catch((error) => {
					setStatusModal({ isOpen: true, title: MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_ERROR, description: MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_ERROR_DESCRIPTION + error, success: false });
				}).finally(() => {
					toast.dismiss(t);
					setTimeout(() => {
						setStatusModal({ isOpen: false, title: "", description: "", success: false });
						props.onClose();
					}, 2000);
				});
		} else if(props.workoutVideo) {
			const t = toast.loading(MESSAGES.PT_BR.TRAINING_MESSAGES.LOADING);
			deleteWorkoutVideo(props.workoutVideo.id, selectedApp.appCode)
				.then(() => {
					setStatusModal({ isOpen: true, title: MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_SUCCESS, description: MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_SUCCESS_DESCRIPTION, success: true });
				}).catch((error) => {
					setStatusModal({ isOpen: true, title: MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_ERROR, description: MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_ERROR_DESCRIPTION + error, success: false });
				}).finally(() => {
					toast.dismiss(t);
					setTimeout(() => {
						setStatusModal({ isOpen: false, title: "", description: "", success: false });
						props.onClose();
					}, 2000);
				});
			return;
		}else{
			toast.error(MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_ERROR);
		}
	};

	return (
		<ModalContainer>
			<StatusModal isOpen={statusModal.isOpen} description={statusModal.description} success={statusModal.success} title={statusModal.title} />
			<ModalContent>
				<ModalHeader>
					{props.type === ModalType.WORKOUT_SECTION ?
						<h3>{MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_SECTION_TITLE}</h3>
						:
						<h3>{MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_VIDEO_TITLE}</h3>
					}
				</ModalHeader>
				<ModalBody>
					<p>{props.type === ModalType.WORKOUT_SECTION ?
						MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_SECTION_DESCRIPTION
						:
						MESSAGES.PT_BR.TRAINING_MESSAGES.DELETE_VIDEO_DESCRIPTION
					}</p>
					<div>
						<MainButton enabled
							onClick={props.onClose}
							text={MESSAGES.PT_BR.FAQ_MESSAGES.CANCEL_BTN}
							lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
							darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
						/>
						<h5></h5>
						<MainButton enabled
							onClick={handleDelete}
							text={MESSAGES.PT_BR.FAQ_MESSAGES.CONFIRM_DELETE}
							lightColor={currentTheme.primaryColor}
							darkColor={currentTheme.primaryColor}
						/>
					</div>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);

};

export default DeleteItemModal;