import React from "react";
import { COLORS } from "../../../../../../assets";
import { MainButton } from "../../../../../../components/Buttons";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { Exercise } from "../../../../../../types/Exercise";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader } from "./styles";

interface ModalProps {
    isOpen: boolean;
    onCancel: () => void;
    onDeleteRequested: (selectedExercise: Exercise) => void;
	selectedExercise: Exercise;
}

const DeleteCustomExerciseModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.DELETE_FEEDBACK}</h1>
				</ModalHeader>
				<ModalBody>
					<p>{MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.DELETE_FEEDBACK_DESCRIPTION}</p>
				</ModalBody>
				<ModalFooter theme={currentTheme}>
					<MainButton
						enabled
						onClick={() => props.onCancel()}
						text={MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.CANCEL_BTN}
						darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
						lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
					/>
					<MainButton
						enabled
						onClick={() => props.onDeleteRequested(props.selectedExercise)}
						text={MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.DELETE_BTN}
						darkColor={currentTheme.primaryColor}
						lightColor={currentTheme.primaryColor}
					/>
				</ModalFooter>
			</ModalContent>
		</ModalContainer>
	);
};

export default DeleteCustomExerciseModal;