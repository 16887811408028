import React from "react";
import { PlacesType, Tooltip as ReactTooltip } from "react-tooltip";

interface ITooltipProps {
  id: string;
  color: string;
  position: PlacesType;
}

export const Tooltip = (props: ITooltipProps) => {
	return (
		<ReactTooltip id={props.id} place={props.position} style={{ backgroundColor: props.color, borderRadius: "10px", padding: "4px 8px"  }} />
	);
};