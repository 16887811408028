import React, { useState } from "react";
import { useTheme } from "../../../contexts/theme";
import { InfoBoxContainer, InputStyled, StackContainer, TextAreaStyled, TitleContainer } from "./styles";
import { AiOutlineInfoCircle } from "react-icons/ai";

interface MainInputProps {
	placeholder: string;
	value: string;
	type: string | undefined;
	onChange: (value: string) => void;
	errorText?: string;
	title?: string;
	rowsNumber?: number;
	style?: React.CSSProperties;
	characterLimit?: number;
	InfoBox?: React.ReactNode;
	infoBoxStyle?: React.CSSProperties;
}

const InfoInput = (props: MainInputProps) => {

	const { currentTheme } = useTheme();
	const [overLimit, setOverLimit] = useState(false);
	const [helpVisible, setHelpVisible] = useState(false);

	const handleOnChange = (value: string) => {
		props.onChange(value);
		if (props.characterLimit && value.length > props.characterLimit) {
			setOverLimit(true);
		} else {
			setOverLimit(false);
		}
	};

	return (
		<StackContainer theme={currentTheme} style={props.style} overLimit={overLimit}>

			{props.title &&
				<TitleContainer theme={currentTheme}>
					<h1>{props.title}</h1>
					<AiOutlineInfoCircle color={currentTheme.primaryColor}
						onMouseEnter={() => {setHelpVisible(true); setTimeout(() => setHelpVisible(false), 1000); }} />
					{ helpVisible && <InfoBoxContainer style={props?.infoBoxStyle}>
						{props.InfoBox}
					</InfoBoxContainer>}
				</TitleContainer>
			}
			{props?.rowsNumber ?
				<TextAreaStyled
					theme={currentTheme}
					placeholder={props.placeholder}
					onChange={(e) => handleOnChange(e.target.value)}
					rowsNumber={props?.rowsNumber}
					rows={props?.rowsNumber}
					value={props.value}
					isError={props.errorText ? true : false}
				/>
				:
				<InputStyled
					theme={currentTheme}
					placeholder={props.placeholder}
					onChange={(e) => handleOnChange(e.target.value)}
					value={props.value}
					isError={props.errorText ? true : false}
				/>
			}
			{props.errorText && <span>{props.errorText}</span>}
			{props.characterLimit && <p>{props?.value ? props.value.length : 0}/{props.characterLimit}</p>}

		</StackContainer>
	);
};

export default InfoInput;