import React from "react";
import { toast } from "react-hot-toast";
import { COLORS } from "../../../../../../assets";
import { MainButton } from "../../../../../../components/Buttons";
import { MESSAGES } from "../../../../../../constants/messages";
import { useDevice } from "../../../../../../contexts/device";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { useTheme } from "../../../../../../contexts/theme";
import { useTutorial } from "../../../../../../contexts/tutorials";
import { Tutorial } from "../../../../../../types/Tutorial";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader } from "./styles";

interface ModalProps {
    isOpen: boolean;
    onFinish: (status) => void;
    currentVideo: Tutorial | null;
}

const DeleteVideoModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();
	const { deleteTutorial } = useTutorial();
	const { selectedApp } = useMobileApp();
	const { currentDevice } = useDevice();

	const handleDeleteVideo = () => {
		if(props.currentVideo === null) return;
		
		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		deleteTutorial(props.currentVideo, selectedApp?.appCode, currentDevice?.slug || "")
			.then(() => {
				props.onFinish(
					{
						title: MESSAGES.PT_BR.TUTORIALS.MODAL.DELETED_SUCCESS_FEEDBACK,
						description: MESSAGES.PT_BR.TUTORIALS.MODAL.DELETED_SUCCESS_DESCRIPTION,
						success: true,
						isOpen: true,
					});
			})
			.catch((err) => {
				toast.error(err);
			}).finally(() => {
				toast.dismiss(t);
			});
	};

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.TUTORIALS.MODAL.DELETE_TITLE}</h1>
				</ModalHeader>
				<ModalBody theme={currentTheme}>
					<p>{MESSAGES.PT_BR.TUTORIALS.MODAL.DELETE_DESCRIPTION}</p>
				</ModalBody>
				<ModalFooter theme={currentTheme}>
					<MainButton
						enabled
						onClick={() => props.onFinish(null)}
						text={MESSAGES.PT_BR.TUTORIALS.MODAL.CANCEL_BTN}
						darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
						lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
					/>
					<MainButton
						enabled
						onClick={() => handleDeleteVideo()}
						text={MESSAGES.PT_BR.TUTORIALS.MODAL.DELETE_BTN}
						darkColor={currentTheme.primaryColor}
						lightColor={currentTheme.primaryColor}
					/>
				</ModalFooter>
			</ModalContent>
		</ModalContainer>
	);
};

export default DeleteVideoModal;