import Keycloak from "keycloak-js";
import keycloakConfig from "./keycloackConfig";

const _kc = keycloakConfig as Keycloak;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
export const initKeycloak = (onAuthenticatedCallback) => {
	console.log("initializing authenticator..!", _kc);
	_kc.init({
		onLoad: "check-sso",
		silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
		pkceMethod: "S256",
	}).then((authenticated) => {
		if (!authenticated) {
			console.log("user is not authenticated..!");
		}
		onAuthenticatedCallback();
	}).catch(error => alert("Erro ao inicializar sessão. Verifique se o autenticador está funcional e tente novamente: " + error));
};

export const doLogin = _kc.login;

export const doLogout = _kc.logout;

export const getId = () => _kc.tokenParsed?.sub;

export const getToken = () => _kc.token;

export const isLoggedIn = () => !!_kc.token;

export const updateToken = () => {
	return new Promise<string | undefined>((resolve, reject) => {
		_kc.updateToken(60)
			.then(() => resolve(_kc.token))
			.catch((error) => reject(error));
	});
};

export const getEmail = () => {
	return _kc.tokenParsed?.email;

	// _kc.loadUserProfile().then((profile) => {
	// 	return profile.email;
	// });
};

export const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));
