import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { Value } from "@wojtekmaj/react-daterange-picker/dist/cjs/shared/types";
import { useTheme } from "../../../contexts/theme";
import { COLORS } from "../../../assets";
import { StackContainer, DateRangePickerStyled } from "./styles";

interface InputProps {
	startDate: Date | null;
	endDate: Date | null;
	onChange: (newValue: Value) => void;
}

const DateRangeInput = (props: InputProps) => {

	const { currentTheme } = useTheme();

	const todayDate = new Date();
	const isActive = props.startDate !== null;

	return (
		<StackContainer active={isActive}>
			<DateRangePickerStyled
				value={[props.startDate, props.endDate]}
				onChange={props.onChange}
				maxDate={todayDate}	
				rangeDivider={" a "}

				calendarIcon={<AiOutlineCalendar color={isActive ? COLORS.DEFAULT_COLORS.WHITE90 : currentTheme.textColor} size={25} />}
				clearIcon={null}

				next2Label={null}
				prev2Label={null}
				showLeadingZeros
				// dayPlaceholder=""
				// monthPlaceholder=""
				// yearPlaceholder=""
			/>
		</StackContainer>
	);
};

export default DateRangeInput;