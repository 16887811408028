import { FaqArticle, IAvailableTheme } from "../types/FaqArticle";


export const getAvailableTags = (faqList: FaqArticle[]) => {
	const availableTags: string[] = [];
	faqList.forEach(faq => {
		faq.topics.forEach(topic => {
			topic.tags.forEach(tag => {
				availableTags.indexOf(tag) === -1 && availableTags.push(tag);
			});
		});
	});
	return availableTags;
};


export const getNextValidIndex = (faqList: FaqArticle[]) => {
	const sortedList = [...faqList].sort((a, b) => b.index - a.index);
	return (sortedList[0]?.index ?? 0) + 1;
};


export const getAvailableThemes = (faqList: FaqArticle[]) => {
	const availableThemes = faqList.map(faq => {
		return { id: faq.id, name: faq.theme };
	});
	return availableThemes as IAvailableTheme[];
};
  
