import styled, { css } from "styled-components";

type ImgContainer = {
	withCssImage: boolean
}

export const ImgContainer = styled.div<ImgContainer>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 140px;
	height: 140px;
	border-radius: 16px;
	background-color: ${props => props.theme.backgroundCardSecondary};
	margin-top: 5px;
	margin-bottom: 25px;
	
	${props => props.withCssImage && css`
		img {
			width: 100%;
			height: 100%;
			object-fit: fill;
			border-radius: 16px;
		}
	`}

	position: relative;
`;

export const InputStyled = styled.input`
	display: none;
`;

export const RoudedBtnContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 56px;
	height: 56px;
	border-radius: 50%;
	background-color: ${props => props.theme.backgroundCardSecondary};
	padding: 12px;
	box-sizing: border-box;

	position: absolute;
	bottom: -15px;
	right: -15px;

	&:hover {
		cursor: pointer;
		opacity: 0.5;
	}
`;