import styled from "styled-components";
import { Theme } from "../../../../types/Theme";

interface IProps {
    theme: Theme,
    selected?: boolean,
}

export const HeaderOptionsContainer = styled.div<IProps>`
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
`;

export const HeaderOption = styled.div<IProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.selected? props.theme.backgroundCardSecondary : "transparent"};
    height: 50px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 16px;
    margin: 8px;

    h5 {
        color: ${props => props.theme.textColor};
        font-size: 18px;
    }

    &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.backgroundCardSecondary};
        opacity: 0.75;
    }
`;

export const Content = styled.div<IProps>`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 16px;
    padding: 32px;
    margin-top: 32px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    justify-content: center; 
`; 

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 32px;
    width: 100%;

    button {
        width: 30%;
        margin: 0;
    }
`;
