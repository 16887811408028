import { getApi } from ".";
import { ShareExercise } from "../../types/ShareExercise";
import { ShareExerciseMapper } from "../../types/Mapper/ShareExerciseMapper";

const END_POINT = "shareExercise";

export const getShareExercise = (appCode: number) => {
	return new Promise<ShareExercise[]>((resolve, reject) => {
		getApi(appCode).get(END_POINT)
			.then((response) => {
				resolve(response.data.map((exercise) => ShareExerciseMapper.fromApiToModel(exercise)));
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};

export const createNewShareExercise = (newExercise: ShareExercise, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post(END_POINT, ShareExerciseMapper.fromModelToApi(newExercise))
			.then(() => {
				resolve();
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};

export const updateShareExercise = (updatedExercise: ShareExercise, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put(`${END_POINT}?Id=${updatedExercise.id}`, ShareExerciseMapper.fromModelToApi(updatedExercise))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log("[API] error:", error.response.data);
				reject(error?.response?.data?.title);
			});
	});
};

export const deleteShareExercise = (exerciseId: number, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`${END_POINT}?Id=${exerciseId}`)
			.then(() => {
				resolve();
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};
