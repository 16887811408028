import React, { useState } from "react";
import { FilesContainer, ImgContainer, LeftSide, ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, RightSide, ToogleContainer } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../../assets/images/closeIcon.svg";
import { useTheme } from "../../../../../../../contexts/theme";
import { MESSAGES } from "../../../../../../../constants/messages";
import { FileInput, InfoInput, MainInput } from "../../../../../../../components/Inputs";
import { MainButton } from "../../../../../../../components/Buttons";
import { WatchFace, WatchFaceSection } from "../../../../../../../types/Watchface";
import { BiImage } from "react-icons/bi";
import { InfoBox, InfoWfBox, TechnosToggle } from "../../../../../../../components";
import { useDevice } from "../../../../../../../contexts/device";
import { useWatchFace } from "../../../../../../../contexts/watchFaces";
import { toast } from "react-hot-toast";
import { useMobileApp } from "../../../../../../../contexts/mobileApp";
import { Helpers } from "../../../../../../../helpers";
import { FIXED_SECTION_NAME } from "../../../../../../../constants";
import { WatchFaceType } from "../../../../../../../types/Device";

interface ModalProps {
	onClose: (feedback) => void;
	currentSection: WatchFaceSection | null;
}

const AddWatchFaceModal = (props: ModalProps) => {
	const emptyFile = new File([], "");

	const { currentTheme } = useTheme();
	const { currentDevice } = useDevice();
	const { createWatchFace } = useWatchFace();
	const { selectedApp } = useMobileApp();

	const [watchFace, setWatchFace] = useState<WatchFace>({
		name: "",
		section: props.currentSection?.sectionName ?? "",
		id: -1,
		preview: null,
		showApp: props.currentSection?.sectionName === FIXED_SECTION_NAME,
		defaultWatchface: props.currentSection?.sectionName === FIXED_SECTION_NAME,
		image: null,
		index: Helpers.WatchFace.getNextValidIndexWatchFace(props.currentSection?.watchFaces ?? []),
	} as WatchFace);
	const [wfImage, setWfImage] = useState<File>(emptyFile);
	const [preview, setPreview] = useState<File>(emptyFile);
	const [nameError, setNameError] = useState<string>("");
	const [previewError, setPreviewError] = useState<string>("");
	const [imageError, setImageError] = useState<string>("");

	const resetStatesAndKeepAdding = (currentSection: WatchFaceSection) => {
		setWatchFace({
			name: "",
			section: currentSection.sectionName ?? "",
			id: -1,
			preview: null,
			showApp: currentSection.sectionName === FIXED_SECTION_NAME,
			defaultWatchface: currentSection.sectionName === FIXED_SECTION_NAME,
			image: null,
			index: Helpers.WatchFace.getNextValidIndexWatchFace(currentSection.watchFaces),
		} as WatchFace);
		setWfImage(emptyFile);
		setPreview(emptyFile);
	};

	const handleSaveNewWatchFace = (keepAdding = false) => {

		let allRight = true;

		if (watchFace.name === "") {
			setNameError(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setNameError("");
		}

		if (!preview.size) {
			setPreviewError(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setPreviewError("");
		}

		if (!wfImage.size) {
			setImageError(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setImageError("");
		}

		if (!allRight) return;

		setWatchFace({ ...watchFace, index: Helpers.WatchFace.getNextValidIndexWatchFace(props.currentSection?.watchFaces ?? []) });
		const t = toast.loading(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.LOADING);
		createWatchFace(selectedApp.appCode, watchFace, wfImage, preview, props.currentSection?.id ?? 0, currentDevice?.slug ?? "")
			.then(() => {
				if (keepAdding) {
					toast.success(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.ADDED_SUCCESSFULLY);
					if (props.currentSection) {
						const currentSection = props.currentSection;
						currentSection.watchFaces.push(watchFace);
						resetStatesAndKeepAdding(currentSection);
					}
				} else {
					props.onClose({ isOpen: true, title: MESSAGES.PT_BR.WATCH_FACE.ADD_WF_FEEDBACK_MESSAGES.TITLE, description: MESSAGES.PT_BR.WATCH_FACE.ADD_WF_FEEDBACK_MESSAGES.DESCRIPTION, success: true });
				}
			}).catch((error) => {
				props.onClose({ isOpen: true, title: MESSAGES.PT_BR.WATCH_FACE.ADD_WF_FEEDBACK_MESSAGES.ERROR_TITLE, description: MESSAGES.PT_BR.WATCH_FACE.ADD_WF_FEEDBACK_MESSAGES.ERROR_DESCRIPTION + error, success: false });
			}).finally(() => toast.dismiss(t));

	};

	const getFileInputPropsForWatchFace = () => {
		switch (currentDevice?.watchFaceType) {
		case WatchFaceType.ZIP:
			return {
				title: MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.ZIP,
				accept: ".zip",
				InfoBox: <InfoBox style={{ height: 50 }} text={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_ZIP_FILE} />,
			};
		case WatchFaceType.WATCH:
			return {
				title: MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.WATCH,
				accept: ".watch",
				InfoBox: <InfoBox style={{ height: 50 }} text={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_WATCH_FILE} />,
			};
		case WatchFaceType.BIN:
		default:
			return {
				title: MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.BIN,
				accept: ".bin",
				InfoBox: <InfoBox style={{ height: 50 }} text={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_BIN_FILE} />,
			};
		}
	};

	return (
		<ModalContainer>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.TITLE}</h1>
					<div>
						<CloseIcon onClick={() => props.onClose(null)} />
					</div>
				</ModalHeader>
				<ModalBody>
					<LeftSide>
						<MainInput
							type="text"
							onChange={() => null}
							placeholder={""} value={props.currentSection?.sectionName ?? ""}
							title={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.SECTION}
							disabled
						/>
						<InfoInput
							type="text"
							onChange={(newName) => setWatchFace({ ...watchFace, name: newName })}
							placeholder={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.WF_NAME_PLACEHOLDER}
							value={watchFace.name}
							title={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.WF_NAME}
							errorText={nameError}
							InfoBox={<InfoWfBox
								style={{ height: "200px" }}
								title1={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_NAME_TITLE1}
								text1={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_NAME_DESCRIPTION1}
								title2={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_NAME_TITLE2}
								text2={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_NAME_DESCRIPTION}
							/>}
						/>
						<FilesContainer>
							<div>
								<FileInput
									onChange={setWfImage}
									value={wfImage.name}
									errorText={imageError}
									{...getFileInputPropsForWatchFace()}
								/>
							</div>
							<h5> </h5>
							<div>
								<FileInput
									onChange={setPreview}
									title={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.WF_PREVIEW}
									accept="image/png, image/jpeg"
									value={preview.name}
									errorText={previewError}
									InfoBox={<InfoBox style={{ height: "90px" }} text={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.HELP_PNG_FILE} />}
								/>
							</div>
						</FilesContainer>
					</LeftSide>
					<RightSide>
						<ImgContainer hasImage={preview.size !== 0}>
							<BiImage color={currentTheme.textColor} size={50} />
							{preview.size ? <img src={URL.createObjectURL(preview)} /> : null}
						</ImgContainer>
						<ToogleContainer>
							<TechnosToggle
								disabled={props.currentSection?.sectionName === FIXED_SECTION_NAME}
								checked={props.currentSection?.sectionName === FIXED_SECTION_NAME || watchFace.showApp}
								defaultChecked={false}
								leftLabel={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.SHOW_ON_APP}
								onChange={() => setWatchFace({ ...watchFace, showApp: !watchFace.showApp })}
							/>
							<TechnosToggle
								disabled={props.currentSection?.sectionName === FIXED_SECTION_NAME}
								checked={props.currentSection?.sectionName === FIXED_SECTION_NAME || watchFace.defaultWatchface}
								defaultChecked={false}
								leftLabel={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.DEFAULT_WHATCHFACE}
								onChange={() => setWatchFace({ ...watchFace, defaultWatchface: !watchFace.defaultWatchface })}
							/>
						</ToogleContainer>
					</RightSide>
				</ModalBody>
				<ModalFooter>
					<p onClick={() => handleSaveNewWatchFace(true)}>{MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.ADD_AND_CREATE_NEW}</p>
					<MainButton
						enabled
						onClick={() => handleSaveNewWatchFace()}
						text={MESSAGES.PT_BR.WATCH_FACE.ADD_WF_MODAL_MESSAGES.SAVE}
						lightColor={currentTheme.primaryColor}
						darkColor={currentTheme.primaryColor}
					/>
				</ModalFooter>
			</ModalContent>
		</ModalContainer>
	);

};

export default AddWatchFaceModal;