import styled from "styled-components";

interface StackContainerProps {
    disabled?: boolean;
}

export const StackContainer = styled.div<StackContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30px;
    opacity: ${props => props.disabled? 0.5 : 1};

    &:hover {
        cursor: pointer;
    }
`; 
