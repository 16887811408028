import { AdminUser } from "../../types/AdminUser";
import { getApi } from ".";
import { AdminUserMapper } from "../../types/Mapper/AdminUserMapper";
/* eslint-disable no-mixed-spaces-and-tabs */

export const getCurrentUser = (appCode: number) => {
	return new Promise<AdminUser>((resolve, reject) => {
		getApi(appCode).get("/admin/users/profile")
			.then((response) => {
				resolve(AdminUserMapper.fromApiToAppModel(response.data) as AdminUser);
			}).catch((err) => reject(err?.response?.data?.title));
	});
};

export const getAdminUsers = (appCode: number) => {
	return new Promise<AdminUser[]>((resolve) => {
		getApi(appCode).get("/admin/users") //TODO: Corrigir aqui
			.then((response) => {
				resolve(response.data.map((item) => AdminUserMapper.fromApiToAppModel(item) as AdminUser));
			}).catch((err) => console.log(err));
	});
};

export const postAdminUsers = (appCode: number, newUser: AdminUser) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/admin/users", AdminUserMapper.fromModelToApi(newUser))
			.then(() => {
				resolve();
			}).catch((err) => {
				reject(err.response?.data?.title);
				console.log(err);
			});
	});
};

export const deleteAdminUser = (appCode: number, id: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`/admin/users/${id}`)
			.then(() => {
				resolve();
			})
			.catch((err) => {
				console.log(err);
				reject(err.response?.data?.title);
			});
	});
};

export const updateAdminUser = (appCode: number, id: string, newRole: string) => {
	return new Promise<void>(() => {
		getApi(appCode).put(`/admin/users/${id}`, { role: AdminUserMapper.convertAppRoleToApi(newRole) });
	});
};


export const sendAdminResetPasswordEmail = (email: string) => {
	return new Promise<void>((resolve) => {
		//TODO: Implementar a chamada para a API
		console.log("Email: ", email);
		setTimeout(() => {
			resolve();
		}, 2000);
	});
};

export const updateCurrentUser = (appCode: number, user: AdminUser) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put("/admin/user", AdminUserMapper.fromModelToApi(user))
			.then(() => {
				resolve();
			}).catch((err) => {
				console.log(err);
				reject(err.response?.data?.title);
			});
	});
};

export const updatePassword = (appCode: number, currentPassword: string, newPassword: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("admin/users/password", { "current_password": currentPassword, "new_password": newPassword })
			.then(() => {
				resolve();
			})
			.catch((err) => {
				console.log(err);
				reject(err.response?.data?.title);
			});
	});
};