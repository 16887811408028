import styled from "styled-components";
import { Theme } from "../../../../../types/Theme";

interface ModalProps {
	isOpen: boolean,
}

export const ModalContainer = styled.div<ModalProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	opacity: ${props => props.isOpen ? 1 : 0};
	visibility: ${props => props.isOpen ? "visible" : "hidden"};
	transition: visibility 0s, opacity 0.3s linear;
`;

interface ContentProps {
	theme: Theme,
}

export const ModalContent = styled.div<ContentProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 500px;
	background-color: ${props => props.theme.backgroundCard};
	border-radius: 10px;
	padding: 20px;
	box-sizing: border-box;
`;

export const ModalHeader = styled.div<ContentProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 20px;

	h1 {
		font-size: 28px;
		padding: 10px;
		text-align: center;
		font-weight: 500;
		line-height: 30px;
		color: ${props => props.theme.primaryColor};
	}

	div {
		&:hover {
			cursor: pointer;
		}
	}
`;

export const ModalBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
`;

export const ModalFooter = styled.div<ContentProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	// justify-content: space-between;
	justify-content: flex-end;
	width: 100%;

	button {
		width: 30%;
		margin-top: 0;
		margin-left: 20px;
		border-radius: 15px;
		background-color: ${props => props.theme.primaryColor};
	}

	p {
		// white-space: nowrap;
		text-decoration: underline;
		color: ${props => props.theme.textColor};

		&:hover {
			cursor: pointer;
		}
	}
`;

