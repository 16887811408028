import React from "react";
import { MESSAGES } from "../../../../../../../../constants/messages";
import { useTheme } from "../../../../../../../../contexts/theme";
import { TableHeaderStyled } from "./styles";
import { useWindowDimensions } from "../../../../../../../../hooks";
import { SCREEN_SIZE } from "../../../../../../../../constants";

const TableHeader = () => {

	const { currentTheme } = useTheme();
	const {width} = useWindowDimensions();

	return (
		<TableHeaderStyled theme={currentTheme} >
			<th>{MESSAGES.PT_BR.USER_MESSAGES.NAME}</th>
			{width > SCREEN_SIZE.DESKTOP && <th>{MESSAGES.PT_BR.USER_MESSAGES.EMAIL}</th>}
			{width > SCREEN_SIZE.DESKTOP && <th>{MESSAGES.PT_BR.USER_MESSAGES.GENRE}</th>}
			<th>{MESSAGES.PT_BR.USER_MESSAGES.ACTION}</th>
		</TableHeaderStyled>
	);
};

export default TableHeader;