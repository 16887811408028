import React, { createContext, useContext, useRef } from "react";
import { Api } from "../services";
import { MobileUser } from "../types/MobileUser";

interface IMobileUserContextData {
	mobileUsers: React.MutableRefObject<MobileUser[]>;
	getPageUsers: (appCode: number, page: number) => Promise<MobileUser[] | null>;
	getUsersByFilter: (appCode: number, key: string) => Promise<MobileUser[]>;
	sendResetPasswordEmail: (appCode: number, email: string) => Promise<void>;
	sendConfirmationEmail: (appCode: number, email: string) => Promise<void>;
	hasNextPage: (currentPage: number) => boolean;
	deleteUser: (appCode: number, email: string) => Promise<void>;
	pageToken: React.MutableRefObject<string | null>;
}

interface MobileUserProviderProps {
	children: React.ReactNode;
}

export const MobileUserContext = createContext({} as IMobileUserContextData);

const MobileUserProvider: React.FC<MobileUserProviderProps> = ({ children }) => {

	const mobileUsers = useRef<MobileUser[]>([]);
	const pageToken = useRef<string | null>(null);
	const PAGE_SIZE = 10;

	const hasNextPage = (currentPage: number) => {
		if (pageToken.current) {
			return true;
		}

		const numPages = Math.ceil(mobileUsers.current.length / PAGE_SIZE);
		return currentPage < numPages - 1;
	};


	const getPageUsers = (appCode: number, page: number) => {
		return new Promise<MobileUser[] | null>((resolve, reject) => {
			const numPages = Math.ceil(mobileUsers.current.length / PAGE_SIZE);
			if (page < numPages) {
				const users = mobileUsers.current.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE);
				resolve(users);
			} else {
				Api.MobileUser.getMobileUsers(appCode, pageToken.current, PAGE_SIZE)
					.then((response) => {
						mobileUsers.current.concat(response.users);
						pageToken.current = response.token;
						resolve(response.users);
					})
					.catch((errorMessage) => {
						reject(errorMessage);
					});
			}
		});
	};

	const sendResetPasswordEmail = (appCode: number, email: string) => {
		return new Promise<void>((resolve, reject) => {
			Api.MobileUser.sendResetPasswordEmail(appCode, email)
				.then(() => {
					resolve();
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const sendConfirmationEmail = (appCode: number, email: string) => {
		return new Promise<void>((resolve, reject) => {
			Api.MobileUser.sendConfirmationEmail(appCode, email)
				.then(() => {
					resolve();
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const getUsersByFilter = (appCode: number, key: string) => {
		return new Promise<MobileUser[]>((resolve, reject) => {
			Api.MobileUser.getUsersByFilter(appCode, key)
				.then((filteredUsers) => {
					resolve(filteredUsers);
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const deleteUser = (appCode: number, email: string) => {
		return new Promise<void>((resolve, reject) => {
			Api.MobileUser.deleteUser(appCode, email)
				.then(() => {
					resolve();
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};


	return (
		<MobileUserContext.Provider
			value={{
				mobileUsers,
				hasNextPage,
				getPageUsers,
				sendResetPasswordEmail,
				sendConfirmationEmail,
				getUsersByFilter,
				deleteUser,
				pageToken,
			}}>
			{children}
		</MobileUserContext.Provider>
	);
};

const useMobileUser = () => {
	const context = useContext(MobileUserContext);

	return context;
};

export { MobileUserProvider, useMobileUser };
