import React, { useEffect } from "react";
import { Card, Container } from "./styles";
import { useTheme } from "../../contexts/theme";
import { ROUTES, THEME_TYPES } from "../../constants";
import { Loader } from "../../components";
import { Auth } from "../../services";
import { useNavigate } from "react-router-dom";
import { useAdminUser } from "../../contexts/adminUser";
import { useMobileApp } from "../../contexts/mobileApp";

/**
 * Esta página é usada parada validacao de autenticacao do usuario.
 * Caso o usuario nao esteja autenticado, ele sera redirecionado para a pagina de login do keyCloak.
 * Caso o usuario esteja autenticado, ele sera redirecionado para a pagina de dashboard após recuperar as informacoes da API.
 * O fluxo completo está descrito em: src/services/auth/AuthFlux.png
 */

const PostLogin = () => {

	const { currentTheme } = useTheme();
	const { selectedApp } = useMobileApp();
	const {getCurrentUser, updateCurrentToken, setCurrentUser} = useAdminUser();
	const navigate = useNavigate();
	const params = new URLSearchParams(window.location.search);

	useEffect(() => {

		const verifyAuth = async () => {
			if(!Auth.isLoggedIn()) {
				Auth.doLogin();
			}
			else {
				const currentToken = Auth.getToken();
				await updateCurrentToken(currentToken || "");
				getCurrentUser(selectedApp?.appCode).then((user) => {
					if(user){
						if(!currentToken || !user) {
							alert("Não foi possível recuperar o token do usuário. Por favor, faça o login novamente.");
						}else{
							setCurrentUser(user);
						}
	
						const redirectUrl = params.get("redirect_url");
						if(redirectUrl){
							navigate(redirectUrl, { replace: true });
						}else{
							navigate( "/" + ROUTES.ADMIN + "/" + ROUTES.DASHBOARD.MOBILE_USERS, { replace: true });
						}
					}
				});
			}
		};

		verifyAuth();

	}, []);

	return (
		<Container theme={currentTheme}>
			<Card  theme={currentTheme}>
				<img src={currentTheme.type === THEME_TYPES.DARK ? "/images/logoDark.png" : "/images/logo.png"} />
				<Loader />
			</Card>
		</Container>
	);
};

export default PostLogin;