import styled from "styled-components";
import { Theme } from "../../../types/Theme";

interface ContainerProps {
    theme: Theme,
}

export const HeaderStyled = styled.header<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    height: 8%;
    position: fixed;
    width: 100%;
    background-color: ${props => props.theme.backgroundPage};
    justify-content: space-between;
    z-index: 5;

    > h1 {
        color: ${props => props.theme.primaryColor};
        display: flex;
        width: 100%;
        min-width: 280px;
        padding-left: 100px;

    }
`;

export const LeftSide = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1%;

    img {
        max-width: 250px;
    }

    h3 {
        text-decoration: underline;
        color: ${props => props.theme.secondaryColor};
        margin-left: 10%;
        white-space: nowrap;

        &:hover {
            cursor: pointer;
            opacity: 0.75;
        }
    }
`;

export const RightSide = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    max-width: 310px;
`;
