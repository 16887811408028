import styled from "styled-components";


export const StackContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30px;

    &:hover {
        cursor: pointer;
    }
`; 
