import { getApi } from ".";
import { Firmware, Firmware3in1, FirmwareSequential } from "../../types/Firmware";
import { FirmwareMapper } from "../../types/Mapper/FirmwareMapper";

export const getFirmwareList = (appCode: number, deviceSlug: string) => {
	return new Promise<(Firmware|Firmware3in1|FirmwareSequential)[]>((resolve, reject) => {
		getApi(appCode).get(`/firmwaresAll?SmartWatchSlug=${deviceSlug}`)
			.then((response) => {
				const firmwareDict = response.data;
				const list1 = firmwareDict.SingleFileUpdate.map((apiFirmware) => FirmwareMapper.fromApiToModel_SingleFile(apiFirmware)) as Firmware[];
				const list2 = firmwareDict.ThreeInOneUpdate.map((apiFirmware) => FirmwareMapper.fromApiToModel_ThreeInOne(apiFirmware)) as Firmware3in1[];
				const list3 = firmwareDict.FirmwareSequential.map((apiFirmware) => FirmwareMapper.fromApiToModel_Sequential(apiFirmware)) as FirmwareSequential[];
				resolve([ ...list1, ...list2, ...list3 ]);
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};


export const createNewFirmware = (firmware: Firmware, appCode: number, deviceSlug: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/firmwares", FirmwareMapper.fromModelToApi_SingleFile(firmware, deviceSlug))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const createNewFirmware3in1 = (firmware: Firmware3in1, appCode: number, deviceSlug: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/firmwares3in1", FirmwareMapper.fromModelToApi_ThreeInOne(firmware, deviceSlug))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const createNewFirmwareSequential = (firmware: FirmwareSequential, appCode: number, deviceSlug: string) => {
	return new Promise<void>((resolve, reject) => {
		console.log("SALVANDO ESSA CRIANCA: ", FirmwareMapper.fromModelToApi_Sequential(firmware, deviceSlug));
		getApi(appCode).post("/firmware_sequential", FirmwareMapper.fromModelToApi_Sequential(firmware, deviceSlug))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const updateFirmware = (firmware: Firmware, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put(`/firmwares?id=${firmware.id}`, FirmwareMapper.fromModelToApi_SingleFile(firmware, null))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const updateFirmware3in1 = (firmware: Firmware3in1, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put(`/firmwares3in1?id=${firmware.id}`, FirmwareMapper.fromModelToApi_ThreeInOne(firmware, null))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const deleteFirmware = (firmwareId: number, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`/firmwares?id=${firmwareId}`)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const deleteFirmware3in1 = (firmwareId: number, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`/firmwares3in1?id=${firmwareId}`)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const deleteFirmwareSequential = (firmwareId: number, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`/firmware_sequential?id=${firmwareId}`)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};