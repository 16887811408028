import React from "react";
import { StackContainer } from "./styles";
import { ReactComponent as TrashIcon } from "../../../assets/images/trashIcon.svg";
import { useTheme } from "../../../contexts/theme";
import { MESSAGES } from "../../../constants/messages";
import { Tooltip } from "../../Tooltip";

interface DeleteButtonProps {
    onClick: () => void;
	disabled?: boolean;
}

const DeleteButton = (props: DeleteButtonProps) => {
	const tooltipId = "delete-tooltip";
	const { currentTheme } = useTheme();

	return (
		<StackContainer onClick={() => props.disabled ? null :  props.onClick()} disabled={props.disabled} data-tooltip-id={tooltipId} data-tooltip-content={MESSAGES.PT_BR.BUTTON_MESSAGES.DELETE}>
			<TrashIcon />
			<Tooltip id={tooltipId} color={currentTheme.primaryColor} position="bottom" />

		</StackContainer>
	);
};

export default DeleteButton;
