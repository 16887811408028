
/**
 * API - Object
 * {
    "version": "string",
	"deviceId": "string",
    "zipFile": "string",
	"size_bytes": "number",
    "md5": "string",
    "updatedAt": "2023-02-09T17:26:54.399Z"
}
 */

/**
 * APP - Object
 * {
 		id: number;
        version: string;
		deviceId?: string,
        description: string;
        file: string;
        md5: string;
		size: number;
		numOfFiles: 1;
        date: string;
    }
 */


const fromApiToModel_SingleFile = (firmware) => {
	return {
		id: firmware.id,
		version: firmware.version,
		androidVersion: firmware.androidVersion,
		iosVersion: firmware.iosVersion,
		deviceId: firmware.deviceId,
		deviceVersionOperator: firmware.deviceVersionOperator,
		deviceVersion: firmware.deviceVersion,
		deviceVersionEnd: firmware.deviceVersionEnd,
		description: firmware.description,
		file: firmware.zipFile,
		size: firmware.size,
		md5: firmware.md5,
		numOfFiles: 1,
		date: firmware.updatedAt
	};
};

const fromApiToModel_ThreeInOne = (firmware) => {
	return {
		id: firmware.id,
		version: firmware.version,
		androidVersion: firmware.androidVersion,
		iosVersion: firmware.iosVersion,
		deviceId: firmware.deviceId,
		deviceVersionOperator: firmware.deviceVersionOperator,
		deviceVersion: firmware.deviceVersion,
		deviceVersionEnd: firmware.deviceVersionEnd,
		description: firmware.description,
		DATA_TRAN: { file: firmware.dataTranFile, size: firmware.dataTranSize, md5: firmware.dataTranMd5 },
		FONT_FILE: { file: firmware.fontFileFile, size: firmware.fontFileSize, md5: firmware.fontFileMd5 },
		DFU: { file: firmware.dfuFile, size: firmware.dfuSize, md5: firmware.dfuMd5 },
		numOfFiles: 3,
		date: firmware.updatedAt
	};
};

const fromApiToModel_Sequential = (firmware) => {
	return {
		id: firmware.id,
		version: firmware.version,
		androidVersion: firmware.androidVersion,
		iosVersion: firmware.iosVersion,
		deviceId: firmware.deviceId,
		description: firmware.description,
		files: firmware.firmwareFileItems.map(file => ({
			file: file.fileUrl,
			md5: file.md5,
			size: file.size
		})),
		date: firmware.updatedAt,
		numOfFiles: firmware.firmwareFileItems.length,
		isSequential: true,
	};
};

const fromModelToApi_SingleFile = (firmware, deviceSlug) => {
	return {
		version: firmware.version,
		android_version: _parseEmptyStringToNull(firmware.androidVersion),
		ios_version: _parseEmptyStringToNull(firmware.iosVersion),
		deviceId: _parseEmptyStringToNull(firmware.deviceId),
		device_version_operator: firmware.deviceVersionOperator,
		device_version: _parseEmptyStringToNull(firmware.deviceVersion),
		device_version_end: _parseEmptyStringToNull(firmware.deviceVersionEnd),
		zipFile: firmware.file,
		description: firmware.description,
		size_bytes: firmware.size,
		md5: firmware.md5,
		smartWatchSlug: deviceSlug
	};
};

const fromModelToApi_ThreeInOne = (firmware, deviceSlug) => {
	return {
		version: firmware.version,
		android_version: _parseEmptyStringToNull(firmware.androidVersion),
		ios_version: _parseEmptyStringToNull(firmware.iosVersion),
		deviceId: _parseEmptyStringToNull(firmware.deviceId),
		device_version_operator: firmware.deviceVersionOperator,
		device_version: _parseEmptyStringToNull(firmware.deviceVersion),
		device_version_end: _parseEmptyStringToNull(firmware.deviceVersionEnd),
		description: firmware.description,
		data_tran_file: firmware.DATA_TRAN.file,
		data_tran_size_bytes: firmware.DATA_TRAN.size,
		data_tran_md5: firmware.DATA_TRAN.md5,
		dfu_file: firmware.DFU.file,
		dfu_size_bytes: firmware.DFU.size,
		dfu_md5: firmware.DFU.md5,
		font_file_file: firmware.FONT_FILE.file,
		font_file_size_bytes: firmware.FONT_FILE.size,
		font_file_md5: firmware.FONT_FILE.md5,
		smartWatchSlug: deviceSlug,
	};
};


const fromModelToApi_Sequential = (firmware, deviceSlug) => {
	return {
		version: firmware.version,
		android_version: _parseEmptyStringToNull(firmware.androidVersion),
		ios_version: _parseEmptyStringToNull(firmware.iosVersion),
		deviceId: _parseEmptyStringToNull(firmware.deviceId),
		description: firmware.description,
		file_items: firmware.files.map(file => ({
			file_url: file.file,
			md5: file.md5,
			size: file.size
		})),
		smartWatchSlug: deviceSlug,
	};
};

const _parseEmptyStringToNull = (value) => {
	// O valor inicial no formulário é null mas caso o usuário escreva algo no campo e apague, fica com uma string vazia
	if (value === "") return null;
	return value;
};

export const FirmwareMapper = {
	fromApiToModel_SingleFile,
	fromApiToModel_ThreeInOne,
	fromApiToModel_Sequential,
	fromModelToApi_SingleFile,
	fromModelToApi_ThreeInOne,
	fromModelToApi_Sequential
};
