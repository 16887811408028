import styled from "styled-components";
import { Theme } from "../../../types/Theme";

interface ContainerProps {
    theme: Theme;
    disabled?: boolean;
}

export const StackContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-bottom: 28px;
    opacity: ${props => props.disabled ? 0.5 : 1};

    h5 {
        width: 100%;
        padding-left: 10px;
        padding-bottom: 5px;
        font-size: 18px;
        box-sizing: border-box;
        color: ${props => props.theme.textColor};
    }

    div {
        &:hover {
            cursor: pointer;
        }
    }

    p {
        color: ${props => props.theme.secondaryColor};
        font-size: 16px;
        font-weight: 400;
        width: 100%;
    }
`;

export const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 55px;
    border-radius: 16px;
    padding: 10px;
    box-sizing: border-box;
    justify-content: space-between;
    flex-direction: row;
    background-color: ${props => props.theme.backgroundCardSecondary};
`;

export const Spacing = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 75px;
    width: 100%;
    padding-top: 10px;
    z-index: 999;
`;

export const ItemsContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: ${props => props.theme.backgroundCardSecondary};
    width: 100%;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    max-height: 250px;
    overflow-y: scroll;
`; 

export const DropdownItem = styled.div<ContainerProps>`
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 5px;

    &:hover {
        background-color: ${props => props.theme.backgroundCardSurface};
    }
`;

export const Placeholder = styled.p`
    font-family: "Normal";
`;