import styled from "styled-components";

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 24px;
    padding: 20px;
    box-sizing: border-box;
`;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    h1 {
        font-size: 28px;
        padding: 5px;
        text-align: center;
        font-weight: 500;
        line-height: 30px;
        color: ${props => props.theme.primaryColor};
    }

    div {
        &:hover {
            cursor: pointer;
        }
    }
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
`;


export const RowContainer = styled.div`
    width: 100%;
`;

export const ModalFooter = styled.div`
    margin-top: 15px;
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button {
        width: 25%;
        margin: 0;
    }

    p{
        align-self: center;
        margin-right: 15%;
        text-decoration: underline;
        color: ${props => props.theme.textColor};

        &:hover {
            cursor: pointer;
        }
    }

`;

export const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    flex: 0.5;
`;

export const FilesContainer = styled.div`
    div {
        margin-left: 0px;
        margin-right: 0px;
    }

    h1{
        margin-left: 10px;
    }
`;

export const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0.5;
    justify-content: center;
    align-items: center;
`;

export const ImgContainer = styled.div`
    display: flex;
    background-color: ${props => props.theme.backgroundCardSecondary};
    width: 220px;
    height: 220px;
    border-radius: 27px;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 27px;
    }
`;