import styled from "styled-components";

interface ModalProps {
	isOpen: boolean,
}

export const ModalContainer = styled.div<ModalProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	opacity: ${props => props.isOpen ? 1 : 0};
	visibility: ${props => props.isOpen ? "visible" : "hidden"};
	transition: visibility 0s, opacity 0.3s linear;
`;


export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 50%;
	min-width: 400px;
	background-color: ${props => props.theme.backgroundCard};
	border-radius: 10px;
	padding: 20px;
	box-sizing: border-box;
`;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    h1 {
        font-size: 28px;
        padding: 10px;
        text-align: center;
        font-weight: 500;
        line-height: 30px;
        color: ${props => props.theme.primaryColor};
    }

    div {
        &:hover {
            cursor: pointer;
        }
    }
`;

export const ModalBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;

	p {
		font-size: 18px;
		font-weight: 200;
		text-align: left;
		margin-bottom: 30px;
        margin-left: 8px;
		color: ${props => props.theme.textColor};
		font-family: "Normal";
	}
`;


export const ModalFooter = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button {
        width: 30%;
        margin-right: 10px;
        border-radius: 15px;
        background-color: ${props => props.theme.primaryColor};
    }

    p {
        text-decoration: underline;
        color: ${props => props.theme.textColor};

        &:hover {
            cursor: pointer;
        }
    }
`;

export const GroupContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;
    width: 40%;
    justify-content: flex-end;

    p {
        white-space: nowrap;
    }
`;





