export const NOTIFICATIONS = {
	NOTIFICATIONS_TITLE: "Notificações",
	NOTIFICATIONS_EMPTY: "Nenhuma notificação",
	NEW_NOTIFICATION_BTN: "Nova mensagem",
	HEADER_TYPE: "Tipo",
	HEADER_MESSAGE: "Mensagem",
	HEADER_SYSTEM: "Sistema",
	HEADER_DATE: "Data",

	NEW_NOTIFICATION_TITLE: "Nova notificação",
	NOTIFICATION: "Notificação",
	INPUT_TITLE: "Título",
	INPUT_CONTENT: "Conteúdo",
	INPUT_LINK: "Link",
	INPUT_PHOTO: "Foto",

	SEGMENTATION: "Segmentação",
	LOCATION: "Localização",
	APP: "Aplicativo",
	PUSH: "Push notifications",
	APP_AND_PUSH: "Aplicativo e Push notifications",

	TITLE_PLACEHOLDER: "Título",
	CONTENT_PLACEHOLDER: "Conteúdo",

	ANDROID: "Android",
	IOS: "iOS",
	ALL_OS: "Android e IOS",

	PLACEHOLDER_INPUT_TITLE: "Digite o título da notificação",
	PLACEHOLDER_INPUT_CONTENT: "Escreva o conteúdo da notificação",
	PLACEHOLDER_INPUT_LINK: "Link da notificação",
	PLACEHOLDER_INPUT_PHOTO: "Escolher arquivo",

	INFO_TITLE: "Quantidade máxima de caracteres para notificação com imagem (incluindo espaços):",
	WITH_IMAGE: "Com Imagem:",
	CHARACTERS: "caracteres",
	WITHOUT_IMAGE: "Sem Imagem:",

	MAX_TAM_IMG: "Tamanho máximo:",
	TAM_IMG: "250x250 pixels",

	IMG_FORMAT: "Formato:",
	FORMAT: ".jpeg, .png",


	PROG: "Programação",
	SEND_NOTIFICATION: "Enviar",
	HOUR: "Hora",

	SENDING: "Enviando...",
	SUCCESS_TITLE: "Notificação enviada com sucesso!",
	SUCCESS_DESCRIPTION: "A notificação foi enviada com sucesso para todos os usuários selecionados!",

	MANDATORY_FIELD: "Campo obrigatório",

	ERROR_TITLE: "Erro ao enviar notificação",
	ERROR_DESCRIPTION: "Ocorreu um erro ao enviar a notificação, tente novamente mais tarde. Erro: ",
	ERROR_USE_TERMS: "Ocorreu um erro ao salvar o termo"
};