export type FIRMWARE = Firmware | Firmware3in1 | FirmwareSequential;

export interface Firmware {
  id: number;
  version: string;
  androidVersion?: string;
  iosVersion?: string;
  deviceId?: string;
  deviceVersionOperator?: Operator;
  deviceVersion?: string;
  deviceVersionEnd?: string;
  description: string;
  file: string;
  size: number;
  md5: string;
  date: string;
  numOfFiles: number;
  isSequential: boolean;
}

interface FirmwareFile {
  file: string;
  size: number;
  md5: string;
}

export interface Firmware3in1 {
  id: number;
  version: string;
  androidVersion?: string;
  iosVersion?: string;
  deviceId?: string;
  deviceVersionOperator?: Operator;
  deviceVersion?: string;
  deviceVersionEnd?: string;
  description: string;
  date: string;
  DATA_TRAN: FirmwareFile,
  FONT_FILE: FirmwareFile,
  DFU: FirmwareFile,
  numOfFiles: number;
  isSequential: boolean;
}

export interface Files3in1 {
	DATA_TRAN: File;
	FONT_FILE: File;
	DFU: File;
}

export interface FirmwareSequential {
  id: number;
  version: string;
  androidVersion?: string;
  iosVersion?: string;
  deviceId?: string;
  deviceVersionOperator?: Operator;
  deviceVersion?: string;
  deviceVersionEnd?: string;
  description: string;
  date: string;
  files: FirmwareFile[];
  numOfFiles?: number;
  isSequential: boolean;
}

export enum Operator {
  EqualTo = 1,
  GreaterOrEqual = 2,
  InBetween = 3,
}

export const OperatorsList = [ "EqualTo", "GreaterOrEqual", "InBetween" ];