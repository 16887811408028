import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { ReactComponent as TrashIcon } from "../../../../../../assets/images/trashIcon.svg";
import { useTheme } from "../../../../../../contexts/theme";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { MESSAGES } from "../../../../../../constants/messages";
import { MainButton } from "../../../../../../components/Buttons";
import { DeleteItemModal } from "../../../../../../components";
import { ImageInputWithPreview } from "../../../../../Inputs";
import { MobileApp } from "../../../../../../types/MobileApp";
import { ModalBody, ModalContainer, ModalContent, ModalHeader, ScrollContainer, AppContainer, EditContainer, ImgContainer, InputStyled, ConfirmDeleteToaster } from "./styles";

interface ModalProps {
	isOpen: boolean;
	onCancel: () => void;
	handleEditMobileApps: (apps: MobileApp[]) => void;
	handleDeleteMobileApp: (app: MobileApp, deletingIndex: number) => void;
}

const EditAppModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();
	const { appList } = useMobileApp();
	
	const [editingMobileApps, setEditingMobileApps] = useState<MobileApp[]>([]);
	const [appsNameError, setMobileAppsNameError] = useState<string[]>([]);
	const [deletingIndex, setDeletingIndex] = useState<number>(-1);
	const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
	const [flag, setFlag] = useState<boolean>(false); // force re-rendering

	useEffect(() => {
		setEditingMobileApps(structuredClone(appList)); // make a deep copy
		setMobileAppsNameError(Array(appList.length).fill(""));
	}, [appList]);

	const onChangeName = (newName: string, index: number) => {
		editingMobileApps[index].name = newName;
		setEditingMobileApps(editingMobileApps);
		setFlag(!flag);
	};

	const onSelectImage = (file: File, index: number) => {
		editingMobileApps[index].image = URL.createObjectURL(file);
		setEditingMobileApps(editingMobileApps);
		setFlag(!flag);
	};

	const handleEditMobileApp = () => {
		let allRight = true;

		editingMobileApps.forEach((app, index) => {
			if (app.name.length > 0) {
				appsNameError[index] = "";
				setMobileAppsNameError(appsNameError);
			} else {
				allRight = false;
				appsNameError[index] = MESSAGES.PT_BR.SUPPORT_MESSAGES.SUBJECT_ERROR;
				setMobileAppsNameError(appsNameError);
			}
		});

		setFlag(!flag);
		if (allRight) props.handleEditMobileApps(editingMobileApps);
	};

	const handleDeleteMobileApp = () => {
		setDeleteModalVisible(false);

		const deleteTimeout = setTimeout(() => {
			props.handleDeleteMobileApp(editingMobileApps[deletingIndex], deletingIndex);
		}, 10500);

		toast(
			(t) => (
				<ConfirmDeleteToaster theme={currentTheme}>
					<span>{MESSAGES.PT_BR.APP_MODAL.APP_DELETED}&emsp;</span>
					<b onClick={() => { clearTimeout(deleteTimeout); toast.dismiss(t.id); }} >
						{MESSAGES.PT_BR.APP_MODAL.UNDO_DELETE}
					</b>
				</ConfirmDeleteToaster>
			)
		);
	};

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<DeleteItemModal
				isOpen={deleteModalVisible}
				onCancel={() => setDeleteModalVisible(false)}
				title={MESSAGES.PT_BR.APP_MODAL.DELETE_APP_TITLE}
				body={MESSAGES.PT_BR.APP_MODAL.DELETE_APP_DESCRIPTION}
				handleDelete={handleDeleteMobileApp}
			/>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.APP_MODAL.EDIT_APP}</h1>
					<div>
						<CloseIcon onClick={props.onCancel} />
					</div>
				</ModalHeader>
				<ModalBody theme={currentTheme}>
					<ScrollContainer>
						{editingMobileApps.map((app, index) => (
							<AppContainer theme={currentTheme} key={index} >
								<ImageInputWithPreview
									onSelectImage={(e) => onSelectImage(e, index)}
									image={app.image}
									style={{ minWidth:"100px", width:"100px",height:"100px", marginTop:"20px", backgroundColor:"#F5F6F7" }}
									editBtnStyle={ {width:"40px", height:"40px", backgroundColor:"#D9D9D9" }}
									defaultImageComponent={<IoPhonePortraitOutline color={currentTheme.textColor} size={"60%"} />}
								/>
								<EditContainer theme={currentTheme}>
									<div>
										<h1>Nome do Dispositivo</h1>
										<ImgContainer onClick={() => { setDeletingIndex(index); setDeleteModalVisible(true); }}>
											<TrashIcon />
										</ImgContainer>
									</div>
									<InputStyled
										theme={currentTheme}
										onChange={(e) => onChangeName(e.target.value, index)}
										value={app.name}
										errorText={appsNameError[index]}
									/>
									{appsNameError[index] && <span>{appsNameError[index]}</span>}
								</EditContainer>
							</AppContainer>
						))}
					</ScrollContainer>
					<MainButton enabled onClick={handleEditMobileApp} text={MESSAGES.PT_BR.APP_MODAL.EDIT_BTN} />
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);

};

export default EditAppModal;