import { FIXED_SECTION_NAME } from "../constants";
import { WatchFace, WatchFaceSection } from "../types/Watchface";

export const getNextValidIndexWatchFace = (wfList: WatchFace[]) => {
	const sortedList = [...wfList].sort((a, b) => b.index - a.index);
	return (sortedList[0]?.index ?? 0) + 1;
};

export const getNextValidIndexWfSection = (sectionList: WatchFaceSection [] | null, newWfSectionTitle: string) => {
	if(newWfSectionTitle === FIXED_SECTION_NAME) return -1; //Deve aparecer em primeiro no app.

	if(sectionList?.length && sectionList?.length > 0){
		return sectionList?.length + 2;
	}
	
	return 2;
};