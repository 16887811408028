import React from "react";
import { StackContainer } from "./styles";
import { useTheme } from "../../../contexts/theme";
import { MESSAGES } from "../../../constants/messages";
import { Tooltip } from "../../Tooltip";
import { ReactComponent as PlusIcon } from "../../../assets/images/dark/plusIcon.svg";
import { ReactComponent as PlusIconLight } from "../../../assets/images/light/plusIcon.svg";
import { THEME_TYPES } from "../../../constants";
import { COLORS } from "../../../assets";


interface AddButtonProps {
	onClick: () => void;
}

const AddButton = (props: AddButtonProps) => {
	const tooltipId = "add-tooltip";
	const { currentTheme } = useTheme();

	return (
		<StackContainer onClick={() => props.onClick()} data-tooltip-id={tooltipId} data-tooltip-content={MESSAGES.PT_BR.BUTTON_MESSAGES.ADD}>
			{currentTheme.type === THEME_TYPES.DARK ? <PlusIconLight /> : <PlusIcon />}
			
			<Tooltip id={tooltipId} color={COLORS.DEFAULT_COLORS.DARK90} position="bottom" />
		</StackContainer>
	);
};

export default AddButton;
