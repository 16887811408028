import React from "react";
import { COLORS } from "../../../../../../assets";
import { MainButton } from "../../../../../../components/Buttons";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { Firmware, Firmware3in1, FirmwareSequential } from "../../../../../../types/Firmware";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader } from "./styles";

interface ModalProps {
    isOpen: boolean;
    onCancel: () => void;
    onDeleteRequested: (firmware: Firmware | Firmware3in1 | FirmwareSequential) => void;
	selectedFirmwareToDel: Firmware | Firmware3in1 | FirmwareSequential;
}

const DeleteFirmwareModal = (props: ModalProps) => {

	const {currentTheme} = useTheme();

	return (
		<ModalContainer isOpen={props.isOpen} >
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.FIRMWARE.DELETE_MODAL_TITLE}</h1>
				</ModalHeader>
				<ModalBody>
					<p>{MESSAGES.PT_BR.FIRMWARE.DELETE_MODAL_DESCRIPTION}</p>
				</ModalBody>
				<ModalFooter>
					<MainButton
						enabled
						onClick={() => props.onCancel()}
						text={MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.CANCEL_BTN}
						darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
						lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
					/>
					<MainButton
						enabled
						onClick={() => props.onDeleteRequested(props.selectedFirmwareToDel)}
						text={MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.DELETE_BTN}
						darkColor={currentTheme.primaryColor}
						lightColor={currentTheme.primaryColor}
					/>
				</ModalFooter>
			</ModalContent>
		</ModalContainer>
	);
};

export default DeleteFirmwareModal;