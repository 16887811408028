export const USER_MESSAGES = {
	SEARCH_PLACEHOLDER: "Digite o nome ou o e-mail do usuário",
	NAME: "Nome",
	EMAIL: "E-mail",
	GENRE: "Gênero",
	ROLE: "Papel",
	EMAIL_CHECK: "E-mail \n confirmado",
	ACTION: "Ação",
	MALE_GENRE: "Homem",
	FEMALE_GENRE: "Mulher",
	OTHER_GENRE: "Outro",
	NOT_INFORMED: "Não informado",
	CONFIRM_BUTTON: "E-mail de\nconfirmação",
	RESET_PASSWORD_BUTTON: "Redefinir\nsenha",
	DELETE_BUTTON: "Excluir\nconta",
	DELETE_MODAL_TITLE: "Você tem certeza que deseja excluir a conta dessa pessoa?",
	DELETE_MODAL_DESCRIPTION_A: "Depois que a conta for excluída, os dados",
	DELETE_MODAL_DESCRIPTION_B: " não poderão ",
	DELETE_MODAL_DESCRIPTION_C: "ser recuperados. Apenas continue caso a",
	DELETE_MODAL_DESCRIPTION_D: " pessoa usuário ",
	DELETE_MODAL_DESCRIPTION_E: "tenha solicitado expressamente essa intenção.",
	DELETE_MODAL_CONFIRM_BUTTON: "Tenho certeza, excluir.",
	DELETE_MODAL_CANCEL_BUTTON: "Não tenho certeza, cancelar.",

	REQUIRED_FIELD: "Campo obrigatório.",
	
	RESET_PASSWORD_TITLE: "E-mail de redefinição de senha enviado com sucesso!",
	RESET_PASSWORD_DESCRIPTION: "O e-mail de redefinição de senha foi enviado com sucesso para o usuário selecionado.",

	RESET_PASSWORD_ERROR_TITLE: "Erro ao enviar e-mail de redefinição de senha!",
	RESET_PASSWORD_ERROR_DESCRIPTION: "Ocorreu um erro ao enviar o e-mail de redefinição de senha para o usuário selecionado. Erro: ",

	CONFIRMATION_ERROR_TITLE: "Erro ao enviar e-mail de confirmação de conta!",
	CONFIRMATION_ERROR_DESCRIPTION: "Ocorreu um erro ao enviar o e-mail de confirmação de conta para o usuário selecionado. Erro: ",
	
	EMAIL_CONFIRMATION_TITLE: "E-mail de confirmação enviado com sucesso!",
	EMAIL_CONFIRMATION_DESCRIPTION: "O e-mail de confirmação de conta foi enviado com sucesso para o usuário selecionado.",
	
	WEBADMIN_USERS_HEADER: "Usuários do webadmin",
	NEW_USER: "Novo usuário",
	ADD_USER_MODAL_TITLE: "Adicionar usuário",
	EDIT_USER_MODAL_TITLE: "Editar usuário",
	EDIT_PROFILE_MODAL_TITLE: "Editar perfil",
	EDIT_PASSWORD_MODAL_TITLE: "Editar senha",
	DELETE_USER_MODAL_TITLE: "Tem certeza que deseja excluir o usuário?",
	DELETE_USER_MODAL_DESCRIPTION: "Caso você exclua esse usuário, ele não conseguirá mais acessar o webadmin, deseja realmente executar essa ação?",
	NAME_PLACEHOLDER: "Digite o nome",
	EMAIL_PLACEHOLDER: "Digite o e-mail",

	OLD_PASSWORD: "Senha antiga",
	NEW_PASSWORD: "Criar nova senha",
	NEW_PASSWORD2: "Confirmar nova senha",
	NEW_PASSWORD_ERROR: "As senhas não coincidem",
	PASSWORD_MINIMUM_CHARACTERS_ERROR: "Mínimo de 8 caracteres",

	ADD_USER_BTN: "Adicionar",
	ADD_AND_NEW_USER_BTN: "Continuar adicionando",
	CHANGE_PASSWORD_BTN: "Alterar senha",
	SAVE_BTN: "Salvar",

	NEW_USER_FEEDBACK_TITLE: "Usuário adicionado com sucesso!",
	NEW_USER_FEEDBACK_TITLE_ERROR: "Usuário não adicionado!",
	NEW_USER_FEEDBACK_DESCRIPTION: "Agora você pode continuar adicionando novos usuários ou voltar para a tela inicial.",
	NEW_USER_FEEDBACK_DESCRIPTION_ERROR: "Ocorreu um erro ao adicionar o usuário: ",
	ROLE_EDIT_FEEDBACK_TITLE: "Salvo com sucesso!",
	ROLE_EDIT_FEEDBACK_DESCRIPTION: "O Papel do usuário foi editado e salvo com sucesso!",

	DELETE_USER_FEEDBACK_TITLE: "Usuário excluído com sucesso",
	DELETE_USER_FEEDBACK_DESCRIPTION: "Usuário selecionado foi excluído com sucesso.",
	DELETE_USER_FEEDBACK_ERROR: "Houve um erro na exclusão do usuário",
	DELETE_USER_FEEDBACK_DES_ERROR: "O usuário selecionado não pode ser deletado.",	
	DELETE_USER_TOAST_LOADING: "Excluíndo usuário.",

	MANDATORY_FIELD: "O campo é obrigatório",
	EMAIL_ADMIN_USER: "Preencher e-mail válido",
	LOADING: "Carregando",
	PROFILE_EDIT_FEEDBACK_TITLE: "Salvo com sucesso!",
	PROFILE_EDIT_FEEDBACK_DESCRIPTION: "Perfil salvo com sucesso!",
	CHANGE_PASSWORD_FEEDBACK_TITLE: "Senha alterada",
	CHANGE_PASSWORD_FEEDBACK_DESCRIPTION: "A sua senha foi alterada com sucesso! Utilize a nova senha para entrar no seu próximo login.",

	UPLOADING_IMAGE: "Enviando imagem",
	IMAGE_UPLOAD_ERROR: "Erro ao enviar imagem",

	PROFILE_EDIT_SUCCESS: "Perfil salvo com sucesso!",
	PROFILE_EDIT_ERROR: "Erro ao salvar perfil",
	PROFILE_EDIT_ERROR_DESCRIPTION: "Ocorreu um erro ao salvar o seu perfil. Tente novamente mais tarde.",

	CHANGE_PASSWORD_ERROR: "Erro ao alterar senha",
	CHANGE_PASSWORD_ERROR_DESCRIPTION: "Ocorreu um erro ao alterar a sua senha. Tente novamente mais tarde.",
	HELP_LOGIN: "*Será preciso entrar novamente após a alteração.",
};

