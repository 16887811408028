import styled from "styled-components";
import { Theme } from "../../../../../../../../types/Theme";

interface ContainerProps {
    theme: Theme,
    padding?: number,
}

export const TableItemStyled = styled.tr<ContainerProps>`

    td {
        color: ${props => props.theme.textColor};
    }

`;

export const TypeContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 130px;
    padding: ${props => props.padding ? props.padding : 0}px;
    box-sizing: border-box;
    padding-left: 50px;
    padding-right: 50px;

    h1 {
        margin: 5px;
    }

    img {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }
`;


export const MsgContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;

    p {
        color: ${props => props.theme.textColor};
        text-align: left;
        width: 100%;
        font-weight: 100;
        font-family: "Normal";
    }

    h3 {
        color: ${props => props.theme.textColor};
        text-align: left;
        width: 100%;
        margin-bottom: 5px;
    }
`;