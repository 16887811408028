import React from "react";
import { MESSAGES } from "../../../constants/messages";
import { useTheme } from "../../../contexts/theme";
import { ModalBody, ModalContainer, ModalContent, ModalHeader } from "./styles";
import { MainButton } from "../../../components/Buttons";
import { COLORS } from "../../../assets";

interface ModalProps {
	isOpen: boolean;
	title: string;
	body: string;
	onCancel: () => void;
	handleDelete: () => void;
}

const DeleteItemModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();

	return (
		<ModalContainer isOpen={props.isOpen}>
			<ModalContent>
				<ModalHeader>
					<h3>{props.title}</h3>
				</ModalHeader>
				<ModalBody>
					<p>{props.body}</p>
					<div>
						<MainButton
							enabled
							onClick={props.onCancel}
							text={MESSAGES.PT_BR.FAQ_MESSAGES.CANCEL_BTN}
							lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
							darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
						/>
						<h5></h5>
						<MainButton
							enabled
							onClick={props.handleDelete}
							text={MESSAGES.PT_BR.FAQ_MESSAGES.CONFIRM_DELETE}
							lightColor={currentTheme.primaryColor}
							darkColor={currentTheme.primaryColor}
						/>
					</div>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);

};

export default DeleteItemModal;