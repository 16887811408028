import React, { useMemo } from "react";
import { FileInput } from "../../../../../../../../components/Inputs";
import { MESSAGES } from "../../../../../../../../constants/messages";
import { ButtonContainer, FileButtonsContainer } from "./styles";
import { IconButton } from "../../../../../../../../components/Buttons";
import { ReactComponent as RedPlusIcon } from "../../../../../../../../assets/images/redPlusIcon.svg";

interface AddFileManagerProps {
	fileList: File[];
	setFileList: (fileList: File[]) => void;
	filesError: {index: number, error: string}[]
}

const AddFileManager = (props: AddFileManagerProps) => {
	const emptyFile = new File([], "");

	const canAddNewFile = useMemo(() => {
		if (props.fileList.length > 0) {
			return props.fileList[props.fileList.length - 1].size !== 0;
		}

		return true;
	}, [props.fileList]);

	const handleOnChangeFile = (file: File) => {
		const newFileList = props.fileList;
		newFileList[newFileList.length - 1] = file;
		props.setFileList([...newFileList]);
	};

	const handleAddFileButton = () => {
		if (canAddNewFile) {
			props.setFileList([...props.fileList, emptyFile]);
		}
		return;
	};

	return (
		<FileButtonsContainer>
			{
				props.fileList?.length > 0 &&
				props.fileList.map((file, index) => {
					return (
						<FileInput
							key={index}
							accept="*"
							onChange={handleOnChangeFile}
							title={MESSAGES.PT_BR.FIRMWARE.GENERIC_FILE + (index + 1)}
							value={file.name}
							style={{ margin: 0, marginLeft: index !== 0 ? 8 : 0, width: "100%" }}
							errorText={props.filesError.find((error) => error.index === index)?.error || ""}
						/>
					);
				})
			}
			<ButtonContainer>
				<IconButton
					onClick={handleAddFileButton}
					enabled={canAddNewFile}
					svgImage={<RedPlusIcon />}
					styles={{ marginLeft: 8, marginTop: props.fileList?.length > 0 ? 0 : 18 }}
				/>
			</ButtonContainer>
		</FileButtonsContainer>
	);
};

export default AddFileManager;