import React from "react";
import { AdminUser } from "../../../../../../../types/AdminUser";
import { TableItemStyled, ImgContainer } from "./styles";
import { DeleteButton, EditButton } from "../../../../../../../components/Buttons";
import { useAdminUser } from "../../../../../../../contexts/adminUser";
import { canDeleteAdminUser, canManage } from "../../../../../../../services/auth/PermissionGate";
import { FEATURES } from "../../../../../../../constants";

interface TableItemProps {
  user: AdminUser;
	onClickEdit: (user: AdminUser) => void;
	onClickDelete: (user: AdminUser) => void;
	adminList: AdminUser[];
}

const TableItem = (props: TableItemProps) => {

	const { currentUser } = useAdminUser();
	const canManagerUsers = canManage(currentUser.role, FEATURES.ADMIN_USERS) && canDeleteAdminUser(currentUser.role, props.user, props.adminList);

	return (
		<TableItemStyled canManage={canManagerUsers}>
			<td>{props.user.name}</td>
			<td>{props.user.email}</td>
			<td>{props.user.role}</td>
			<td>
				<div>
					<ImgContainer >
						<EditButton disabled={!canManagerUsers} onClick={() => canManagerUsers && props.onClickEdit(props.user)} />
					</ImgContainer>
					<ImgContainer >
						<DeleteButton disabled={!canManagerUsers} onClick={() => canManagerUsers && props.onClickDelete(props.user)} />
					</ImgContainer>
				</div>
			</td>
		</TableItemStyled>
	);
};

export default TableItem;