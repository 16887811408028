import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { MESSAGES } from "../../../../../../constants/messages";
import { FIRMWARE, FirmwareSequential } from "../../../../../../types/Firmware";
import { MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, RowContainer } from "./styles";
import { Helpers } from "../../../../../../helpers";
import { AddFileManager } from "./components";

interface Props {
	isOpen: boolean;
	onCancel: () => void;
	handleNewFirmware: (firmware: FirmwareSequential, files: File[]) => void;
	firmwareList: FIRMWARE[] | null;
}

const AddFirmwareSequentialModal = (props: Props) => {
	const emptyFile = new File([], "");

	const [buttonEnabled, setButtonEnabled] = useState<boolean>(true);
	const [newFirmwareSequential, setNewFirmwareSequential] = useState<FirmwareSequential>({} as FirmwareSequential);
	const [fileList, setFileList] = useState<File[]>([emptyFile]);
	const [versionError, setVersionError] = useState<string>("");
	const [androidVersionError, setAndroidVersionError] = useState<string>("");
	const [iosVersionError, setIosVersionError] = useState<string>("");
	const [descriptionError, setDescriptionError] = useState<string>("");
	const [fileError, setFileError] = useState<{index: number, error: string}[]>([]);

	const checkNewFirmwareVersionAndDescription = (newFirmware: FirmwareSequential) => {
		let allRight = true;

		if(!newFirmware.version || newFirmware.version === "") {
			setVersionError(MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD);
			allRight = false;
		} else if(props.firmwareList && props.firmwareList.find(firmware => (firmware.version === newFirmware.version) && (firmware.deviceId == newFirmware.deviceId))) {
			setVersionError(MESSAGES.PT_BR.FIRMWARE.VERSION_ALREADY_EXISTS);
			allRight = false;
		}else{
			setVersionError("");
		}

		if (newFirmware.androidVersion && !Helpers.Validators.versionIsValid(newFirmware.androidVersion)) {
			setAndroidVersionError(MESSAGES.PT_BR.FIRMWARE.INVALID_VERSION);
			allRight = false;
		} else {
			setAndroidVersionError("");
		}

		if (newFirmware.iosVersion && !Helpers.Validators.versionIsValid(newFirmware.iosVersion)) {
			setIosVersionError(MESSAGES.PT_BR.FIRMWARE.INVALID_VERSION);
			allRight = false;
		} else {
			setIosVersionError("");
		}
		
		if(!newFirmware.description || newFirmware.description === "") {	
			setDescriptionError(MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD);
			allRight = false;
		} else {
			setDescriptionError("");
		}

		fileList.forEach((file, index) => {
			if (!file.size || file.name === "") {
				setFileError([...fileError, {index: index, error: MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD}]);
				allRight = false;
			} else {
				setFileError(fileError.filter((error) => error.index !== index));
			}
		});

		return allRight;
	};

	const handleNewFirmware = () => {
		const allRight = checkNewFirmwareVersionAndDescription(newFirmwareSequential);

		if(allRight){
			setButtonEnabled(false);
			newFirmwareSequential.isSequential = true;
			props.handleNewFirmware(newFirmwareSequential, fileList);
			resetStates();
		}
	};

	const resetStates = () => {
		setNewFirmwareSequential({} as FirmwareSequential);
		setButtonEnabled(true);
		setVersionError("");
		setDescriptionError("");
		setFileError([]);
		setFileList([emptyFile]);
	};

	const oneFileForm = () => {
		return (
			<>
				<RowContainer>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.HEADER_VERSION}
						value={newFirmwareSequential.version ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.VERSION_PLACEHOLDER}
						onChange={(newValue) => setNewFirmwareSequential({ ...newFirmwareSequential, version: newValue })}
						errorText={versionError}
					/>
					<h5></h5>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.HEADER_DEVICE_ID}
						value={newFirmwareSequential.deviceId ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.DEVICE_ID_PLACEHOLDER}
						onChange={(newValue) => setNewFirmwareSequential({ ...newFirmwareSequential, deviceId: newValue })}
					/>
				</RowContainer>
				<RowContainer>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.HEADER_ANDROID_VERSION}
						value={newFirmwareSequential.androidVersion ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.ANDROID_VERSION_PLACEHOLDER}
						onChange={(newValue) => setNewFirmwareSequential({ ...newFirmwareSequential, androidVersion: newValue })}
						errorText={androidVersionError}
					/>
					<h5></h5>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.HEADER_IOS_VERSION}
						value={newFirmwareSequential.iosVersion ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.IOS_VERSION_PLACEHOLDER}
						onChange={(newValue) => setNewFirmwareSequential({ ...newFirmwareSequential, iosVersion: newValue })}
						errorText={iosVersionError}
					/>
				</RowContainer>
				<RowContainer>
					<AddFileManager 
						fileList={fileList}
						setFileList={setFileList}
						filesError={fileError}
					/>
				</RowContainer>
				<MainInput
					type="text"
					title={MESSAGES.PT_BR.FIRMWARE.DESCRIPTION}
					value={newFirmwareSequential.description ?? ""}
					placeholder={MESSAGES.PT_BR.FIRMWARE.DESCRIPTION_PLACEHOLDER}
					onChange={(newValue) => setNewFirmwareSequential({ ...newFirmwareSequential, description: newValue })}
					errorText={descriptionError}
				/>
			</>
		);
	};

	return (
		<ModalContainer isOpen={props.isOpen}>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.FIRMWARE.NEW_FIRMWARE_SEQUENTIAL_TITLE}</h1>
					<div>
						<CloseIcon onClick={() => {resetStates(); props.onCancel();}} />
					</div>
				</ModalHeader>
				<ModalBody>
					{oneFileForm()}
					<ModalFooter>
						<MainButton
							enabled={buttonEnabled}
							onClick={handleNewFirmware}
							text={MESSAGES.PT_BR.FIRMWARE.ADD_BTN}
						/>
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default AddFirmwareSequentialModal;
