import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { TextIconButton } from "../../../../components/Buttons";
import { Container, DroppableArea, FakeBtn, RightHeaderInfos, WfHeader } from "./styles";
import { ReactComponent as PlusIcon } from "../../../../assets/images/plusIcon.svg";
import { ReactComponent as DataIcon } from "../../../../assets/images/dataIcon.svg";
import { ReactComponent as DragEnabledIcon } from "../../../../assets/images/dragEnabledIcon.svg";
import { ReactComponent as DragDisabledIcon } from "../../../../assets/images/dragDisabledIcon.svg";
import { Loader, StatusModal } from "../../../../components";
import { MESSAGES } from "../../../../constants/messages";
import { COLORS } from "../../../../assets";
import { useWatchFace } from "../../../../contexts/watchFaces";
import { WatchFaceSection } from "../../../../types/Watchface";
import SectionItem from "./components/sections/SectionItem";
import { AddSectionModal } from "./components";
import toast from "react-hot-toast";
import DataAnalytics from "./components/DataAnalytics";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { useDevice } from "../../../../contexts/device";
import { FIXED_SECTION_NAME, SCREEN_SIZE } from "../../../../constants";
import { Helpers } from "../../../../helpers";
import { useWindowDimensions } from "../../../../hooks";

const findFixedSection = (sections: WatchFaceSection[]) => {
	const fixedSection = sections.find((section) => section.sectionName === FIXED_SECTION_NAME);
	return fixedSection ?? null;
};


const WatchFaces = () => {
	const [watchFaceSectionList, setWatchFaceSectionList] = useState<WatchFaceSection[] | null>(null);
	const [fixedWatchFaceSection, setFixedWatchFaceSection] = useState<WatchFaceSection | null>(null);
	const { getWatchFaceListBySection,
		saveNewSortOrder,
		createWatchFaceSection } = useWatchFace();

	const { selectedApp } = useMobileApp();
	const { currentDevice } = useDevice();

	const { width } = useWindowDimensions();

	const [newSectionModalVisible, setNewSectionModalVisible] = useState<boolean>(false);
	const [isDragDisabled, setIsDragDisabled] = useState<boolean>(false);
	const [userDisableDrag, setUserDisableDrag] = useState<boolean>(true);

	const [showAnalytics, setShowAnalytics] = useState<boolean>(false);

	const [statusModalInfo, setStatusModalInfo] = useState({
		isOpen: false,
		title: "",
		description: "",
		success: false,
	});

	useEffect(() => {
		setShowAnalytics(false);
		refreshList();
	}, [currentDevice]);

	const refreshList = () => {
		// sendo chamado toda hora, fica dando loading sem necessidade
		const currentSlug = currentDevice?.slug;
		if (!currentSlug) return;
		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		getWatchFaceListBySection(selectedApp?.appCode ?? 0, currentSlug)
			.then((wfList) => {
				if (wfList) {
					setFixedWatchFaceSection(findFixedSection(wfList));
					wfList = wfList.filter((section) => section.sectionName !== FIXED_SECTION_NAME);
					setWatchFaceSectionList(wfList.sort((a, b) => a.index - b.index));
				} else {
					setWatchFaceSectionList([]);
				}
			})
			.catch((errorMessage) => {
				console.log(errorMessage);
			}).finally(() => {
				toast.dismiss(t);
			});
	};

	const handleOnDragEnd = (result: DropResult) => {
		if (!result.destination || result.source.index === result.destination.index || watchFaceSectionList === null) return;

		const items = Array.from(watchFaceSectionList);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		items.forEach((item, index) => {
			if (item.sectionName === FIXED_SECTION_NAME) {
				item.index = -1;
				return;
			}
			item.index = index;
		});

		setWatchFaceSectionList(items);

		const t = toast.loading(MESSAGES.PT_BR.FAQ_MESSAGES.LOADING);
		saveNewSortOrder(selectedApp.appCode ?? 0, items)
			.then(() => {
				toast.success(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.SUCCESS);
			}).catch(() => {
				toast.error(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.ERROR);
			}).finally(() => {
				toast.dismiss(t);
			});

		return;
	};

	const handleCreateSection = (title: string) => {
		const newWfSection: WatchFaceSection = { id: -1, sectionName: title, watchFaces: [], index: Helpers.WatchFace.getNextValidIndexWfSection(watchFaceSectionList, title) };
		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		setNewSectionModalVisible(false);
		createWatchFaceSection(newWfSection, selectedApp?.appCode ?? 0, currentDevice?.slug ?? "")
			.then(() => {
				setStatusModalInfo({
					isOpen: true,
					title: MESSAGES.PT_BR.WATCH_FACE.CREATE_SECTION_FEEDBACK_MESSAGES.TITLE,
					description: MESSAGES.PT_BR.WATCH_FACE.CREATE_SECTION_FEEDBACK_MESSAGES.DESCRIPTION,
					success: true,
				});
				refreshList();
			}).catch((errorMessage) => {
				toast.error(errorMessage);
			}).finally(() => {
				toast.dismiss(t);
				setTimeout(() => {
					setStatusModalInfo({ ...statusModalInfo, isOpen: false });
				}, 3000);
			});
	};

	const handleShowFeedback = (statusFeedback) => {
		refreshList();
		if (statusFeedback !== null) {
			setStatusModalInfo(statusFeedback);
			setTimeout(() => {
				setStatusModalInfo({ isOpen: false, title: "", description: "", success: false });
			}, 3000);
		}
	};

	return (
		showAnalytics ? <DataAnalytics onBackRequested={() => setShowAnalytics(false)} /> :
			<Container>
				<AddSectionModal isOpen={newSectionModalVisible} onCancel={() => setNewSectionModalVisible(false)} handleCreateSection={handleCreateSection} />
				<StatusModal isOpen={statusModalInfo.isOpen} title={statusModalInfo.title} description={statusModalInfo.description} success={statusModalInfo.success} />
				<WfHeader>
					<h1>{MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.GALLERY_TITLE}</h1>
					<RightHeaderInfos>
						{width > SCREEN_SIZE.DESKTOP &&
							<FakeBtn onClick={() => setUserDisableDrag(!userDisableDrag)}>
								<div>
									{userDisableDrag ? <DragDisabledIcon /> : <DragEnabledIcon />}
								</div>
								<p>{userDisableDrag ? MESSAGES.PT_BR.FAQ_MESSAGES.DRAG_DISABLED : MESSAGES.PT_BR.FAQ_MESSAGES.DRAG_ENABLED}</p>
							</FakeBtn>
						}
						<TextIconButton
							text={MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.DATA_BTN}
							onClick={() => { setShowAnalytics(true); }}
							svgImage={<DataIcon style={{ "width": "100%", "height": "100%" }} />}
							enabled
							darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
							lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
						/>
						<TextIconButton
							text={MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.NEW_SECTION_BTN}
							onClick={() => { setNewSectionModalVisible(true); }}
							svgImage={<PlusIcon style={{ "width": "100%", "height": "100%" }} />}
							enabled
						/>
					</RightHeaderInfos>
				</WfHeader>
				{watchFaceSectionList === null || watchFaceSectionList === undefined ?
					<Loader /> :
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId="sections">
							{(provided) => (
								<DroppableArea {...provided.droppableProps} ref={provided.innerRef}>
									{watchFaceSectionList?.map((item, index) => {
										return (
											<Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={userDisableDrag || isDragDisabled} >
												{(provided) => (
													<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
														<SectionItem
															userDisabledDrag={userDisableDrag}
															watchFaceSection={item}
															onDragDisabled={setIsDragDisabled || item.sectionName === FIXED_SECTION_NAME}
															onFeedbackRequested={(feedback) => handleShowFeedback(feedback)}
														/>
													</div>
												)}
											</Draggable>
										);
									})}
									{provided.placeholder}

									{fixedWatchFaceSection && <SectionItem
										userDisabledDrag={userDisableDrag}
										watchFaceSection={fixedWatchFaceSection}
										onDragDisabled={setIsDragDisabled}
										onFeedbackRequested={(feedback) => handleShowFeedback(feedback)}
									/>}

								</DroppableArea>
							)}
						</Droppable>
					</DragDropContext>
				}
			</Container>
	);
};

export default WatchFaces;

