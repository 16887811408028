import React, { useState } from "react";
import { MESSAGES } from "../../../../../../constants/messages";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, RowContainer } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { FileInput, MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import Dropdown from "../../../../../../components/Dropdown/MainDropdown";
import { WorkoutSection } from "../../../../../../types/Training";
import { toast } from "react-hot-toast";
import { EXERCISE_TYPE } from "../../../../../../constants";
import { useTraining } from "../../../../../../contexts/training";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { StatusModal, InfoBox } from "../../../../../../components";

interface Props {
	onClose: () => void;
	nextValidIndex: number;
}

const AddWorkoutModal = (props: Props) => {
	const { selectedApp } = useMobileApp();
	const { saveWorkoutSection } = useTraining();
	const [newWorkout, setNewWorkout] = useState<WorkoutSection>({ exerciseType: EXERCISE_TYPE[0].type } as WorkoutSection);
	const [iconFile, setIconFile] = useState<File>(new File([], ""));
	const [iconSelectedFile, setIconSelectedFile] = useState<File>(new File([], ""));
	const [nameError, setNameError] = useState<string>("");
	const [iconError, setIconError] = useState<string>("");
	const [iconSelectedError, setIconSelectedError] = useState<string>("");
	const [statusModal, setStatusModal] = useState({ isOpen: false, title: "", description: "", success: false });

	const handleAddIcon = (file: File) => {
		setIconFile(file);
		if (iconError) setIconError("");
	};

	const handleAddIconSelected = (file: File) => {
		setIconSelectedFile(file);
		if (iconSelectedError) setIconError("");
	};

	const handleSaveWorkoutSection = () => {
		let allRight = true;
		if (newWorkout.name === undefined || newWorkout.name === "") {
			setNameError(MESSAGES.PT_BR.TRAINING_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setNameError("");
		}

		if (iconFile.size === 0) {
			setIconError(MESSAGES.PT_BR.TRAINING_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setIconError("");
		}

		if (iconSelectedFile.size === 0) {
			setIconSelectedError(MESSAGES.PT_BR.TRAINING_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setIconSelectedError("");
		}

		if (allRight) {
			const t = toast.loading(MESSAGES.PT_BR.TRAINING_MESSAGES.LOADING);
			saveWorkoutSection({ ...newWorkout, index: props.nextValidIndex }, iconFile, iconSelectedFile, selectedApp.appCode)
				.then(() => {
					setStatusModal({ isOpen: true, title: MESSAGES.PT_BR.TRAINING_MESSAGES.ADD_SUCCESS, description: MESSAGES.PT_BR.TRAINING_MESSAGES.ADD_WORKOUT_DESCRIPTION, success: true });
				})
				.catch((error) => {
					console.log(error);
					setStatusModal({ isOpen: true, title: MESSAGES.PT_BR.TRAINING_MESSAGES.ADD_ERROR, description: MESSAGES.PT_BR.TRAINING_MESSAGES.ADD_ERROR_WORKOUT_DESCRIPTION + error, success: false });
				})
				.finally(() => {
					toast.dismiss(t);
					setTimeout(() => {
						props.onClose();
						setStatusModal({ isOpen: false, title: "", description: "", success: false });
					}, 3000);
				});
		}
	};

	return (
		<ModalContainer>
			<StatusModal isOpen={statusModal.isOpen} title={statusModal.title} description={statusModal.description} success={statusModal.success} />
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.TRAINING_MESSAGES.NEW_WORKOUT}</h1>
					<div>
						<CloseIcon onClick={props.onClose} />
					</div>
				</ModalHeader>
				<ModalBody>
					<RowContainer>
						<MainInput
							type="text"
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.NAME}
							value={newWorkout.name ?? ""}
							placeholder={MESSAGES.PT_BR.TRAINING_MESSAGES.NAME_PLACEHOLDER}
							onChange={(value) => { setNewWorkout({ ...newWorkout, name: value }); }}
							errorText={nameError}
							inputStyle={{ borderRadius: "16px" }}
						/>
						<h5></h5>
						<Dropdown
							items={EXERCISE_TYPE.map((exercise) => exercise.name)}
							onChange={(newSelectedValue) => setNewWorkout({ ...newWorkout, exerciseType: EXERCISE_TYPE.find((exercise) => exercise.name === newSelectedValue)?.type ?? 0 })}
							selected={EXERCISE_TYPE.find((exercise) => exercise.type === newWorkout.exerciseType)?.name ?? " "}
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.ACTIVITY}
							style={{ marginBottom: "10px", height: "74px" }}
						/>
					</RowContainer>
					<RowContainer>
						<FileInput
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.ICON}
							value={iconFile.name}
							onChange={(file) => handleAddIcon(file)}
							style={{ margin: 0, height: "74px" }}
							errorText={iconError}
						/>
						<h5></h5>
						<FileInput
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.ICON_SELECTED}
							InfoBox={<InfoBox text={MESSAGES.PT_BR.TRAINING_MESSAGES.ICON_SELECTED_HELP} style={{height: "50px"}}/>}
							value={iconSelectedFile.name}
							onChange={(file) =>handleAddIconSelected(file)}
							style={{ margin: 0, height: "74px" }}
							errorText={iconSelectedError}
						/>
					</RowContainer>
					<ModalFooter>
						<MainButton enabled onClick={() => handleSaveWorkoutSection()} text={MESSAGES.PT_BR.TRAINING_MESSAGES.ADD} />
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default AddWorkoutModal;
