import React from "react";
import { useTheme } from "../../../../../../contexts/theme";
import { Sleep } from "../../../../../../types/Sleep";
import { TableHeader, TableItem } from "./components";
import { Line, LineTr, ResultTableContainer } from "./styles";

interface TableProps {
	result: Sleep[];
	onDeleteRequested: (customExercise: Sleep) => void;
	onEditRequested: (customExercise: Sleep) => void;
}

const ResultTable = (props: TableProps) => {

	const { currentTheme } = useTheme();

	return (
		<ResultTableContainer>
			<table>
				<thead>
					<TableHeader />
				</thead>
				<tbody>
					{
						props.result.map((currentSleep, index) => (
							<>
								<TableItem
									currentSleep={currentSleep}
									key={index} 
									onDeleteRequested={props.onDeleteRequested}
									onEditRequested={props.onEditRequested} 
								/>
								<LineTr theme={currentTheme}>
									<td colSpan={5}>
										<Line theme={currentTheme} />
									</td>
								</LineTr>
							</>
						))
					}
				</tbody>
			</table>
		</ResultTableContainer>
	);
};

export default ResultTable;