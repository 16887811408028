import React, { useState } from "react";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { WorkoutLevel, WorkoutSection } from "../../../../../../types/Training";
import { Container, HeaderStyled } from "./styles";
import { ReactComponent as ArrowDownLight } from "../../../../../../assets/images/light/arrowDownIcon.svg";
import { ReactComponent as ArrowUpLight } from "../../../../../../assets/images/light/arrowUpIcon.svg";
import { ReactComponent as ArrowUpDark } from "../../../../../../assets/images/dark/arrowUpIcon.svg";
import { ReactComponent as ArrowDownDark } from "../../../../../../assets/images/dark/arrowDownIcon.svg";
import { THEME_TYPES } from "../../../../../../constants";
import { ResultTable } from "./components";


interface ShowWorkoutVideosProps {
    selectedWorkout: WorkoutSection;
    type: WorkoutLevel;
	onReloadRequested: () => void;
}

const ShowWorkoutVideos = (props: ShowWorkoutVideosProps) => {

	const [isExpanded, setIsExpanded] = useState(false);
	const filteredVideos = props.selectedWorkout.trainingVideos?.filter((video) => video.difficulty === props.type) ?? [];
	const { currentTheme } = useTheme();

	const getTitle = () => {
		switch (props.type) {
		case WorkoutLevel.BEGINNER:
			return MESSAGES.PT_BR.TRAINING_MESSAGES.BEGINNER;
		case WorkoutLevel.INTERMEDIATE:
			return MESSAGES.PT_BR.TRAINING_MESSAGES.INTERMEDIATE;
		case WorkoutLevel.ADVANCED:
		default:
			return MESSAGES.PT_BR.TRAINING_MESSAGES.ADVANCED;
		}
	};

	const changeExpanded = () => {
		if(filteredVideos.length > 0){
			setIsExpanded(!isExpanded);
		}
	};


	return (
		<Container theme={currentTheme} isOpen={isExpanded && filteredVideos.length > 0 } disabled={filteredVideos.length <= 0}>
			<HeaderStyled>
				<p>{getTitle()}</p>
				{isExpanded ?
					currentTheme.type === THEME_TYPES.LIGHT ? <ArrowUpLight onClick={() => changeExpanded()} /> : <ArrowUpDark onClick={() => changeExpanded()} />
					:
					currentTheme.type === THEME_TYPES.LIGHT ? <ArrowDownLight onClick={() => changeExpanded()} /> : <ArrowDownDark onClick={() => changeExpanded()} />
				}
			</HeaderStyled>
			{isExpanded &&
                <ResultTable filteredVideos={filteredVideos} type={props.type} onReloadRequested={props.onReloadRequested} />
			}
		</Container>
	);
};

export default ShowWorkoutVideos;