/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useRef } from "react";
import { useTheme } from "../../../contexts/theme";
import { FilenameContainer, FilenameText, InfoBoxContainer, InputContainer, InputStyled, StackContainer, TitleContainer } from "./styles";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MESSAGES } from "../../../constants/messages";
import { BiImage } from "react-icons/bi";
import { Helpers } from "../../../helpers";


interface MainInputProps {
	value?: string | undefined | null;
	valueLink?: string | null;
	onChange: (value: File) => void;
	errorText?: string;
	title?: string;
	style?: React.CSSProperties;
	InfoBox?: React.ReactNode;
	infoBoxStyle?: React.CSSProperties;
	accept?: string;
}

const FileInput = (props: MainInputProps) => {

	const { currentTheme } = useTheme();
	const [helpVisible, setHelpVisible] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleOnChange = () => {
		if (inputRef?.current?.files?.[0]) {
			const userFileValue = inputRef?.current?.files?.[0];
			const updatedFile = new File([userFileValue], Helpers.NameFormatter.formatFileName(userFileValue.name), { type: userFileValue.type });
			props.onChange(updatedFile);
			inputRef.current.value = "";
		}
	};

	const handleDrop = (event) => {
		event.preventDefault();
		if (event.dataTransfer.files?.[0]) {
			const userFileValue = event.dataTransfer.files[0];
			const updatedFile = new File([userFileValue], Helpers.NameFormatter.formatFileName(userFileValue.name), { type: userFileValue.type });
			props.onChange(updatedFile);

			if (inputRef?.current?.files?.[0]) {
				inputRef.current.value = "";
			}
		}
	};

	const getValue = () => {
		const value = props.value;
		if (value === null || value === undefined) return "";
		if (value?.includes("/")) {
			return "..." + value?.split("/").pop()?.substring(0, 20);
		} else if (value.length > 20) {
			return value.substring(0, 20) + "...";
		}
		return value;
	};

	return (
		<StackContainer theme={currentTheme} style={props.style} >

			{props.title &&
				<TitleContainer theme={currentTheme}>
					<h1>{props.title}</h1>
					{props.InfoBox !== undefined ?
						<>
							<AiOutlineInfoCircle color={currentTheme.primaryColor}
								onMouseEnter={() => setHelpVisible(true)}
								onMouseLeave={() => setHelpVisible(false)} />
							<InfoBoxContainer visible={helpVisible} style={props?.infoBoxStyle}>
								{props.InfoBox}
							</InfoBoxContainer>
						</> : null
					}
				</TitleContainer>
			}
			<InputContainer theme={currentTheme} isError={props.errorText !== ""}
				onClick={() => inputRef?.current?.click()}
				onDragOver={(event) => { event.preventDefault(); }}
				onDrop={handleDrop}
			>
				<BiImage color={currentTheme.textColor} size={30} />
				<FilenameText theme={currentTheme}>{MESSAGES.PT_BR.NOTIFICATIONS.PLACEHOLDER_INPUT_PHOTO}</FilenameText>
				<InputStyled
					onChange={handleOnChange}
					ref={inputRef}
					type="file"
					hidden
					accept={props.accept ?? "image/png, image/jpeg"}
				/>
			</InputContainer>
			<FilenameContainer>
				{props.valueLink ?
					<a href={props.valueLink} target="_blank" rel="noopener noreferrer" >link</a>
					:
					<h3>{getValue()}</h3>
				}
				{props.errorText && <span>{props.errorText}</span>}
			</FilenameContainer>
		</StackContainer>
	);
};

export default FileInput;