import styled from "styled-components";
import { Theme } from "../../../types/Theme";

interface ITagInputProps {
    theme: Theme
}

export const StackContainer = styled.div<ITagInputProps>`
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
        width: 100%;
        text-align: right;
        font-family: "Normal";
        padding-right: 10px;
        padding-top: 5px;
        font-size: 14px;
        box-sizing: border-box;
        color: ${props => props.theme.textColor};
    }
`;

interface InfoBoxProps {
    visible: boolean
}

export const InfoBoxContainer = styled.div<InfoBoxProps>`
    display: ${props => props.visible ? "flex" : "none"};
    /* display: flex; */
    position: absolute;
    margin-top: 130px;
`;

export const TitleContainer = styled.div<ITagInputProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    margin-left: 10px;
    width: fit-content;

    h1{
        font-size: 18px;
        font-weight: 500;
        margin-right: 5px;
        color: ${props => props.theme.textColor};
        text-align: center;
    }
`;

export const InputStyled = styled.input`
    display: flex;
`;

export const InputContainer = styled.div<ITagInputProps>`
    display: flex;
    flex-direction: row;
    background-color: ${props => props.theme.backgroundCardSecondary};
    width: 100%;
    height: 55px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    max-width: 230px;
    padding: 10px;
    box-sizing: border-box;

    p {
        width: fit-content;
        font-size: 16px;
        text-align: center;
        justify-content: center;
        align-items: center;
        vertical-align: center;
        color: ${props => props.theme.textColor};
        padding: 10px;
        font-family: "Normal";
        overflow: scroll;
        white-space: nowrap;
    }

    h1 {
        background-color: blue;
        position: relative;
        width: 00px;
        height: 45px;
    }
`;

export const DateInputStyled = styled.input<ITagInputProps>`
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
    
    background-color: transparent;

    ::-webkit-datetime-edit { font-size: 18px; color: ${props => props.theme.textColor};  font-family: "Normal"; }
    ::-webkit-datetime-edit-fields-wrapper { background-color:  ${props => props.theme.backgroundCardSecondary};  }
    ::-webkit-calendar-picker-indicator { 
        background:  transparent;
        opacity: 0;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        }

`;

export const OverText = styled.h1`
    display: flex;
    position: absolute;
    background-color: blue;
    width: 100px;
    height: 100px;
`;



