import { Axios } from "axios";
import { getApi } from ".";

/**
 * Faz o upload de um arquivo ou o atualiza (caso já exista) no serviço do Amazon S3.
 * @param appCode Código do aplicativo atual.
 * @param file Arquivo a ser enviado/atualizado.
 * @param folder Pasta do BUCKET que o arquivo deve ser criado/atualizado.
 * @param oldObjectURL URL do objeto antigo (caso exista). Default NULL.
 * @param uniqueFileName Se o nome do arquivo deve ser exclusivo. Campo opcional. Default TRUE.
 * @returns URL do arquivo criado/atualizado.
 */
export const sendOrUpdateFileToS3 = (
	appCode: number,
	file: File,
	folder: string,
	oldObjectURL: string | null = null,
	uniqueFileName = true
): Promise<string> => {
	const API = getApi(appCode);

	const bodyFormData = new FormData();
	bodyFormData.append("file", file);
	bodyFormData.append("bucket", process.env.REACT_APP_BUCKET_NAME ??  "");
	bodyFormData.append("folder", folder);
	bodyFormData.append("uniqueFileName", String(uniqueFileName));
	
	let formMethod: Axios["putForm"] | Axios["postForm"];
	if (oldObjectURL) {
		// já existe arquivo, atualizar o atual
		bodyFormData.append("oldObjectURL", oldObjectURL);
		formMethod = API.putForm; 
	} else {
		// faz upload do novo arquivo
		formMethod = API.postForm;
	}
	
	return new Promise((resolve, reject) => {
		formMethod("/awsS3", bodyFormData).then((res) => {
			resolve(res.data);
		}).catch((e) => {
			console.log("[sendOrUpdateFileToS3] error:", e);
			reject(e?.response?.data?.title);
		});
	});
};
