import React, { useState } from "react";
import { MESSAGES } from "../../../../constants/messages";
import { useTheme } from "../../../../contexts/theme";
import { StravaContent } from "./components";
import { HeaderOptionsContainer, HeaderOption, Content } from "./styles";

const ApiKey = () => {

	const HEADER_ITEMS = [
		MESSAGES.PT_BR.API_KEYS.STRAVA,
	];

	const { currentTheme } = useTheme();
	const [selectedTab, setSelectedTab] = useState(HEADER_ITEMS[0]);

	const getContentByTab = () => {
		switch (selectedTab) {
		case MESSAGES.PT_BR.API_KEYS.STRAVA:
			return <StravaContent />;
		default:
			return <></>;
		}
	};

	return (
		<div>
			<HeaderOptionsContainer theme={currentTheme}>
				{HEADER_ITEMS.map((item, index) => (
					<HeaderOption
						key={index}
						theme={currentTheme}
						selected={selectedTab === item}
						onClick={() => setSelectedTab(item)}>
						<h5>{item}</h5>
					</HeaderOption>
				))}
			</HeaderOptionsContainer>
			<Content theme={currentTheme}>
				{getContentByTab()}
			</Content>
		</div>
	);
};

export default ApiKey;