import styled from "styled-components";
import { COLORS } from "../../../assets";
import { THEME_TYPES } from "../../../constants";
import { Theme } from "../../../types/Theme";

interface ButtonStyledProps {
    theme?: Theme;
    enabled?: boolean;
    darkColor?: string;
    lightColor?: string;
    selected: boolean;
}

const getBackgroundColor = (props: ButtonStyledProps) => {
	if(props?.darkColor && props.theme?.type == THEME_TYPES.DARK) {
		return props.darkColor;
	}else if(props?.lightColor && props.theme?.type == THEME_TYPES.LIGHT) {
		return props.lightColor;
	}else if(props?.theme?.type == THEME_TYPES.DARK) {
		return props.theme?.primaryColor;
	}else {
		return props.theme?.secondaryColor;
	}
};

export const ButtonStyled = styled.button<ButtonStyledProps>`
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 16px;
    font-size: 20px;
    color: ${props => props.theme.textColor};
    margin-top: 4%;
    opacity: ${props => props.enabled ? 1 : 0.5};
    border: 3px solid ${props => getBackgroundColor(props)};
    background-color: ${props => props.selected ? getBackgroundColor(props) : "transparent" } ;
    color: ${props => props.selected ? COLORS.DEFAULT_COLORS.WHITE : props.theme.textColor};

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;