import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES, THEME_TYPES } from "../../../constants";
import { MESSAGES } from "../../../constants/messages";
import { useTheme } from "../../../contexts/theme";
import { ConfigDropdown, ProfileDropdown } from "../components";
import { HeaderStyled, LeftSide, RightSide } from "./styles";
import { useDevice } from "../../../contexts/device";
import { AboutModal } from "../components/ConfigDropdown/components";
import { Feature } from "../../../types/Feature";

//TODO: Add config to constants?
const configList: Feature[] = [
	{name: MESSAGES.PT_BR.CONFIG.ABOUT, feature: null}
];

//TODO: Add config to constants?
const profileList: string[] = [MESSAGES.PT_BR.PROFILE.PROFILE, MESSAGES.PT_BR.PROFILE.LOGOUT];

const SecondaryHeader = () => {

	const { currentTheme } = useTheme();
	const navigate = useNavigate();	
	const { setAddDeviceModalVisible, setEditDeviceModalVisible } = useDevice();
	const [aboutModalVisible, setAboutModalVisible] = useState(false);

	const onConfigOptionClick = (option: string) => {

		switch (option) {
		case MESSAGES.PT_BR.CONFIG.ADM_USERS:
			navigate("/" + ROUTES.SETTING + "/" + ROUTES.SETTINGS.ADMIN_USERS);
			break;
		case MESSAGES.PT_BR.CONFIG.DEVICES:
			setEditDeviceModalVisible(true);
			break;
		case MESSAGES.PT_BR.CONFIG.NEW_DEVICE:
			setAddDeviceModalVisible(true);
			break;
		case MESSAGES.PT_BR.CONFIG.ABOUT:
			setAboutModalVisible(true);
			break;
		}
	};

	return (
		<HeaderStyled theme={currentTheme}>
			<AboutModal isOpen={aboutModalVisible} onClose={() => setAboutModalVisible(false)} />
			<LeftSide theme={currentTheme}>
				<img src={currentTheme.type === THEME_TYPES.DARK ? "/images/logoDark.png" : "/images/logo.png"} alt="logo" />
				<h3 onClick={() => navigate("/")}>{MESSAGES.PT_BR.CONFIG.START_SCREEN}</h3>
			</LeftSide>
			<RightSide>
				<ConfigDropdown
					title={MESSAGES.PT_BR.CONFIG.TITLE}
					items={configList}
					onClick={(newConfig) => { onConfigOptionClick(newConfig); }}
				/>
				<ProfileDropdown
					items={profileList}
				/>
			</RightSide>
		</HeaderStyled>
	);
};

export default SecondaryHeader;