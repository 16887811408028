export const POLICIES_AND_TERMS = {

	HEADER_ITEMS: [
		"Políticas de privacidade",
		"Termos de uso",
		"Termos de consentimento"
	],

	PLACEHOLDER_ITEMS: [
		"Escreva aqui as políticas de privacidade do aplicativo",
		"Escreva aqui os termos de uso do aplicativo",
		"Escreva aqui os termos de consentimento do aplicativo"
	],

	SAVE: "Salvar",

	FEEDBACK_TITLE: "Salvo com sucesso!",
	FEEDBACK_DESCRIPTION: [
		"As políticas de privacidade do aplicativo foram salvas com sucesso!",
		"Os termos de uso do aplicativo foram salvas com sucesso!",
		"Os termos de consentimento do aplicativo foram salvas com sucesso!"
	],

	LEGAL_INFOS_MODAL: {
		TITLE: "Atualização dos termos",
		DESCRIPTION: "Precisamos avisar os usuários o que tem de novo nos termos e políticas editados, por isso, digite abaixo o que deseja comunicar para todos os usuários.",
		DESCRIPTION_INPUT: "Descrição",
		DESCRIPTION_PLACEHOLDER: "Digite aqui a descrição dessa atualização",
		SAVE_AND_KEEP_EDITING: "Continuar para próximo termo ou política",
		UPDATE: "Atualizar",
		MANDATORY_FIELD: "Campo obrigatório",
	}
};