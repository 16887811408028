import { NotificationType, OperationSystem } from "../Notification";


/**
 * App Object
 * export interface Notification {
    id: number;
    title: string;
    message: string;
    image?: string;
    link?: string;
    sendDate: string;
    operationSystem: OperationSystem;
    type: NotificationType;
}
export enum OperationSystem {
    ANDROID_AND_IOS = 0,
    IOS = 1,
    ANDROID = 2
}
export enum NotificationType {
    PUSH_AND_CENTRAL = 0,
    CENTRAL_NOTIFICATION = 1,
    PUSH_NOTIFICATION = 2
}
 */


/**
 *  Api Object
     {
      "id": 1,
      "title": "Atualização disponível!",
      "content": "Atualize seu aplicativo na App Store!",
      "image": "https://mybucket.s3.amazonaws.com/myfolder/afile.jpg",
      "link": "app://open.my.app",
      "start": "2023-03-03T13:38:34.308Z",
      "operating_systems": [
        "android"
      ],
      "segmentation": [
        "push"
      ],
      "status": "new"
    }
 */

const fromApiToModel = (notification) => {

	return {
		id: notification.id,
		title: notification.title,
		message: notification.content,
		image: notification.image,
		link: notification.link,
		sendDate: notification.start,
		operationSystem: getOS(notification.operating_systems),
		type: getType(notification.segmentation)
	};
};

const getOS = (osArray) => {
	if (osArray.length === 2) {
		return OperationSystem.ANDROID_AND_IOS;
	} else if (osArray[0] === "ios") {
		return OperationSystem.IOS;
	} else {
		return OperationSystem.ANDROID;
	}
};

const getType = (typeArray) => {
	if (typeArray.length === 2) {
		return NotificationType.PUSH_AND_CENTRAL;
	} else if (typeArray[0] === "push") {
		return NotificationType.PUSH_NOTIFICATION;
	} else {
		return NotificationType.CENTRAL_NOTIFICATION;
	}
};


const fromModelToApi = (notification) => {
	return {
		id: notification.id,
		title: notification.title,
		content: notification.message,
		image: notification.image ?? null,
		link: notification.link ?? null,
		start: notification.sendDate,
		operating_systems: getOSArray(notification.operationSystem),
		segmentation: getTypeArray(notification.type),
	};
};

const getOSArray = (os) => {
	switch (os) {
	case OperationSystem.ANDROID_AND_IOS:
		return ["android", "ios"];
	case OperationSystem.IOS:
		return ["ios"];
	case OperationSystem.ANDROID:
	default:
		return ["android"];
	}
};

const getTypeArray = (type) => {
	switch (type) {
	case NotificationType.PUSH_AND_CENTRAL:
		return ["push", "central"];
	case NotificationType.PUSH_NOTIFICATION:
		return ["push"];
	case NotificationType.CENTRAL_NOTIFICATION:
	default:
		return ["central"];
	}
};


export const NotificationsMapper = {
	fromApiToModel,
	fromModelToApi
};