import axios from "axios";
import moment from "moment";

const getVideoId = (url: string) => {
	// eslint-disable-next-line no-useless-escape
	const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
	const id = url.match(regExp);
	if (id && id[2].length === 11) {
		return id[2];
	}
	return "";
};

const getVideoDuration = (videoUrl: string) => {
	return new Promise<number>((resolve, reject) => {
		const videoId = getVideoId(videoUrl);
		if (!videoId) reject("Link do YouTube inválido!");
		axios.get(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`)
			.then((response) => {
				const duration = response.data.items[0].contentDetails.duration;
				const durationInSeconds = moment.duration(duration).asSeconds();
				resolve(durationInSeconds);
			}).catch((error) => {
				console.log("error", error);
				reject(error);
			});
	});
};

export const YoutubeService = {
	getVideoId,
	getVideoDuration
};