import styled from "styled-components";

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: visibility 0s, opacity 0.3s linear;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 340px;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 10px;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    h4 {
        font-size: 28px;
        padding: 10px;
        text-align: center;
        font-weight: 500;
        line-height: 30px;
        color: ${props => props.theme.secondaryColor};
    }

    p {
        text-align: center;
        font-size: 16px;
        color: ${props => props.theme.secondaryColor};
        font-family: "Normal";
        margin-top: 10px;
        margin-bottom: 10px;
    }

    img {
        width: 100px;
        height: 100px;
    }
`;

