import ROUTES from "../../../routes";

export const CONFIG = {
	TITLE: "Configurações",
	API_DOCS: "Documentação da API",
	DEVICES: "Dispositivos",
	NEW_DEVICE: "Add dispositivo",
	EDIT_DEVICE: "Editar",
	NEW_APP: "Add Aplicativo",
	EDIT_APP: "Editar",
	ADM_USERS: "Usuários WebAdmin",
	THEME: "Tema Claro",
	START_SCREEN: "Tela Inicial",
	ABOUT: "Sobre",
};

export const PROFILE = {
	PROFILE: "Perfil",
	LOGOUT: "Sair",
};

export const DEVICE_MODAL = {
	NEW_DEVICE: "Adicionar dispositivo",
	DEVICE_NAME: "Nome do dispositivo",
	DEVICE_NAME_PLACEHOLDER: "Digite o nome",
	DEVICE_SLUG: "Slug do dispositivo",
	DEVICE_SLUG_PLACEHOLDER: "Digite o slug",
	ADD_BTN: "Adicionar",
	NEW_DEVICE_FEEDBACK_TITLE: "Adicionado com sucesso!",
	NEW_DEVICE_FEEDBACK_TITLE_ERROR: "Erro ao adicionar dispositivo!",
	NEW_DEVICE_FEEDBACK_DESCRIPTION: "O dispositivo foi adicionado com sucesso!",
	NEW_DEVICE_FEEDBACK_DESCRIPTION_ERROR: "O dispositivo não foi adicionado corretamente. Error: ",
	MANDATORY_FIELD: "Campo obrigatório",
	FUNCTIONS: "Funções",

	EDIT_DEVICE: "Editar dispositivo",
	EDIT_BTN: "Salvar",
	EDIT_DEVICE_FEEDBACK_TITLE: "Salvo com sucesso!",
	EDIT_DEVICE_FEEDBACK_DESCRIPTION: "As alterações feitas foram salvas com sucesso!",
	EDIT_DEVICE_FEEDBACK_TITLE_ERROR: "Erro ao editar dispositivo!",
	EDIT_DEVICE_FEEDBACK_DESCRIPTION_ERROR: "O dispositivo não foi atualizado corretamente. Error: ",

	DEVICE_DELETED: "Dispositivo excluido",
	DEVICE_DELETED_ERROR: "Erro ao excluir dispositivo: ",
	UNDO_DELETE:"Desfazer",
	DELETE_DEVICE_TITLE: "Você tem certeza que deseja excluir esse dispositivo?",
	DELETE_DEVICE_DESCRIPTION: "Caso exclua esse dispositivo, todos os dados de usuários contidos nesse dispositivo serão completamente apagados e não poderão ser recuperados. Tem certeza dessa ação?",
	SAVE: "Salvar",

	TYPE_BIN: "bin",
	TYPE_ZIP: "zip",
	TYPE_WATCH: "watch",
	SEQUENTIAL: "Sequencial",
	TYPE_AGPS: "A-GPS",
	TYPE_EPO: "EPO",
};

export const APP_MODAL = {
	NEW_APP: "Adicionar aplicativo",
	APP_NAME: "Nome do aplicativo",
	APP_NAME_PLACEHOLDER: "Digite o nome",
	ADD_BTN: "Adicionar",
	NEW_APP_FEEDBACK_TITLE: "Adicionado com sucesso!",
	NEW_APP_FEEDBACK_DESCRIPTION: "O aplicativo foi adicionado com sucesso!",

	EDIT_APP: "Editar aplicativo",
	EDIT_BTN: "Salvar",
	EDIT_APP_FEEDBACK_TITLE: "Salvo com sucesso!",
	EDIT_APP_FEEDBACK_DESCRIPTION: "As alterações feitas foram salvas com sucesso!",

	APP_DELETED:"Aplicativo excluido",
	UNDO_DELETE:"Desfazer",
	DELETE_APP_TITLE: "Não recomendamos fazer essa exclusão, você tem certeza?",
	DELETE_APP_DESCRIPTION: "Caso exclua esse aplicativo, todos os dados de usuários e todos os dispositivos incluídos nesse aplicativo serão completamente apagados e não poderão ser recuperados. Tem certeza dessa ação?",
};

export const ROUTES_TITLE = {
	[ROUTES.DASHBOARD.MOBILE_USERS]: "Usuários",
	[ROUTES.DASHBOARD.PUSH_NOTIFICATIONS]: "Push Notifications",
	[ROUTES.DASHBOARD.FAQ]: "FAQ",
	[ROUTES.DASHBOARD.LOGO]: "Logotipo",
	[ROUTES.DASHBOARD.SUPPORT]: "E-mail e suporte",
	[ROUTES.DASHBOARD.TERMS_AND_CONDITIONS]: "Termos e políticas",
	[ROUTES.DASHBOARD.API_KEY]: "API Key",
	[ROUTES.DASHBOARD.WATCH_FACES]: "Mostradores",
	[ROUTES.DASHBOARD.TUTORIALS]: "Vídeos Tutoriais",
	[ROUTES.DASHBOARD.MANUAL]: "Manual do Usuário",
	[ROUTES.DASHBOARD.FIRMWARE]: "Firmware",
	[ROUTES.DASHBOARD.TRAINING]: "Treine com a Mormaii",
	[ROUTES.DASHBOARD.SHARE_EXERCISE]: "Compartilhar Exercício",
	[ROUTES.DASHBOARD.SLEEP_SCORE]: "Score de Sono",
	[ROUTES.DASHBOARD.CUSTOM_EXERCISE]: "Exercício customizado",
	[ROUTES.DASHBOARD.GPS]: "GPS",
};
