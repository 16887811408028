import styled from "styled-components";
import { Theme } from "../../../../../../types/Theme";

interface ModalProps {
	isOpen: boolean,
	theme: Theme,
}

export const ModalContainer = styled.div<ModalProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	opacity: ${props => props.isOpen ? 1 : 0};
	visibility: ${props => props.isOpen? "visible" : "hidden"};
	transition: visibility 0s, opacity 0.3s linear;
`;

interface ContentProps {
	theme: Theme,
}

export const ModalContent = styled.div<ContentProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 35%;
	height: 23%;
	background-color: ${props => props.theme.backgroundCard};
	border-radius: 24px;
	padding: 20px;
	box-sizing: border-box;
`;

export const ModalHeader = styled.div<ContentProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 10px;

	h1 {
		font-size: 28px;
		margin-left: 10px;
		text-align: center;
		font-weight: 500;
		line-height: 30px;
		color: ${props => props.theme.primaryColor};
	}

	div {
		margin-right: 10px;
		&:hover {
			cursor: pointer;
		}
	}
`;

export const ModalBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin-bottom: 30px;
	margin-top: 20px;

	input {
		margin-bottom: 10px;
	}
`;

export const RowContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;

	h5 {
		margin: 1%;
	}
`;

export const ModalFooter = styled.div<ContentProps>`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	align-items: center;
	margin-top: 10px;

	button {
		width: 30%;
		margin: 0;
		margin-left: 20px;
		border-radius: 15px;
		background-color: ${props => props.theme.primaryColor};
	}

	p {
		text-decoration: underline;
		color: ${props => props.theme.textColor};
		font-family: "normal";
		font-size: 14px;

		&:hover {
			cursor: pointer;
		}
	}

`;
