import React, { useEffect, useRef } from "react";
import { useTheme } from "../../contexts/theme";
import { ClosedSideBar, CloseSidebarButton, Container, DropdownContainer, LargeImgContainer, OpenSidebarButton, Sidebar, SmallImage, SmallImageContainer } from "./styles";
import logoSmallLight from "../../assets/images/logoSmallLight.png";
import logoSmallDark from "../../assets/images/logoSmallDark.png";
import { DEFAULT_DEVICE, ROUTES, THEME_TYPES } from "../../constants";
import { SidebarItem, SidebarSmallItem } from "./components";
import { MESSAGES } from "../../constants/messages";
import { useNavigate, useLocation } from "react-router-dom";
import DeviceSelectionDropdown from "./components/DeviceSelectionDropdown";
import { useDevice } from "../../contexts/device";
import { FEATURES } from "../../constants/features";
//Icons
import { FaRegUserCircle, FaWalking, FaShareAlt } from "react-icons/fa";
import { HiOutlineBell, HiOutlineMail } from "react-icons/hi";
import { AiOutlineQuestionCircle, AiOutlineClockCircle } from "react-icons/ai";
import { BiPlayCircle } from "react-icons/bi";
import { CgNotes } from "react-icons/cg";
import { TbGps, TbTool } from "react-icons/tb";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsCloudArrowDown, BsPlayCircle } from "react-icons/bs";
import { GiNightSleep } from "react-icons/gi";
import { Device } from "../../types/Device";
import { useMobileApp } from "../../contexts/mobileApp";

interface Props {
	sideBarOpen: boolean;
	onOpen: (isOpen: boolean) => void;
}

const LeftMenu = (props: Props) => {

	const { currentTheme } = useTheme();
	const deviceStore = useDevice();
	const navigate = useNavigate();
	const location = useLocation();
	const { selectedApp } = useMobileApp();
	const lastDeviceList = useRef<Device[]>([]);

	useEffect(() => {
		if (lastDeviceList.current.length === deviceStore.deviceList.length) {
			return;
		}
		lastDeviceList.current = deviceStore.deviceList;

		//Corrige cenário em que o relógio selecionado é apagado.
		if (deviceStore.currentDevice !== null && !deviceStore.deviceList.includes(deviceStore.currentDevice)) {
			deviceStore.setCurrentDevice(deviceStore.deviceList[0]);
		}
	}, [deviceStore.deviceList]);

	const redirectTo = (route: string) => {
		navigate(route);
	};

	const isSelected = (route: string) => {
		return location.pathname.includes(route);
	};

	const closedSideBar = () => {
		return (
			<ClosedSideBar open={props.sideBarOpen} theme={currentTheme}>
				<SmallImageContainer>
					<SmallImage src={currentTheme.type === THEME_TYPES.DARK ? logoSmallDark : logoSmallLight} alt={"Logo"} open={props.sideBarOpen} onClick={() => redirectTo("/")} />
					{deviceStore.currentDevice?.slug === DEFAULT_DEVICE.slug ?
						<SmallImage src={selectedApp.image ?? ""} alt={"Device image"} open={props.sideBarOpen} radiusEnabled /> :
						<SmallImage src={deviceStore.currentDevice?.image ?? ""} alt={"Device image"} open={props.sideBarOpen} radiusEnabled />}
					<h1> </h1>
				</SmallImageContainer>

				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.MOBILE_USERS) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.MOBILE_USERS}} icon={FaRegUserCircle} selected={isSelected(ROUTES.DASHBOARD.MOBILE_USERS)} onClick={() => redirectTo(ROUTES.DASHBOARD.MOBILE_USERS)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.PUSH_NOTIFICATIONS) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.PUSH_NOTIFICATIONS}} icon={HiOutlineBell} selected={isSelected(ROUTES.DASHBOARD.PUSH_NOTIFICATIONS)} onClick={() => redirectTo(ROUTES.DASHBOARD.PUSH_NOTIFICATIONS)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.FAQ) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.FAQ}} icon={AiOutlineQuestionCircle} selected={isSelected(ROUTES.DASHBOARD.FAQ)} onClick={() => redirectTo(ROUTES.DASHBOARD.FAQ)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.SUPPORT) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.SUPPORT}} icon={HiOutlineMail} selected={isSelected(ROUTES.DASHBOARD.SUPPORT)} onClick={() => redirectTo(ROUTES.DASHBOARD.SUPPORT)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.TERMS_AND_CONDITIONS) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.TERMS_AND_CONDITIONS}} icon={CgNotes} selected={isSelected(ROUTES.DASHBOARD.TERMS_AND_CONDITIONS)} onClick={() => redirectTo(ROUTES.DASHBOARD.TERMS_AND_CONDITIONS)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.API_KEY) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.API_KEY}} icon={TbTool} selected={isSelected(ROUTES.DASHBOARD.API_KEY)} onClick={() => redirectTo(ROUTES.DASHBOARD.API_KEY)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.WATCH_FACES) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.WATCH_FACES}} icon={AiOutlineClockCircle} selected={isSelected(ROUTES.DASHBOARD.WATCH_FACES)} onClick={() => redirectTo(ROUTES.DASHBOARD.WATCH_FACES)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.TUTORIALS) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.TUTORIALS}} icon={BiPlayCircle} selected={isSelected(ROUTES.DASHBOARD.TUTORIALS)} onClick={() => redirectTo(ROUTES.DASHBOARD.TUTORIALS)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.MANUAL) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.MANUAL}} icon={IoDocumentTextOutline} selected={isSelected(ROUTES.DASHBOARD.MANUAL)} onClick={() => redirectTo(ROUTES.DASHBOARD.MANUAL)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.FIRMWARE) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.FIRMWARE}} icon={BsCloudArrowDown} selected={isSelected(ROUTES.DASHBOARD.FIRMWARE)} onClick={() => redirectTo(ROUTES.DASHBOARD.FIRMWARE)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.SLEEP_SCORE) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.SLEEP_SCORE}} icon={GiNightSleep} selected={isSelected(ROUTES.DASHBOARD.SLEEP_SCORE)} onClick={() => redirectTo(ROUTES.DASHBOARD.SLEEP_SCORE)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.TRAINING) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.TRAINING}} icon={BsPlayCircle} selected={isSelected(ROUTES.DASHBOARD.TRAINING)} onClick={() => redirectTo(ROUTES.DASHBOARD.TRAINING)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.SHARE_EXERCISE) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.SHARE_EXERCISE}} icon={FaShareAlt} selected={isSelected(ROUTES.DASHBOARD.SHARE_EXERCISE)} onClick={() => redirectTo(ROUTES.DASHBOARD.SHARE_EXERCISE)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.CUSTOM_EXERCISE) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.CUSTOM_EXERCISE}} icon={FaWalking} selected={isSelected(ROUTES.DASHBOARD.CUSTOM_EXERCISE)} onClick={() => redirectTo(ROUTES.DASHBOARD.CUSTOM_EXERCISE)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.GPS) && <SidebarSmallItem requestedFeature={{name: "", feature: FEATURES.GPS}} icon={TbGps} selected={isSelected(ROUTES.DASHBOARD.GPS)} onClick={() => redirectTo(ROUTES.DASHBOARD.GPS)} />}


				<OpenSidebarButton theme={currentTheme} onClick={() => props.onOpen(true)}>
					<div>
						<img src={currentTheme.type === THEME_TYPES.DARK ? "/images/dark/expandIcon.svg" : "/images/light/expandIcon.svg"} alt="Expand Logo" onClick={() => props.onOpen(!props.sideBarOpen)} />
					</div>
				</OpenSidebarButton>
			</ClosedSideBar>
		);
	};

	const openSideBar = () => {
		return (
			<Sidebar open={props.sideBarOpen} theme={currentTheme}>
				<LargeImgContainer src={currentTheme.type === THEME_TYPES.DARK ? "/images/logoDark.png" : "/images/logo.png"} alt={"Logo"} onClick={() => redirectTo("/")}/>

				<DropdownContainer>
					<DeviceSelectionDropdown items={deviceStore.deviceList} selected={deviceStore.currentDevice} onChange={(newDevice) => deviceStore.setCurrentDevice(newDevice)} />
				</DropdownContainer>

				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.MOBILE_USERS) && <SidebarItem icon={FaRegUserCircle} requestedFeature={{name: MESSAGES.PT_BR.MENU.USERS, feature: FEATURES.MOBILE_USERS}} selected={isSelected(ROUTES.DASHBOARD.MOBILE_USERS)} onClick={() => redirectTo(ROUTES.DASHBOARD.MOBILE_USERS)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.PUSH_NOTIFICATIONS) && <SidebarItem icon={HiOutlineBell} requestedFeature={{name: MESSAGES.PT_BR.MENU.PUSH, feature: FEATURES.PUSH_NOTIFICATIONS }} selected={isSelected(ROUTES.DASHBOARD.PUSH_NOTIFICATIONS)} onClick={() => redirectTo(ROUTES.DASHBOARD.PUSH_NOTIFICATIONS)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.FAQ) && <SidebarItem icon={AiOutlineQuestionCircle} requestedFeature={{name: MESSAGES.PT_BR.MENU.FAQ, feature: FEATURES.FAQ}} selected={isSelected(ROUTES.DASHBOARD.FAQ)} onClick={() => redirectTo(ROUTES.DASHBOARD.FAQ)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.SUPPORT) && <SidebarItem icon={HiOutlineMail} requestedFeature={{name: MESSAGES.PT_BR.MENU.EMAIL, feature: FEATURES.SUPPORT}} selected={isSelected(ROUTES.DASHBOARD.SUPPORT)} onClick={() => redirectTo(ROUTES.DASHBOARD.SUPPORT)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.TERMS_AND_CONDITIONS) && <SidebarItem icon={CgNotes} requestedFeature={{name: MESSAGES.PT_BR.MENU.TERMS, feature: FEATURES.TERMS_AND_CONDITIONS}} selected={isSelected(ROUTES.DASHBOARD.TERMS_AND_CONDITIONS)} onClick={() => redirectTo(ROUTES.DASHBOARD.TERMS_AND_CONDITIONS)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.API_KEY) && <SidebarItem icon={TbTool} requestedFeature={{name: MESSAGES.PT_BR.MENU.API_KEY, feature: FEATURES.API_KEY}} selected={isSelected(ROUTES.DASHBOARD.API_KEY)} onClick={() => redirectTo(ROUTES.DASHBOARD.API_KEY)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.WATCH_FACES) && <SidebarItem icon={AiOutlineClockCircle} requestedFeature={{name: MESSAGES.PT_BR.MENU.WATCH_FACES, feature: FEATURES.WATCH_FACES}} selected={isSelected(ROUTES.DASHBOARD.WATCH_FACES)} onClick={() => redirectTo(ROUTES.DASHBOARD.WATCH_FACES)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.TUTORIALS) && <SidebarItem icon={BiPlayCircle} requestedFeature={{name: MESSAGES.PT_BR.MENU.TUTORIALS, feature: FEATURES.TUTORIALS}} selected={isSelected(ROUTES.DASHBOARD.TUTORIALS)} onClick={() => redirectTo(ROUTES.DASHBOARD.TUTORIALS)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.MANUAL) && <SidebarItem icon={IoDocumentTextOutline} requestedFeature={{name: MESSAGES.PT_BR.MENU.MANUAL, feature: FEATURES.MANUAL}} selected={isSelected(ROUTES.DASHBOARD.MANUAL)} onClick={() => redirectTo(ROUTES.DASHBOARD.MANUAL)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.FIRMWARE) && <SidebarItem icon={BsCloudArrowDown} requestedFeature={{name: MESSAGES.PT_BR.MENU.FIRMWARE, feature: FEATURES.FIRMWARE}} selected={isSelected(ROUTES.DASHBOARD.FIRMWARE)} onClick={() => redirectTo(ROUTES.DASHBOARD.FIRMWARE)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.SLEEP_SCORE) && <SidebarItem icon={GiNightSleep} requestedFeature={{name: MESSAGES.PT_BR.MENU.SLEEP_SCORE, feature: FEATURES.SLEEP_SCORE}} selected={isSelected(ROUTES.DASHBOARD.SLEEP_SCORE)} onClick={() => redirectTo(ROUTES.DASHBOARD.SLEEP_SCORE)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.TRAINING) && <SidebarItem icon={BsPlayCircle} requestedFeature={{name: MESSAGES.PT_BR.MENU.TRAIN, feature: FEATURES.TRAINING}} selected={isSelected(ROUTES.DASHBOARD.TRAINING)} onClick={() => redirectTo(ROUTES.DASHBOARD.TRAINING)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.SHARE_EXERCISE) && <SidebarItem icon={FaShareAlt} requestedFeature={{name: MESSAGES.PT_BR.MENU.SHARE_EXERCISE, feature: FEATURES.SHARE_EXERCISE}} selected={isSelected(ROUTES.DASHBOARD.SHARE_EXERCISE)} onClick={() => redirectTo(ROUTES.DASHBOARD.SHARE_EXERCISE)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.CUSTOM_EXERCISE) && <SidebarItem icon={FaWalking} requestedFeature={{name: MESSAGES.PT_BR.MENU.CUSTOM_EXERCISE, feature: FEATURES.CUSTOM_EXERCISE}} selected={isSelected(ROUTES.DASHBOARD.CUSTOM_EXERCISE)} onClick={() => redirectTo(ROUTES.DASHBOARD.CUSTOM_EXERCISE)} />}
				{deviceStore.currentDevice?.availableFeatures.includes(FEATURES.GPS) && <SidebarItem icon={TbGps} requestedFeature={{name: MESSAGES.PT_BR.MENU.GPS, feature: FEATURES.GPS}} selected={isSelected(ROUTES.DASHBOARD.GPS)} onClick={() => redirectTo(ROUTES.DASHBOARD.GPS)} />}

				<CloseSidebarButton
					theme={currentTheme}
					onClick={() => props.onOpen(!props.sideBarOpen)}>
					<div>
						<p>{MESSAGES.PT_BR.MENU.SHOW_LESS}</p>
						<img src={currentTheme.type === THEME_TYPES.DARK ? "/images/dark/shrinkIcon.svg" : "/images/light/shrinkIcon.svg"} alt="Expand Logo" onClick={() => props.onOpen(!props.sideBarOpen)} />
					</div>
				</CloseSidebarButton>
			</Sidebar>
		);
	};

	return (
		<Container theme={currentTheme}>
			{openSideBar()}
			{closedSideBar()}
		</Container>
	);
};

export default LeftMenu;