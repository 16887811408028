import React from "react";
import { Auth } from "..";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { setToken } from "../api";
import { useAdminUser } from "../../contexts/adminUser";
import PermissionGate from "./PermissionGate";

interface PermissionManagerProps {
    children: React.ReactNode;
	requestedFeature: string;
	skipRoleCheck?: boolean;
}

/**
 * Filtra usuários autenticados e não autenticados.
 * @param param Página que será renderizada caso o usuário esteja logado
 * @returns Se logado, retorna children, caso contrário, redireciona para a página de login
 */
const PermissionManager: React.FC<PermissionManagerProps> = ({ children, skipRoleCheck=false, requestedFeature: string }) => {

	const { currentUser } = useAdminUser();
	const isLoggedIn = Auth.isLoggedIn() && currentUser?.email != null;

	if(isLoggedIn){
		setToken(Auth.getToken() ?? "");
	}


	return isLoggedIn ? 
		<PermissionGate skipRoleCheck={skipRoleCheck} userRole={currentUser.role} requestedFeature={string}>{children}</PermissionGate> :
		<Navigate to={"/" + ROUTES.POST_LOGIN + `?redirect_url=${window.location.pathname}`} replace/>;
};

export default PermissionManager;