import { Operator } from "../types/Firmware";

export const translateOperator = (operator?: Operator) => {
	switch (operator) {
	case Operator.EqualTo: return "Igual a";
	case Operator.GreaterOrEqual: return "Maior ou igual";
	case Operator.InBetween: return "Entre";
	}
	return null;
};

export const getOperatorByTranslation = (translation: string) => {
	switch (translation) {
	case "Igual a": return Operator.EqualTo;
	case "Maior ou igual": return Operator.GreaterOrEqual;
	case "Entre": return Operator.InBetween;
	}
	return undefined;
};
