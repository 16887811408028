/* eslint-disable no-mixed-spaces-and-tabs */
import { getApi } from ".";
import { WatchFaceMapper } from "../../types/Mapper/WatchFaceMapper";
import { WatchFace, WatchFaceSection } from "../../types/Watchface";


export const createWatchFaceSection = (section: WatchFaceSection, appCode: number, deviceSlug: string ) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/watch_faces_section/create", WatchFaceMapper.fromWatchFaceSectionModelToApi(section, deviceSlug))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.title);
			});
	});
};


export const getWatchFacesBySection = (appCode: number, deviceSlug: string, start: string|null, end: string|null) => {
	return new Promise<WatchFaceSection[]>((resolve) => {
		let url = `/watch_faces/watch_faces_by_section?smart_watch_slug=${deviceSlug}`;
		if (start && end)
			url += `&start=${start}&end=${end}`;
		
		getApi(appCode).get(url)
			.then((response) => {
				resolve(response.data.section.map((section) => WatchFaceMapper.fromApiToWatchSectionModel(section)));
			}).catch((error) => {
				console.log(error);
				resolve([]);
			});
	});
};


export const updateWatchFaceSection = (appCode: number, section: WatchFaceSection) => {
	return new Promise<void>((resolve) => {
		getApi(appCode).put(`/watch_faces_section/update?id=${section.id}`, WatchFaceMapper.fromWatchFaceSectionModelToApi(section))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				resolve();
			});
	});
};


export const deleteWatchFaceSection = (appCode: number, itemId: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`/watch_faces_section/delete?id=${itemId}`)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const createWatchFace = (appCode: number, newWatchFace: WatchFace, sectionId: number, deviceSlug: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/watch_faces/create", WatchFaceMapper.fromWatchModelToApiModel(newWatchFace, sectionId, deviceSlug))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const deleteWatchFace= (appCode: number, itemId: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`/watch_faces/delete?id=${itemId}`)
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const updateWatchFace = (appCode: number, watchFace: WatchFace) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put(`/watch_faces/update?id=${watchFace.id}`, WatchFaceMapper.fromWatchModelToApiModel(watchFace))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};
