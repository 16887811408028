export const MANUAL = {
	PREVIWE: "Preview",
	MANUAL: "Manual",
	DOWNLOAD: "Baixar",
	FILE_FORMAT: ".pdf",
	FILE_MAX_SIZE: "5Mb",
	SAVE: "Salvar",
	FEEDBACK_TITLE: "Salvo com sucesso!",
	FEEDBACK_DESCRIPTION: "O Manual do usuário foi salvo com sucesso e já está disponível no aplicativo",

	AWS_ERROR: "Erro ao salvar o arquivo",
	AWS_ERROR_DESCRIPTION: "Ocorreu um erro ao salvar o arquivo na AWS, verifique o bucket e tente novamente",
	DEFUALT_ERROR: "Erro ao salvar o arquivo no servidor, tente novamente mais tarde.",
	LOADING: "Carregando...",
};
