import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { COLORS } from "../../../../../../assets";
import { StatusModal } from "../../../../../../components";
import { MainButton } from "../../../../../../components/Buttons";
import { MainInput } from "../../../../../../components/Inputs";
import { MESSAGES } from "../../../../../../constants/messages";
import { useApiKeys } from "../../../../../../contexts/apiKeys";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { StravaKeys } from "../../../../../../types/ApiKey";
import { ContentFooter } from "./styles";

const StravaContent = () => {

	const [stravaInfos, setStravaInfos] = useState<StravaKeys>({} as StravaKeys);
	const [clientIdError, setClientIdError] = useState<string>("");
	const [clientSecretError, setClientSecretError] = useState<string>("");
	const [updateTokenError, setUpdateTokenError] = useState<string>("");
	
	const [modalStatus, setModalStatus] = useState({ isOpen: false, title: MESSAGES.PT_BR.API_KEYS.SAVE_FEEDBACK, description: MESSAGES.PT_BR.API_KEYS.STRAVA_FEEDBACK, success: true });
	const { saveStravaInfos, getStravaInfos } = useApiKeys();
	const { selectedApp } = useMobileApp();

	useEffect(() => {
		resetStates();
		getStravaInfos(selectedApp?.appCode ?? 0)
			.then((stravaKeys) => {
				setStravaInfos(stravaKeys);
			}).catch(() => {
				setStravaInfos({clientId: "", clientSecret: "", updateToken: ""} as StravaKeys);
			});
	}, [selectedApp]);

	const resetStates = () => {
		setClientIdError("");
		setClientSecretError("");
		setUpdateTokenError("");
		setStravaInfos({} as StravaKeys);
	};

	const handleSaveKeys = () => {
		if(stravaInfos == null) return;

		let allRight = true;

		if(stravaInfos?.clientId?.length === 0) {
			setClientIdError(MESSAGES.PT_BR.API_KEYS.MANDATORY_FIELD);
			allRight = false;
		} else {
			setClientIdError("");
		}

		if(stravaInfos?.clientSecret?.length === 0) {
			setClientSecretError(MESSAGES.PT_BR.API_KEYS.MANDATORY_FIELD);
			allRight = false;
		} else {
			setClientSecretError("");
		}

		if(stravaInfos?.updateToken?.length === 0) {
			setUpdateTokenError(MESSAGES.PT_BR.API_KEYS.MANDATORY_FIELD);
			allRight = false;
		} else {
			setUpdateTokenError("");
		}

		if(!allRight) return;

		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		
		saveStravaInfos(stravaInfos, selectedApp?.appCode ?? 0)
			.then(() => {
				setModalStatus({ ...modalStatus, isOpen: true });
			}).catch((error) => {
				toast.error(error);
			}).finally(() => {
				toast.dismiss(t);
				setTimeout(() => {
					setModalStatus({ ...modalStatus, isOpen: false });
				}, 3000);
			});
	};


	return (
		<div>
			<StatusModal
				isOpen={modalStatus.isOpen}
				description={modalStatus.description}
				success={modalStatus.success}
				title={modalStatus.title}
			/>
			<MainInput
				title={MESSAGES.PT_BR.API_KEYS.STRAVA_ID}
				value={stravaInfos?.clientId ?? ""}
				onChange={(value) => { setStravaInfos({ ...stravaInfos, clientId: value }); }}
				placeholder={MESSAGES.PT_BR.API_KEYS.STRAVA_ID_PLACEHOLDER}
				type="text"
				style={{ marginBottom: 28 }}
				isLoading={stravaInfos?.clientId == undefined}
				errorText={clientIdError}
			/>
			<MainInput
				title={MESSAGES.PT_BR.API_KEYS.STRAVA_CLIENT}
				value={stravaInfos?.clientSecret ?? ""}
				onChange={(value) => { setStravaInfos({ ...stravaInfos, clientSecret: value }); }}
				placeholder={MESSAGES.PT_BR.API_KEYS.STRAVA_CLIENT_PLACEHOLDER}
				type="password"
				style={{ marginBottom: 28 }}
				isLoading={stravaInfos?.clientSecret == undefined}
				errorText={clientSecretError}
			/>
			<MainInput
				title={MESSAGES.PT_BR.API_KEYS.STRAVA_TOKEN}
				value={stravaInfos?.updateToken ?? ""}
				onChange={(value) => { setStravaInfos({ ...stravaInfos, updateToken: value }); }}
				placeholder={MESSAGES.PT_BR.API_KEYS.STRAVA_TOKEN_PLACEHOLDER}
				type="password"
				isLoading={stravaInfos?.updateToken == undefined}
				errorText={updateTokenError}
			/>
			<ContentFooter>
				<MainButton
					enabled
					onClick={() => { handleSaveKeys(); }}
					text={MESSAGES.PT_BR.API_KEYS.SAVE}
					darkColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
					lightColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
				/>
			</ContentFooter>
		</div>
	);
};

export default StravaContent;