import React from "react";
import { useTheme } from "../../contexts/theme";
import { TitleInfoBoxContainer } from "./styles";

interface Props {
	title1: string;
	text1: string;
	title2?: string;
	text2?: string;
	style?: React.CSSProperties;
}

const InfoWfBox = (props: Props) => {

	const { currentTheme } = useTheme();

	return (
		<TitleInfoBoxContainer theme={currentTheme} style={props.style}>

			<div>
				<p><strong>{props.title1}</strong></p>
				<p>{props.text1}</p>
			</div>

			{
				props.title2 && props.text2 &&
				<div>
					<p><strong>{props.title2}</strong></p>
					<p>{props.text2}</p>
				</div>
			}

		</TitleInfoBoxContainer>
	);
};

export default InfoWfBox;