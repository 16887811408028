import React, { useState } from "react";
import { OutlineButton } from "../../../../../../../../components/Buttons";
import { MESSAGES } from "../../../../../../../../constants/messages";
import { AndroidNotification, AndroidPreview, CentralNotification, CentralNotificationContent, CentralNotificationPreview, ImgContainer, IosNotification, IOSPreview, PreviewContainer, PreviewHeader, TemplatesContainer } from "./styles";
import androidTemplateLight from "../../../../../../../../assets/images/light/androidMobileTemplate.png";
import androidTemplateDark from "../../../../../../../../assets/images/dark/androidMobileTemplate.png";
import iOSTemplateLight from "../../../../../../../../assets/images/light/iosMobileTemplate.png";
import iOSTemplateDark from "../../../../../../../../assets/images/dark/iosMobileTemplate.png";
import centralNotificationTemplateLight from "../../../../../../../../assets/images/light/centralNotificationsTemplate.png";
import centralNotificationTemplateDark from "../../../../../../../../assets/images/dark/centralNotificationsTemplate.png";
import { useTheme } from "../../../../../../../../contexts/theme";
import { THEME_TYPES } from "../../../../../../../../constants";
import { Notification, NotificationType, OperationSystem } from "../../../../../../../../types/Notification";
import { BiImage } from "react-icons/bi";

interface PreviewProps {
	newNotification: Notification;
	maxTitleLength: number;
	maxMessageLength: number;
}

const NotificationsPreview = (props: PreviewProps) => {

	const { currentTheme } = useTheme();
	const [previewType, setPreviewType] = useState<NotificationType>(NotificationType.PUSH_NOTIFICATION);

	const filterString = (str: string, max: number) => {
		if (str.length > max) {
			return str.substring(0, max - 3) + "...";
		}
		return str;
	};

	const getNotification = (os: OperationSystem | null) => {

		if (os === OperationSystem.ANDROID) {
			return (
				<AndroidNotification theme={currentTheme}>
					<h3>{filterString(props.newNotification.title?.length > 0 ? props.newNotification.title : MESSAGES.PT_BR.NOTIFICATIONS.TITLE_PLACEHOLDER, 30)}</h3>
					<p>{filterString(props.newNotification.message?.length > 0 ? props.newNotification.message : MESSAGES.PT_BR.NOTIFICATIONS.CONTENT_PLACEHOLDER, 70)}</p>
				</AndroidNotification>
			);
		}
		if (os === OperationSystem.IOS) {
			return (
				<IosNotification theme={currentTheme}>
					<h3>{filterString(props.newNotification.title?.length > 0 ? props.newNotification.title : MESSAGES.PT_BR.NOTIFICATIONS.TITLE_PLACEHOLDER, 33)}</h3>
					<p>{filterString(props.newNotification.message?.length > 0 ? props.newNotification.message : MESSAGES.PT_BR.NOTIFICATIONS.CONTENT_PLACEHOLDER, 70)}</p>
				</IosNotification>
			);
		}

		return (
			<CentralNotification theme={currentTheme}>
				<CentralNotificationContent theme={currentTheme}>
					<h3>{filterString(props.newNotification.title?.length > 0 ? props.newNotification.title : MESSAGES.PT_BR.NOTIFICATIONS.TITLE_PLACEHOLDER, 23)}</h3>
					<p>{filterString(props.newNotification.message?.length > 0 ? props.newNotification.message : MESSAGES.PT_BR.NOTIFICATIONS.CONTENT_PLACEHOLDER, 133)}</p>
				</CentralNotificationContent>
				<ImgContainer theme={currentTheme}>
					{props.newNotification.image ?
						<img src={props.newNotification.image} alt="Imagem da notificação" />
						:
						<BiImage size={30} color={currentTheme.textColor} />
					}
				</ImgContainer>
			</CentralNotification>
		);

	};

	const getPreview = () => {

		if (previewType === NotificationType.PUSH_NOTIFICATION) {
			return (
				<TemplatesContainer>
					<AndroidPreview backgroundImage={currentTheme.type == THEME_TYPES.DARK ? androidTemplateDark : androidTemplateLight} theme={currentTheme}>
						{getNotification(OperationSystem.ANDROID)}
						<h4>{MESSAGES.PT_BR.NOTIFICATIONS.ANDROID}</h4>
					</AndroidPreview>
					<IOSPreview backgroundImage={currentTheme.type == THEME_TYPES.DARK ? iOSTemplateDark : iOSTemplateLight} theme={currentTheme}>
						{getNotification(OperationSystem.IOS)}
						<h4>{MESSAGES.PT_BR.NOTIFICATIONS.IOS}</h4>
					</IOSPreview>
				</TemplatesContainer>
			);
		}
		return (
			<TemplatesContainer>
				<CentralNotificationPreview backgroundImage={currentTheme.type == THEME_TYPES.DARK ? centralNotificationTemplateDark : centralNotificationTemplateLight} theme={currentTheme}>
					<h4>{MESSAGES.PT_BR.NOTIFICATIONS.NOTIFICATIONS_TITLE}</h4>
					{getNotification(null)}
				</CentralNotificationPreview>
			</TemplatesContainer>
		);

	};

	return (
		<PreviewContainer theme={currentTheme}>
			<PreviewHeader>
				<OutlineButton selected={previewType === NotificationType.PUSH_NOTIFICATION} enabled onClick={() => setPreviewType(NotificationType.PUSH_NOTIFICATION)} darkColor={currentTheme.primaryColor} lightColor={currentTheme.primaryColor} text={MESSAGES.PT_BR.NOTIFICATIONS.HEADER_SYSTEM} />
				<OutlineButton selected={previewType != NotificationType.PUSH_NOTIFICATION} enabled onClick={() => setPreviewType(NotificationType.CENTRAL_NOTIFICATION)} darkColor={currentTheme.primaryColor} lightColor={currentTheme.primaryColor} text={MESSAGES.PT_BR.NOTIFICATIONS.APP} />
			</PreviewHeader>
			{getPreview()}
		</PreviewContainer>
	);
};

export default NotificationsPreview;