/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import { useTheme } from "../../../contexts/theme";
import {InputContainer, StackContainer, TimeInputStyled, TitleContainer } from "./styles";
import { AiOutlineClockCircle } from "react-icons/ai";
import moment from "moment";


interface MainInputProps {
    value: string | undefined;
    onChange: (value: string) => void;
	selectedDate?: string;
    errorText?: string;
    title?: string;
    style?: React.CSSProperties;
}

const TimeInput = (props: MainInputProps) => {

	const { currentTheme } = useTheme();

	const handleTimeChanged = (newTime: string) => {
		const hour = newTime.split(":")[0];
		const minute = newTime.split(":")[1];
		if(props?.selectedDate){
			const parsedDate = moment(props?.selectedDate).set({hour: parseInt(hour), minute: parseInt(minute)}).toISOString();
			props.onChange(parsedDate);
			return;
		}
		const parsedDate = moment().set({hour: parseInt(hour), minute: parseInt(minute)}).toISOString();
		props.onChange(parsedDate);
		return;
	};

	return (
		<StackContainer theme={currentTheme} style={props.style} >
			{props.title &&
                <TitleContainer theme={currentTheme}>
                	<h1>{props.title}</h1>
                </TitleContainer>
			}
			<InputContainer theme={currentTheme}>
				<TimeInputStyled onChange={(newDate) => handleTimeChanged(newDate.target.value)}
					placeholder={""}
					value={props.value}
					type={"time"} 
					theme={currentTheme}
					min={moment(props?.selectedDate).isBefore(moment()) ? moment().format("HH:mm") : ""}
				/>
				<AiOutlineClockCircle color={currentTheme.textColor} size={30} />
			</InputContainer>
		</StackContainer>
	);
};

export default TimeInput;