import React from "react";
import moment from "moment";
import { useTheme } from "../../../../../../../../contexts/theme";
import { Notification, NotificationType, OperationSystem } from "../../../../../../../../types/Notification";
import { MsgContainer, TableItemStyled, TypeContainer } from "./styles";
import centralNotificationIconLight from "../../../../../../../../assets/images/light/centralIcon.png";
import centralNotificationIconDark from "../../../../../../../..//assets/images/dark/centralIcon.png";
import pushNotificationIconLight from "../../../../../../../../assets/images/light/pushIcon.png";
import pushNotificationIconDark from "../../../../../../../../assets/images/dark/pushIcon.png";
import androidIconLight from "../../../../../../../../assets/images/light/androidIcon.png";
import androidIconDark from "../../../../../../../../assets/images/dark/androidIcon.png";
import iosIconLight from "../../../../../../../../assets/images/light/iosIcon.png";
import iosIconDark from "../../../../../../../../assets/images/dark/iosIcon.png";
import { THEME_TYPES } from "../../../../../../../../constants";


interface TableItemProps {
	notification: Notification;
}

const TableItem = (props: TableItemProps) => {

	const { currentTheme } = useTheme();

	const getNotificationType = (type: NotificationType) => {
		switch (type) {
		case NotificationType.PUSH_NOTIFICATION:
			return (
				<>
					<h1></h1>
					<img src={currentTheme.type == THEME_TYPES.DARK ? pushNotificationIconDark : pushNotificationIconLight} alt="Notification icon" />
					<h1></h1>
				</>
			);
		case NotificationType.CENTRAL_NOTIFICATION:
			return (
				<>
					<h1></h1>
					<img src={currentTheme.type == THEME_TYPES.DARK ? centralNotificationIconDark : centralNotificationIconLight} alt="Notification icon" />
					<h1></h1>
				</>
			);
		default:
			return (
				<>
					<img src={currentTheme.type == THEME_TYPES.DARK ? centralNotificationIconDark : centralNotificationIconLight} alt="Notification icon" />
					<h1></h1>
					<img src={currentTheme.type == THEME_TYPES.DARK ? pushNotificationIconDark : pushNotificationIconLight} alt="Notification icon" />
				</>
			);
		}
	};

	const getSystemIcon = (os: OperationSystem) => {
		switch (os) {
		case OperationSystem.ANDROID:
			return (
				<>
					<h1></h1>
					<img src={currentTheme.type == THEME_TYPES.DARK ? androidIconDark : androidIconLight} alt="SO icon" />
					<h1></h1>
				</>
			);
		case OperationSystem.IOS:
			return (
				<>
					<h1></h1>
					<img src={currentTheme.type == THEME_TYPES.DARK ? iosIconDark : iosIconLight} alt="SO icon" />
					<h1></h1>

				</>
			);
		default:
			return (
				<>
					<img src={currentTheme.type == THEME_TYPES.DARK ? androidIconDark : androidIconLight} alt="SO icon" />
					<h1></h1>
					<img src={currentTheme.type == THEME_TYPES.DARK ? iosIconDark : iosIconLight} alt="SO icon" />
				</>
			);
		}
	};

	const getNotificationContent = (title: string, content: string) => {
		return (
			<MsgContainer>
				<h3>{title}</h3>
				<p>{content}</p>
			</MsgContainer>
		);
	};

	return (
		<TableItemStyled theme={currentTheme}>
			<td>
				<TypeContainer padding={8}>
					{getNotificationType(props.notification.type)}
				</TypeContainer>
			</td>
			<td>{getNotificationContent(props.notification.title, props.notification.message)}</td>
			<td>
				<TypeContainer padding={5}>
					{getSystemIcon(props.notification.operationSystem)}
				</TypeContainer>
			</td>
			<td>{moment(props.notification.sendDate).format("DD/MM/YYYY")}</td>
		</TableItemStyled>
	);
};

export default TableItem;