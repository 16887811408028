import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { COLORS } from "../../../../../../assets";
import { StatusModal } from "../../../../../../components";
import { MainButton } from "../../../../../../components/Buttons";
import { MainInput } from "../../../../../../components/Inputs";
import { MESSAGES } from "../../../../../../constants/messages";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { Container, ContentFooter } from "./styles";
import { AGPS } from "../../../../../../types/Gps";
import { useGps } from "../../../../../../contexts/gps";
import { useDevice } from "../../../../../../contexts/device";

const AgpsContent = () => {
	
	const [modalStatus, setModalStatus] = useState({ isOpen: false, title: MESSAGES.PT_BR.GPS.SAVE_FEEDBACK, description: MESSAGES.PT_BR.GPS.AGPS_FEEDBACK, success: true });

	const [agpsInfos, setAgpsInfos] = useState<AGPS>({} as AGPS);
	const [offlineFileError, setOfflineFileError] = useState<string>("");
	const [onlineFileError, setOnlineFileError] = useState<string>("");
	
	const { getAgpsInfos, saveAgpsInfos } = useGps();
	const { selectedApp } = useMobileApp();
	const { currentDevice } = useDevice();

	useEffect(() => {
		resetStates();
		getAgpsInfos(selectedApp?.appCode ?? 0, currentDevice?.slug ?? "")
			.then((agpsInfos) => {
				setAgpsInfos(agpsInfos);
			}).catch(() => {
				setAgpsInfos({offlineFile: "", onlineFile: ""} as AGPS);
			});
	}, [selectedApp]);

	const resetStates = () => {
		setOfflineFileError("");
		setOnlineFileError("");
		setAgpsInfos({} as AGPS);
	};

	const handleSaveAgps = () => {
		if(agpsInfos == null) return;

		let allRight = true;

		if(agpsInfos?.offlineFile?.length === 0) {
			setOfflineFileError(MESSAGES.PT_BR.GPS.MANDATORY_FIELD);
			allRight = false;
		} else {
			setOfflineFileError("");
		}

		if(agpsInfos?.onlineFile?.length === 0) {
			setOnlineFileError(MESSAGES.PT_BR.GPS.MANDATORY_FIELD);
			allRight = false;
		} else {
			setOnlineFileError("");
		}

		if(!allRight) return;

		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		
		saveAgpsInfos(agpsInfos, selectedApp?.appCode ?? 0, currentDevice?.slug ?? "")
			.then(() => {
				setModalStatus({ ...modalStatus, isOpen: true });
			}).catch((error) => {
				if(!error || error.length === 0)
					error = MESSAGES.PT_BR.GPS.GENERIC_ERROR;
				toast.error(error);
			}).finally(() => {
				toast.dismiss(t);
				setTimeout(() => {
					setModalStatus({ ...modalStatus, isOpen: false });
				}, 3000);
			});
	};


	return (
		<Container>
			<StatusModal
				isOpen={modalStatus.isOpen}
				description={modalStatus.description}
				success={modalStatus.success}
				title={modalStatus.title}
			/>
			<MainInput
				title={MESSAGES.PT_BR.GPS.AGPS_OFFLINE_URL}
				value={agpsInfos?.offlineFile ?? ""}
				onChange={(value) => { setAgpsInfos({ ...agpsInfos, offlineFile: value }); }}
				placeholder={MESSAGES.PT_BR.GPS.AGPS_OFFLINE_URL_PLACEHOLDER}
				type="text"
				style={{ marginBottom: 28 }}
				errorText={offlineFileError}
				isLoading={agpsInfos?.offlineFile == undefined}
			/>
			<MainInput
				title={MESSAGES.PT_BR.GPS.AGPS_ONLINE_URL}
				value={agpsInfos?.onlineFile ?? ""}
				onChange={(value) => { setAgpsInfos({ ...agpsInfos, onlineFile: value }); }}
				placeholder={MESSAGES.PT_BR.GPS.AGPS_ONLINE_URL_PLACEHOLDER}
				type="text"
				errorText={onlineFileError}
				isLoading={agpsInfos?.onlineFile == undefined}
			/>
			<ContentFooter>
				<MainButton
					enabled
					onClick={() => { handleSaveAgps(); }}
					text={MESSAGES.PT_BR.GPS.SAVE}
					darkColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
					lightColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
				/>
			</ContentFooter>
		</Container>
	);
};

export default AgpsContent;