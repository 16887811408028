import React, { useEffect, useRef, useState } from "react";
import { Loader, StatusModal } from "../../../../components";
import { TextIconButton } from "../../../../components/Buttons";
import { MESSAGES } from "../../../../constants/messages";
import { ReactComponent as PlusIcon } from "../../../../assets/images/plusIcon.svg";
import { ResultTable, AddCustomExerciseModal, EditCustomExerciseModal, DeleteCustomExerciseModal } from "./components";
import { MainContainer, Header, CardContainer } from "./styles";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { toast } from "react-hot-toast";
import { Exercise } from "../../../../types/Exercise";
import { useCustomExercise } from "../../../../contexts/customExercise";


const CustomExercise = () => {

	const { selectedApp } = useMobileApp();
	const { getCustomExerciseList, createNewCustomExercise, updateCustomExercise, deleteCustomExercise } = useCustomExercise();

	const [customExerciseList, setCustomExerciseList] = useState<Exercise[] | null>(null);
	const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
	const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
	const selectedExercise = useRef<Exercise | null>(null);
	const [modalStatus, setModalStatus] = useState({ isOpen: false, title: "", description: "", success: false });

	useEffect(() => {
		refreshCustomExerciseList();
	}, []);

	const refreshCustomExerciseList = () => {
		getCustomExerciseList(selectedApp?.appCode || 0).then((list) => {
			if (list) setCustomExerciseList(list);
		});
	};

	const handleAddCustomExercise = (customExercise: Exercise, keepAdding) => {
		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		createNewCustomExercise(customExercise, selectedApp?.appCode)
			.then(() => {
				refreshCustomExerciseList();
				if(!keepAdding){
					setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.SUCCESS_FEEDBACK, description: MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.SUCCESS_FEEDBACK_DESCRIPTION, success: true });
				}else{
					toast.success(MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.SUCCESS_FEEDBACK);
				}
			})
			.catch((error) => {
				setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.ERROR_FEEDBACK, description: MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.ERROR_FEEDBACK_DESCRIPTION + error, success: false });
			})
			.finally(() => {
				if(!keepAdding){
					setAddModalVisible(false);
					setTimeout(() => {
						setModalStatus({ ...modalStatus, isOpen: false });
					}, 3000);
				}
				toast.dismiss(t);
			});
	};

	//Apenas para exibir o modal e setar o selected
	const showUpdateCustomExerciseModal = (customExercise: Exercise) => {
		selectedExercise.current = customExercise;
		setEditModalVisible(true);
	};

	const handleEditCustomExercise = (customExercise: Exercise, originalName: string) => {
		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		updateCustomExercise(originalName, customExercise, selectedApp?.appCode)
			.then(() => {
				refreshCustomExerciseList();
				setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.SUCCESS_EDIT_FEEDBACK, description: MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.SUCCESS_EDIT_FEEDBACK_DESCRIPTION, success: true });
			})
			.catch((error) => {
				setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.ERROR_EDIT_FEEDBACK, description: MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.ERROR_EDIT_FEEDBACK_DESCRIPTION + error, success: false });
			})
			.finally(() => {
				toast.dismiss(t);
				setEditModalVisible(false);
				setTimeout(() => {
					setModalStatus({ ...modalStatus, isOpen: false });
				}, 3000);
			});
	};


	//Apenas para exibir o modal e setar o selected
	const showDeleteCustomExercise = (customExercise: Exercise) => {
		selectedExercise.current = customExercise;
		setDeleteModalVisible(true);
	};

	const handleDeleteCustomExercise = (customExercise: Exercise) => {
		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		deleteCustomExercise(customExercise, selectedApp?.appCode)
			.then(() => {
				refreshCustomExerciseList();
				setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.DELETE_SUCCESS_FEEDBACK, description: MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.DELETE_SUCCESS_FEEDBACK_DESCRIPTION, success: true });
			})
			.catch((error) => {
				setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.DELETE_ERROR_FEEDBACK, description: MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.DELETE_ERROR_FEEDBACK_DESCRIPTION + error, success: false });
			})
			.finally(() => {
				toast.dismiss(t);
				setDeleteModalVisible(false);
				setTimeout(() => {
					setModalStatus({ ...modalStatus, isOpen: false });
				}, 3000);
			});
	};

	const showResults = () => {
		if (customExerciseList && customExerciseList.length > 0) {
			return <ResultTable
				result={customExerciseList}
				onDeleteRequested={(selectedExercise) => showDeleteCustomExercise(selectedExercise)}
				onEditRequested={(selectedExercise) => showUpdateCustomExerciseModal(selectedExercise)} />;
		} else if (customExerciseList?.length === 0) {
			return (
				<CardContainer>
					<p>{MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.EMPTY_LIST}</p>
				</CardContainer>
			);
		}
		return (
			<CardContainer>
				<Loader />
			</CardContainer>
		);
	};

	return (
		<MainContainer>
			<StatusModal isOpen={modalStatus.isOpen} title={modalStatus.title} description={modalStatus.description} success={modalStatus.success} />
			<AddCustomExerciseModal isOpen={addModalVisible} onCancel={() => setAddModalVisible(false)} handleNewExercise={handleAddCustomExercise} />
			{selectedExercise.current && <DeleteCustomExerciseModal isOpen={deleteModalVisible} selectedExercise={selectedExercise.current} onCancel={() => setDeleteModalVisible(false)} onDeleteRequested={handleDeleteCustomExercise} />}
			{selectedExercise.current && <EditCustomExerciseModal isOpen={editModalVisible} currentExercise={selectedExercise.current} onCancel={() => setEditModalVisible(false)} handleEditExercise={handleEditCustomExercise} />}
			<Header>
				<h1>{MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.EXERCISES}</h1>
				<TextIconButton
					text={MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.NEW_EXERCISE}
					onClick={() => setAddModalVisible(true)}
					svgImage={<PlusIcon style={{ "width": "100%", "height": "100%" }} />}
					enabled
				/>
			</Header>
			{showResults()}
		</MainContainer>
	);
};

export default CustomExercise;