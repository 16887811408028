import React, { useState } from "react";
import { BiImage } from "react-icons/bi";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { useDevice } from "../../../../../../contexts/device";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { InfoInput, FileInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { InfoBox, TechnosToggle, HorizontalDeviceSelection } from "../../../../../../components";
import { ShareExercise } from "../../../../../../types/ShareExercise";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, RowContainer, ModalHeader, LeftSide, FilesContainer, RightSide, ImgContainer } from "./styles";

interface Props {
	onClose: () => void;
	handleNewShareExercise: (shareExercise: ShareExercise, imageFile: File) => void;
}

const AddShareExerciseModal = (props: Props) => {
	const { currentTheme } = useTheme();
	const { deviceListWithoutDefault } = useDevice();

	const [shareExercise, setShareExercise] = useState<ShareExercise>({ showApp: true } as ShareExercise);
	const [imageFile, setImageFile] = useState<File>(new File([], ""));
	const [nameError, setNameError] = useState<string>("");
	const [imageError, setImageError] = useState<string>("");

	const resetStates = (notResetSlug = false) => {
		if (!notResetSlug) {
			setShareExercise({ showApp: true, name: "" } as ShareExercise);
		} else {
			setShareExercise({ showApp: true, name: "", slugs: shareExercise.slugs } as ShareExercise);
		}

		setNameError("");
		setImageError("");
	};

	const handleAddNew = (keepAdding = false) => {
		let allRight = true;
		
		if (shareExercise.name === undefined || shareExercise.name === "") {
			setNameError(MESSAGES.PT_BR.TRAINING_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setNameError("");
		}

		if (shareExercise.image === undefined || shareExercise.image === "") {
			setImageError(MESSAGES.PT_BR.TRAINING_MESSAGES.MANDATORY_FIELD);
			allRight = false;
		} else {
			setImageError("");
		}

		if (allRight) {
			props.handleNewShareExercise(shareExercise, imageFile);
			if (keepAdding)
				resetStates(keepAdding);
			else
				props.onClose();
		}
	};

	const handleOnChangeFile = async (file: File) => {
		setImageFile(file);
		setShareExercise({ ...shareExercise, image: URL.createObjectURL(file) });
	};

	return (
		<ModalContainer>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.SHARE_EXERCISE.NEW_BTN}</h1>
					<div>
						<CloseIcon onClick={() => { resetStates(); props.onClose(); }} />
					</div>
				</ModalHeader>
				<ModalBody>
					<LeftSide>
						<InfoInput
							type="text"
							onChange={(newName) => setShareExercise({ ...shareExercise, name: newName })}
							placeholder={MESSAGES.PT_BR.SHARE_EXERCISE.NAME_PLACEHOLDER}
							value={shareExercise.name}
							title={MESSAGES.PT_BR.SHARE_EXERCISE.NAME}
							errorText={nameError}
							InfoBox={<InfoBox text={MESSAGES.PT_BR.SHARE_EXERCISE.NAME_INFO} />}
						/>
						<FilesContainer>
							<FileInput
								onChange={handleOnChangeFile}
								title={MESSAGES.PT_BR.SHARE_EXERCISE.IMAGE}
								accept="image/png, image/jpeg"
								value={shareExercise.image}
								errorText={imageError}
								InfoBox={<InfoBox style={{ height: "65px" }} text={MESSAGES.PT_BR.SHARE_EXERCISE.IMAGE_INFO} />}
							/>
						</FilesContainer>
					</LeftSide>
					<RightSide>
						<ImgContainer>
							{shareExercise.image ? 
								<img src={shareExercise.image} />
								: 
								<BiImage color={currentTheme.textColor} size={50} />
							}
						</ImgContainer>
						<TechnosToggle
							checked={shareExercise.showApp}
							defaultChecked={false}
							leftLabel={MESSAGES.PT_BR.SHARE_EXERCISE.SHOW_ON_APP}
							onChange={() => setShareExercise({ ...shareExercise, showApp: !shareExercise.showApp })}
						/>
					</RightSide>
				</ModalBody>
				<RowContainer>
					<HorizontalDeviceSelection
						deviceList={deviceListWithoutDefault}
						selectedDeviceSlugList={shareExercise.slugs ?? []}
						onSelectionChange={(selectedDevices) => setShareExercise({ ...shareExercise, slugs: selectedDevices })}
					/>
				</RowContainer>
				<ModalFooter>
					<p onClick={() => handleAddNew(true)}>{MESSAGES.PT_BR.SHARE_EXERCISE.KEEP_ADDING}</p>
					<MainButton
						enabled
						onClick={() => handleAddNew()}
						text={MESSAGES.PT_BR.SHARE_EXERCISE.ADD_BTN}
						lightColor={currentTheme.primaryColor}
						darkColor={currentTheme.primaryColor}
					/>
				</ModalFooter>
			</ModalContent>
		</ModalContainer>
	);
};

export default AddShareExerciseModal;