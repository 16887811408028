import styled from "styled-components";

export const TableHeaderStyled = styled.tr`
	width: 100%;

	th {
		font-size: 22px;
		font-weight: 500;
		color: ${props => props.theme.textColor};
		padding: 10px;
	}
`;
