import React, { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { TechnosToggle } from "../../../../../../../../components";
import { DeleteButton, EditButton } from "../../../../../../../../components/Buttons";
import { MESSAGES } from "../../../../../../../../constants/messages";
import { useMobileApp } from "../../../../../../../../contexts/mobileApp";
import { useTheme } from "../../../../../../../../contexts/theme";
import { useTraining } from "../../../../../../../../contexts/training";
import { WorkoutLevel, WorkoutVideo } from "../../../../../../../../types/Training";
import { DeleteItemModal, EditWorkoutVideoModal } from "../../..";
import { ModalType } from "../../../DeleteItemModal";
import { ActionsStyled, DescriptionStyled, TableContainer, TableLine } from "./styles";

interface TableProps {
	filteredVideos: WorkoutVideo[];
	type: WorkoutLevel;
	onReloadRequested: () => void;
}

const ResultTable = (props: TableProps) => {

	const filteredArray = props.filteredVideos ?? [];
	const { currentTheme } = useTheme();
	const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
	const selectedVideo = useRef<WorkoutVideo | null>(null);
	const { updateTrainningVideoVisibility } = useTraining();
	const { selectedApp } = useMobileApp();

	const updateVideoVisibility = (video: WorkoutVideo) => {
		const t = toast.loading(MESSAGES.PT_BR.TRAINING_MESSAGES.LOADING);
		updateTrainningVideoVisibility(selectedApp.appCode, {...video, available: !video.available})
			.then(() => {
				toast.success(MESSAGES.PT_BR.TRAINING_MESSAGES.EDIT_SUCCESS);
				props.onReloadRequested();
			}).catch(() => {
				toast.error(MESSAGES.PT_BR.TRAINING_MESSAGES.EDIT_ERROR);
			})
			.finally(() => {
				toast.dismiss(t);
			});
	};

	return (
		<TableContainer theme={currentTheme}>
			{(selectedVideo.current && editModalVisible) &&
				<EditWorkoutVideoModal onClose={() => {setEditModalVisible(false); props.onReloadRequested();}} currentWorkoutVideo={selectedVideo.current} />
			}
			{(selectedVideo.current && deleteModalVisible) &&
				<DeleteItemModal onClose={() => {setDeleteModalVisible(false); props.onReloadRequested();}} workoutVideo={selectedVideo.current} type={ModalType.WORKOUT_VIDEO} />
			}
			<table>
				<thead>
					<tr>
						<th>{MESSAGES.PT_BR.TRAINING_MESSAGES.TH_EP}</th>
						<th>{MESSAGES.PT_BR.TRAINING_MESSAGES.TH_DESCRIPTION}</th>
						<th>{MESSAGES.PT_BR.TRAINING_MESSAGES.TH_SHOW_ON_APP}</th>
						<th>{MESSAGES.PT_BR.TRAINING_MESSAGES.TH_ACTIONS}</th>
					</tr>
				</thead>
				<tbody>
					{
						filteredArray.map((video, index) => {
							return (
								<TableLine key={index} theme={currentTheme}>
									<td>{video.episode}</td>
									<DescriptionStyled>{video.description}</DescriptionStyled>
									<td>{
										<TechnosToggle
											defaultChecked={false}
											onChange={() => updateVideoVisibility(video)}
											checked={video.available}
										/>
									}</td>
									<td>
										<ActionsStyled>
											<EditButton onClick={() => {
												selectedVideo.current = video;
												setEditModalVisible(true);
											}} />
											<DeleteButton onClick={() => {
												selectedVideo.current = video;
												setDeleteModalVisible(true);
											}} />
										</ActionsStyled>
									</td>
								</TableLine>
							);
						})
					}
				</tbody>
			</table>

		</TableContainer>
	);
};

export default ResultTable;