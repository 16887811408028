import React from "react";
import { useTheme } from "../../../../../../../../contexts/theme";
import { RowContainer, TableItemStyled } from "./styles";
import { EditButton } from "../../../../../../../../components/Buttons";
import { Sleep } from "../../../../../../../../types/Sleep";

interface TableItemProps {
	currentSleep: Sleep;
	onEditRequested: (customExercise: Sleep) => void;
	onDeleteRequested: (customExercise: Sleep) => void;
}

const TableItem = (props: TableItemProps) => {

	const { currentTheme } = useTheme();

	return (
		<TableItemStyled theme={currentTheme}>
			<td>{props.currentSleep.title}</td>
			<td>{props.currentSleep.description}</td>
			<td>{props.currentSleep.min}</td>
			<td>{props.currentSleep.max}</td>
			<td>
				<RowContainer>
					<EditButton onClick={() => props.onEditRequested(props.currentSleep)} />
					{/* <h1> </h1>
					<DeleteButton onClick={() => props.onDeleteRequested(props.currentSleep)}/> */}
				</RowContainer>
			</td>
		</TableItemStyled>
	);
};

export default TableItem;