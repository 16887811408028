export const CUSTOM_EXERCISE_MESSAGES = {
	EXERCISES: "Exercícios",
	NAME: "Nome",
	ACTIONS: "Ações",
	NEW_EXERCISE: "Novo Exercício",
	EDIT_EXERCISE: "Editar Exercício",
	NEW_EXERCISE_PLACEHOLDER: "Digite o nome do exercício",
	ADD: "Adicionar",
	KEEP_ADDING: "Continuar Adicionando",
	SAVE: "Salvar",

	SUCCESS_FEEDBACK: "Adicionado com sucesso!",
	SUCCESS_FEEDBACK_DESCRIPTION: "O exercício foi adicionado com sucesso!",
	SUCCESS_EDIT_FEEDBACK: "Editado com sucesso!",
	SUCCESS_EDIT_FEEDBACK_DESCRIPTION: "O exercício foi editado com sucesso!",

	ERROR_FEEDBACK: "Erro ao adicionar o exercício!",
	ERROR_FEEDBACK_DESCRIPTION: "Ocorreu um erro ao adicionar o exercício, tente novamente mais tarde. Erro: ",
	ERROR_EDIT_FEEDBACK: "Erro ao editar o exercício!",
	ERROR_EDIT_FEEDBACK_DESCRIPTION: "Ocorreu um erro ao editar o exercício, tente novamente mais tarde. Erro: ",

	DELETE_FEEDBACK: "Tem certeza que deseja excluir esse exercício?",
	DELETE_FEEDBACK_DESCRIPTION: "Caso exclua o exercício, ele será retirado automaticamente do aplicativo do usuário.",
	DELETE_BTN: "Tenho certeza, excluir.",
	CANCEL_BTN: "Não tenho certeza, cancelar.",

	DELETE_SUCCESS_FEEDBACK: "Excluído com sucesso!",
	DELETE_SUCCESS_FEEDBACK_DESCRIPTION: "O exercício foi excluído com sucesso!",
	DELETE_ERROR_FEEDBACK: "Erro ao excluir o exercício!",
	DELETE_ERROR_FEEDBACK_DESCRIPTION: "Ocorreu um erro ao excluir o exercício, tente novamente mais tarde. Erro: ",

	EMPTY_LIST: "Nenhum exercício cadastrado",
	MANDATORY_FIELD: "Campo obrigatório",
};