import styled from "styled-components";

export const NotificationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 20px;
    width: 100%;
    min-width: 700px;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 20px;
    margin-top: 40px;
    margin-bottom: 40px;

    p {
        color: ${props => props.theme.textColor};
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 2%;

    h2 {
        color: ${props => props.theme.textColor};
    }

    button {
        background-color: ${props => props.theme.primaryColor};
        margin: 0;
        width: 25%;
        min-width: 250px;
    }
`;