import { LOGIN } from "./login";
import { ERROR } from "./errors";
import { MENU } from "./sidebar";
import { CONFIG, PROFILE, ROUTES_TITLE, DEVICE_MODAL, APP_MODAL } from "./header";
import { USER_MESSAGES } from "./users";
import { SIGN_UP } from "./signup";
import { FAQ_MESSAGES } from "./faq";
import { NOTIFICATIONS } from "./notifications";
import { WATCH_FACE } from "./watchfaces";
import { SUPPORT_MESSAGES } from "./support";
import { POLICIES_AND_TERMS } from "./policiesAndTerms";
import { PHOTO_BOX } from "./photoBox";
import { API_KEYS } from "./apiKeys";
import { MANUAL } from "./manual";
import { FIRMWARE } from "./firmware";
import { BUTTON_MESSAGES } from "./buttons";
import { TUTORIALS } from "./tutorials";
import { TRAINING_MESSAGES } from "./training";
import { CUSTOM_EXERCISE_MESSAGES } from "./customExercise";
import { SLEEP_SCORE_MESSAGES } from "./sleepScore";
import { SHARE_EXERCISE } from "./shareExercise";
import { GPS } from "./gps";

export const PT_BR = {
	LOGIN,
	ERROR,
	MENU,
	CONFIG,
	PROFILE,
	ROUTES_TITLE,
	USER_MESSAGES,
	DEVICE_MODAL,
	APP_MODAL,
	SIGN_UP,
	FAQ_MESSAGES,
	NOTIFICATIONS,
	WATCH_FACE,
	SUPPORT_MESSAGES,
	POLICIES_AND_TERMS,
	PHOTO_BOX,
	API_KEYS,
	MANUAL,
	FIRMWARE,
	TUTORIALS,
	BUTTON_MESSAGES,
	TRAINING_MESSAGES,
	CUSTOM_EXERCISE_MESSAGES,
	SLEEP_SCORE_MESSAGES,
	SHARE_EXERCISE, 
	GPS
};