const fromApiToModel = (sleep) => {
	return {
		id: sleep.id,
		title: sleep.title ?? "",
		description: sleep.text ?? "",
		min: sleep.min,
		max: sleep.max,
	};
};

const fromModelToApi = (sleep) => {
	return {
		id: sleep.id,
		title: sleep.title,
		text: sleep.description,
		min: sleep.min,
		max: sleep.max,
	};
};

export const SleepMapper = {
	fromApiToModel,
	fromModelToApi,
};