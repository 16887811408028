import styled from "styled-components";
import { THEME_TYPES } from "../../../../../../constants";

export const ResultTableContainer = styled.div`
	width: 100%;
	border-radius: 20px;
	min-width: 800px;
	margin-top: 20px;
	padding-bottom: 20px;

	table {
		padding-right: 2%;
		width: 100%;
		margin-bottom: 20px;
	}
`;

export const LineTr = styled.tr`
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 20px;

		hr {
			width: 100%;
			opacity: 0.5;
			border: 1px solid ${props => props.theme.borderColor};
			justify-content: center;
			align-items: center;
		}
	}
`;

export const Line = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.type === THEME_TYPES.DARK ? "rgba(245, 246, 247, 0.1)" : "#00222222"};
    width: 98%;
    margin-left: 1%;
    height: 1px;
    border-radius: 50px;
`;

