import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AppProvider from "./contexts";
import { Auth } from "./services";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const renderApp = () => root.render(
	<AppProvider>
		<App />
	</AppProvider>
);

Auth.initKeycloak(renderApp);