import { getApi } from ".";
import { TutorialMapper } from "../../types/Mapper/TutorialMapper";
import { Tutorial } from "../../types/Tutorial";

export const getTutorialList = (appCode: number, deviceSlug: string) => {
	return new Promise<Tutorial[]>((resolve, reject) => {
		getApi(appCode).get(`/tutorials?smart_watch_slug=${deviceSlug}`)
			.then((response) => {
				const tutorials: Tutorial[] = response.data.map((tutorial) => TutorialMapper.fromApiToModel(tutorial));
				
				resolve(tutorials);
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};


export const saveTutorial = (newTutorial: Tutorial, appCode: number, deviceSlug: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/tutorials", TutorialMapper.fromModelToApi(newTutorial, deviceSlug))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const updateTutorial = (updatedTutorial: Tutorial, appCode: number, deviceSlug: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put("/tutorials", TutorialMapper.fromModelToApi(updatedTutorial, deviceSlug))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const deleteTutorial = (deletedTutorial: Tutorial, appCode: number, deviceSlug: string) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete("/tutorials", { data: TutorialMapper.fromModelToApi(deletedTutorial, deviceSlug) })
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};