import styled from "styled-components";


export const TableItemStyled = styled.tr`
	td {
		height: 70px;
		color: ${props => props.theme.textColor};
		font-weight: 400;
		font-size: 20px;
		font-family: "Normal";
		text-align: center;
	}
`;

