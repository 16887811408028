import styled from "styled-components";
import { Theme } from "../../../../../../../../types/Theme";

interface ContainerProps {
	theme: Theme
}

export const TableHeaderStyled = styled.tr<ContainerProps>`
	width: 100%;

	th {
		font-size: 22px;
		font-weight: 500;
		color: ${props => props.theme.textColor};
		padding: 10px;
	}
`;
