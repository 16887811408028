/**
 * App Object
 * {
        name: string;
        email: string;
        genre?: GENRE;
        emailConfirmedAt?: string;
 }
 */

/**
 * Api Object
 *     "id": 25,
      "name": "Chris Luissa",
      "profile_picture": null,
      "email": "ch.luissa@gmail.com",
      gender: m | f | o | null,
 */
const fromApiToAppModel = (apiObjUser) => {
	return {
		id: apiObjUser.id,
		name: apiObjUser.name,
		email: apiObjUser.email,
		genre: apiObjUser.gender,
		profilePicture: apiObjUser.profile_picture,
	};
};

export const UserMapper = {
	fromApiToAppModel
};