import styled from "styled-components";
import { Helpers } from "../../../../helpers";

interface ContainerProps {
    selected?: boolean;
    enabled: boolean;
}

export const SidebarItemStyled = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 80%;

        > span {
            font-size: 18px;
            font-weight: 400;
            margin-left: 10px;
            color: ${props => props?.selected? props.theme.primaryColor : Helpers.Hex.hex2rgba( props.theme.secondaryColor, props.enabled ? 1 : 0.5)};
        }
    }

    &:hover {
            cursor: pointer;
            opacity: 0.7;
    }
`;
