import React from "react";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { ChangeInfos, ModalBody, ModalContainer, ModalContent, ModalHeader, UpdateInfos } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { CHANGELOG } from "../../../../../../changelog";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
}


const AboutModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h3>{MESSAGES.PT_BR.CONFIG.ABOUT}</h3>
					<div>
						<CloseIcon onClick={() => props.onClose()} />
					</div>
				</ModalHeader>
				<ModalBody theme={currentTheme}>
					<div>
						<p>Versão atual: {Object.keys(CHANGELOG).slice(-1)}</p>
						<p>Ambiente: {process.env.REACT_APP_ENVIRONMENT}</p>
					</div>
					{
						Object.keys(CHANGELOG).slice(0).reverse().map((version, index) => {
							return (
								<UpdateInfos key={index}>
									<h4>{version}</h4>
									<h6>{CHANGELOG[version].date}</h6>
									{CHANGELOG[version].changes.map((change, index) => {
										return (
											<ChangeInfos key={index}>{" - " + change}</ChangeInfos>
										);
									})}
								</UpdateInfos>
							);
						})
					}
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);

};

export default AboutModal;