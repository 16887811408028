import React from "react";
import ReactLoading from "react-loading";
import { useTheme } from "../../contexts/theme";

const Loader = () => {

	const { currentTheme } = useTheme();

	return (
		<ReactLoading color={currentTheme.primaryColor} type={"bubbles"} />
	);
};

export default Loader;