import React from "react";
import { useTheme } from "../../contexts/theme";
import { TitleInfoBoxContainer } from "./styles";

interface Props {
	text: string;
	style?: React.CSSProperties;
}

const InfoBox = (props: Props) => {

	const { currentTheme } = useTheme();

	return (
		<TitleInfoBoxContainer theme={currentTheme} style={props.style}>
			<div>
				<p>{props.text}</p>
			</div>
		</TitleInfoBoxContainer>
	);
};

export default InfoBox;