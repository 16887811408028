import React from "react";
import { MESSAGES } from "../../../../../../../constants/messages";
import { useTheme } from "../../../../../../../contexts/theme";
import { TableHeaderStyled } from "./styles";

const TableHeader = () => {

	const { currentTheme } = useTheme();

	return (
		<TableHeaderStyled theme={currentTheme} >
			<th>{MESSAGES.PT_BR.USER_MESSAGES.NAME}</th>
			<th>{MESSAGES.PT_BR.USER_MESSAGES.EMAIL}</th>
			<th>{MESSAGES.PT_BR.USER_MESSAGES.ROLE}</th>
			<th>{MESSAGES.PT_BR.USER_MESSAGES.ACTION}</th>
		</TableHeaderStyled>
	);
};

export default TableHeader;