import React, { createContext, useContext } from "react";
import { Api } from "../services";
import { Tutorial } from "../types/Tutorial";

interface ITutorialContextData {
	getTutorialsList: (appCode: number, deviceSlug: string) => Promise<Tutorial[]>;
	saveTutorial: (newTutorial: Tutorial, appCode: number, deviceSlug: string) => Promise<void>;
	updateTutorial: (updatedTutorial: Tutorial, appCode: number, deviceSlug: string) => Promise<void>;
	deleteTutorial: (updatedTutorial: Tutorial, appCode: number, deviceSlug: string) => Promise<void>;
}

interface TutorialProviderProps {
	children: React.ReactNode;
}

export const TutorialContext = createContext({} as ITutorialContextData);

const TutorialProvider: React.FC<TutorialProviderProps> = ({ children }) => {

	const getTutorialsList = (appCode: number, deviceSlug: string) => {
		return new Promise<Tutorial[]>((resolve, reject) => {
			Api.Tutorial.getTutorialList(appCode, deviceSlug)
				.then((response) => {
					resolve(response);
				}).catch((error) => {
					reject(error);
				});
		});
	};

	const saveTutorial = (newTutorial: Tutorial, appCode: number, deviceSlug: string) => {
		return new Promise<void>((resolve, reject) => {
			Api.Tutorial.saveTutorial(newTutorial, appCode, deviceSlug)
				.then((response) => {
					resolve(response);
				}).catch((error) => {
					reject(error);
				});
		});
	};

	const updateTutorial = (updatedTutorial: Tutorial, appCode: number, deviceSlug: string) => {
		return new Promise<void>((resolve, reject) => {
			Api.Tutorial.updateTutorial(updatedTutorial, appCode, deviceSlug)
				.then((response) => {
					resolve(response);
				}).catch((error) => {
					reject(error);
				});
		});
	};

	const deleteTutorial = (tutorial: Tutorial, appCode: number, deviceSlug: string) => {
		return new Promise<void>((resolve, reject) => {
			Api.Tutorial.deleteTutorial(tutorial, appCode, deviceSlug)
				.then((response) => {
					resolve(response);
				}).catch((error) => {
					reject(error);
				});
		});
	};

	return (
		<TutorialContext.Provider
			value={{
				getTutorialsList,
				saveTutorial,
				updateTutorial,
				deleteTutorial
			}}>
			{children}
		</TutorialContext.Provider>
	);
};

const useTutorial = () => {
	const context = useContext(TutorialContext);

	return context;
};

export { TutorialProvider, useTutorial };
