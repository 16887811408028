import React from "react";
import { useTheme } from "../../../../../../contexts/theme";
import { Notification } from "../../../../../../types/Notification";
import { TableHeader, TableItem } from "./components";
import { LineTr, ResultTableContainer } from "./styles";


interface TableProps {
	result: Notification[];
}


const ResultTable = (props: TableProps) => {

	const { currentTheme } = useTheme();

	return (
		<ResultTableContainer>
			<table>
				<thead>
					<TableHeader />
				</thead>
				<tbody>
					{
						props.result.map((currentNotification) => (
							<React.Fragment key={currentNotification.id}>
								<TableItem
									notification={currentNotification}
								/>
								<LineTr theme={currentTheme}>
									<td colSpan={4}>
										<div>
											<hr />
										</div>
									</td>
								</LineTr>
							</React.Fragment>
						))
					}
				</tbody>
			</table>
		</ResultTableContainer>
	);
};

export default ResultTable;