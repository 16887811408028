import styled from "styled-components";

export const RowContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
`;

export const VersionContainer = styled.div`
	display: flex;
	width: 100%;
	margin: 1%;
`;