import styled from "styled-components";
import { Theme } from "../../../types/Theme";

interface ContainerProps {
	theme: Theme;
}

export const MainContainer = styled.div`
  width: 95%;
  align-self: center;
  border-radius: 20px;
  background-color: ${props => props.theme.backgroundCard};
`;

export const CardContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.theme.backgroundCard};
  border-radius: 15px;
  margin-top: 40px;
`;

export const Header = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2%;

  h1 {
    margin-left: 5%;
    color: ${props => props.theme.textColor};
  }

  button {
    margin-right: 5%;
    max-width: 280px;
    height: 50px;
    font-size: 18px;
  }
`;