import React, { useState } from "react";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { ModalBody, ModalContainer, ModalContent, ModalHeader, RowContainer, ScrollContainer, StackContainer, Subtitle } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { InfoBox, NotificationsPreview, PhotoBox } from "./components";
import { DateInput, FileInput, InfoInput, MainInput, TimeInput } from "../../../../../../components/Inputs";
import { Notification, NotificationType, OperationSystem } from "../../../../../../types/Notification";
import Dropdown from "../../../../../../components/Dropdown/MainDropdown";
import moment from "moment";
import { MainButton } from "../../../../../../components/Buttons";

interface Props {
	onCancel: () => void;
	onSendRequest: (notification: Notification, notificationImage: File) => void;
}

const NewMessageModal = (props: Props) => {

	const { currentTheme } = useTheme();
	const [newNotification, setNewNotification] = useState<Notification>({sendDate: moment().toISOString(), type: NotificationType.PUSH_AND_CENTRAL, operationSystem: OperationSystem.ANDROID_AND_IOS} as Notification);
	const [notificationImage, setNotificationImage] = useState<File>(new File([], ""));
	const [titleError, setTitleError] = useState<string>("");
	const [contentError, setContentError] = useState<string>("");
	const [titleCharacterLimit, setTitleCharacterLimit] = useState(51);
	const [contentCharacterLimit, setContentCharacterLimit] = useState(148);

	const handleSaveImage = (file: File) => {
		setNotificationImage(file);
		setTitleCharacterLimit(35);
		setContentCharacterLimit(97);
	};

	const handleSendNotification = (notification: Notification) => {

		let allRight = true;

		if(!notification?.title || notification?.title.length === 0) {
			setTitleError(MESSAGES.PT_BR.NOTIFICATIONS.MANDATORY_FIELD);
			allRight = false;
		}else{
			setTitleError("");
		}

		if(!notification?.message || notification?.message.length === 0) {
			setContentError(MESSAGES.PT_BR.NOTIFICATIONS.MANDATORY_FIELD);
			allRight = false;
		}else{
			setContentError("");
		}

		if(!allRight) {
			return;
		}

		props.onSendRequest(notification, notificationImage);
		return;
	};

	const getOperationSystemName = (value: string) => {
		switch (value) {
		case MESSAGES.PT_BR.NOTIFICATIONS.ANDROID:
			return OperationSystem.ANDROID;
		case MESSAGES.PT_BR.NOTIFICATIONS.IOS:
			return OperationSystem.IOS;
		default:
			return OperationSystem.ANDROID_AND_IOS;
		}
	};

	const getOperationSystemByCode = (value: OperationSystem) => {
		switch (value) {
		case OperationSystem.ANDROID:
			return MESSAGES.PT_BR.NOTIFICATIONS.ANDROID;	
		case OperationSystem.IOS:
			return MESSAGES.PT_BR.NOTIFICATIONS.IOS;	
		default:
			return MESSAGES.PT_BR.NOTIFICATIONS.ALL_OS;
		}
	};

	const getNotificationTypeByName = (value: string) => {
		switch (value) {
		case MESSAGES.PT_BR.NOTIFICATIONS.APP:
			return NotificationType.CENTRAL_NOTIFICATION;
		case MESSAGES.PT_BR.NOTIFICATIONS.PUSH:
			return NotificationType.PUSH_NOTIFICATION;
		default:
			return NotificationType.PUSH_AND_CENTRAL;
		}
	};

	const getNotificationTypeByCode = (value: NotificationType) => {
		switch (value) {
		case NotificationType.CENTRAL_NOTIFICATION:
			return MESSAGES.PT_BR.NOTIFICATIONS.APP;
		case NotificationType.PUSH_NOTIFICATION:
			return MESSAGES.PT_BR.NOTIFICATIONS.PUSH;
		default:
			return MESSAGES.PT_BR.NOTIFICATIONS.APP_AND_PUSH;
		}
	};

	return (
		<ModalContainer>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.NOTIFICATIONS.NEW_NOTIFICATION_TITLE}</h1>
					<div>
						<CloseIcon onClick={props.onCancel} />
					</div>
				</ModalHeader>
				<ModalBody>
					<ScrollContainer>
						<Subtitle>{MESSAGES.PT_BR.NOTIFICATIONS.NOTIFICATION}</Subtitle>
						<InfoInput
							value={newNotification.title}
							onChange={(newTitle) => (setNewNotification({ ...newNotification, title: newTitle }))}
							placeholder={MESSAGES.PT_BR.NOTIFICATIONS.PLACEHOLDER_INPUT_TITLE}
							type="text"
							title={MESSAGES.PT_BR.NOTIFICATIONS.INPUT_TITLE}
							style={{ marginBottom: 20 }}
							characterLimit={titleCharacterLimit}
							InfoBox={<InfoBox withImageCharacters={35} withoutImageCharacters={51} />}
							infoBoxStyle={{ marginLeft: 90 }}
							errorText={titleError}
						/>
						<InfoInput
							value={newNotification.message}
							onChange={(newMessage) => (setNewNotification({ ...newNotification, message: newMessage }))}
							placeholder={MESSAGES.PT_BR.NOTIFICATIONS.PLACEHOLDER_INPUT_CONTENT}
							type="text"
							title={MESSAGES.PT_BR.NOTIFICATIONS.INPUT_CONTENT}
							rowsNumber={3}
							style={{ marginBottom: 20 }}
							characterLimit={contentCharacterLimit}
							InfoBox={<InfoBox withImageCharacters={97} withoutImageCharacters={148} />}
							infoBoxStyle={{ marginLeft: 130 }}
							errorText={contentError}
						/>
						<RowContainer>
							<MainInput
								value={newNotification?.link ? newNotification.link : ""}
								onChange={(newLink) => (setNewNotification({ ...newNotification, link: newLink }))}
								placeholder={MESSAGES.PT_BR.NOTIFICATIONS.PLACEHOLDER_INPUT_LINK}
								type="text"
								title={MESSAGES.PT_BR.NOTIFICATIONS.INPUT_LINK}
							/>
							<FileInput
								value={notificationImage.name}
								onChange={(newImage) => handleSaveImage(newImage)}
								title={MESSAGES.PT_BR.NOTIFICATIONS.INPUT_PHOTO}
								InfoBox={<PhotoBox />}
								infoBoxStyle={{ marginLeft: 80 }}
								errorText={""}
							/>
						</RowContainer>

						<Subtitle>{MESSAGES.PT_BR.NOTIFICATIONS.SEGMENTATION}</Subtitle>

						<Dropdown 
							items={[MESSAGES.PT_BR.NOTIFICATIONS.ANDROID, MESSAGES.PT_BR.NOTIFICATIONS.IOS, MESSAGES.PT_BR.NOTIFICATIONS.ALL_OS]}
							onChange={(selected) => { setNewNotification({ ...newNotification, operationSystem: getOperationSystemName(selected)});}}
							selected={getOperationSystemByCode(newNotification.operationSystem)}
							title={MESSAGES.PT_BR.NOTIFICATIONS.HEADER_SYSTEM}
						/>
						<Dropdown 
							items={[MESSAGES.PT_BR.NOTIFICATIONS.APP, MESSAGES.PT_BR.NOTIFICATIONS.PUSH, MESSAGES.PT_BR.NOTIFICATIONS.APP_AND_PUSH]}
							onChange={(selected) => { setNewNotification({ ...newNotification, type: getNotificationTypeByName(selected)});}}
							selected={getNotificationTypeByCode(newNotification.type)}
							title={MESSAGES.PT_BR.NOTIFICATIONS.LOCATION}
						/>

						<Subtitle>{MESSAGES.PT_BR.NOTIFICATIONS.PROG}</Subtitle>
						<RowContainer>
							<DateInput
								value={newNotification?.sendDate ? moment(newNotification.sendDate).format("YYYY-MM-DD") : ""}
								onChange={(newDate) => (setNewNotification({ ...newNotification, sendDate: newDate }))}
								title={MESSAGES.PT_BR.NOTIFICATIONS.SEND_NOTIFICATION}
							/>
							<TimeInput
								value={newNotification?.sendDate ? moment(newNotification.sendDate).format("HH:mm") : ""}
								onChange={(newDate) => (setNewNotification({ ...newNotification, sendDate: newDate }))}
								title={MESSAGES.PT_BR.NOTIFICATIONS.HOUR}
								style={{ marginLeft: 20 }}
								selectedDate={newNotification?.sendDate ? newNotification?.sendDate : ""}
							/>
						</RowContainer>
					</ScrollContainer>
					<StackContainer>
						<h1> </h1>
						<NotificationsPreview newNotification={newNotification} maxMessageLength={contentCharacterLimit} maxTitleLength={titleCharacterLimit} />
						<MainButton enabled 
							onClick={() => handleSendNotification(newNotification)}
							text={MESSAGES.PT_BR.NOTIFICATIONS.SEND_NOTIFICATION} 
							lightColor={currentTheme.primaryColor}
							darkColor={currentTheme.primaryColor}
						/>
					</StackContainer>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default NewMessageModal;
