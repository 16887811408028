import { getApi } from ".";
import { StravaKeys } from "../../types/ApiKey";
import { ApiKeysMapper } from "../../types/Mapper/ApiKeysMapper";

export const getStravaInfos = (appCode: number) => {
	return new Promise<StravaKeys>((resolve, reject) => {
		getApi(appCode).get("/strava")
			.then((response) => {
				return resolve(ApiKeysMapper.fromApiToStravaModel(response.data));
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};

export const saveStravaInfos = (stravaKeys: StravaKeys, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/strava", ApiKeysMapper.fromStravaModelToApi(stravaKeys))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				reject(error?.response?.data?.title);
			});
	});
};
