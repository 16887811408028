import styled from "styled-components";

export const StackContainer = styled.div`
    display: flex;
    flex-direction: column;
`;


export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    flex-direction: row;
    height: 50px;
    width: 200px;
    padding: 0 5px;
    box-sizing: border-box;
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }

    img {
        height: 30px;
        width: 30px;
        object-fit: cover;
    }

    > div {
        display: flex;
        width: 15%;
    }

    p {
        color: ${props => props.theme.textColor};
        padding-left: 5px;
        padding-right: 5px;

    }
`;

export const SpacingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    margin-top: 50px;
    width: 200px;
    padding-top: 10px;
`;

export const OpenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.backgroundCard};
    width: 250px;
    border: none;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
`;

interface DropdownItemProps {
    enabled: boolean;
}

export const DropdownItem = styled.div<DropdownItemProps>`
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        opacity: ${props => props.enabled ? 1 : 0.5};

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-radius: 8px;
        height: 45px;
        padding: 4px;

        &:hover {
            cursor: pointer;
            opacity: 0.7;
            background-color: ${props => props.theme.backgroundCardSecondary};
        }
    }

    hr {
        width: 90%;
        opacity: 0.5;
        margin-top: 5px;
        margin-bottom: 5px;
        display: block; 
        height: 1px;
        border: 0; 
        border-top: 1px solid ${props => props.theme.textColor};
        padding: 0;
    }

    p {
        color: ${props => props.theme.textColor};
        font-family: "Normal";
    }

    img {
        height: 30px;
        width: 30px;
        object-fit: cover;
    }
`; 
