import React from "react";
import { DeleteButton, EditButton } from "../../../../../../../../components/Buttons";
import { useTheme } from "../../../../../../../../contexts/theme";
import { Tutorial } from "../../../../../../../../types/Tutorial";
import { TableItemStyled, ThumbImg } from "./styles";

interface TableItemProps {
	tutorial: Tutorial;
	onEditRequested: (tutorial: Tutorial) => void;
	onDeleteRequested: (tutorial: Tutorial) => void;
}

const TableItem = (props: TableItemProps) => {

	const { currentTheme } = useTheme();

	return (
		<TableItemStyled theme={currentTheme}>
			<td><ThumbImg src={props.tutorial?.thumb || ""} alt="Video thumb" onClick={() => window.open(props?.tutorial.link, "_blank")?.focus()} /></td>
			<td style={{ textAlign: "start" }} >{props.tutorial.title}</td>
			<td>
				<div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
					<EditButton onClick={() => props.onEditRequested(props.tutorial)} />
					<DeleteButton onClick={() => props.onDeleteRequested(props.tutorial)} />
				</div>
			</td>
		</TableItemStyled>
	);
};

export default TableItem;