import React, { createContext, useContext } from "react";
import { Api } from "../services";
import { Exercise } from "../types/Exercise";

interface ICustomExerciseContextData {
	getCustomExerciseList: (appCode: number) => Promise<Exercise[] | null>;
	createNewCustomExercise: (newExercise: Exercise, appCode: number) => Promise<void>;
	updateCustomExercise: (lastExerciseName: string, updatedExercise: Exercise, appCode: number) => Promise<void>;
	deleteCustomExercise: (exercise: Exercise, appCode: number) => Promise<void>;
}

interface CustomExerciseProviderProps {
	children: React.ReactNode;
}

export const CustomExerciseContext = createContext({} as ICustomExerciseContextData);

const CustomExerciseProvider: React.FC<CustomExerciseProviderProps> = ({ children }) => {

	const getCustomExerciseList = (appCode: number) => {
		return new Promise<Exercise[] | null>((resolve, reject) => {
			Api.CustomExercise.getCustomExerciseList(appCode)
				.then((customExerciseList) => {
					resolve(customExerciseList);
				}).catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const createNewCustomExercise = (newExercise: Exercise, appCode: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.CustomExercise.createNewCustomExercise(newExercise, appCode)
				.then(() => {
					resolve();
				}).catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const updateCustomExercise = (lastExerciseName: string, updatedExercise: Exercise, appCode: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.CustomExercise.updateCustomExercise(lastExerciseName, updatedExercise, appCode)
				.then(() => {
					resolve();
				}).catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const deleteCustomExercise = (exercise: Exercise, appCode: number) => {
		return Api.CustomExercise.deleteCustomExercise(exercise, appCode);
	};


	return (
		<CustomExerciseContext.Provider
			value={{
				getCustomExerciseList,
				createNewCustomExercise,
				updateCustomExercise,
				deleteCustomExercise
			}}>
			{children}
		</CustomExerciseContext.Provider>
	);
};

const useCustomExercise = () => {
	const context = useContext(CustomExerciseContext);

	return context;
};

export { CustomExerciseProvider, useCustomExercise };
