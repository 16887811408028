import React, { createContext, useContext } from "react";
import { Api } from "../services";

interface ISupportContextData {
	getWppNumber: (appCode: number) => Promise<string>;
	saveWppNumber: (wppNumber: string, appCode: number) => Promise<void>;
	getSupportEmail: (appCode: number) => Promise<string>;
	saveSupport: (email: string, appCode: number) => Promise<void>;
	sendTestEmail: (fromEmail: string, appCode: number) => Promise<void>;
}

interface SupportProviderProps {
	children: React.ReactNode;
}

export const SupportContext = createContext({} as ISupportContextData);

const SupportProvider: React.FC<SupportProviderProps> = ({ children }) => {

	const getWppNumber = (appCode: number) => {
		return new Promise<string>((resolve, reject) => {
			Api.Support.getWppNumber(appCode)
				.then((res) => {
					resolve(res);
				}).catch((err) => {
					reject(err);
				});
		});
	};


	const saveWppNumber = (wppNumber: string, appCode: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.Support.saveWppNumber(wppNumber, appCode)
				.then(() => {
					resolve();
				}).catch((err) => {
					reject(err);
				});
		});
	};

	const getSupportEmail = ( appCode: number) => {
		return new Promise<string>((resolve, reject) => {
			Api.Support.getSupportEmail(appCode)
				.then((res) => {
					resolve(res);
				}).catch((err) => {
					reject(err);
				});
		});
	};


	const saveSupport = (email: string, appCode: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.Support.saveSupport(email, appCode)
				.then(() => {
					resolve();
				}).catch((err) => {
					reject(err);
				});
		});
	};

	const sendTestEmail = (fromEmail: string, appCode: number) => {
		return Api.Support.sendTestEmail(fromEmail, appCode);
	};

	return (
		<SupportContext.Provider
			value={{
				getWppNumber,
				saveWppNumber,
				getSupportEmail,
				saveSupport,
				sendTestEmail
			}}>
			{children}
		</SupportContext.Provider>
	);
};

const useSupport = () => {
	const context = useContext(SupportContext);

	return context;
};

export { SupportProvider, useSupport };
