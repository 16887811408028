import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { COLORS } from "../../../../assets";
import { Loader, StatusModal } from "../../../../components";
import { MainButton } from "../../../../components/Buttons";
import { EditorHtmlInput } from "../../../../components/Inputs";
import { MESSAGES } from "../../../../constants/messages";
import { useTerms } from "../../../../contexts/terms";
import { useTheme } from "../../../../contexts/theme";
import { Content, Footer, HeaderOption, HeaderOptionsContainer } from "./styles";
import { stateToHTML } from "draft-js-export-html";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { getConsentTerms, getUseTerms } from "../../../../services/api/Terms";
import { UpdateInfosModal } from "./components";

const enum TABS {
	PRIVACY = 0,
	USE_TERMS = 1,
	CONSENT_TERMS = 2
}

const TermsAndPolicies = () => {

	const { currentTheme } = useTheme();
	const { selectedApp } = useMobileApp();
	const { getPrivacy, savePrivacy, saveConsentTerms, saveUseTerms } = useTerms();
	const [selectedTab, setSelectedTab] = useState(0);
	const [editorStateArray, setEditorStateArray] = useState(new Array(MESSAGES.PT_BR.POLICIES_AND_TERMS.HEADER_ITEMS.length).map(() => EditorState.createEmpty()));
	const [modalStatus, setModalStatus] = useState({ isOpen: false, title: "", description: "", success: true });
	const [isLoading, setIsLoading] = useState(true);
	const [updateModalInfosVisible, setUpdateModalInfosVisible] = useState(false);

	useEffect(() => {
		const convertAndSetEditor = (backendResponse: string) => {
			setEditorStateArray((prevState) => {
				const blocks = convertFromHTML(backendResponse);
				const parsedContent = ContentState.createFromBlockArray(
					blocks.contentBlocks,
					blocks.entityMap
				);
				prevState[selectedTab] = EditorState.createWithContent(parsedContent);
				return [...prevState];
			});
		};

		let content = MESSAGES.PT_BR.POLICIES_AND_TERMS.PLACEHOLDER_ITEMS[selectedTab];
		if (selectedApp) {
			switch (selectedTab) {
			case TABS.PRIVACY:
				getPrivacy(selectedApp.appCode).then((res) => {
					content = res;
				}).finally(() => {
					convertAndSetEditor(content);
					setIsLoading(false);
				});
				break;
			case TABS.USE_TERMS:
				getUseTerms(selectedApp.appCode).then((res) => {
					content = res;
				}).finally(() => {
					convertAndSetEditor(content);
					setIsLoading(false);
				});
				break;
			case TABS.CONSENT_TERMS:
			default:
				getConsentTerms(selectedApp.appCode).then((res) => {
					content = res;
				}).finally(() => {
					convertAndSetEditor(content);
					setIsLoading(false);
				});
				break;
			}
		}
	}, [selectedApp, selectedTab]);

	const handleSave = (legalInfosText: string) => {
		setUpdateModalInfosVisible(false);
		let res: Promise<void>;
		switch (selectedTab) {
		case TABS.PRIVACY:
			res = savePrivacy(stateToHTML(editorStateArray[selectedTab]?.getCurrentContent()), legalInfosText, selectedApp?.appCode);
			break;
		case TABS.USE_TERMS:
			res = saveUseTerms(stateToHTML(editorStateArray[selectedTab]?.getCurrentContent()), legalInfosText, selectedApp?.appCode);
			break;
		case TABS.CONSENT_TERMS:
		default:
			res = saveConsentTerms(stateToHTML(editorStateArray[selectedTab]?.getCurrentContent()), legalInfosText, selectedApp?.appCode);
			break;
		}

		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		res.then(() => {
			setModalStatus({ ...modalStatus, isOpen: true, title: MESSAGES.PT_BR.POLICIES_AND_TERMS.FEEDBACK_TITLE, description: MESSAGES.PT_BR.POLICIES_AND_TERMS.FEEDBACK_DESCRIPTION[selectedTab] });
			setTimeout(() => {
				setModalStatus({ ...modalStatus, isOpen: false });
			}, 3000);
		}).catch((error) => {
			toast.error(MESSAGES.PT_BR.NOTIFICATIONS.ERROR_USE_TERMS);
			console.log(error);
		}).finally(() => {
			toast.dismiss(t);
		});

	};

	return (
		<div>
			<StatusModal isOpen={modalStatus.isOpen} description={modalStatus.description} success title={modalStatus.title} />
			<UpdateInfosModal isOpen={updateModalInfosVisible} onCancel={() => setUpdateModalInfosVisible(false)} onSaveRequested={handleSave}/>
			<HeaderOptionsContainer theme={currentTheme}>
				{MESSAGES.PT_BR.POLICIES_AND_TERMS.HEADER_ITEMS.map((item, index) => (
					<HeaderOption
						key={index}
						theme={currentTheme}
						selected={selectedTab === index}
						onClick={() => setSelectedTab(index)}>
						<h5>{item}</h5>
					</HeaderOption>
				))}
			</HeaderOptionsContainer>
			{isLoading ?
				<Content theme={currentTheme}>
					<Loader />
				</Content> :
				<Content theme={currentTheme}>
					<EditorHtmlInput
						placeholder={MESSAGES.PT_BR.POLICIES_AND_TERMS.PLACEHOLDER_ITEMS[selectedTab]}
						editorState={editorStateArray[selectedTab]}
						setEditorState={(editorState) => {
							const newEditorStateArray = [...editorStateArray];
							newEditorStateArray[selectedTab] = editorState;
							setEditorStateArray(newEditorStateArray);
						}}
					/>
					<Footer >
						<MainButton
							enabled
							onClick={() => setUpdateModalInfosVisible(true)}
							text={MESSAGES.PT_BR.POLICIES_AND_TERMS.SAVE}
							darkColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
							lightColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON} />
					</Footer>
				</Content>
			}
		</div>
	);
};

export default TermsAndPolicies;