import styled from "styled-components";
import { Theme } from "../../../../../../types/Theme";

interface IContentProps {
    theme: Theme;
}

export const ContentFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 32px;

    button {
        width: 30%;
        max-width: 300px;
    }

    h5 {
        margin-right: 16px;
    }
`;

export const InfoContainer = styled.div<IContentProps> `
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 16px;
    background-color: ${props => props.theme.backgroundCardSurface};
    width: 300px;
    margin-top: 200px;
    margin-left: 200px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);

    p {
        text-align: left;
    }

    img {
        margin-top: 10px;
        border-radius: 16px;
    }
`;
