import React from "react";
import moment from "moment";
import { Firmware, Firmware3in1, FirmwareSequential, Operator } from "../../../../../../../../types/Firmware";
import { TableItemStyled } from "./styles";
import { DeleteButton, EditButton } from "../../../../../../../../components/Buttons";

interface TableItemProps {
	firmware: Firmware | Firmware3in1 | FirmwareSequential;
	onDeleteRequested: () => void;
	onEditRequested: () => void;
	disableEdit?: boolean;
}

const TableItem = (props: TableItemProps) => {
	const androidVersion = props.firmware.androidVersion ??  "-";
	const iosVersion = props.firmware.iosVersion ??  "-";

	const getDeviceVersion = () => {
		const firmware = props.firmware;
		switch (firmware.deviceVersionOperator) {
		case Operator.EqualTo: return `Igual a ${firmware.deviceVersion}`;
		case Operator.GreaterOrEqual: return `Maior ou igual ${firmware.deviceVersion}`;
		case Operator.InBetween: return `Entre ${firmware.deviceVersion} e ${firmware.deviceVersionEnd}`;
		}
		return "-";
	};

	return (
		<TableItemStyled>
			<td>{props.firmware.version}</td>
			<td>{getDeviceVersion()}</td>
			<td>
				<h5>{`Android ${androidVersion}`}</h5>
				<h5>{`iOS ${iosVersion}`}</h5>
			</td>
			<td style={{ textAlign:"left" }} >{props.firmware.description}</td>
			<td>{props.firmware.numOfFiles}</td>
			<td>{props.firmware.deviceId}</td>
			<td>{moment(props.firmware.date).format("DD/MM/YYYY")}</td>
			<td  style={{ minWidth:"80px", justifyContent: "center" , alignItems: "center", display: "flex" }} >
				{!props.disableEdit && <EditButton onClick={props.onEditRequested} />}
				<DeleteButton onClick={props.onDeleteRequested} />				
			</td>
		</TableItemStyled>
	);
};

export default TableItem;