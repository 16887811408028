export interface Device {
    name: string;
    slug: string;
    image?: string;
    linkManual?: string;
    availableFeatures: string[];
    watchFaceType: WatchFaceType;
    firmwareType: FirmwareType;
    gpsType: GpsType;
}

export enum WatchFaceType {
    BIN = "wf-type-bin",
    ZIP = "wf-type-zip",
    WATCH = "wf-type-watch",
}

export enum FirmwareType {
    SEQUENTIAL = "fw-type-sequential",
    BIN = "fw-type-bin",
    ZIP = "fw-type-zip"
}

export enum GpsType {
    AGPS = "gps-type-agps",
    EPO = "gps-type-epo"
}
