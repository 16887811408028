import React from "react";
import { Firmware, Firmware3in1, FirmwareSequential } from "../../../../../../types/Firmware";
import { TableHeader, TableItem } from "./components";
import { Line, LineTr, ResultTableContainer } from "./styles";

interface TableProps {
	result: (Firmware|Firmware3in1|FirmwareSequential)[];
	onDeleteRequested: (firmware: Firmware | Firmware3in1 | FirmwareSequential) => void;
	onEditRequested: (firmware: Firmware | Firmware3in1 | FirmwareSequential) => void;
}

const ResultTable = (props: TableProps) => {

	return (
		<ResultTableContainer>
			<table>
				<thead>
					<TableHeader />
				</thead>
				<tbody>
					{
						props.result.map((currentFirmware, index) => (
							<React.Fragment key={index}>
								<TableItem
									key={index}
									firmware={currentFirmware} 
									onDeleteRequested={() => props.onDeleteRequested(currentFirmware)}
									onEditRequested={() => props.onEditRequested(currentFirmware)}
									disableEdit={currentFirmware.isSequential === true}
								/>
								<LineTr>
									<td colSpan={7}>
										<Line/>
									</td>
								</LineTr>
							</React.Fragment>
						))
					}
				</tbody>
			</table>
		</ResultTableContainer>
	);
};

export default ResultTable;