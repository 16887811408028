import styled from "styled-components";
import { THEME_TYPES } from "../../../constants";
import { Theme } from "../../../types/Theme";

interface ButtonStyledProps {
    theme?: Theme;
    enabled?: boolean;
    darkColor?: string;
    lightColor?: string;
}

const getBackgroundColor = (props: ButtonStyledProps) => {
	if(props?.darkColor && props.theme?.type == THEME_TYPES.DARK) {
		return props.darkColor;
	}else if(props?.lightColor && props.theme?.type == THEME_TYPES.LIGHT) {
		return props.lightColor;
	}else if(props?.theme?.type == THEME_TYPES.DARK) {
		return props.theme?.primaryColor;
	}else {
		return props.theme?.primaryColor;
	}
};

export const ButtonStyled = styled.button<ButtonStyledProps>`
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 16px;
    background-color: ${props => getBackgroundColor(props)};
    font-size: 20px;
    color: ${props => props.theme.type === THEME_TYPES.DARK ? props.theme.textColor : props.theme.secondaryTextColor};
    opacity: ${props => props.enabled ? 1 : 0.5};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    div {
        height: 25px;
        width: 25px;
        margin-right: 10px;
    }
`;