import React, { useState } from "react";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { useTheme } from "../../../../../../contexts/theme";
import { MESSAGES } from "../../../../../../constants/messages";
import { MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { Tutorial } from "../../../../../../types/Tutorial";
import { useTutorial } from "../../../../../../contexts/tutorials";
import toast from "react-hot-toast";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { useDevice } from "../../../../../../contexts/device";

interface ModalProps {
	isOpen: boolean;
	onCancel: (status) => void;
	onVideoAdded: () => void;
}

const AddVideoModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();
	const { saveTutorial } = useTutorial();
	const { selectedApp } = useMobileApp();
	const { currentDevice } = useDevice();
	const [videoTitle, setVideoTitle] = useState<string>("");
	const [titleError, setTitleError] = useState<string>("");
	const [videoLink, setVideoLink] = useState<string>("");
	const [videoLinkError, setVideoLinkError] = useState<string>("");

	const handleSaveVideo = (keepAdding: boolean) => {
		let allRight = true;
		if (videoTitle === "") {
			setTitleError(MESSAGES.PT_BR.TUTORIALS.MODAL.TITLE_ERROR);
			allRight = false;
		} else {
			setTitleError("");
		}

		if (videoLink === "") {
			setVideoLinkError(MESSAGES.PT_BR.TUTORIALS.MODAL.LINK_ERROR);
			allRight = false;
		} else {
			setVideoLinkError("");
		}

		if (allRight) {
			const newVideo = {
				title: videoTitle,
				link: videoLink,
			} as Tutorial;
			
			const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
			saveTutorial(newVideo, selectedApp?.appCode, currentDevice?.slug || "")
				.then(() => {
					props.onVideoAdded();
					if (!keepAdding) {
						props.onCancel(
							{
								title: MESSAGES.PT_BR.TUTORIALS.MODAL.SUCCESS_FEEDBACK,
								description: MESSAGES.PT_BR.TUTORIALS.MODAL.SUCCESS_DESCRIPTION,
								success: true,
								isOpen: true,
							});
					}
					setVideoTitle("");
					setVideoLink("");
				})
				.catch((error) => {
					toast.error(error);
				})
				.finally(() => { 
					toast.dismiss(t);
				});
		}
	};

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.TUTORIALS.MODAL.ADD_TITLE}</h1>
					<div>
						<CloseIcon onClick={() => props.onCancel({ isOpen: false, title: "", description: "", success: false })} />
					</div>
				</ModalHeader>
				<ModalBody>
					<MainInput
						type="text"
						placeholder={MESSAGES.PT_BR.TUTORIALS.MODAL.TITLE_PLACEHOLDER}
						value={videoTitle}
						errorText={titleError}
						onChange={(value) => { setVideoTitle(value); }}
						title={MESSAGES.PT_BR.TUTORIALS.MODAL.TITLE}
					/>
					<MainInput
						type="text"
						placeholder={MESSAGES.PT_BR.TUTORIALS.MODAL.LINK_PLACEHOLDER}
						value={videoLink}
						errorText={videoLinkError}
						onChange={(value) => { setVideoLink(value); }}
						title={MESSAGES.PT_BR.TUTORIALS.MODAL.LINK}
					/>
				</ModalBody>
				<ModalFooter theme={currentTheme}>
					<p onClick={() => handleSaveVideo(true)}>{MESSAGES.PT_BR.TUTORIALS.MODAL.KEEP_ADDING}</p>
					<MainButton
						enabled
						onClick={() => handleSaveVideo(false)}
						text={MESSAGES.PT_BR.TUTORIALS.MODAL.ADD_BTN}
					/>
				</ModalFooter>
			</ModalContent>
		</ModalContainer>
	);

};

export default AddVideoModal;