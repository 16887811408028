import React from "react";
import { AdminUser, Role } from "../../../types/AdminUser";
import { ROLES } from "../../../constants";
import { WithoutPermissionBox } from "../../../components";
import { Device } from "../../../types/Device";

export const canSee = (userRole: Role, requestedFeature: string) => {
	return ROLES.CAN_SEE_FEATURES[userRole].includes(requestedFeature);
};

export const canManage = (userRole: Role, requestedFeature: string) => {
	return ROLES.CAN_MANAGE_FEATURES[userRole].includes(requestedFeature);
};

export const getNextAllowedFeature = (currentDevice: Device, userRole: Role) => {
	let index = 0;
	while(!canSee(userRole, currentDevice.availableFeatures[index])){
		index++;
	}
	return currentDevice.availableFeatures[index];
};

export const canDeleteAdminUser = (userRole: Role, userToBeDeleted: AdminUser, adminList: AdminUser[]) => {
	//Se não é admin ou owner, não pode deletar
	if(userRole !== Role.ADMIN && userRole !== Role.OWNER){
		return false;
	}

	//Se é admin, não pode deletar owner
	if(userRole === Role.ADMIN && userToBeDeleted.role === Role.OWNER){
		return false;
	}

	const ownerCount = adminList.filter((admin) => admin.role === Role.OWNER).length;

	//Deve existir pelo menos um owner
	if(userRole === Role.OWNER && userToBeDeleted.role === Role.OWNER && ownerCount === 1){
		return false;
	}

	return true;
};

interface PermissionGateProps {
    children: React.ReactNode;
	skipRoleCheck: boolean;
	userRole: Role;
	requestedFeature: string;
}

/**
 * Filtra rotas possíveis com base no cargo do usuário autenticado.
 * @param children Página que o usuário deseja acessar
 * @returns Se usuário tem permissão, retorna página, se não, retorna página de erro
 */
const PermissionGate :React.FC<PermissionGateProps> = ({children, skipRoleCheck, userRole, requestedFeature}) => {

	if(canSee(userRole, requestedFeature) || skipRoleCheck){
		return <>{children}</>;
	}

	return (<WithoutPermissionBox />);
};

export default PermissionGate;