import styled from "styled-components";
import { Theme } from "../../../../../../../../types/Theme";

interface ContainerProps {
    theme: Theme
}

export const TableHeaderStyled = styled.tr<ContainerProps>`
    width: 100%;
  
    th {
        font-size: 18px;
        font-weight: 100;
        color: ${props => props.theme.textColor};
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
    }
`;
