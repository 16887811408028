import styled from "styled-components";

export const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	background-color: ${props => props.theme.backgroundCard};
	border-radius: 20px;
	width: 100%;
	min-width: 700px;
`;

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: ${props => props.theme.backgroundCard};
	margin-top: 40px;
	margin-bottom: 40px;

	p {
		width: 30%;
		text-align: center;
		font-weight: 500;
		font-size: 32px;
		color: ${props => props.theme.textColor};
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	padding-left: 30px;
	padding-right: 30px;
	box-sizing: border-box;
	width: 100%;
	margin-top: 2%;

	h1 {
		font-weight: 500;
		font-size: 32px;
		color: ${props => props.theme.textColor};
	}

	button {
		background-color: ${props => props.theme.primaryColor};
		Width: 20%;
	}
`;