import React, { useEffect, useState, useRef } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { toast } from "react-hot-toast";
import { TextIconButton } from "../../../../components/Buttons";
import { ShareExerciseItem, AddShareExerciseModal, EditShareExerciseModal, DeleteShareExerciseModal } from "./components";
import { Container, DroppableArea, FakeBtn, TrainingHeader } from "./styles";
import { ReactComponent as PlusIcon } from "../../../../assets/images/plusIcon.svg";
import { ReactComponent as DragEnabledIcon } from "../../../../assets/images/dragEnabledIcon.svg";
import { ReactComponent as DragDisabledIcon } from "../../../../assets/images/dragDisabledIcon.svg";
import { Loader } from "../../../../components";
import { MESSAGES } from "../../../../constants/messages";
import { useShareExercise } from "../../../../contexts/shareExercise";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { ShareExercise } from "../../../../types/ShareExercise";
import { useWindowDimensions } from "../../../../hooks";
import { SCREEN_SIZE } from "../../../../constants";

const ShareExercises = () => {
	const { selectedApp } = useMobileApp();
	const { getShareExerciseList, createNewShareExercise, updateShareExercise, deleteShareExercise, saveNewOrder } = useShareExercise();

	const [shareExercises, setShareExercises] = useState<ShareExercise[] | null>(null);
	const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
	const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
	const [isDragDisabled, setIsDragDisabled] = useState<boolean>(true);
	const { width } = useWindowDimensions();

	const selectedShareExerciseToEdit = useRef<ShareExercise>({} as ShareExercise);
	const selectedShareExerciseToDel = useRef<ShareExercise>({} as ShareExercise);

	useEffect(() => {
		refreshShares();
	}, []);

	const refreshShares = () => {
		getShareExerciseList(selectedApp.appCode).then((response) => {
			setShareExercises(response);
		}).catch(() => {
			setShareExercises([]);
			toast.error(MESSAGES.PT_BR.SHARE_EXERCISE.GET_ERROR);
		});
	};

	const handleNewShareExercise = (shareExercise: ShareExercise, imageFile: File) => {
		const t = toast.loading(MESSAGES.PT_BR.SHARE_EXERCISE.SAVING);
		createNewShareExercise(shareExercise, imageFile, selectedApp.appCode)
			.then(() => toast.success(MESSAGES.PT_BR.SHARE_EXERCISE.SAVED))
			.catch(() => toast.error(MESSAGES.PT_BR.SHARE_EXERCISE.SAVING_ERROR))
			.finally(() => {
				refreshShares();
				toast.dismiss(t);
			});
	};

	const handleEdit = (shareExercise: ShareExercise, newImageFile: File) => {
		const t = toast.loading(MESSAGES.PT_BR.SHARE_EXERCISE.SAVING);
		updateShareExercise(shareExercise, newImageFile, selectedApp.appCode)
			.then(() => toast.success(MESSAGES.PT_BR.SHARE_EXERCISE.SAVED))
			.catch(() => toast.error(MESSAGES.PT_BR.SHARE_EXERCISE.SAVING_ERROR))
			.finally(() => {
				refreshShares();
				toast.dismiss(t);
			});
	};

	const handleDelete = () => {
		const t = toast.loading(MESSAGES.PT_BR.SHARE_EXERCISE.DELETING);
		deleteShareExercise(selectedShareExerciseToDel.current, selectedApp.appCode)
			.then(() => toast.success(MESSAGES.PT_BR.SHARE_EXERCISE.DELETED_SUCCESSFULLY))
			.catch(() => toast.error(MESSAGES.PT_BR.SHARE_EXERCISE.DELETING_ERROR))
			.finally(() => {
				refreshShares();
				toast.dismiss(t);
			});
	};

	const handleOnDragEnd = (result: DropResult) => {
		const destination = result.destination;
		const source = result.source;
		if (!destination || destination.index === source.index || shareExercises === null) return;

		const items = Array.from(shareExercises);
		const [reorderedItem] = items.splice(source.index, 1);
		items.splice(destination.index, 0, reorderedItem);

		items.forEach((item, index) => {
			item.index = index + 1;
		});

		setShareExercises(items);
		const t = toast.loading(MESSAGES.PT_BR.SHARE_EXERCISE.SAVING);
		saveNewOrder(items, selectedApp.appCode)
			.then(() => toast.success(MESSAGES.PT_BR.SHARE_EXERCISE.SAVED))
			.catch(() => toast.error(MESSAGES.PT_BR.SHARE_EXERCISE.SAVING_ERROR))
			.finally(() => toast.dismiss(t));
	};

	return (
		<Container>
			{addModalVisible && (
				<AddShareExerciseModal onClose={() => setAddModalVisible(false)} handleNewShareExercise={handleNewShareExercise} />
			)}
			{editModalVisible && (
				<EditShareExerciseModal onClose={() => setEditModalVisible(false)} selectedShareExercise={selectedShareExerciseToEdit.current} handleEdit={handleEdit} />
			)}
			{deleteModalVisible && (
				<DeleteShareExerciseModal onClose={() => setDeleteModalVisible(false)} selectedShareExercise={selectedShareExerciseToDel.current} handleDelete={handleDelete} />
			)}
			<TrainingHeader>
				<h1>{MESSAGES.PT_BR.SHARE_EXERCISE.TITLE}</h1>
				{width > SCREEN_SIZE.DESKTOP &&
					<FakeBtn onClick={() => setIsDragDisabled(!isDragDisabled)}>
						<div>
							{isDragDisabled ? <DragDisabledIcon /> : <DragEnabledIcon />}
						</div>
						<p>{isDragDisabled ? MESSAGES.PT_BR.SHARE_EXERCISE.DRAG_DISABLED : MESSAGES.PT_BR.SHARE_EXERCISE.DRAG_ENABLED}</p>
					</FakeBtn>
				}

				<TextIconButton
					enabled
					text={MESSAGES.PT_BR.SHARE_EXERCISE.NEW_BTN}
					onClick={() => { setAddModalVisible(true); }}
					svgImage={<PlusIcon style={{ "width": "100%", "height": "100%" }} />}
				/>
			</TrainingHeader>
			{/* <TableHeaderStyled>
				<h3>{MESSAGES.PT_BR.SHARE_EXERCISE.PREVIEW}</h3>
				<h3 style={{ width:"70%" }}>{MESSAGES.PT_BR.SHARE_EXERCISE.EXERCISE_TITLE}</h3>
				<h3>{MESSAGES.PT_BR.SHARE_EXERCISE.ACTION}</h3>
			</TableHeaderStyled> */}
			{shareExercises === null ?
				<Loader /> :
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<Droppable droppableId="characters">
						{(provided) => (
							<DroppableArea {...provided.droppableProps} ref={provided.innerRef}>
								{shareExercises?.map((item, index) => {
									return (
										<Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={isDragDisabled} >
											{(provided) => (
												<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<ShareExerciseItem
														isDragDisabled={isDragDisabled}
														currentShareExercise={item}
														onEditRequested={() => {
															selectedShareExerciseToEdit.current = item;
															setEditModalVisible(true);
														}}
														onDeleteRequested={() => {
															selectedShareExerciseToDel.current = item;
															setDeleteModalVisible(true);
														}}
													/>
												</div>
											)}
										</Draggable>);
								})}
								{provided.placeholder}
							</DroppableArea>
						)}
					</Droppable>
				</DragDropContext>}
		</Container>
	);
};

export default ShareExercises;