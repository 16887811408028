import React, { useEffect, useState } from "react";
import { MESSAGES } from "../../../../../../constants/messages";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, RowContainer } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { FileInput, MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import Dropdown from "../../../../../../components/Dropdown/MainDropdown";
import { WorkoutLevel, WorkoutSection, WorkoutVideo } from "../../../../../../types/Training";
import { HorizontalDeviceSelection, StatusModal } from "../../../../../../components";
import { useDevice } from "../../../../../../contexts/device";
import { toast } from "react-hot-toast";
import { useTraining } from "../../../../../../contexts/training";
import { useMobileApp } from "../../../../../../contexts/mobileApp";

interface Props {
	onClose: () => void;
	currentSection: WorkoutSection;
}

const getNextValidEpisode = (currentSection: WorkoutSection, difficulty: number) => {
	let nextValidEpisode = 1;
	if (currentSection.trainingVideos && currentSection.trainingVideos.length > 0) {
		const inSameLevelCount = currentSection.trainingVideos.filter((video) => video.difficulty === difficulty).length; //+1 pq difficulty começa em 0
		nextValidEpisode = inSameLevelCount + 1;
		return nextValidEpisode;
	}
	return nextValidEpisode;
};

const AddWorkoutVideoModal = (props: Props) => {
	const { deviceListWithoutDefault } = useDevice();
	const { saveWorkoutVideo } = useTraining();
	const { selectedApp } = useMobileApp();
	const [newWorkoutVideo, setNewWorkoutVideo] = useState<WorkoutVideo>({
		difficulty: WorkoutLevel.BEGINNER,
		available: true,
		trainingVideoSectionId: props.currentSection.id,
		slugs: deviceListWithoutDefault.map((device) => device.slug),
	} as WorkoutVideo);
	const [thumbnailFile, setThumbnailFile] = useState<File>(new File([], ""));
	const [statusModal, setStatusModal] = useState({ isOpen: false, title: "", description: "", success: false });
	const [descriptionError, setDescriptionError] = useState<string>("");
	const [linkError, setLinkError] = useState<string>("");
	const [thumbnailError, setThumbnailError] = useState<string>("");

	useEffect(() => {
		setNewWorkoutVideo({ ...newWorkoutVideo, episode: getNextValidEpisode(props.currentSection, newWorkoutVideo.difficulty) });
	}, [newWorkoutVideo.difficulty]);

	const handleAddThumbnail = (file: File) => {
		setThumbnailFile(file);
		if (thumbnailError) setThumbnailError("");
	};

	const handleSaveVideo = (keepAdding: boolean) => {
		let allRight = true;
		if (newWorkoutVideo.description == undefined || newWorkoutVideo.description == "") {
			allRight = false;
			setDescriptionError(MESSAGES.PT_BR.TRAINING_MESSAGES.MANDATORY_FIELD);
		} else {
			setDescriptionError("");
		}

		if (newWorkoutVideo.link == undefined || newWorkoutVideo.link == "") {
			allRight = false;
			setLinkError(MESSAGES.PT_BR.TRAINING_MESSAGES.MANDATORY_FIELD);
		} else {
			setLinkError("");
		}

		if (thumbnailFile.size === 0) {
			allRight = false;
			setThumbnailError(MESSAGES.PT_BR.TRAINING_MESSAGES.MANDATORY_FIELD);
		} else {
			setThumbnailError("");
		}

		if (allRight) {
			const t = toast.loading(MESSAGES.PT_BR.TRAINING_MESSAGES.LOADING);
			saveWorkoutVideo(newWorkoutVideo, thumbnailFile, selectedApp.appCode)
				.then(() => {
					if(keepAdding){
						resetStates();
						toast.success(MESSAGES.PT_BR.TRAINING_MESSAGES.ADD_SUCCESS);
					}else{
						setStatusModal({ isOpen: true, title: MESSAGES.PT_BR.TRAINING_MESSAGES.ADD_SUCCESS, description: MESSAGES.PT_BR.TRAINING_MESSAGES.ADD_WORKOUT_DESCRIPTION, success: true });
					}
				})
				.catch((error) => {
					console.log(error);
					setStatusModal({ isOpen: true, title: MESSAGES.PT_BR.TRAINING_MESSAGES.ADD_ERROR, description: MESSAGES.PT_BR.TRAINING_MESSAGES.ADD_ERROR_WORKOUT_DESCRIPTION + error, success: false });
				})
				.finally(() => {
					toast.dismiss(t);
					setTimeout(() => {
						if(!keepAdding){
							props.onClose();
						}
						setStatusModal({ isOpen: false, title: "", description: "", success: false });
					}, 3000);
				});
		}
	};

	const resetStates = () => {
		setNewWorkoutVideo({
			difficulty: WorkoutLevel.BEGINNER,
			available: true,
			trainingVideoSectionId: props.currentSection.id,
			episode: getNextValidEpisode(props.currentSection, WorkoutLevel.BEGINNER),
			slugs: deviceListWithoutDefault.map((device) => device.slug)
		} as WorkoutVideo);
		setThumbnailFile(new File([], ""));
		setDescriptionError("");
		setLinkError("");
		setThumbnailError("");
		setStatusModal({ isOpen: false, title: "", description: "", success: false });
	};

	return (
		<ModalContainer>
			<StatusModal isOpen={statusModal.isOpen} title={statusModal.title} description={statusModal.description} success={statusModal.success} />
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.TRAINING_MESSAGES.NEW_WORKOUT_VIDEO}</h1>
					<div>
						<CloseIcon onClick={props.onClose} />
					</div>
				</ModalHeader>
				<ModalBody>
					<RowContainer>
						<Dropdown
							items={MESSAGES.PT_BR.TRAINING_MESSAGES.LEVEL_ARRAY}
							onChange={(newSelectedValue) =>
								setNewWorkoutVideo({
									...newWorkoutVideo,
									difficulty: MESSAGES.PT_BR.TRAINING_MESSAGES.LEVEL_ARRAY.indexOf(newSelectedValue) + 1,
								})}
							selected={MESSAGES.PT_BR.TRAINING_MESSAGES.LEVEL_ARRAY[newWorkoutVideo.difficulty - 1]}
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.LEVEL}
							style={{ marginBottom: "10px", height: "74px" }}
						/>
						<h5></h5>
						<MainInput
							onChange={() => null}
							type="number"
							placeholder="1"
							value={newWorkoutVideo?.episode?.toString() ?? ""}
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.EP}
							disabled
						/>
					</RowContainer>
					<RowContainer>
						<MainInput
							onChange={(e) => setNewWorkoutVideo({ ...newWorkoutVideo, description: e })}
							type="text"
							placeholder=""
							value={newWorkoutVideo?.description ?? ""}
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.DESCRIPTION}
							rowsNumber={3}
							errorText={descriptionError}
						/>
					</RowContainer>
					<RowContainer>
						<MainInput
							onChange={(e) => setNewWorkoutVideo({ ...newWorkoutVideo, link: e })}
							type="text"
							placeholder={MESSAGES.PT_BR.TRAINING_MESSAGES.LINK_PLACEHOLDER}
							value={newWorkoutVideo?.link?.toString() ?? ""}
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.LINK}
							errorText={linkError}
						/>
						<h5></h5>
						<FileInput
							onChange={(newFile) => handleAddThumbnail(newFile)}
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.THUMBNAIL}
							accept="image/*"
							errorText={thumbnailError}
							value={thumbnailFile.name}
						/>
					</RowContainer>
					<RowContainer>
						<HorizontalDeviceSelection
							deviceList={deviceListWithoutDefault}
							selectedDeviceSlugList={newWorkoutVideo.slugs ?? []}
							onSelectionChange={(newSelectedDeviceSlugList) => {newSelectedDeviceSlugList.length > 0 && setNewWorkoutVideo({ ...newWorkoutVideo, slugs: newSelectedDeviceSlugList });}}
						/>
					</RowContainer>
					<ModalFooter>
						<p onClick={() => handleSaveVideo(true)}>{MESSAGES.PT_BR.TRAINING_MESSAGES.KEEP_ADDING}</p>
						<MainButton enabled onClick={() => handleSaveVideo(false)} text={MESSAGES.PT_BR.TRAINING_MESSAGES.ADD} />
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default AddWorkoutVideoModal;
