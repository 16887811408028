import React from "react";
import { useTheme } from "../../../../../../../../contexts/theme";
import { RowContainer, TableItemStyled } from "./styles";
import { Exercise } from "../../../../../../../../types/Exercise";
import { DeleteButton, EditButton } from "../../../../../../../../components/Buttons";

interface TableItemProps {
	customExercise: Exercise;
	onEditRequested: (customExercise: Exercise) => void;
	onDeleteRequested: (customExercise: Exercise) => void;
}

const TableItem = (props: TableItemProps) => {

	const { currentTheme } = useTheme();

	return (
		<TableItemStyled theme={currentTheme}>
			<td>{props.customExercise.name}</td>
			<td>
				<RowContainer>
					<EditButton onClick={() => props.onEditRequested(props.customExercise)} />
					<h1> </h1>
					<DeleteButton onClick={() => props.onDeleteRequested(props.customExercise)}/>
				</RowContainer>
			</td>
		</TableItemStyled>
	);
};

export default TableItem;