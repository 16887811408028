import React, { useEffect, useRef, useState } from "react";
import { Loader, StatusModal } from "../../../../components";
import { TextIconButton } from "../../../../components/Buttons";
import { MESSAGES } from "../../../../constants/messages";
import { useTheme } from "../../../../contexts/theme";
import { Tutorial } from "../../../../types/Tutorial";
import { Container, Content, EmptyContainer, Header } from "./styles";
import playIconDark from "../../../../assets/images/dark/playIcon.svg";
import playIconLight from "../../../../assets/images/light/playIcon.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/images/plusIcon.svg";
import { THEME_TYPES } from "../../../../constants";
import { AddVideoModal, EditVideoModal, ResultTable, DeleteVideoModal } from "./components";
import { useTutorial } from "../../../../contexts/tutorials";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { useDevice } from "../../../../contexts/device";
import toast from "react-hot-toast";

const Tutorials = () => {

	const [tutorialsList, setTutorialsList] = useState<Tutorial[] | null>(null);
	const [addModalIsOpen, setAddModalIsOpen] = useState<boolean>(false);
	const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
	const [statusModal, setStatusModal] = useState({ isOpen: false, title: "", description: "", success: false });
	const { currentTheme } = useTheme();
	const { getTutorialsList } = useTutorial();
	const { selectedApp } = useMobileApp();
	const { currentDevice } = useDevice();
	const selectedVideo = useRef<Tutorial | null>(null);

	useEffect(() => {
		refreshList();
	}, [selectedApp, currentDevice]);

	const refreshList = () => {
		getTutorialsList(selectedApp?.appCode, currentDevice?.slug || "")
			.then((response) => {
				setTutorialsList(response);
			})
			.catch((error) => {
				console.log(error);
				toast.error(error);
				setTutorialsList([]);
			});
	};


	const getContent = () => {
		if (tutorialsList === null) {
			return <Loader />;
		}
		if (tutorialsList?.length === 0) {
			return (
				<EmptyContainer theme={currentTheme}>
					<img src={currentTheme.type === THEME_TYPES.DARK ? playIconDark : playIconLight} alt={"play icon"} />
					<h2>{MESSAGES.PT_BR.TUTORIALS.MAIN_MESSAGES.EMPTY}</h2>
				</EmptyContainer>
			);
		}
		return <ResultTable
			result={tutorialsList}
			onEditRequested={(t) => { selectedVideo.current = t; setEditModalIsOpen(true); }}
			onDeleteRequested={(t) => { selectedVideo.current = t; setDeleteModalIsOpen(true); }}
		/>;
	};

	return (
		<Container theme={currentTheme}>
			<AddVideoModal
				isOpen={addModalIsOpen}
				onVideoAdded={() => refreshList()}
				onCancel={(status) => {
					setAddModalIsOpen(false);
					setStatusModal(status);
					setTimeout(() => {
						setStatusModal({ ...statusModal, isOpen: false });
					}, 3000);
				}
				} />

			<EditVideoModal
				isOpen={editModalIsOpen}
				currentVideo={selectedVideo.current}
				onVideoUpdated={() => refreshList()}
				onCancel={(status) => {
					setEditModalIsOpen(false);
					selectedVideo.current = null;
					setStatusModal(status);
					setTimeout(() => {
						setStatusModal({ ...statusModal, isOpen: false });
					}, 3000);
				}
				} />

			<DeleteVideoModal
				isOpen={deleteModalIsOpen}
				currentVideo={selectedVideo.current}
				onFinish={(status) => {
					refreshList();
					setDeleteModalIsOpen(false);
					selectedVideo.current = null;
					if(status != null) {
						setStatusModal(status);
						setTimeout(() => {
							setStatusModal({ ...statusModal, isOpen: false });
						}, 3000);
					}
				}
				} />

			<StatusModal
				description={statusModal.description}
				success={statusModal.success}
				isOpen={statusModal.isOpen}
				title={statusModal.title}
			/>
			<Header theme={currentTheme}>
				<h1>{MESSAGES.PT_BR.TUTORIALS.MAIN_MESSAGES.TITLE}</h1>
				<TextIconButton
					enabled
					onClick={() => setAddModalIsOpen(true)}
					text={MESSAGES.PT_BR.TUTORIALS.MAIN_MESSAGES.NEW_BTN}
					darkColor={currentTheme.primaryColor}
					lightColor={currentTheme.primaryColor}
					svgImage={<PlusIcon style={{ "width": "100%", "height": "100%" }} />}
				/>
			</Header>
			<Content>
				{getContent()}
			</Content>
		</Container>
	);
};

export default Tutorials;