
/**
 * API - Object
 * {
    "link": "https://www.youtube.com/watch?v=VVDQglAMyE0",
    "name": "Tutorial teste",
    "smart_watch_slug": "teste"
  }
 */

import { YoutubeService } from "../../services/youtube";

/**
 * APP - Object
 * Tutorial {
        thumb?: string;
        title: string;
        link: string;
    }
 */


const fromApiToModel = (tutorial) => {

	const videoId = YoutubeService.getVideoId(tutorial.link);
	const thumb = `https://img.youtube.com/vi/${videoId}/0.jpg`;

	return {
		title: tutorial.name,
		link: tutorial.link,
		thumb: thumb
	};
};

const fromModelToApi = (tutorial, deviceSlug) => {
	return {
		smart_watch_slug: deviceSlug,
		link: tutorial.link,
		name: tutorial.title
	};
};

export const TutorialMapper = {
	fromApiToModel,
	fromModelToApi
};
