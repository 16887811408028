import React from "react";
import { useTheme } from "../../../contexts/theme";
import { ButtonStyled } from "./styles";

interface MainButtonProps {
	onClick: () => void;
	enabled?: boolean;
	svgImage: React.ReactNode;
	styles?: React.CSSProperties;
}

const IconButton = (props: MainButtonProps) => {

	const { currentTheme } = useTheme();

	return (
		<ButtonStyled
			style={props.styles}
			onClick={() => props?.enabled ? props.onClick() : null}
			theme={currentTheme}
			enabled={props.enabled}
		>
			{props.svgImage}
		</ButtonStyled>
	);
};

export default IconButton;