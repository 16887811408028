export const API_KEYS = {
	STRAVA: "Strava",
	STRAVA_ID: "ID do cliente",
	STRAVA_CLIENT: "Cliente secreto",
	STRAVA_TOKEN: "Token de atualização",
	STRAVA_ID_PLACEHOLDER: "Cole aqui o ID do cliente do Strava",
	STRAVA_CLIENT_PLACEHOLDER: "Cole aqui o cliente secreto do strava",
	STRAVA_TOKEN_PLACEHOLDER: "Cole aqui o token de atualização do strava",

	SAVE: "Salvar",
	SAVE_FEEDBACK: "Salvo com sucesso!",
	STRAVA_FEEDBACK: "As chaves do Strava foram salvas com sucesso!",
	AGPS_FEEDBACK: "Os links do AGPS foram salvos com sucesso!",

	MANDATORY_FIELD: "Este campo é obrigatório",
};