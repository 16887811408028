import styled from "styled-components";
import { Theme } from "../../../../../../../../types/Theme";

interface Props {
    theme: Theme,
}

export const TitleInfoBoxContainer = styled.div<Props>`
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: ${props => props.theme.backgroundCardSecondary};
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
    padding: 10px;
    justify-content: space-around;
    width: 200px;
    height: 100px;

    p {
        text-align: start;

        strong {
            font-family: "Bold";
        }
    }

`;