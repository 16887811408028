import React, { createContext, useContext, useState } from "react";
import { Helpers } from "../helpers";
import { Api } from "../services";
import { FaqArticle, FaqTopic } from "../types/FaqArticle";

interface IFaqContextData {
	availableTags: string[],
	faqList: FaqArticle[],
	addLocalTag: (newTag: string) => void,
	getFaqList: (appCode: number) => Promise<FaqArticle[] | null>,
	updateFaqTheme: (appCode: number, faqArticle: FaqArticle, lightIcon: File, darkIcon: File) => Promise<void>,
	updateFaqTopic: (appCode: number, faqTopic: FaqTopic, themeIndex: number) => Promise<void>,
	deleteFaqTheme: (appCode: number, themeId: number) => Promise<void>,
	addFaqTheme: (appCode: number, title: string, index: number) => Promise<number>,
	addFaqTopic: (appCode: number, topic: FaqTopic, themeId: number) => Promise<void>,
	deleteFaqTopic: (appCode: number, topicId: number) => Promise<void>,
	saveNewOrder: (appCode: number, faqList: FaqArticle[]) => Promise<void>,
}

interface FaqProviderProps {
	children: React.ReactNode;
}

export const FaqContext = createContext({} as IFaqContextData);

const FaqProvider: React.FC<FaqProviderProps> = ({ children }) => {

	const S3_FOLDER = "faqThemeIcon";

	const [availableTags, setAvailableTags] = useState<string[]>([]);
	const [faqList, setFaqList] = useState<FaqArticle[]>([]);

	const addLocalTag = (newTag: string) => {
		if (availableTags.indexOf(newTag) === -1) {
			setAvailableTags([...availableTags, newTag]);
		}
	};


	const getFaqList = (appCode: number) => {
		return new Promise<FaqArticle[] | null>((resolve, reject) => {
			Api.Faq.getFaqArticleList(appCode)
				.then((faqs) => {
					setFaqList(faqs);
					setAvailableTags(Helpers.Faq.getAvailableTags(faqs));
					resolve(faqs);
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const updateFaqTheme = (appCode: number, updatedFaqArticle: FaqArticle, lightIcon: File, darkIcon: File) => {
		let lightIconPromise: Promise<string>;
		let darkIconPromise: Promise<string>;
		return new Promise<void>((resolve, reject) => {
			if (lightIcon.size) lightIconPromise = Api.AWS.sendOrUpdateFileToS3(appCode, lightIcon, S3_FOLDER, updatedFaqArticle.lightIcon);
			if (darkIcon.size) darkIconPromise = Api.AWS.sendOrUpdateFileToS3(appCode, darkIcon, S3_FOLDER, updatedFaqArticle.darkIcon);

			Promise.all([lightIconPromise, darkIconPromise]).then(urls => {
				if (urls[0]) updatedFaqArticle.lightIcon = urls[0];
				if (urls[1]) updatedFaqArticle.darkIcon = urls[1];
				Api.Faq.updateFaqTheme(appCode, updatedFaqArticle)
					.then(() => resolve())
					.catch(errorMessage => reject(errorMessage));
			}).catch(s3Error => {
				reject(s3Error);
			});
		});
	};


	const updateFaqTopic = (appCode: number, updatedFaqTopic: FaqTopic, themeIndex: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.Faq.updateFaqTopic(appCode, updatedFaqTopic, themeIndex)
				.then(() => {
					resolve();
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const deleteFaqTheme = (appCode: number, themeId: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.Faq.deleteFaqTheme(appCode, themeId)
				.then(() => {
					resolve();
				}).catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const addFaqTheme = (appCode: number, title: string, index: number) => {
		return new Promise<number>((resolve, reject) => {
			Api.Faq.addFaqTheme(appCode, title, index)
				.then((themeId) => {
					resolve(themeId);
				}).catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const addFaqTopic = (appCode: number, topic: FaqTopic, themeId: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.Faq.addFaqTopic(appCode, themeId, topic)
				.then(() => {
					resolve();
				}).catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const deleteFaqTopic = (appCode: number, topicId: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.Faq.deleteFaqTopic(appCode, topicId)
				.then(() => {
					resolve();
				}).catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const saveNewOrder = async (appCode: number, sortedFaqList: FaqArticle[]) => {
		const emptyFile = new File([], "");
		const tasks = new Array(sortedFaqList.length).fill(updateFaqTheme);
		for (let i = 0; i < sortedFaqList.length; i++) {
			try{
				await tasks[i](appCode, sortedFaqList[i], emptyFile, emptyFile);
			}catch(e){
				console.log(e);
				return Promise.reject(e);
			}
		}
		return Promise.resolve();
	};


	return (
		<FaqContext.Provider
			value={{
				faqList,
				availableTags,
				addLocalTag,
				getFaqList,
				updateFaqTheme,
				updateFaqTopic,
				deleteFaqTheme,
				addFaqTheme,
				addFaqTopic,
				deleteFaqTopic,
				saveNewOrder,
			}}>
			{children}
		</FaqContext.Provider>
	);
};

const useFaq = () => {
	const context = useContext(FaqContext);

	return context;
};

export { FaqProvider, useFaq };
