import styled from "styled-components";


export const FileButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    /* background-color: red; */
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    height: 100px;
    /* background-color: black; */
`;