import React from "react";
import { MESSAGES } from "../../../../../../../../constants/messages";
import { useTheme } from "../../../../../../../../contexts/theme";
import { TitleInfoBoxContainer } from "./styles";

const PhotoBox = () => {

	const { currentTheme } = useTheme();

	return (
		<TitleInfoBoxContainer theme={currentTheme}>
			<div>
				<p><strong>{MESSAGES.PT_BR.NOTIFICATIONS.MAX_TAM_IMG}</strong></p>
				<p>{MESSAGES.PT_BR.NOTIFICATIONS.TAM_IMG}</p>
			</div>

			<div>
				<p><strong>{MESSAGES.PT_BR.NOTIFICATIONS.IMG_FORMAT}</strong></p>
				<p>{MESSAGES.PT_BR.NOTIFICATIONS.FORMAT}</p>
			</div>
		</TitleInfoBoxContainer>
	);
};

export default PhotoBox;