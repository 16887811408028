import React, { useEffect, useState } from "react";
import { Loader, StatusModal } from "../../../../components";
import { TextIconButton } from "../../../../components/Buttons";
import { MESSAGES } from "../../../../constants/messages";
import { useNotification } from "../../../../contexts/notification";
import { Notification } from "../../../../types/Notification";
import { NewMessageModal, ResultTable } from "./components";
import { CardContainer, Header, NotificationsContainer } from "./styles";
import { ReactComponent as PlusIcon } from "../../../../assets/images/plusIcon.svg";
import toast from "react-hot-toast";
import { useMobileApp } from "../../../../contexts/mobileApp";


const PushNotifications = () => {

	const [currentNotifications, setCurrentNotifications] = useState<Notification[] | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const { getNotificationList, sendNotification } = useNotification();
	const { selectedApp } = useMobileApp();
	const [statusModalInfo, setStatusModalInfo] = useState({
		isOpen: false,
		title: "",
		description: "",
		success: false,
	});

	useEffect(() => {
		setCurrentNotifications(null);
		refreshNotifications();
	}, [selectedApp]);
	

	const handleSendNotification = (notification: Notification, notificationImage: File) => {
		setIsModalOpen(false);
		const t = toast.loading(MESSAGES.PT_BR.NOTIFICATIONS.SENDING);
		sendNotification(selectedApp.appCode, notification, notificationImage)
			.then(() => {
				setStatusModalInfo({
					isOpen: true,
					title: MESSAGES.PT_BR.NOTIFICATIONS.SUCCESS_TITLE,
					description: MESSAGES.PT_BR.NOTIFICATIONS.SUCCESS_DESCRIPTION,
					success: true,
				});
				refreshNotifications();
			}).catch((errorMessage) => {
				setStatusModalInfo({
					isOpen: true,
					title: MESSAGES.PT_BR.NOTIFICATIONS.ERROR_TITLE,
					description: MESSAGES.PT_BR.NOTIFICATIONS.ERROR_DESCRIPTION + errorMessage,
					success: false,
				});
			}).finally(() => {
				toast.dismiss(t);
				setTimeout(() => {
					setStatusModalInfo({ ...statusModalInfo, isOpen: false });
				}, 3000);
			});
	};

	const refreshNotifications = () => {
		getNotificationList(selectedApp.appCode)
			.then((notifications) => {
				if (notifications) {
					setCurrentNotifications(notifications);
				}
			}).catch((errorMessage) => {
				toast.error(errorMessage);
				setCurrentNotifications([]);
			});
	};


	const showResults = () => {
		if (currentNotifications && currentNotifications.length > 0) {
			return <ResultTable
				result={currentNotifications}
			/>;
		} else if (currentNotifications?.length === 0) {
			return (
				<CardContainer>
					<p>Nenhuma notificação encontrada</p>
				</CardContainer>
			);
		}
		return (
			<CardContainer>
				<Loader />
			</CardContainer>
		);
	};


	return (
		<NotificationsContainer>
			<StatusModal isOpen={statusModalInfo.isOpen} title={statusModalInfo.title} description={statusModalInfo.description} success={statusModalInfo.success} />
			{isModalOpen && <NewMessageModal onCancel={() => setIsModalOpen(false)} onSendRequest={handleSendNotification} />}
			<Header>
				<h2>{MESSAGES.PT_BR.NOTIFICATIONS.NOTIFICATIONS_TITLE}</h2>
				<TextIconButton
					text={MESSAGES.PT_BR.NOTIFICATIONS.NEW_NOTIFICATION_BTN}
					onClick={() => { setIsModalOpen(true); }}
					svgImage={<PlusIcon style={{ "width": "100%", "height": "100%" }} />}
					enabled
				/>
			</Header>
			{showResults()}
		</NotificationsContainer>
	);
};

export default PushNotifications;