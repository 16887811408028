export const LOGIN = {
	LOGIN_BTN: "Entrar",
	EMAIL: "E-mail",
	PASSWORD: "Senha",
	FORGET_PASSWORD: "Esqueci minha senha",
	LOGIN_TITLE: "Faça seu Login",
	EMAIL_REQUIRED: "O email é obrigatório",
	PASSWORD_REQUIRED: "A senha é obrigatória",
	LOADING: "Carregando...",
	LOGIN_ERROR: "Erro ao fazer login",
	EMAIL_INVALID: "Conta não encontrada. Verifique os dados e tente novamente.",
};
