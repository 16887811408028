import styled from "styled-components";


interface ModalProps {
	isOpen: boolean,
}

export const ModalContainer = styled.div<ModalProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	opacity: ${props => props.isOpen ? 1 : 0};
	visibility: ${props => props.isOpen? "visible" : "hidden"};
	transition: visibility 0s, opacity 0.3s linear;
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 50%;
	background-color: ${props => props.theme.backgroundCard};
	border-radius: 24px;
	padding: 20px;
	box-sizing: border-box;
	min-width: 500px;
`;

export const ModalHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 10px;

	h1 {
		font-size: 28px;
		margin-left: 10px;
		text-align: center;
		font-weight: 500;
		line-height: 30px;
		color: ${props => props.theme.primaryColor};
	}

	div {
		margin-right: 10px;
		&:hover {
			cursor: pointer;
		}
	}
`;

export const ModalBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;

	input {
		margin-bottom: 10px;
	}
`;

export const RowContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	overflow-x: auto;

	h5 {
		margin: 1%;
	}
`;

export const ModalFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;

	button {
		width: 30%;
		margin-left: 20px;
		border-radius: 15px;
		background-color: ${props => props.theme.primaryColor};
	}
`;
