import React, { useState } from "react";
import { MESSAGES } from "../../../../../../constants/messages";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, RowContainer } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { FileInput, MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import Dropdown from "../../../../../../components/Dropdown/MainDropdown";
import { WorkoutVideo } from "../../../../../../types/Training";
import { HorizontalDeviceSelection, StatusModal } from "../../../../../../components";
import { useDevice } from "../../../../../../contexts/device";
import { toast } from "react-hot-toast";
import { useTraining } from "../../../../../../contexts/training";
import { useMobileApp } from "../../../../../../contexts/mobileApp";

interface Props {
	onClose: () => void;
	currentWorkoutVideo: WorkoutVideo;
}

const EditWorkoutVideoModal = (props: Props) => {
	const { deviceListWithoutDefault } = useDevice();
	const { updateTrainningVideo } = useTraining();
	const { selectedApp } = useMobileApp();
	const [currentWorkoutVideo, setCurrentWorkoutVideo] = useState<WorkoutVideo>(props.currentWorkoutVideo);
	const [thumbnailFile, setThumbnailFile] = useState<File>(new File([], ""));
	const [statusModal, setStatusModal] = useState({ isOpen: false, title: "", description: "", success: false });
	const [descriptionError, setDescriptionError] = useState<string>("");
	const [linkError, setLinkError] = useState<string>("");
	const [thumbnailError, setThumbnailError] = useState<string>("");

	const originalVideoLink = props.currentWorkoutVideo.link;

	const handleAddThumbnail = (file: File) => {
		setThumbnailFile(file);
		if (thumbnailError) setThumbnailError("");
	};

	const handleUpdateVideo = () => {

		let allRight = true;
		if (currentWorkoutVideo.description == undefined || currentWorkoutVideo.description == "") {
			allRight = false;
			setDescriptionError(MESSAGES.PT_BR.TRAINING_MESSAGES.MANDATORY_FIELD);
		} else {
			setDescriptionError("");
		}

		if (currentWorkoutVideo.link == undefined || currentWorkoutVideo.link == "") {
			allRight = false;
			setLinkError(MESSAGES.PT_BR.TRAINING_MESSAGES.MANDATORY_FIELD);
		} else {
			setLinkError("");
		}

		if (currentWorkoutVideo.thumbnail || thumbnailFile.size) {
			setThumbnailError("");
		} else {
			allRight = false;
			setThumbnailError(MESSAGES.PT_BR.TRAINING_MESSAGES.MANDATORY_FIELD);
		}

		if (allRight) {
			const t = toast.loading(MESSAGES.PT_BR.TRAINING_MESSAGES.LOADING);
			const isSameVideo = originalVideoLink === currentWorkoutVideo.link;
			updateTrainningVideo(selectedApp.appCode, currentWorkoutVideo, thumbnailFile, isSameVideo)
				.then(() => {
					setStatusModal({ isOpen: true, title: MESSAGES.PT_BR.TRAINING_MESSAGES.EDIT_SUCCESS, description: MESSAGES.PT_BR.TRAINING_MESSAGES.EDIT_DESCRIPTION, success: true });
				})
				.catch((error) => {
					console.log(error);
					setStatusModal({ isOpen: true, title: MESSAGES.PT_BR.TRAINING_MESSAGES.EDIT_ERROR, description: MESSAGES.PT_BR.TRAINING_MESSAGES.EDIT_ERROR_DESCRIPTION + error, success: false });
				})
				.finally(() => {
					toast.dismiss(t);
					setTimeout(() => {
						props.onClose();
						setStatusModal({ isOpen: false, title: "", description: "", success: false });
					}, 3000);
				});
		}
	};

	return (
		<ModalContainer>
			<StatusModal isOpen={statusModal.isOpen} title={statusModal.title} description={statusModal.description} success={statusModal.success} />
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.TRAINING_MESSAGES.EDIT_WORKOUT_VIDEO}</h1>
					<div>
						<CloseIcon onClick={props.onClose} />
					</div>
				</ModalHeader>
				<ModalBody>
					<RowContainer>
						<Dropdown
							items={MESSAGES.PT_BR.TRAINING_MESSAGES.LEVEL_ARRAY}
							onChange={(newSelectedValue) =>
								setCurrentWorkoutVideo({
									...currentWorkoutVideo,
									difficulty: MESSAGES.PT_BR.TRAINING_MESSAGES.LEVEL_ARRAY.indexOf(newSelectedValue) + 1,
								})}
							selected={MESSAGES.PT_BR.TRAINING_MESSAGES.LEVEL_ARRAY[currentWorkoutVideo.difficulty - 1]}
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.LEVEL}
							style={{ marginBottom: "10px", height: "74px" }}
							disabled
						/>
						<h5></h5>
						<MainInput
							onChange={() => null}
							type="number"
							placeholder="1"
							value={currentWorkoutVideo?.episode?.toString() ?? ""}
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.EP}
							disabled
						/>
					</RowContainer>
					<RowContainer>
						<MainInput
							onChange={(e) => setCurrentWorkoutVideo({ ...currentWorkoutVideo, description: e })}
							type="text"
							placeholder=""
							value={currentWorkoutVideo?.description ?? ""}
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.DESCRIPTION}
							rowsNumber={3}
							errorText={descriptionError}
						/>
					</RowContainer>
					<RowContainer>
						<MainInput
							onChange={(e) => setCurrentWorkoutVideo({ ...currentWorkoutVideo, link: e })}
							type="text"
							placeholder={MESSAGES.PT_BR.TRAINING_MESSAGES.LINK_PLACEHOLDER}
							value={currentWorkoutVideo?.link?.toString() ?? ""}
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.LINK}
							errorText={linkError}
						/>
						<h5></h5>
						<FileInput
							onChange={(newFile) => handleAddThumbnail(newFile)}
							title={MESSAGES.PT_BR.TRAINING_MESSAGES.THUMBNAIL}
							accept="image/*"
							errorText={thumbnailError}
							value={thumbnailFile.name}
							valueLink={thumbnailFile.size ? undefined : currentWorkoutVideo.thumbnail}
						/>
					</RowContainer>
					<RowContainer>
						<HorizontalDeviceSelection
							deviceList={deviceListWithoutDefault}
							selectedDeviceSlugList={currentWorkoutVideo.slugs ?? []}
							onSelectionChange={(newSelectedDeviceSlugList) => { newSelectedDeviceSlugList.length > 0 && setCurrentWorkoutVideo({ ...currentWorkoutVideo, slugs: newSelectedDeviceSlugList }); }}
						/>
					</RowContainer>
					<ModalFooter>
						<MainButton enabled onClick={() => handleUpdateVideo()} text={MESSAGES.PT_BR.TRAINING_MESSAGES.SAVE} />
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default EditWorkoutVideoModal;
