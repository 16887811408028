import styled from "styled-components";
import { TRANSITION_TIME } from "../../../constants";
import { Theme } from "../../../types/Theme";

interface ContainerProps {
    theme: Theme,
    sideBarOpen: boolean,
}

export const HeaderStyled = styled.header<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 1%;
    height: 8%;
    max-height: 40px;
    position: fixed;
    width: 100%;
    background-color: ${props => props.theme.backgroundPage};
    z-index: 90;


    > h1 {
        color: ${props => props.theme.primaryColor};
        display: flex;
        width: 100%;
        min-width: 280px;
        padding-left: ${props => props.sideBarOpen ? 310 : 100}px;

        transition: padding-left ${TRANSITION_TIME};
        transition-delay: ${props => props.sideBarOpen ? TRANSITION_TIME : 0};

    }
`;

export const DropdownContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 25px;
`;