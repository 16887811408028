import styled from "styled-components";
import { Theme } from "../../../../../types/Theme";

interface IDeviceItemProps {
    theme: Theme,
}

export const DeviceContainer = styled.div<IDeviceItemProps>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${props => props.theme.backgroundCardSecondary};
	padding-right:20px;
	padding-left: 15px;
	width: 95%;
	min-height: 150px;
	border-radius: 16px;
	margin-bottom: 20px;

	img {
		width: 100px;
		height: 100px;
		border-radius: 16px;
		object-fit: cover;
	}
`;

export const EditContainer = styled.div<IDeviceItemProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin-left: 25px;

	h1 {
		font-size: 24px;
		font-weight: 500;
		color: ${props => props.theme.textColor};
	}

	span {
		color: ${props => props.theme.primaryColor};
		font-size: 12px;
		margin-left: 8px;
		margin-top: 2px;
		font-family: "Normal";
	}

	> div {
		display: flex;
		justify-content: space-between;
	}
`;

interface ITagInputProps {
	theme: Theme,
	errorText?: string
}

export const InputStyled = styled.input<ITagInputProps>`
	display: flex;
	align-items: flex-start;
	height: 60px;
	width: 100%;
	border: ${props => props.errorText ? `1px solid ${props.theme.primaryColor}` : "none"};
	outline: none;
	color: ${props => props.theme.textColor};
	font-size: 24px;
	background-color: ${props => props.theme.inputColor};
	border-radius: 16px;
	box-sizing: border-box;
	padding-left: 18px;
	font-family: "Normal";
	margin-top: 10px;
	::placeholder {
		color: ${props => props.theme.placeholderColor};
	}
`;

export const ImgContainer = styled.div`
	display:flex;
	flex-direction: row;
	width: 12%;
	justify-content: space-between;
`;


export const ConfirmDeleteToaster = styled.div`
	span {
		font-family: "Normal";
		font-size: 20px;
		font-weight: 400;
	}

	b {
		font-family: "Normal";
		font-size: 20px;
		text-decoration: underline;

		&:hover {
			cursor: pointer;
			opacity: 0.7;
		}
	}
`;