import styled from "styled-components";
import { COLORS } from "../../../assets";

interface InputStyledProps {
    errorText?: string;
}

export const TextInputStyled = styled.input<InputStyledProps>`
    width: 90%;
    height: 55px;
    border: none;
    border-radius: 20px;
    padding: 0 20px;
    margin-top: 3%;
    font-size: 16px;
    outline: none;
    transition: all 0.3s ease-in-out;
    background-color: #D1D2D3;
    font-weight: 500;

    border: ${(props) => props.errorText ? `1px solid ${COLORS.DEFAULT_COLORS.TEXT_ERROR}` : "none"};

    &:focus {
        border: 1px solid #000;
    }
`;

export const ErrorText = styled.span <InputStyledProps>`
    color: ${COLORS.DEFAULT_COLORS.TEXT_ERROR};
    font-size: 12px;
    width: 100%;
    text-align: left;
    display: ${props => props.errorText? "block" : "none"};
`;