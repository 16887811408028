import React, { useRef, useState } from "react";
import { FaqArticle, FaqTopic } from "../../../../../../types/FaqArticle";
import { ExpandedContainer, ImgContainer, ItemContainer, StackContainer, TextContainer, TopicContainer } from "./styles";
import { ReactComponent as ArrowDownLight } from "../../../../../../assets/images/light/arrowDownIcon.svg";
import { ReactComponent as ArrowUpLight } from "../../../../../../assets/images/light/arrowUpIcon.svg";
import { ReactComponent as ArrowUpDark } from "../../../../../../assets/images/dark/arrowUpIcon.svg";
import { ReactComponent as ArrowDownDark } from "../../../../../../assets/images/dark/arrowDownIcon.svg";
import { ReactComponent as DndIconLight } from "../../../../../../assets/images/light/dndIcon.svg";
import { ReactComponent as DndIconDark } from "../../../../../../assets/images/dark/dndIcon.svg";
import { useTheme } from "../../../../../../contexts/theme";
import { DeleteItemModal, EditThemeModal, EditTopicModal, ModalType } from "./components";
import { THEME_TYPES } from "../../../../../../constants";
import { DeleteButton, EditButton } from "../../../../../../components/Buttons";


interface FaqItemProps {
	faq: FaqArticle;
	onFeedbackRequested: (statusFeedback) => void;
	onRefreshRequired: () => void;
}

const FaqItem = (props: FaqItemProps) => {

	const emptyFaqTopic = {
		topic: "",
		content: "",
		tags: [],
		devices: [],
		topicId: 0,
	} as FaqTopic;

	const { currentTheme } = useTheme();
	const [isDragging, setIsDragging] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);

	const [editThemeModalVisible, setEditThemeModalVisible] = useState(false);
	const editingFaqTopic = useRef(emptyFaqTopic);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [deleteModalType, setDeleteModalType] = useState<ModalType>(ModalType.THEME);
	const deleteId = useRef(-1);

	const [editTopicModalVisible, setEditTopicModalVisible] = useState(false);


	const getArrowIcon = () => {

		if (isExpanded) {
			return currentTheme.type == THEME_TYPES.DARK ? <ArrowUpDark onClick={() => setIsExpanded(!isExpanded)} /> : <ArrowUpLight onClick={() => setIsExpanded(!isExpanded)} />;
		}

		if (!isExpanded && props.faq.topics.length === 0) {
			return currentTheme.type == THEME_TYPES.DARK ? <ArrowDownDark style={{ opacity: 0.3 }} /> : <ArrowDownLight style={{ opacity: 0.3 }} />;
		}

		return currentTheme.type == THEME_TYPES.DARK ? <ArrowDownDark onClick={() => setIsExpanded(!isExpanded)} /> : <ArrowDownLight onClick={() => setIsExpanded(!isExpanded)} />;
	};

	const openEditFaqTopicModal = (topic: FaqTopic) => {
		editingFaqTopic.current = topic;
		setEditTopicModalVisible(true);
	};

	return (
		<>
			{editThemeModalVisible && <EditThemeModal
				onClose={(feedback) => {
					setEditThemeModalVisible(false);
					props.onFeedbackRequested(feedback);
					props.onRefreshRequired();
				}}
				faq={props.faq}
			/>}
			<EditTopicModal
				faqTopic={editingFaqTopic.current}
				isOpen={editTopicModalVisible}
				onClose={(feedback) => {
					setEditTopicModalVisible(false);
					props.onFeedbackRequested(feedback);
					props.onRefreshRequired();
				}}
				faqArticle={props.faq}
			/>
			<DeleteItemModal
				type={deleteModalType}
				isOpen={deleteModalVisible}
				onClose={(feedback) => {
					deleteId.current = -1;
					setDeleteModalVisible(false);
					props.onFeedbackRequested(feedback);
					props.onRefreshRequired();
				}}
				id={deleteId.current}
			/>
			<StackContainer theme={currentTheme} isDragging={isDragging}>
				<ItemContainer
					onMouseDownCapture={() => setIsDragging(true)}
					onMouseOverCapture={() => setIsDragging(false)}>
					<ImgContainer>
						{currentTheme.type == THEME_TYPES.DARK ? <DndIconDark /> : <DndIconLight />}
					</ImgContainer>
					<TextContainer theme={currentTheme}>
						<p>{props.faq.theme}</p>
					</TextContainer>

					{!isExpanded ?
						<>
							<ImgContainer>
								<EditButton onClick={() => setEditThemeModalVisible(true)} />
							</ImgContainer>
							<DeleteButton onClick={() => {
								deleteId.current = props.faq.id;
								setDeleteModalVisible(true);
								setDeleteModalType(ModalType.THEME);
							}} />
						</>
						: null
					}

					<ImgContainer>
						{getArrowIcon()}
					</ImgContainer>

				</ItemContainer>
				{isExpanded &&
					<ExpandedContainer>
						{props.faq.topics.map((item, index) => (
							<div key={index}>
								<TopicContainer theme={currentTheme}>
									<p>{item.topic}</p>
									<div>
										<EditButton onClick={() => openEditFaqTopicModal(item)} />
										<ImgContainer>
											<DeleteButton onClick={() => {
												deleteId.current = item.topicId;
												setDeleteModalVisible(true);
												setDeleteModalType(ModalType.TOPIC);
											}} />
										</ImgContainer>
									</div>
								</TopicContainer>
							</div>
						))}
					</ExpandedContainer>
				}
			</StackContainer>
		</>
	);
};

export default FaqItem;