import { emailIsValid, isNumber, versionIsValid, checkVersionAndSetSateError } from "./validators";
import { createMd5Hash } from "./md5";
import { getAvailableTags, getNextValidIndex, getAvailableThemes } from "./faq";
import { translateOperator, getOperatorByTranslation } from "./firmware";
import { getNextValidIndexWatchFace, getNextValidIndexWfSection } from "./watchFace";
import { formatFileName } from "./nameFormatter";
import { hex2rgba } from "./hex";

const Validators = {
	emailIsValid,
	versionIsValid,
	checkVersionAndSetSateError,
	isNumber
};

const MD5 = {
	createMd5Hash
};

const Faq = {
	getAvailableTags,
	getNextValidIndex,
	getAvailableThemes
};

const Firmware = {
	translateOperator,
	getOperatorByTranslation,
};

const WatchFace = {
	getNextValidIndexWatchFace,
	getNextValidIndexWfSection
};

const NameFormatter = {
	formatFileName
};

const Hex = {
	hex2rgba
};

export const Helpers = {
	Validators,
	MD5,
	Faq,
	Firmware,
	WatchFace,
	NameFormatter,
	Hex
};