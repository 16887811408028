import styled from "styled-components";
import { Theme } from "../../../../../../types/Theme";

interface ModalProps {
    isOpen: boolean,
    theme: Theme,
}

export const ModalContainer = styled.div<ModalProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    opacity: ${props => props.isOpen ? 1 : 0};
    visibility: ${props => props.isOpen? "visible" : "hidden"};
    transition: visibility 0s, opacity 0.3s linear;

`;

interface ContentProps {
    theme: Theme,
}

export const ModalContent = styled.div<ContentProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 340px;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;

    h4 {
        font-size: 28px;
        padding: 10px;
        text-align: center;
        font-weight: 500;
        line-height: 30px;
        color: ${props => props.theme.secondaryColor};
    }

    p {
        text-align: center;
        font-size: 16px;
        color: ${props => props.theme.secondaryColor};
        font-family: "Normal";

        strong {
            font-family: "Bold";
        }
    }

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-direction: row;

        button {
            font-size: 14px;
            border-radius: 10px;
            padding-left: 15px;
            padding-right: 15px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
`;

