import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { HeaderButtonsContainer, BackButtonContainer, TotalButton, DateSelectorContainer, CardContainer, Container, HeaderCounterContainer, ShowResultsContainer, WatchFaceCard } from "./styles";
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip } from "victory";
import { useWatchFace } from "../../../../../../contexts/watchFaces";
import { useWindowDimensions } from "../../../../../../hooks";
import { MainButton } from "../../../../../../components/Buttons";
import Dropdown from "../../../../../../components/Dropdown/MainDropdown";
import { DateRangeInput } from "../../../../../../components/Inputs";
import { WatchFace, WatchFaceSection } from "../../../../../../types/Watchface";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { useDevice } from "../../../../../../contexts/device";
import { FIXED_SECTION_NAME } from "../../../../../../constants";
import { COLORS } from "../../../../../../assets";

interface DataAnalyticsProps {
	onBackRequested: () => void;
}

interface IGraphData {
	x: string;
	y: number;
}

const DataAnalytics = (props: DataAnalyticsProps) => {
	const { currentTheme } = useTheme();
	const { selectedApp } = useMobileApp();
	const { currentDevice } = useDevice();
	const { width, height } = useWindowDimensions();
	const { getWatchFaceListBySection } = useWatchFace();

	const [graphData, setGraphData] = useState<IGraphData[]>([]);
	const [watchFaceSectionList, setWatchFaceSectionList] = useState<WatchFaceSection[] | null>(null);
	const [selectedSection, setSelectedSection] = useState<string | null>(null);
	const [sectionsDropdown, setSectionsDropdown] = useState<string[]>([]);
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);

	const dateRangeSelected = startDate !== null;

	useEffect(() => {
		resetStates();
		getApiData();
	}, [startDate]);

	const resetStates = () => {
		setGraphData([]),
		setWatchFaceSectionList(null),
		setSelectedSection(null),
		setSectionsDropdown([]);
	};

	const clearDateRangeInput = () => {
		setStartDate(null);
		setEndDate(null);
	};

	const filterFixedSection = (watchFaceList: WatchFaceSection[]) => {
		return watchFaceList.filter((section) => section.sectionName != FIXED_SECTION_NAME) ?? [];
	};

	const sortByDownloadCountInPlace = (watchFaceList: WatchFaceSection[]) => {
		watchFaceList.forEach(item => {
			item.watchFaces.sort((watchFace1, watchFace2) => (watchFace2.downloadsCount ?? 0) - (watchFace1.downloadsCount ?? 0));
			if (dateRangeSelected)
				item.watchFaces.sort((watchFace1, watchFace2) => (watchFace2.downloadCountWithinRange ?? 0) - (watchFace1.downloadCountWithinRange ?? 0));
		});
	};

	const getGraphData = (watchFaceList: WatchFaceSection[]) => {
		return watchFaceList.map((section) => {
			const sectionName = section.sectionName ?? "";

			let downloads = 0;
			if (dateRangeSelected)
				downloads = section.watchFaces.reduce((acc, wf) => acc + (wf.downloadCountWithinRange ?? 0), 0);
			else
				downloads = section.watchFaces.reduce((acc, wf) => acc + (wf.downloadsCount ?? 0), 0);

			return { x: sectionName, y: downloads };
		})
			.sort((chartItem1, chartItem2) => chartItem2.y - chartItem1.y);
	};

	const getApiData = () => {
		getWatchFaceListBySection(selectedApp.appCode, currentDevice?.slug ?? "", startDate?.toISOString(), endDate?.toISOString())
			.then((wfList) => {
				if (wfList === null || wfList.length === 0) return;

				const filteredList = filterFixedSection(wfList);
				if (filteredList.length === 0) return;

				sortByDownloadCountInPlace(filteredList);
				setWatchFaceSectionList(filteredList);

				const sections = filteredList.map((section) => section.sectionName ?? "") ?? [];
				sections.unshift("Todos");
				setSectionsDropdown(sections);
				setSelectedSection(sections[0]);

				const newGraphData = getGraphData(filteredList);
				setGraphData(newGraphData);
			})
			.catch((errorMessage) => {
				console.log("Erro Data:", errorMessage);
			});
	};

	const getTicketFormatted = (value: number) => {
		if (value > 999) {
			return (value / 1000).toFixed(0) + "k";
		}
		return value;
	};

	const onChangeDateRangeInput = (newDateRange) => {
		if(newDateRange === null)
			clearDateRangeInput();
		else {
			setStartDate(newDateRange[0]);
			setEndDate(newDateRange[1]);
		}
	};

	const getResults = () => {
		if (selectedSection === "Todos") {
			return watchFaceSectionList?.reduce((accumulator: WatchFace[], section) => {
				return accumulator.concat(section.watchFaces);
			}, [])
				.sort((watchFace1, watchFace2) => (watchFace2.downloadsCount ?? 0) - (watchFace1.downloadsCount ?? 0))
				.map(watchFace => (
					<WatchFaceCard key={watchFace.id}>
						<img src={watchFace.preview ?? ""} alt={"WatchFace Preview"} />
						<p>{MESSAGES.PT_BR.WATCH_FACE.DATA_ANALYTICS_MESSAGES.SECTION + ": " + watchFace.sectionName}</p>
						<h6>{MESSAGES.PT_BR.WATCH_FACE.DATA_ANALYTICS_MESSAGES.DOWNLOADS + ": " + watchFace.downloadsCount}</h6>
						{dateRangeSelected && 
							<h6>{MESSAGES.PT_BR.WATCH_FACE.DATA_ANALYTICS_MESSAGES.DOWNLOADS_WITHIN_RANGE + ": " + watchFace.downloadCountWithinRange}</h6>
						}
					</WatchFaceCard>)
				);
		}

		return (
			<>
				{
					watchFaceSectionList?.find((section) => section.sectionName === selectedSection)?.watchFaces.map((watchFace) => {
						return (
							<WatchFaceCard key={watchFace.id}>
								<img src={watchFace.preview ?? ""} alt={"WatchFace Preview"} />
								<p>{MESSAGES.PT_BR.WATCH_FACE.DATA_ANALYTICS_MESSAGES.SECTION + ": " + selectedSection}</p>
								<h6>{MESSAGES.PT_BR.WATCH_FACE.DATA_ANALYTICS_MESSAGES.DOWNLOADS + ": " + watchFace.downloadsCount}</h6>
								{dateRangeSelected && 
									<h6>{MESSAGES.PT_BR.WATCH_FACE.DATA_ANALYTICS_MESSAGES.DOWNLOADS_WITHIN_RANGE + ": " + watchFace.downloadCountWithinRange}</h6>
								}
							</WatchFaceCard>
						);
					})
				}
			</>
		);
	};

	return (
		<Container>
			<HeaderButtonsContainer>
				<BackButtonContainer onClick={() => props.onBackRequested()}>
					<IoIosArrowBack color={currentTheme.primaryColor} size={30} />
					<h4>{MESSAGES.PT_BR.WATCH_FACE.DATA_ANALYTICS_MESSAGES.BACK_BTN}</h4>
				</BackButtonContainer>

				<DateSelectorContainer>
					<TotalButton>
						<MainButton
							enabled
							border={dateRangeSelected}
							text={MESSAGES.PT_BR.WATCH_FACE.DATA_ANALYTICS_MESSAGES.TOTAL}
							onClick={clearDateRangeInput}
							lightColor={dateRangeSelected ? "transparent" : currentTheme.primaryColor}
							darkColor={dateRangeSelected ? "transparent" : currentTheme.primaryColor}
						/>
					</TotalButton>
					<DateRangeInput
						startDate={startDate}
						endDate={endDate}
						onChange={onChangeDateRangeInput}
					/>
				</DateSelectorContainer>
			</HeaderButtonsContainer>
			<CardContainer>
				<h2>{MESSAGES.PT_BR.WATCH_FACE.DATA_ANALYTICS_MESSAGES.SECTIONS}</h2>
				<div style={{ marginTop: "-50px", paddingBottom: "30px", minWidth: "500px" }}>
					<VictoryChart
						domainPadding={{ x: 10 }}
						// width={width * 0.3}
						animate={{ duration: 100 }}
						height={height * 0.22}>
						<VictoryAxis

							style={{
								axis: { stroke: COLORS.DEFAULT_COLORS.INPUT, strokeWidth: 2 },
								axisLabel: { fontSize: 16, fill: COLORS.DEFAULT_COLORS.INPUT, padding: 0 },
								ticks: { stroke: COLORS.DEFAULT_COLORS.INPUT },
								tickLabels: { fontSize: 8, fill: currentTheme.textColor, fontWeight: 100 },
								grid: { stroke: COLORS.DEFAULT_COLORS.INPUT, strokeWidth: 2 }

							}} dependentAxis
							tickFormat={(x) => getTicketFormatted(x)}
						/>
						<VictoryAxis
							style={{
								axis: { stroke: COLORS.DEFAULT_COLORS.INPUT, strokeWidth: 2 },
								axisLabel: { fontSize: 16, padding: 0 },
								ticks: { stroke: "#ccc" },
								tickLabels: { fontSize: 8, fill: currentTheme.textColor, fontWeight: 100, angle: -45, textAnchor: "end" },
							}}
						/>
						<VictoryBar
							cornerRadius={{ top: 3, bottom: 3 }}
							style={{
								data: { fill: currentTheme.primaryColor }
							}}
							alignment="start"
							data={graphData}
							labels={({ datum }) => `${datum.x}\n${datum.y}`}
							labelComponent={
								<VictoryTooltip
									constrainToVisibleArea={true}
									style={{ fontSize: 7, fill: currentTheme.textColor }}
									flyoutStyle={{ fill: currentTheme.backgroundCardSecondary, strokeWidth: 0 }}
								/>
							}
						/>
					</VictoryChart>
				</div>
			</CardContainer>

			<CardContainer>
				<HeaderCounterContainer>
					<h2>{MESSAGES.PT_BR.WATCH_FACE.DATA_ANALYTICS_MESSAGES.WATCH_FACES}</h2>
					<Dropdown
						items={sectionsDropdown}
						onChange={(newSection) => setSelectedSection(newSection)}
						selected={selectedSection ?? ""}
						title={MESSAGES.PT_BR.WATCH_FACE.DATA_ANALYTICS_MESSAGES.SECTION}
						style={{ width: "30%", margin: 0 }}
					/>
				</HeaderCounterContainer>
				<ShowResultsContainer screenWidth={width}>
					{getResults()}
				</ShowResultsContainer>

			</CardContainer>

		</Container>
	);
};

export default DataAnalytics;