import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useTheme } from "../../../../../../contexts/theme";
import { MobileApp } from "../../../../../../types/MobileApp";
import technosLogo from "../../../../../../assets/images/technosLogo.svg";
import { Container, InfosContainer } from "./styles";

interface Props {
    onClick: (selectedApp: MobileApp) => void;
    item: MobileApp;
    index: number;
}

const DropdownItem = (props: Props) => {

	const {currentTheme} = useTheme();

	return (
		<Container
			theme={currentTheme}
			onClick={() => {
				props.onClick(props.item);
			}}>
			<div>
				<InfosContainer>
					<img src={props.item.image || technosLogo} />
					<p>{props.item.name}</p>
				</InfosContainer>
				<IoIosArrowForward color={currentTheme.borderColor} fontSize="1.5em" />
			</div>
		</Container>
	);
};

export default DropdownItem;
