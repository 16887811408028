import React from "react";
import { ThemeProvider } from "./theme";
import { AdminUserProvider } from "./adminUser";
import { MobileUserProvider } from "./mobileUser";
import { MobileAppProvider } from "./mobileApp";
import { FaqProvider } from "./faq";
import { DeviceProvider } from "./device";
import { NotificationProvider } from "./notification";
import { WatchFaceProvider } from "./watchFaces";
import { SupportProvider } from "./support";
import { TermsProvider } from "./terms";
import { FirmwareProvider } from "./firmware";
import { TutorialProvider } from "./tutorials";
import { ApiKeysProvider } from "./apiKeys";
import { TrainingProvider } from "./training";
import { CustomExerciseProvider } from "./customExercise";
import { SleepScoreProvider } from "./sleepScore";
import { ShareExerciseProvider } from "./shareExercise";
import { GpsProvider } from "./gps";

interface AppProviderProps {
	children: React.ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
	return (
		<SleepScoreProvider>
			<CustomExerciseProvider>
				<TrainingProvider>
					<ApiKeysProvider>
						<TutorialProvider>
							<TermsProvider>
								<SupportProvider>
									<WatchFaceProvider>
										<NotificationProvider>
											<DeviceProvider>
												<FaqProvider>
													<MobileUserProvider>
														<AdminUserProvider>
															<ThemeProvider>
																<MobileAppProvider>
																	<FirmwareProvider>
																		<ShareExerciseProvider>
																			<GpsProvider>
																				{children}
																			</GpsProvider>
																		</ShareExerciseProvider>
																	</FirmwareProvider>
																</MobileAppProvider>
															</ThemeProvider>
														</AdminUserProvider>
													</MobileUserProvider>
												</FaqProvider>
											</DeviceProvider>
										</NotificationProvider>
									</WatchFaceProvider>
								</SupportProvider>
							</TermsProvider>
						</TutorialProvider>
					</ApiKeysProvider>
				</TrainingProvider>
			</CustomExerciseProvider>
		</SleepScoreProvider>
	);
};

export default AppProvider;
