import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { MESSAGES } from "../../../../../../constants/messages";
import { Firmware, Firmware3in1, FirmwareSequential, Operator } from "../../../../../../types/Firmware";
import { MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader, RowContainer } from "./styles";
import DeviceVersionDropdown from "../DeviceVersionDropdown";
import { versionIsValid, checkVersionAndSetSateError } from "../../../../../../helpers/validators";

interface Props {
	isOpen: boolean;
	onCancel: () => void;
	onEditRequested: (firmware: Firmware | Firmware3in1) => void;
	firmwareList: (Firmware|Firmware3in1|FirmwareSequential)[] | null;
	selectedFirmwareToEdit: Firmware|Firmware3in1;
}

const EditFirmwareModal = (props: Props) => {	
	const [versionError, setVersionError] = useState<string>("");
	const [deviceVersionError, setDeviceVersionError] = useState<string>("");
	const [deviceVersionEndError, setDeviceVersionEndError] = useState<string>("");
	const [androidVersionError, setAndroidVersionError] = useState<string>("");
	const [iosVersionError, setIosVersionError] = useState<string>("");
	const [descriptionError, setDescriptionError] = useState<string>("");
	const [currentFirmware, setCurrentFirmware] = useState(props.selectedFirmwareToEdit);

	useEffect(() => {
		setVersionError("");
		setDescriptionError("");
		setCurrentFirmware(props.selectedFirmwareToEdit);
	}, [props.isOpen]);


	const checkEditFirmwareVersionAndDescription = (newFirmware: Firmware | Firmware3in1) => {
		let allRight = true;
		setVersionError("");

		if(!newFirmware.version || newFirmware.version === "") {
			setVersionError(MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD);
			allRight = false;
		} else if (!versionIsValid(newFirmware.version)) {
			setVersionError(MESSAGES.PT_BR.FIRMWARE.INVALID_VERSION);
			allRight = false;
		} else if(props.firmwareList) {
			for(const firmware of props.firmwareList) {
				if (firmware.id === newFirmware.id)
					continue;
				else if (firmware.version === newFirmware.version && firmware.deviceId == newFirmware.deviceId) {
					setVersionError(MESSAGES.PT_BR.FIRMWARE.VERSION_ALREADY_EXISTS);
					allRight = false;
					break;
				}
			}
		}

		const versionChecks = [
			checkVersionAndSetSateError(setAndroidVersionError, newFirmware.androidVersion),
			checkVersionAndSetSateError(setIosVersionError, newFirmware.iosVersion),
			checkVersionAndSetSateError(setDeviceVersionError, newFirmware.deviceVersion),
			checkVersionAndSetSateError(setDeviceVersionEndError, newFirmware.deviceVersionEnd),
		];
		allRight = versionChecks.every((check) => check === true);
		
		if (newFirmware.deviceVersionOperator) {
			if (!newFirmware.deviceVersion) {
				currentFirmware.deviceVersionOperator = undefined;
				currentFirmware.deviceVersion = undefined;
				currentFirmware.deviceVersionEnd = undefined;
			} else if (newFirmware.deviceVersionOperator === Operator.InBetween && !newFirmware.deviceVersionEnd) {
				setDeviceVersionEndError(MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD);
				allRight = false;
			}
		}
			
		if(!newFirmware.description || newFirmware.description === "") {	
			setDescriptionError(MESSAGES.PT_BR.FIRMWARE.MANDATORY_FIELD);
			allRight = false;
		} else {
			setDescriptionError("");
		}
		return allRight;
	};

	const handleEditFirmware = () => {
		const allRight = checkEditFirmwareVersionAndDescription(currentFirmware);
		if(allRight){
			props.onEditRequested(currentFirmware);
		}
	};

	return (
		<ModalContainer isOpen={props.isOpen}>
			<ModalContent>
				<ModalHeader>
					<h1>{MESSAGES.PT_BR.FIRMWARE.EDIT_FIRMWARE_TITLE}</h1>
					<div>
						<CloseIcon onClick={props.onCancel} />
					</div>
				</ModalHeader>
				<ModalBody>
					<RowContainer>
						<MainInput
							type="text"
							title={MESSAGES.PT_BR.FIRMWARE.HEADER_VERSION}
							value={currentFirmware.version}
							placeholder={MESSAGES.PT_BR.FIRMWARE.VERSION_PLACEHOLDER}
							onChange={(newValue) => setCurrentFirmware({ ...currentFirmware, version: newValue })}
							errorText={versionError}
						/>
						<h5></h5>
						<MainInput
							type="text"
							title={MESSAGES.PT_BR.FIRMWARE.HEADER_DEVICE_ID}
							value={currentFirmware.deviceId ?? ""}
							placeholder={MESSAGES.PT_BR.FIRMWARE.DEVICE_ID_PLACEHOLDER}
							onChange={(newValue) => setCurrentFirmware({ ...currentFirmware, deviceId: newValue })}
						/>
					</RowContainer>
					<DeviceVersionDropdown
						firmware={currentFirmware}
						setFirmware={setCurrentFirmware}
						deviceVersionError={deviceVersionError}
						deviceVersionEndError={deviceVersionEndError}
					/>
					<RowContainer>
						<MainInput
							type="text"
							title={MESSAGES.PT_BR.FIRMWARE.HEADER_ANDROID_VERSION}
							value={currentFirmware.androidVersion ?? ""}
							placeholder={MESSAGES.PT_BR.FIRMWARE.ANDROID_VERSION_PLACEHOLDER}
							onChange={(newValue) => setCurrentFirmware({ ...currentFirmware, androidVersion: newValue })}
							errorText={androidVersionError}
						/>
						<h5></h5>
						<MainInput
							type="text"
							title={MESSAGES.PT_BR.FIRMWARE.HEADER_IOS_VERSION}
							value={currentFirmware.iosVersion ?? ""}
							placeholder={MESSAGES.PT_BR.FIRMWARE.IOS_VERSION_PLACEHOLDER}
							onChange={(newValue) => setCurrentFirmware({ ...currentFirmware, iosVersion: newValue })}
							errorText={iosVersionError}
						/>
					</RowContainer>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.FIRMWARE.DESCRIPTION}
						value={currentFirmware.description}
						placeholder={MESSAGES.PT_BR.FIRMWARE.DESCRIPTION_PLACEHOLDER}
						onChange={(newValue) => setCurrentFirmware({ ...currentFirmware, description: newValue })}
						errorText={descriptionError}
					/>
					<ModalFooter>
						<MainButton
							enabled
							onClick={() => handleEditFirmware()}
							text={MESSAGES.PT_BR.FIRMWARE.SAVE_BTN}
						/>
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default EditFirmwareModal;
