import React from "react";
import { COLORS } from "../../../../../../../../assets";
import { MainButton } from "../../../../../../../../components/Buttons";
import { MESSAGES } from "../../../../../../../../constants/messages";
import { useTheme } from "../../../../../../../../contexts/theme";
import { GENRE, MobileUser } from "../../../../../../../../types/MobileUser";
import { TableItemStyled } from "./styles";
import { useAdminUser } from "../../../../../../../../contexts/adminUser";
import { canManage } from "../../../../../../../../services/auth/PermissionGate";
import { FEATURES, SCREEN_SIZE } from "../../../../../../../../constants";
import { useWindowDimensions } from "../../../../../../../../hooks";

interface TableItemProps {
    user: MobileUser;
	onDeleteRequest: (mobileUser: MobileUser) => void;
	onResetPasswordRequest: (mobileUser: MobileUser) => void;
	onSendEmailConfirmationRequest: (mobileUser: MobileUser) => void;
}

const TableItem = (props: TableItemProps) => {

	const { currentTheme } = useTheme();
	const { currentUser } = useAdminUser();
	const {width} = useWindowDimensions();

	const getGenre = (genre: GENRE | undefined) => {
		switch (genre) {
		case GENRE.MALE:
			return MESSAGES.PT_BR.USER_MESSAGES.MALE_GENRE;
		case GENRE.FEMALE:
			return MESSAGES.PT_BR.USER_MESSAGES.FEMALE_GENRE;
		case GENRE.OTHER:
			return MESSAGES.PT_BR.USER_MESSAGES.OTHER_GENRE;
		default:
			return MESSAGES.PT_BR.USER_MESSAGES.NOT_INFORMED;
		}
	};

	return (
		<TableItemStyled theme={currentTheme}>
			<td>{props.user.name}</td>
			{width > SCREEN_SIZE.DESKTOP && <td>{props.user.email}</td>}
			{width > SCREEN_SIZE.DESKTOP && <td>{getGenre(props.user?.genre)}</td>}
			<td>
				<div>
					<MainButton enabled 
						onClick={() => {props.onSendEmailConfirmationRequest(props.user);}} 
						text={MESSAGES.PT_BR.USER_MESSAGES.CONFIRM_BUTTON} 
						darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
						lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
					/>
					<MainButton enabled 
						onClick={() => {props.onResetPasswordRequest(props.user);}}  
						text={MESSAGES.PT_BR.USER_MESSAGES.RESET_PASSWORD_BUTTON} 
						darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
						lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
					/>
					<MainButton 
						enabled={canManage(currentUser.role, FEATURES.DELETE_APP_USER)}
						onClick={() => {canManage(currentUser.role, FEATURES.DELETE_APP_USER) && props.onDeleteRequest(props.user);}} 
						text={MESSAGES.PT_BR.USER_MESSAGES.DELETE_BUTTON} 
						darkColor={currentTheme.primaryColor}
						lightColor={currentTheme.primaryColor}
					/>
				</div>
			</td>
		</TableItemStyled>
	);
};

export default TableItem;