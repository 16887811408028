import styled from "styled-components";
import { Theme } from "../../../types/Theme";

interface ButtonStyledProps {
	theme?: Theme;
	enabled?: boolean;
}

export const ButtonStyled = styled.button<ButtonStyledProps>`
	width: 100%;
	height: 50px;
	border: none;
	border-radius: 16px;
	background-color: ${props => props.theme.backgroundCardSecondary};
	font-size: 20px;
	color: ${props => props.theme.textColor};
	opacity: ${props => props.enabled ? 1 : 0.5};
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}

	div {
		height: 25px;
		width: 25px;
		margin-right: 10px;
	}
`;