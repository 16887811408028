const MAIN_MESSAGES = {
	TITLE: "Vídeos",
	NEW_BTN: "Novo vídeo",
	EMPTY: "Nenhum vídeo tutorial\ncadastrado",
	THUMB: "Thumb",
	VIDEO_TITLE: "Título do vídeo",
	ACTIONS: "Ações"
};

const MODAL = {
	ADD_TITLE: "Adicionar vídeo",
	TITLE: "Título",
	TITLE_PLACEHOLDER: "Título do vídeo",
	LINK: "Link",
	LINK_PLACEHOLDER: "Link do vídeo no youtube",
	KEEP_ADDING: "Continuar adicionando",
	ADD_BTN: "Adicionar",
	SAVE_BTN: "Salvar",
	SUCCESS_FEEDBACK: "Vídeo adicionado com sucesso",
	SUCCESS_DESCRIPTION: "Um novo vídeo tutorial foi adicionado com sucesso",
	DELETE_TITLE: "Tem certeza que deseja excluir este vídeo?",
	DELETE_DESCRIPTION: "Caso exclua o vídeo tutorial, ele será retirado automaticamente do aplicativo do usuário.",
	DELETE_BTN: "Tenho certeza, excluir.",
	CANCEL_BTN: "Não tenho certeza, cancelar.",
	
	DELETED_SUCCESS_FEEDBACK: "Vídeo excluído com sucesso",
	DELETED_SUCCESS_DESCRIPTION: "O vídeo tutorial foi excluído com sucesso",

	TITLE_ERROR: "O título do vídeo é obrigatório",
	LINK_ERROR: "O link do vídeo é obrigatório",

	EDIT_TITLE: "Editar vídeo",
};

export const TUTORIALS = {
	MAIN_MESSAGES,
	MODAL
};