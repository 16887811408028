import React, {useState} from "react";
import { ModalBody, ModalContainer, ModalContent, ModalHeader } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../../assets/images/closeIcon.svg";
import { useTheme } from "../../../../../../../contexts/theme";
import { MESSAGES } from "../../../../../../../constants/messages";
import { MainInput } from "../../../../../../../components/Inputs";
import { MainButton } from "../../../../../../../components/Buttons";

interface ModalProps {
	isOpen: boolean;
	onCancel: () => void;
	handleCreateSection: (title: string) => void;
}

const AddSectionModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();
	const [sectionTitle, setSectionTitle] = useState<string>("");
	const [sectionTitleError, setSectionTitleError] = useState<string>("");

	const handleSaveSectionTitle = () => {
		if (sectionTitle.length > 0) {
			setSectionTitleError("");
			props.handleCreateSection(sectionTitle);
			setSectionTitle("");
		} else {
			setSectionTitleError(MESSAGES.PT_BR.SUPPORT_MESSAGES.SUBJECT_ERROR);
		}
	};

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.NEW_SECTION_BTN}</h1>
					<div>
						<CloseIcon onClick={props.onCancel} />
					</div>
				</ModalHeader>
				<ModalBody theme={currentTheme}>
					<MainInput
						type="text"
						placeholder={MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.INPUT_PLACEHOLDER}
						value={sectionTitle}
						errorText={sectionTitleError}
						onChange={(value) => { setSectionTitle(value); }}
					/>
					<MainButton enabled onClick={handleSaveSectionTitle} text={MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.CREATE_BTN} />
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);

};

export default AddSectionModal;