import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorContainer } from "./styles";

interface EditorHtmlInputProps {
    placeholder: string;
    editorState: unknown; //TODO: Replace With DraftJs EditorState Type
    setEditorState: unknown; //TODO: Replace With DraftJs EditorState Type
}

const EditorHtmlInput = (props: EditorHtmlInputProps) => {

	const [flag, setFlag] = useState<boolean>(false); // force re-rendering

	useEffect(() => {
		setFlag(!flag);
	}, [props.placeholder]);

	//Adicionei o removeClass aos itens que não quero exibir. Foi a forma mais rápida que achei de ocultar isso :)
	return (
		<EditorContainer>
			<Editor
				editorState={props.editorState}
				onEditorStateChange={props.setEditorState}
				toolbarClassName="toolbarStyle"
				wrapperClassName="wrapperStyle"
				editorClassName="editorStyle"
				placeholder={props.placeholder}
				toolbar={{
					inline: {
						bold: { className: "iconStyle" },
						italic: { className: "iconStyle" },
						underline: { className: "iconStyle" },
						strikethrough: { className: "iconStyle" },
						monospace: { className: "removeClass" },
						superscript: { className: "removeClass" },
						subscript: { className: "removeClass" },
					},
					blockType: { className: "iconStyle-wide", dropdownClassName: "demo-dropdown-custom" },
					fontSize: { className: "iconStyle-medium" },
					list: {
						unordered: { className: "iconStyle" },
						ordered: { className: "iconStyle" },
						indent: { className: "iconStyle" },
						outdent: { className: "iconStyle" },
					},
					textAlign: {
						left: { className: "iconStyle" },
						center: { className: "iconStyle" },
						right: { className: "iconStyle" },
						justify: { className: "iconStyle" },
					},
					fontFamily: { className: "iconStyle-wide", dropdownClassName: "demo-dropdown-custom" },
					colorPicker: { className: "iconStyle", popupClassName: "demo-popup-custom" },
					link: {
						popupClassName: "demo-popup-custom",
						link: { className: "iconStyle" },
						unlink: { className: "removeClass" },
					},
					emoji: { className: "removeClass", popupClassName: "demo-popup-custom" },
					embedded: { className: "removeClass", popupClassName: "demo-popup-custom" },
					image: { className: "removeClass", popupClassName: "demo-popup-custom" },
					remove: { className: "removeClass" },
					history: {
						undo: { className: "iconStyle" },
						redo: { className: "iconStyle" },
					},
				}}
			/>
		</EditorContainer>
	);
};

export default EditorHtmlInput;