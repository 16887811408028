import React, { createContext, useContext } from "react";
import { Api } from "../services";
import { YoutubeService } from "../services/youtube";
import { WorkoutSection, WorkoutVideo } from "../types/Training";

interface ITrainingContextData {
    getWorkouts: ( appCode: number) => Promise<WorkoutSection[]>;
    saveWorkoutSection: (workoutSection: WorkoutSection, iconFile: File, iconSelectedFile: File, appCode: number) => Promise<void>;
    updateWorkoutSection: (workoutSection: WorkoutSection, iconFile: File, iconSelectedFile: File, appCode: number) => Promise<void>;
	deleteWorkoutSection: (workoutSectionId: number, appCode: number) => Promise<void>;
	deleteWorkoutVideo: (workoutVideoId: number, appCode: number) => Promise<void>;
	saveWorkoutVideo: (workoutVideo: WorkoutVideo, thumbnailFile: File, appCode: number) => Promise<void>;
	updateTrainningVideo: (appCode: number, newWorkoutVideo: WorkoutVideo, thumbnailFile: File, isSameVideo: boolean) => Promise<void>;
	updateTrainningVideoVisibility: (appCode: number, newWorkoutVideo: WorkoutVideo) => Promise<void>;
	saveNewOrder: (appCode: number, sectionList: WorkoutSection[]) => Promise<void>,
}

interface TrainingProviderProps {
	children: React.ReactNode;
}

export const TrainingContext = createContext({} as ITrainingContextData);

const TrainingProvider: React.FC<TrainingProviderProps> = ({ children }) => {

	const S3_FOLDER = "workoutImages";

	const getWorkouts = (appCode: number) => {
		return Api.Training.getWorkouts(appCode);
	};

	const saveWorkoutSection = (workoutSection: WorkoutSection, iconFile: File, iconSelectedFile: File, appCode: number) => {
		const iconPromise = Api.AWS.sendOrUpdateFileToS3(appCode, iconFile, S3_FOLDER);
		const iconSelectedPromise = Api.AWS.sendOrUpdateFileToS3(appCode, iconSelectedFile, S3_FOLDER);

		return new Promise<void>((resolve, reject) => {
			Promise.all([iconPromise, iconSelectedPromise]).then((values) => {
				workoutSection.icon = values[0];
				workoutSection.iconSelected = values[1];
				resolve(Api.Training.saveWorkoutSection(workoutSection, appCode));
			}).catch(s3Error => {
				reject(s3Error);
			});
		});
	};

	const updateWorkoutSection = (workoutSection: WorkoutSection, iconFile: File, iconSelectedFile: File, appCode: number) => {
		let iconPromise: Promise<string>;
		let iconSelectedPromise: Promise<string>;

		if (iconFile.size) {
			// uma nova imagem foi escolhida, enviar a nova ou atualizar a anterior
			const oldObjectURL = workoutSection.icon;
			iconPromise = Api.AWS.sendOrUpdateFileToS3(appCode, iconFile, S3_FOLDER, oldObjectURL);
		}
		
		if (iconSelectedFile.size) {
			const oldObjectURL = workoutSection.iconSelected;
			iconSelectedPromise = Api.AWS.sendOrUpdateFileToS3(appCode, iconSelectedFile, S3_FOLDER, oldObjectURL);
		}

		return new Promise<void>((resolve, reject) => {
			Promise.all([iconPromise, iconSelectedPromise]).then((values) => {
				if (values[0]) workoutSection.icon = values[0];
				if (values[1]) workoutSection.iconSelected = values[1];
				resolve(Api.Training.updateWorkoutSection(workoutSection, appCode));
			}).catch(s3Error => {
				reject(s3Error);
			});
		});
	};

	const deleteWorkoutSection = (workoutSectionId: number, appCode: number) => {
		return Api.Training.deleteWorkoutSection(workoutSectionId, appCode);
	};

	const deleteWorkoutVideo = (workoutVideoId: number, appCode: number) => {
		return Api.Training.deleteWorkoutVideo(workoutVideoId, appCode);
	};

	const saveWorkoutVideo = async (workoutVideo: WorkoutVideo, thumbnailFile: File, appCode: number) => {
		const thumbnailPromise = Api.AWS.sendOrUpdateFileToS3(appCode, thumbnailFile, S3_FOLDER);
		const secondsDurationPromise = YoutubeService.getVideoDuration(workoutVideo.link);

		return new Promise<void>((resolve, reject) => {
			Promise.all([thumbnailPromise, secondsDurationPromise]).then((values) => {
				workoutVideo.thumbnail = values[0];
				workoutVideo.secondsDuration = values[1];
				resolve(Api.Training.saveWorkoutVideo(workoutVideo, appCode));
			}).catch(error => {
				reject(error);
			});
		});
	};

	const updateTrainningVideo = (appCode: number, newWorkoutVideo: WorkoutVideo, thumbnailFile: File, isSameVideo: boolean) => {
		let thumbnailPromise: Promise<string>;
		if (thumbnailFile && thumbnailFile.size) {
			// nova imagem escolhida, enviar a nova ou atualizar a anterior
			const oldObjectURL = newWorkoutVideo.thumbnail;
			thumbnailPromise = Api.AWS.sendOrUpdateFileToS3(appCode, thumbnailFile, S3_FOLDER, oldObjectURL);
		}
		
		let secondsDurationPromise: Promise<number>;
		if (!isSameVideo)
			secondsDurationPromise = YoutubeService.getVideoDuration(newWorkoutVideo.link);

		return new Promise<void>((resolve, reject) => {
			Promise.all([thumbnailPromise, secondsDurationPromise]).then((values) => {
				if (values[0]) newWorkoutVideo.thumbnail = values[0];
				if (values[1]) newWorkoutVideo.secondsDuration = values[1];
				resolve(Api.Training.updateTrainningVideo(appCode, newWorkoutVideo));
			}).catch(s3Error => {
				reject(s3Error);
			});
		});
	};

	const updateTrainningVideoVisibility = (appCode: number, newWorkoutVideo: WorkoutVideo) => {
		return Api.Training.updateTrainningVideo(appCode, newWorkoutVideo);
	};

	const saveNewOrder = async (appCode: number, sectionList: WorkoutSection[]) => {

		const tasks = new Array(sectionList.length).fill(updateWorkoutSection);
		const emptyFile = new File([], "");

		for (let i = 0; i < sectionList.length; i++) {
			try{
				await tasks[i](sectionList[i], emptyFile, emptyFile, appCode);
			}catch(e){
				console.log(e);
				return Promise.reject(e);
			}
		}
		return Promise.resolve();
	};

	
	return (
		<TrainingContext.Provider
			value={{
				getWorkouts,
				saveWorkoutSection,
				updateWorkoutSection,
				deleteWorkoutSection,
				deleteWorkoutVideo,
				saveWorkoutVideo,
				updateTrainningVideo,
				updateTrainningVideoVisibility,
				saveNewOrder
			}}>
			{children}
		</TrainingContext.Provider>
	);
};

const useTraining = () => {
	const context = useContext(TrainingContext);

	return context;
};

export { TrainingProvider, useTraining };
