import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../../../../assets/images/closeIcon.svg";
import { useTheme } from "../../../../../../../contexts/theme";
import { MESSAGES } from "../../../../../../../constants/messages";
import { MainInput } from "../../../../../../../components/Inputs";
import { MainButton } from "../../../../../../../components/Buttons";
import { ModalBody, ModalContainer, ModalContent, ModalHeader } from "./styles";
import { WatchFaceSection } from "../../../../../../../types/Watchface";
import { toast } from "react-hot-toast";
import { useWatchFace } from "../../../../../../../contexts/watchFaces";
import { useMobileApp } from "../../../../../../../contexts/mobileApp";

interface ModalProps {
	watchFaceSection: WatchFaceSection;
	isOpen: boolean;
	onClose: (feedback) => void;
}

const EditSectionModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();
	const { updateWatchFaceSection } = useWatchFace();
	const { selectedApp } = useMobileApp();
	const [currentSection, setCurrentSection] = useState<WatchFaceSection>(props.watchFaceSection);
	const [nameError, setNameError] = useState<string>("");

	useEffect(() => {
		resetStates();
	}, [props.isOpen]);

	const handleEditSectionTitle = () => {

		if (currentSection?.sectionName === "") {
			setNameError(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.MANDATORY_FIELD);
			return;
		}else {
			setNameError("");
		}

		const t = toast.loading(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.LOADING);
		updateWatchFaceSection(selectedApp.appCode ?? 0, currentSection)
			.then(() => {
				props.onClose({ isOpen: true, title: MESSAGES.PT_BR.WATCH_FACE.UPDATE_SECTION_FEEDBACK_MESSAGES.SUCCESS_TITLE, description: MESSAGES.PT_BR.WATCH_FACE.UPDATE_SECTION_FEEDBACK_MESSAGES.SUCCESS_DESCRIPTION, success: true });
			}).catch((error) => {
				props.onClose({ isOpen: true, title: MESSAGES.PT_BR.WATCH_FACE.UPDATE_SECTION_FEEDBACK_MESSAGES.ERROR_TITLE, description: MESSAGES.PT_BR.WATCH_FACE.UPDATE_SECTION_FEEDBACK_MESSAGES.ERROR_DESCRIPTION+ error, success: false });
			}).finally(() => {
				toast.dismiss(t);
			});
		
	};

	const resetStates = () => {
		setCurrentSection(props.watchFaceSection);
		setNameError("");
	};

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.EDIT_SECTION_BTN}</h1>
					<div>
						<CloseIcon onClick={() => props.onClose(null)} />
					</div>
				</ModalHeader>
				<ModalBody theme={currentTheme}>
					<MainInput
						type="text"
						placeholder={MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.INPUT_PLACEHOLDER}
						value={currentSection.sectionName ?? ""}
						errorText={nameError}
						onChange={(value) => { setCurrentSection({ ...currentSection, sectionName: value }); }}
					/>
					<MainButton enabled onClick={handleEditSectionTitle} text={MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.SAVE} />
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);

};

export default EditSectionModal;