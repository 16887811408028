import { FEATURES } from "..";
import { Role } from "../../../types/AdminUser";


/*
RN:
    Owner: Acesso total, podendo editar/excluir qualquer usuário do webAdmin. Sua conta pode ser apagada por outro Owner desde que exista pelo menos um Owner.
    Admin: Acesso total, podendo editar/excluir qualquer usuário do webAdmin, exceto usuário do tipo Owner.
    Developer: Acesso total, não podendo editar/excluir usuários do webAdmin.
    Editor: Acesso total, exceto ao gerenciamento de usuários do webAdmin.
    Designer: Apenas "Versão do aplicativo", "Logotipo", "FAQ", "Mensagens de erro", "Manual" e "Mostradores".
    Post-Officer: Acesso total, exceto ao gerenciamento de usuários do webAdmin.
*/



export const ROLES = {
	CAN_SEE_FEATURES: {
		[Role.OWNER]: Object.values(FEATURES),
		[Role.ADMIN]: Object.values(FEATURES),
		[Role.DEVELOPER]: Object.values(FEATURES),
		[Role.EDITOR]: Object.values(FEATURES).filter(key => key !== FEATURES.ADMIN_USERS),
		[Role.DESIGNER]: [FEATURES.FAQ, FEATURES.MANUAL, FEATURES.WATCH_FACES],
		[Role.POST_OFFICER]: [FEATURES.MOBILE_USERS, FEATURES.PUSH_NOTIFICATIONS],
	},

	CAN_MANAGE_FEATURES: {
		[Role.OWNER]: Object.values(FEATURES),
		[Role.ADMIN]: Object.values(FEATURES),
		[Role.DEVELOPER]: Object.values(FEATURES).filter(key => key !== FEATURES.ADMIN_USERS && key !== FEATURES.ADD_ADMIN_USER),
		[Role.EDITOR]: Object.values(FEATURES).filter(key => key !== FEATURES.ADMIN_USERS),
		[Role.DESIGNER]: [FEATURES.FAQ, FEATURES.MANUAL, FEATURES.WATCH_FACES],
		[Role.POST_OFFICER]: [FEATURES.CONFIRMATION_EMAIL, FEATURES.RECOVERY_PASSWORD, FEATURES.PUSH_NOTIFICATIONS],
	},

	CAN_DELETE_ADMIN_USERS: {
		[Role.OWNER]: Object.values(Role),
		[Role.ADMIN]: Object.values(Role).filter(key => key !== Role.OWNER),
		[Role.DEVELOPER]: [],
		[Role.EDITOR]: [],
		[Role.DESIGNER]: [],
		[Role.POST_OFFICER]: [],
	}
};