import React from "react";
import { useTheme } from "../../../../../../contexts/theme";
import { SearchButton, SearchContainer, SearchInputStyled } from "./styles";
import { ReactComponent as SearchIcon } from "../../../../../../assets/images/searchIcon.svg";


interface Props {
	placeholder: string;
	textValue: string;
	onChangeText: (value: string) => void;
	onSearch: () => void;
}

const SearchInput = (props: Props) => {

	const { currentTheme } = useTheme();

	return (
		<SearchContainer theme={currentTheme}>
			<SearchInputStyled 
				theme={currentTheme}
				placeholder={props.placeholder}
				value={props.textValue}
				onChange={(e) => props.onChangeText(e.target.value)}
			/>		
			<div/>
			<SearchButton theme={currentTheme} onClick={() => props.onSearch()}>
				<SearchIcon fill={currentTheme.primaryColor} />
			</SearchButton>
		</SearchContainer>
	);
};

export default SearchInput;