import React, { useEffect, useState } from "react";
import { useTheme } from "../../../contexts/theme";
import { InputContainer, Select, StackContainer } from "./styles";

type SelectOption = {
	label: string
	value: string
}

interface SuggestionInputProps {
	placeholder: string;
	values?: string[] | string;
	availableValues: string[];
	errorText?: string;
	isMulti?: boolean;
	createItemText: string;
	onAddItemRequested: (value: string[]) => void;
	onCreateOption?: (value: string) => void;
	title?: string;
}

const InputWithSuggestion = (props: SuggestionInputProps) => {
	const { currentTheme } = useTheme();

	const [selected, setSelected] = useState<SelectOption[] | SelectOption | null>(null);
	const [values, setValues] = useState<SelectOption[]>([]);

	useEffect(() => {
		setValues(props.availableValues.map(option => ({
			label: option,
			value: option
		})));

		if (props.values) {
			if (Array.isArray(props.values)) {
				setSelected(props.values.map(option => ({
					value: option,
					label: option,
				})));
			} else {
				setSelected([{
					value: props.values,
					label: props.values
				}]);
			}
			
		}
	}, [props.values, props.availableValues]);

	const handleChangeValue = (values) => {
		if (Array.isArray(values)) {
			props.onAddItemRequested(values.map(v => v.value));
		} else {
			props.onAddItemRequested([values.value]);
		}
	};

	let getValue = {};

	if (selected) {
		getValue = {
			value: selected
		};
	}

	return (
		<StackContainer theme={currentTheme}>
			{props.title && <h1>{props.title}</h1>}
			<InputContainer 
				theme={currentTheme} 
				hasTheme={!!(props.values && props.values?.length > 0)} 
				isError={props.errorText ? true : false}
			>
				<Select 
					className="custom-select"
					classNamePrefix="select"
					{...getValue}
					closeMenuOnSelect={!props.isMulti}
					onChange={handleChangeValue}
					onCreateOption={props.onCreateOption}
					formatCreateLabel={value => `${props.createItemText}: ${value}`}
					isMulti={props.isMulti} 
					placeholder={props.placeholder}
					options={values}
				/>
			</InputContainer>
			{props.errorText && <span>{props.errorText}</span>}
		</StackContainer>
	);
};

export default InputWithSuggestion;