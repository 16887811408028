import React, { createContext, useState, useContext } from "react";
import { COLORS } from "../assets";
import { THEME_TYPES } from "../constants";
import { Theme } from "../types/Theme";
import { LocalStorage } from "../services/storage";
const THEME_KEY = "lastTheme";

interface IThemeContextData {
	currentTheme: Theme;
	changeTheme: () => void;
	setLastTheme: () => void;
}

interface ThemeProviderProps {
	children: React.ReactNode;
}

export const ThemeContext = createContext({} as IThemeContextData);

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {

	const [currentTheme, setCurrentTheme] = useState<Theme>(COLORS.LIGHT);

	const changeTheme = () => {
		if (currentTheme.type === THEME_TYPES.LIGHT) {
			setCurrentTheme(COLORS.DARK);
			LocalStorage.storeLocalData(THEME_KEY, JSON.stringify(COLORS.DARK));
		} else {
			setCurrentTheme(COLORS.LIGHT);
			LocalStorage.storeLocalData(THEME_KEY, JSON.stringify(COLORS.LIGHT));
		}
	};

	const setLastTheme = () => {
		const lastTheme = LocalStorage.getLocalData(THEME_KEY);
		if (lastTheme) {
			setCurrentTheme(JSON.parse(lastTheme));
		}
	};

	return (
		<ThemeContext.Provider
			value={{
				currentTheme,
				changeTheme,
				setLastTheme
			}}>
			{children}
		</ThemeContext.Provider>
	);
};

const useTheme = () => {
	const context = useContext(ThemeContext);

	return context;
};

export { ThemeProvider, useTheme };
