import styled from "styled-components";
import { Theme } from "../../../../types/Theme";

interface ContainerProps {
    theme: Theme
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.backgroundCard};
    padding: 10px;
    border-radius: 24px;
    flex-direction: column;
`;

export const Header = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-top: 16px;

    button {
        margin: 0;
        width: 30%;
        min-width: 250px;
    }

    h1 {
        font-size: 28px;
        font-weight: 100;
        color: ${props => props.theme.textColor};
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
`;

export const EmptyContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        margin-bottom: 20px;
    }

    h2 {
        font-size: 22px;
        width: 70%;
        text-align: center;
        color: ${props => props.theme.textColor};
    }

`;