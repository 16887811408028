import React from "react";
import { useTheme } from "../../../../../contexts/theme";
import { AdminUser } from "../../../../../types/AdminUser";
import { TableHeader, TableItem } from "./components";
import { Line, LineTr, ResultTableContainer } from "./styles";

interface TableProps {
	result: AdminUser[];
	onClickEdit: (user: AdminUser) => void;
	onClickDelete: (user: AdminUser) => void;
}

const ResultTable = (props: TableProps) => {

	const { currentTheme } = useTheme();

	return (
		<ResultTableContainer theme={currentTheme}>
			<table>
				<thead>
					<TableHeader />
				</thead>
				<tbody>
					{
						props.result.map((user, index) => (
							<>
								<TableItem
									key={index}
									user={user}
									onClickEdit={props.onClickEdit}
									onClickDelete={props.onClickDelete}
									adminList={props.result}
								/>
								<LineTr theme={currentTheme}>
									<td colSpan={4}>
										<Line theme={currentTheme}/>
									</td>
								</LineTr>
							</>
						))
					}
				</tbody>
			</table>
		</ResultTableContainer>
	);
};

export default ResultTable;