import React from "react";
import { DarkModeToggle } from "@anatoliygatt/dark-mode-toggle";
import { useTheme } from "../../contexts/theme";

const ColorDotSelection = () => {

	const { currentTheme, changeTheme } = useTheme();

	return (
		<DarkModeToggle
			mode={currentTheme.type}
			size="sm"
			inactiveTrackColor={currentTheme.secondaryColor}
			inactiveTrackColorOnHover="#f8fafc"
			inactiveTrackColorOnActive="#cbd5e1"
			activeTrackColor={currentTheme.primaryColor}
			activeTrackColorOnHover={currentTheme.primaryColor}
			activeTrackColorOnActive={currentTheme.primaryColor}
			inactiveThumbColor={currentTheme.primaryColor}
			activeThumbColor="#e2e8f0"
			onChange={() => {
				changeTheme();
			}}
		/>
	);
};

export default ColorDotSelection;