import CryptoJS from "crypto-js";

export const createMd5Hash = (file: File) => {
	return new Promise<string>(function (resolve, reject) {
		try {
			const reader = new FileReader();

			reader.addEventListener("load", function () {
				const hash = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(this.result as string));
				const md5 = hash.toString(CryptoJS.enc.Hex);
				resolve(md5);
			});
			reader.readAsBinaryString(file);
		}catch (e) {
			reject(e);
		}

	});
};

