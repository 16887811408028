import styled from "styled-components";
import { Theme } from "../../../../../../types/Theme";

interface ContainerProps {
    isOpen: boolean,
    theme: Theme,
    disabled?: boolean
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.backgroundCardSecondary};
    border-radius: 16px;
    width: 95%;
    margin: 5px 0;
    opacity: ${props => props.disabled ? 0.5 : 1};

    p {
        color: ${props => props.theme.textColor};
    }
`;

export const HeaderStyled = styled.div`
    display: flex;
    flex-direction: row;
    height: 55px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;

    p {
        font-size: 18px;
    }
`;