import React from "react";
import { MESSAGES } from "../../../../../../../../constants/messages";
import { TableHeaderStyled } from "./styles";

const TableHeader = () => {

	return (
		<TableHeaderStyled>
			<th>{MESSAGES.PT_BR.FIRMWARE.HEADER_VERSION}</th>
			<th>{MESSAGES.PT_BR.FIRMWARE.HEADER_DEVICE_VERSION}</th>
			<th>{MESSAGES.PT_BR.FIRMWARE.HEADER_MINIMUM_OS_VERSION}</th>
			<th style={{ textAlign:"left" }}>{MESSAGES.PT_BR.FIRMWARE.HEADER_DESCRIPTION}</th>
			<th>{MESSAGES.PT_BR.FIRMWARE.HEADER_FILES}</th>
			<th>{MESSAGES.PT_BR.FIRMWARE.HEADER_DEVICE_ID}</th>
			<th>{MESSAGES.PT_BR.FIRMWARE.HEADER_DATE}</th>
			<th>{MESSAGES.PT_BR.FIRMWARE.HEADER_ACTIONS}</th>
		</TableHeaderStyled>
	);
};

export default TableHeader;