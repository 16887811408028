import styled from "styled-components";
import { Theme } from "../../../../../../types/Theme";

interface ModalProps {
    isOpen: boolean,
    theme: Theme,
}

export const ModalContainer = styled.div<ModalProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    opacity: ${props => props.isOpen ? 1 : 0};
    visibility: ${props => props.isOpen ? "visible" : "hidden"};
    transition: visibility 0s, opacity 0.3s linear;

`;

interface ContentProps {
    theme: Theme,
}

export const ModalContent = styled.div<ContentProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 28%;
    min-width: 350px;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 24px;
    padding: 20px;
    box-sizing: border-box;
`;

export const ModalHeader = styled.div<ContentProps>`
    display: flex;
    align-items: center;
    width: 100%;

    h1 {
        font-size: 28px;
        padding: 10px;
        text-align: center;
        font-weight: 500;
        line-height: 30px;
        width: 100%;
        color: ${props => props.theme.textColor};
    }
`;

export const ModalBody = styled.div<ContentProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    padding: 10px;
    box-sizing: border-box;

    p {
        font-size: 16px;
        font-family: "normal";
        font-weight: 400;
        text-align: center;
        color: ${props => props.theme.textColor};
    }
`;

export const ModalFooter = styled.div<ContentProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;

    button {
            width: 40%;
            border-radius: 8px;
            margin: 0;
            font-size: 14px;
            font-weight: 500;
        }
`;






