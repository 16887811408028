import styled from "styled-components";
import { Theme } from "../../../../../../types/Theme";

interface ContainerProps {
    theme: Theme
}

export const Container = styled.div<ContainerProps>`
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-top: 5px;

        > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 5px;
        border-radius: 8px;

        &:hover {
            cursor: pointer;
            opacity: 0.7;
            background-color: ${props => props.theme.backgroundCardSecondary};
        }
    }

    hr {
        width: 95%;
        opacity: 0.5;
        margin-top: 7px;
        display: block; 
        height: 1px;
        border: 0; 
        border-top: 1px solid ${props => props.theme.textColor};
        padding: 0;
    }

    p {
        color: ${props => props.theme.textColor};
        margin-left: 10px;
        font-family: "Normal";
    }

    img {
        height: 35px;
        width: 35px;
        object-fit: cover;
        border-radius: 5px;
        box-sizing: border-box;
    }
`;

export const InfosContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
