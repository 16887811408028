
import { Role } from "../AdminUser";

const fromApiToAppModel = (apiObjAdminUser) => {
	return {
		id: apiObjAdminUser.id,
		email: apiObjAdminUser.email,
		name: apiObjAdminUser.name,
		photo: apiObjAdminUser.profile_picture,
		role: convertApiRoleToAppRole(apiObjAdminUser.role)
	};
};

const fromModelToApi = (appObj) => {
	return ({
		email: appObj.email,
		name: appObj.name,
		profile_picture: appObj.photo ?? null,
		role: convertAppRoleToApi(appObj.role)
	});
};

const convertAppRoleToApi = (appRole) => {
	switch (appRole) {
	case Role.ADMIN:
		return "admin";
	case Role.OWNER:
		return "owner";
	case Role.DEVELOPER:
		return "developer";
	case Role.EDITOR:
		return "editor";
	case Role.DESIGNER:
		return "designer";
	case Role.POST_OFFICER:
		return "post-officer";
	default:
		return "";
	}
};

const convertApiRoleToAppRole = (apiRole) => {
	switch (apiRole) {
	case "admin":
		return Role.ADMIN;
	case "owner":
		return Role.OWNER;
	case "developer":
		return Role.DEVELOPER;
	case "editor":
		return Role.EDITOR;
	case "designer":
		return Role.DESIGNER;
	case "post-officer":
		return Role.POST_OFFICER;
	default:
		return "";
	}
};

export const AdminUserMapper = {
	fromApiToAppModel,
	fromModelToApi,
	convertAppRoleToApi,
};
