import React, { useEffect, useState, useRef } from "react";
import { Loader, StatusModal } from "../../../../components";
import { MESSAGES } from "../../../../constants/messages";
import { MainContainer, Header, CardContainer } from "./styles";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { EditSleepModal, ResultTable } from "./components";
import { useSleepScore } from "../../../../contexts/sleepScore";
import { Sleep } from "../../../../types/Sleep";
import { toast } from "react-hot-toast";


const SleepScore = () => {

	const { selectedApp } = useMobileApp();
	const { getSleepScoreList, updateSleepScore} = useSleepScore();
	
	const [sleepScoreList, setSleepScoreList] = useState<Sleep[] | null>(null);
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [modalStatus, setModalStatus] = useState({ isOpen: false, title: "", description: "", success: false });
	const selectedSleepRef = useRef<Sleep | null>(null);

	useEffect(() => {
		refreshSleepList();
	}, []);

	const refreshSleepList = () => {
		getSleepScoreList(selectedApp?.appCode).then((list) => {
			if (list) {
				setSleepScoreList(list);
			}
		});
	};

	const handleShowEditModal = (selectedSleep: Sleep) => {
		selectedSleepRef.current = selectedSleep;
		setEditModalVisible(true);
	};

	const handleUpdateSleep = (updatedSleep: Sleep) => {
		setEditModalVisible(false);

		const t = toast.loading(MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.UPDATING);
		updateSleepScore(updatedSleep, selectedApp?.appCode,)
			.then(() => {
				refreshSleepList();
				setModalStatus({
					isOpen: true,
					title: MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.FEEDBACK_SUCCESS,
					description: MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.FEEDBACK_SUCCESS_DESCRIPTION,
					success: true,
				});
			})
			.catch(() => {
				setModalStatus({
					isOpen: true,
					title: MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.FEEDBACK_ERROR,
					description: MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.FEEDBACK_ERROR_DESCRIPTION,
					success: false,
				});
			})
			.finally(() => {
				toast.dismiss(t);
				setTimeout(() => {
					setModalStatus({ isOpen: false, title: "", description: "", success: false });
				}, 3000);
			});
	};

	const showResults = () => {
		if (sleepScoreList && sleepScoreList.length > 0) {
			return <ResultTable
				result={sleepScoreList}
				onDeleteRequested={() => null} 
				onEditRequested={(selectedSleep) => handleShowEditModal(selectedSleep)}/>;
		} else if (sleepScoreList?.length === 0) {
			return (
				<CardContainer>
					<p>{MESSAGES.PT_BR.CUSTOM_EXERCISE_MESSAGES.EMPTY_LIST}</p>
				</CardContainer>
			);
		}
		return (
			<CardContainer>
				<Loader />
			</CardContainer>
		);
	};

	return (
		<MainContainer>
			<StatusModal isOpen={modalStatus.isOpen} title={modalStatus.title} description={modalStatus.description} success={modalStatus.success} />
			{selectedSleepRef.current && 
				<EditSleepModal 
					isOpen={editModalVisible} 
					onClose={() => setEditModalVisible(false)} 
					currentSleep={selectedSleepRef.current} 
					onUpdateSleep={(updatedSleep) => handleUpdateSleep(updatedSleep)}
				/>}
			<Header>
				<h1>{MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.SLEEP_SCORE}</h1>
			</Header>
			{showResults()}
		</MainContainer>
	);
};

export default SleepScore;