import React, { useMemo } from "react";
import { MESSAGES } from "../../../../constants/messages";
import { MainContainer, Header } from "./styles";
import { useDevice } from "../../../../contexts/device";
import { GpsType } from "../../../../types/Device";
import { AgpsContent } from "./components";
import EpoContent from "./components/EpoContent";


const Gps = () => {

	const {currentDevice} = useDevice();
	const isAgps = useMemo(() => currentDevice?.gpsType === GpsType.AGPS, [currentDevice]);

	const getContent = () => {
		if(isAgps){
			return <AgpsContent />;
		}
		return <EpoContent />;
	};

	return (
		<MainContainer>
			<Header>
				<h1>{ isAgps ?  MESSAGES.PT_BR.GPS.AGPS : MESSAGES.PT_BR.GPS.EPO}</h1>
			</Header>
			{getContent()}
		</MainContainer>
	);
};

export default Gps;