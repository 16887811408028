import styled from "styled-components";

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.backgroundCardSecondary};
    width: 100%;
    border-radius: 16px;
    padding: 16px;
    margin: 8px 0;
    box-sizing: border-box;
`;

export const SectionHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
        color: ${props => props.theme.textColor};
    }
`;

export const SectionLeft = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ImgContainer = styled.div`
    display: flex;
    margin-right: 16px;

    &:hover {
        cursor: pointer;
        opacity: 0.75;
    }
`;

export const SectionRight = styled.div`
    display: flex;
    flex-direction: row;
    gap: .2rem;
`;

export const SectionContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const BtnContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 10px;

    &:hover {
        cursor: pointer;
        opacity: 0.75;
    }
`;

export const ImgMask = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 170px;
    width: 140px;
    margin-right: 16px;
    border-radius: 32px;
    margin-top: -150px;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    padding: .5rem;
    box-sizing: border-box;
    /* padding: 10px; */


    div {
        display: flex;
        flex-direction: row;
    }
`;

export const PreviewImg = styled.img`
    display: flex;
    /* height: 180px; */
    width: 140px;
    border-radius: 32px;
    margin-right: 16px;
    object-fit:  contain;
    transition: filter ease-in-out .2s;
    box-sizing: border-box;
`;

export const RoudedBtnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #F2F2F2;
    padding: 12px;
    box-sizing: border-box;

    &:hover {
        cursor: pointer;
        opacity: 0.90;
    }
`;

export const PreviewCard = styled.div`
    height: 170px;
    width: 140px;
    margin-right: 16px;
    border-radius: 32px;
    justify-content: center;
    align-items: center;

    &:hover ${ImgMask} {
        opacity: 1;
    }

    &:hover ${PreviewImg} {
        filter: brightness(50%);
    }
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    margin-top: 16px;

    ::-webkit-scrollbar {
        height: 5px;
    }
`;

export const EmptyList = styled.p`
    height: 170px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: "Light";
    font-size: 18px;
    text-align: center;
    color: ${props => props.theme.textColor};
`;