import styled from "styled-components";
import { COLORS } from "../../assets";
import { Theme } from "../../types/Theme";

interface DeviceProps {
    selected: boolean;
    theme: Theme;
}

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    margin-bottom: 5px;


    ::-webkit-scrollbar {
    height: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;

    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555;
    }
`;



export const DeviceContainer = styled.div<DeviceProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 50px;
    border: ${props => props.selected ? `2px solid ${COLORS.DEFAULT_COLORS.BACKGROUND_PRIMARY }` : `2px solid ${props.theme.textColor}`};
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${props => props.selected ? COLORS.DEFAULT_COLORS.BACKGROUND_PRIMARY : "none"};
    margin-right: 10px;
    margin-left: 10px;
    

    img {
        height: 40px;
        width: 40px;
        border-radius: 10px;
    }

    h1 {
        padding-left: 5px;
        padding-right: 5px;
    }

    span {
        font-size: 14px;
        color: ${props => props.selected ? COLORS.DEFAULT_COLORS.WHITE : props.theme.textColor};
        white-space: nowrap;
    }

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;

