import React from "react";
import { useTheme } from "../../../../contexts/theme";
import { IconType } from "react-icons";
import { SidebarSmallItemStyled } from "./styles";
import { Feature } from "../../../../types/Feature";
import { canSee } from "../../../../services/auth/PermissionGate";
import { useAdminUser } from "../../../../contexts/adminUser";
import { Helpers } from "../../../../helpers";


interface Props {
	icon: IconType;
	selected?: boolean;
	onClick: () => void;
	requestedFeature: Feature;
}

const SidebarSmallItem = (props: Props) => {

	const { currentTheme } = useTheme();
	const { currentUser } = useAdminUser();
	const canAccessFeature = !props.requestedFeature.feature || canSee(currentUser.role, props.requestedFeature.feature);

	return (
		<SidebarSmallItemStyled
			enabled={canAccessFeature}
			onClick={() => canAccessFeature && props.onClick()}
		>
			<props.icon color={props?.selected ? currentTheme.primaryColor : Helpers.Hex.hex2rgba(currentTheme.secondaryColor, canAccessFeature ? 1 : 0.5)} fontSize="1.5em" />
		</SidebarSmallItemStyled>
	);
};

export default SidebarSmallItem;