export interface Notification {
    id: number;
    title: string;
    message: string;
    image?: string | null;
    link?: string | null;
    sendDate: string;
    operationSystem: OperationSystem;
    type: NotificationType;
}

export enum OperationSystem {
    ANDROID_AND_IOS = 0,
    IOS = 1,
    ANDROID = 2
}

export enum NotificationType {
    PUSH_AND_CENTRAL = 0,
    CENTRAL_NOTIFICATION = 1,
    PUSH_NOTIFICATION = 2
}