import React from "react";
import { MESSAGES } from "../../../../../../../../constants/messages";
import { useTheme } from "../../../../../../../../contexts/theme";
import { TableHeaderStyled } from "./styles";

const TableHeader = () => {

	const { currentTheme } = useTheme();

	return (
		<TableHeaderStyled theme={currentTheme} >
			<th>{MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.TITLE}</th>
			<th>{MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.DESCRIPTION}</th>
			<th>{MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.MIN}</th>
			<th>{MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.MAX}</th>
			<th>{MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.ACTION}</th>
		</TableHeaderStyled>
	);
};

export default TableHeader;