import styled from "styled-components";
import { Theme } from "../../../types/Theme";

interface ITagInputProps {
    theme: Theme
    rowsNumber?: number
    overLimit?: boolean
    isError?: boolean
}

export const StackContainer = styled.div<ITagInputProps>`
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
        width: 100%;
        text-align: right;
        font-family: "Normal";
        padding-right: 10px;
        padding-top: 5px;
        font-size: 14px;
        box-sizing: border-box;
        color: ${props => props.overLimit ? props.theme.primaryColor : props.theme.textColor};
    }

    span {
        color: ${props => props.theme.primaryColor};
        font-size: 12px;
        margin-left: 8px;
        margin-top: 5px;
        font-family: "Normal";
    }
`;

export const InfoBoxContainer = styled.div`
    display: flex;
    position: absolute;
`;

export const TitleContainer = styled.div<ITagInputProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    margin-left: 10px;
    width: fit-content;

    h1{
        font-size: 18px;
        font-weight: 500;
        margin-right: 5px;
        color: ${props => props.theme.textColor};
        text-align: center;
    }
`;

export const TextAreaStyled = styled.textarea<ITagInputProps>`
    display: flex;
    align-items: flex-start;
    height: ${props => props?.rowsNumber ? props.rowsNumber * 40 : 40}px;
    width: 100%;
    background-color: transparent;
    border: ${props => props.isError ? `1px solid ${props.theme.primaryColor}` : "none"};
    outline: none;
    color: ${props => props.theme.textColor};
    font-size: 16px;
    background-color: ${props => props.theme.backgroundCardSecondary};
    border-radius: 16px;
    box-sizing: border-box;
    padding: 10px;
    resize: none;
    font-family: "Normal";
    ::placeholder {
        color: ${props => props.theme.textColor};
      }
`;

export const InputStyled = styled.input<ITagInputProps>`
    display: flex;
    align-items: flex-start;
    height: 55px;
    width: 100%;
    background-color: transparent;
    border: ${props => props.isError ? `1px solid ${props.theme.primaryColor}` : "none"};
    outline: none;
    color: ${props => props.theme.textColor};
    font-size: 16px;
    background-color: ${props => props.theme.backgroundCardSecondary};
    border-radius: 16px;
    box-sizing: border-box;
    padding-left: 10px;
    font-family: "Normal";
    ::placeholder {
        color: ${props => props.theme.textColor};
      }
`;
