import styled from "styled-components";
import { Theme } from "../../../../../../types/Theme";

interface ModalProps {
	isOpen: boolean,
	theme: Theme,
}

export const ModalContainer = styled.div<ModalProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	opacity: ${props => props.isOpen ? 1 : 0};
	visibility: ${props => props.isOpen? "visible" : "hidden"};
	transition: visibility 0s, opacity 0.3s linear;
`;

interface ContentProps {
	theme: Theme,
}

export const ModalContent = styled.div<ContentProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 28%;
	min-width: 700px;
	height: 520px;
	background-color: ${props => props.theme.backgroundCard};
	border-radius: 24px;
	padding: 25px;
	padding-top: 5px;
	padding-bottom: 0px;
	box-sizing: border-box;
`;

export const ModalHeader = styled.div<ContentProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	h1 {
		font-size: 28px;
		padding: 10px;
		text-align: center;
		font-weight: 600;
		line-height: 30px;
		color: ${props => props.theme.primaryColor};
	}

	div {
		&:hover {
			cursor: pointer;
		}
	}
`;

export const ModalBody = styled.div<ContentProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	height: 100%;

	button {
		align-self: flex-end;
		width: 30%;
		margin-top: 0px;
		background-color: ${props => props.theme.primaryColor};
	}
`;

export const ScrollContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	max-height: 350px;
	align-items: center;
	padding-right: 5px;
	
	// overflow-y: scroll;
	overflow: auto;
`;

export const AppContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${props => props.theme.backgroundCardSecondary};
	padding-right:20px;
	padding-left: 15px;
	width: 90%;
	min-height: 140px;
	border-radius: 16px;
	margin-bottom: 20px;
`;

export const EditContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin-left: 25px;

	h1 {
		font-size: 20px;
		font-weight: 500;
		color: ${props => props.theme.textColor};
	}

	span {
		color: ${props => props.theme.primaryColor};
		font-size: 12px;
		margin-left: 8px;
		margin-top: 2px;
		font-family: "Normal";
	}

	div {
		display: flex;
		justify-content: space-between;
	}
`;

interface ITagInputProps {
	theme: Theme
	errorText?: string
}

export const InputStyled = styled.input<ITagInputProps>`
	display: flex;
	align-items: flex-start;
	height: 50px;
	width: 100%;
	border: ${props => props.errorText ? `1px solid ${props.theme.primaryColor}` : "none"};
	outline: none;
	color: ${props => props.theme.textColor};
	font-size: 18px;
	background-color: ${props => props.theme.inputColor};
	border-radius: 16px;
	box-sizing: border-box;
	padding-left: 18px;
	font-family: "Normal";
	margin-top: 10px;
	::placeholder {
		color: ${props => props.theme.placeholderColor};
	}
`;

export const ImgContainer = styled.div`
	
	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}
`;

export const ConfirmDeleteToaster = styled.div`
	span {
		// color: ${props => props.theme.textColor};
		font-family: "Normal";
		font-size: 20px;
		font-weight: 400;
	}

	b {
		// color: ${props => props.theme.textColor};
		font-family: "Normal";
		font-size: 20px;
		text-decoration: underline;

		&:hover {
			cursor: pointer;
			opacity: 0.7;
		}
	}
`;