export const WATCH_FACES_MESSAGES = {
	GALLERY_TITLE: "Galeria de mostradores",
	DATA_BTN: "Dados",
	NEW_SECTION_BTN: "Nova seção",
	SHOW_ON_APP: "Mostrar no app",

	CREATE_BTN: "Criar",
	INPUT_PLACEHOLDER: "Nome que aparecerá no aplicativo.",
	EDIT_SECTION_BTN: "Editar seção",
	SAVE: "Salvar",

	LOADING: "Carregando...",
	SUCCESS: "Sucesso!",
	ERROR: "Erro!",
	MANDATORY_FIELD: "Campo obrigatório",
	ADDED_SUCCESSFULLY: "Adicionado com sucesso!",
	EMPTY_SECTION: "Nenhum mostrador nessa sessão, toque no \"+”para adicionar",
};

export const CREATE_SECTION_FEEDBACK_MESSAGES = {
	TITLE: "Seção criada com sucesso!",
	DESCRIPTION: "A seção foi criada com sucesso! Agora, adicione pelo menos um mostrador para a seção ficar visível no aplicativo. "
};

export const DELETE_SECTION_FEEDBACK_MESSAGES = {
	TITLE: "Você tem certeza que deseja excluir essa seção?",
	DESCRIPTION_1: "Caso você exclua essa seção, ",
	DESCRIPTION_2: "todos os mostradores ",
	DESCRIPTION_3: "que estão nela serão ",
	DESCRIPTION_4: "apagados, ",
	DESCRIPTION_5: "assim como seus dados de download.",

	CONFIRM_BTN: "Tenho certeza, excluir.",
	CANCEL_BTN: "Não tenho certeza, cancelar.",

	SUCCESS_DELETE_TITLE: "Seção excluída com sucesso!",
	SUCCESS_DELETE_DESCRIPTION: "A seção foi excluída com sucesso!",

	ERROR_DELETE_TITLE: "Não foi possível excluir a seção",
	ERROR_DELETE_DESCRIPTION: "Não foi possível excluir a seção, tente novamente mais tarde. Erro: ",
};

export const ADD_WF_FEEDBACK_MESSAGES = {
	TITLE: "Mostrador adicionado com sucesso!",
	DESCRIPTION: "O mostrador foi adicionado com sucesso! Você pode continuar adicionado novos mostradores.",

	ERROR_TITLE: "Não foi possível adicionar o mostrador",
	ERROR_DESCRIPTION: "Não foi possível adicionar o mostrador, tente novamente mais tarde. Erro: ",
};

export const DELETE_WF_FEEDBACK_MESSAGES = {
	TITLE: "Você tem certeza que deseja excluir esse mostrador?",
	DESCRIPTION_1: "Caso você exclua esse mostrador, todos os dados de downloads serão ",
	DESCRIPTION_2: "perdidos e não poderão ",
	DESCRIPTION_3: "ser recuperados.",

	CONFIRM_BTN: "Tenho certeza, excluir.",
	CANCEL_BTN: "Não tenho certeza, cancelar.",

	SUCCESS_DELETE_TITLE: "Mostrador excluído com sucesso!",
	SUCCESS_DELETE_DESCRIPTION: "O mostrador foi excluído com sucesso!",
	ERROR_DELETE_TITLE: "Não foi possível excluir o mostrador",
	ERROR_DELETE_DESCRIPTION: "Não foi possível excluir o mostrador, tente novamente mais tarde. Erro: ",
};

export const ADD_WF_MODAL_MESSAGES = {
	TITLE: "Novo mostrador",
	EDIT_TITLE: "Editar mostrador",
	SECTION: "Seção",
	WF_NAME: "Nome do mostrador",
	WF_NAME_PLACEHOLDER: "Digite o nome do mostrador",
	WF_FILE: "Arquivo do mostrador",
	WF_PREVIEW: "Preview do aplicativo",
	SELECT_FILE: "Escolher arquivo",
	SHOW_ON_APP: "Mostrar no app",
	DEFAULT_WHATCHFACE: "Mostrador default",
	ZIP: "watch.zip",
	BIN: "watch.bin",
	WATCH: "watch.watch",
	PNG: "Preview.png",
	ADD_AND_CREATE_NEW: "Adicionar e criar novo mostrador",
	ADD_BTN: "Adicionar",

	HELP_ZIP_FILE: "O formato do arquivo do mostrador precisa ser em .zip",
	HELP_BIN_FILE: "O formato do arquivo do mostrador precisa ser em .bin",
	HELP_WATCH_FILE: "O formato do arquivo do mostrador precisa ser em .watch",
	HELP_PNG_FILE: "O formato do preview do mostrador precisa ser em .png e se recomenda uma resolução maior que 480px.",

	HELP_NAME_TITLE1: "Mostradores fixos: ",
	HELP_NAME_DESCRIPTION1: "O nome deve seguir o formato: time_plateN, sendo N o número do mostrador.",
	HELP_NAME_TITLE2: "Mostradores da galeria: ",
	HELP_NAME_DESCRIPTION: "O nome deve ser apenas watch.",

	SAVE: "Salvar",
};

export const SHOW_SECTION_ERROR_MODAL_MESSAGES = {
	TITLE: "Não é possível mostrar essa seção no aplicativo",
	DESCRIPTION: "Você precisa adicionar pelo menos 1 mostrador para que a seção fique visível no aplicativo.",
	CLOSE_BTN: "Entendi",
};

export const DATA_ANALYTICS_MESSAGES = {
	BACK_BTN: "Voltar",
	SECTIONS: "Seções",
	WATCH_FACES: "Mostradores",
	SECTION: "Seção",
	ALL: "Todos",
	DOWNLOADS: "Downloads",
	DOWNLOADS_WITHIN_RANGE: "Downloads no período",
	TOTAL: "Total",
};

export const UPDATE_SECTION_FEEDBACK_MESSAGES = {
	SUCCESS_TITLE: "Seção atualizada com sucesso!",
	SUCCESS_DESCRIPTION: "A seção foi atualizada com sucesso!",
	ERROR_TITLE: "Não foi possível atualizar a seção",
	ERROR_DESCRIPTION: "Não foi possível atualizar a seção, tente novamente mais tarde. Erro: ",
};

export const UPDATE_WATCH_FACE_FEEDBACK_MESSAGES = {
	SUCCESS_TITLE: "Mostrador atualizado com sucesso!",
	SUCCESS_DESCRIPTION: "O mostrador foi atualizado com sucesso!",
	ERROR_TITLE: "Não foi possível atualizar o mostrador",
	ERROR_DESCRIPTION: "Não foi possível atualizar o mostrador, tente novamente mais tarde. Erro: ",
};


export const WATCH_FACE = {
	WATCH_FACES_MESSAGES,
	ADD_WF_MODAL_MESSAGES,
	CREATE_SECTION_FEEDBACK_MESSAGES,
	DELETE_SECTION_FEEDBACK_MESSAGES,
	DELETE_WF_FEEDBACK_MESSAGES,
	SHOW_SECTION_ERROR_MODAL_MESSAGES,
	ADD_WF_FEEDBACK_MESSAGES,
	DATA_ANALYTICS_MESSAGES,
	UPDATE_SECTION_FEEDBACK_MESSAGES,
	UPDATE_WATCH_FACE_FEEDBACK_MESSAGES
};

