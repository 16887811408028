import React, { useState } from "react";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader } from "./styles";

interface Props {
	isOpen: boolean;
	onCancel: () => void;
	onEditPasswordRequested: (oldPassword: string, newPassword: string) => void;
}

const EditPasswordModal = (props: Props) => {

	const { currentTheme } = useTheme();

	const [oldPassword, setOldPassword] = useState<string>("");
	const [newPassword, setNewPassword] = useState<string>("");
	const [newPassword2, setNewPassword2] = useState<string>("");
	const [newPasswordError, setNewPasswordError] = useState<string>("");
	const [newPasswordError2, setNewPasswordError2] = useState<string>("");

	const onSavePassword = () => {

		const passwordMinimumCharacters = 8;

		if (newPassword.length >= passwordMinimumCharacters) {
			setNewPasswordError("");
		} else {
			setNewPasswordError(MESSAGES.PT_BR.USER_MESSAGES.PASSWORD_MINIMUM_CHARACTERS_ERROR);
			return;
		}

		if (newPassword === newPassword2) {
			setNewPasswordError2("");
		} else {
			setNewPasswordError2(MESSAGES.PT_BR.USER_MESSAGES.NEW_PASSWORD_ERROR);
			return;
		}

		props.onEditPasswordRequested(oldPassword, newPassword);
		setOldPassword("");
		setNewPassword("");
		setNewPassword2("");
	};

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.USER_MESSAGES.EDIT_PASSWORD_MODAL_TITLE}</h1>
					<div>
						<CloseIcon onClick={props.onCancel} />
					</div>
				</ModalHeader>
				<ModalBody theme={currentTheme}>
					<MainInput
						type="password"
						title={MESSAGES.PT_BR.USER_MESSAGES.OLD_PASSWORD}
						value={oldPassword}
						placeholder={""}
						onChange={setOldPassword}
						style={{ marginBottom: "10px" }}
					/>
					<MainInput
						type="password"
						title={MESSAGES.PT_BR.USER_MESSAGES.NEW_PASSWORD}
						value={newPassword}
						placeholder={""}
						onChange={setNewPassword}
						errorText={newPasswordError}
						style={{ marginBottom: "10px" }}
					/>
					<MainInput
						type="password"
						title={MESSAGES.PT_BR.USER_MESSAGES.NEW_PASSWORD2}
						value={newPassword2}
						placeholder={""}
						onChange={setNewPassword2}
						errorText={newPasswordError2}
						style={{ marginBottom: "10px" }}
					/>
					<p>{MESSAGES.PT_BR.USER_MESSAGES.HELP_LOGIN}</p>
					<ModalFooter theme={currentTheme}>
						<MainButton enabled onClick={() => onSavePassword()} text={MESSAGES.PT_BR.USER_MESSAGES.SAVE_BTN} />
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default EditPasswordModal;
