import React from "react";
import { MESSAGES } from "../../../../../../../../constants/messages";
import { useTheme } from "../../../../../../../../contexts/theme";
import { TableHeaderStyled } from "./styles";

const TableHeader = () => {

	const { currentTheme } = useTheme();

	return (
		<TableHeaderStyled theme={currentTheme} >
			<th>{MESSAGES.PT_BR.TUTORIALS.MAIN_MESSAGES.THUMB}</th>
			<th style={{ width:"70%", textAlign:"left", paddingLeft:"10%" }}>{MESSAGES.PT_BR.TUTORIALS.MAIN_MESSAGES.VIDEO_TITLE}</th>
			<th>{MESSAGES.PT_BR.TUTORIALS.MAIN_MESSAGES.ACTIONS}</th>
		</TableHeaderStyled>
	);
};

export default TableHeader;