import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Loader, StatusModal } from "../../../../components";
import { MESSAGES } from "../../../../constants/messages";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { useMobileUser } from "../../../../contexts/mobileUser";
import { useTheme } from "../../../../contexts/theme";
import { MobileUser } from "../../../../types/MobileUser";
import { ResultTable, SearchInput } from "./components";
import DeleteUserModal from "./components/DeleteUserModal";
import { CardContainer, CustomIcon, PageNavigator } from "./styles";

const Users = () => {

	const [searchText, setSearchText] = useState<string>("");
	const [users, setUsers] = useState<MobileUser[] | null>(null);
	const [autoFindUsers, setAutoFindUsers] = useState<MobileUser[]>([]);
	const { getPageUsers, getUsersByFilter, sendConfirmationEmail, sendResetPasswordEmail, hasNextPage, mobileUsers, deleteUser, pageToken } = useMobileUser();
	const [currentPage, setCurrentPage] = useState<number>(0);
	const { selectedApp } = useMobileApp();
	const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
	const [statusModalInfo, setStatusModalInfo] = useState({
		isOpen: false,
		title: "",
		description: "",
		success: false,
	});
	const [selectedUser, setSelectedUser] = useState<MobileUser | null>(null);

	const { currentTheme } = useTheme();

	const getUsers = () => {
		getPageUsers(selectedApp?.appCode ?? 0, currentPage)
			.then((users) => {
				setUsers(users);
			}).catch(() => {
				setUsers([]);
			});
	};

	const autoSearchUsers = (key) => {
		getUsersByFilter(selectedApp?.appCode ?? 0, key).then((users) => {
			setAutoFindUsers(users);
		}).catch((error) => {
			toast.error(error);
			setAutoFindUsers([]);
		});
	};

	useEffect(() => {
		getUsers();
	}, [currentPage]);

	useEffect(() => {
		setUsers(null);
		mobileUsers.current = [];
		setCurrentPage(0);
		pageToken.current = null; 
		getUsers();
	}, [selectedApp]);


	const openDeleteUserModal = () => {
		setDeleteModalVisible(true);
	};


	const onDeleteRequested = () => {
		setDeleteModalVisible(false);
		if (selectedUser) {
			const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
			deleteUser(selectedApp?.appCode ?? 0, selectedUser.email)
				.then(() => {
					setStatusModalInfo({
						isOpen: true,
						title: MESSAGES.PT_BR.USER_MESSAGES.DELETE_USER_FEEDBACK_TITLE,
						description: MESSAGES.PT_BR.USER_MESSAGES.DELETE_USER_FEEDBACK_DESCRIPTION,
						success: true,
					});
					getUsers();
				}).catch((error) => {
					setStatusModalInfo({
						isOpen: true,
						title: MESSAGES.PT_BR.USER_MESSAGES.DELETE_USER_FEEDBACK_ERROR,
						description: MESSAGES.PT_BR.USER_MESSAGES.DELETE_USER_FEEDBACK_DESCRIPTION + error,
						success: false,
					});
				}).finally(() => {
					toast.dismiss(t);
					setTimeout(() => {
						setStatusModalInfo({ ...statusModalInfo, isOpen: false });
					}, 3000);
					setSelectedUser(null);
				});
		}
	};


	const onResetPasswordRequested = (email: string) => {
		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		sendResetPasswordEmail(selectedApp?.appCode ?? 0, email)
			.then(() => {
				setStatusModalInfo({
					isOpen: true,
					title: MESSAGES.PT_BR.USER_MESSAGES.RESET_PASSWORD_TITLE,
					description: MESSAGES.PT_BR.USER_MESSAGES.RESET_PASSWORD_DESCRIPTION,
					success: true,
				});
			}).catch((error) => {
				setStatusModalInfo({
					isOpen: true,
					title: MESSAGES.PT_BR.USER_MESSAGES.RESET_PASSWORD_ERROR_TITLE,
					description: MESSAGES.PT_BR.USER_MESSAGES.RESET_PASSWORD_ERROR_DESCRIPTION + error,
					success: false,
				});
			}).finally(() => {
				toast.dismiss(t);
				setTimeout(() => {
					setStatusModalInfo({ ...statusModalInfo, isOpen: false });
				}, 3000);
			});
	};


	const onSendEmailConfirmationRequested = (email: string) => {
		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		sendConfirmationEmail(selectedApp?.appCode ?? 0, email)
			.then(() => {
				setStatusModalInfo({
					isOpen: true,
					title: MESSAGES.PT_BR.USER_MESSAGES.EMAIL_CONFIRMATION_TITLE,
					description: MESSAGES.PT_BR.USER_MESSAGES.EMAIL_CONFIRMATION_DESCRIPTION,
					success: true,
				});
			}).catch((error) => {
				setStatusModalInfo({
					isOpen: true,
					title: MESSAGES.PT_BR.USER_MESSAGES.CONFIRMATION_ERROR_TITLE,
					description: MESSAGES.PT_BR.USER_MESSAGES.CONFIRMATION_ERROR_DESCRIPTION + error,
					success: false,
				});
			}).finally(() => {
				toast.dismiss(t);
				setTimeout(() => {
					setStatusModalInfo({ ...statusModalInfo, isOpen: false });
				}, 3000);
			});
	};

	const pageNavigator = () => {
		return (
			<PageNavigator>
				<CustomIcon disabled={currentPage == 0}>
					<IoIosArrowBack color={currentTheme.textColor} fontSize="1.5em" onClick={() => setCurrentPage((previousState) => previousState > 0 ? previousState - 1 : 0)} />
				</CustomIcon>
				<CustomIcon disabled={!hasNextPage(currentPage)} >
					<IoIosArrowForward color={currentTheme.textColor} fontSize="1.5em" onClick={() => setCurrentPage((previousState) => hasNextPage(previousState) ? previousState + 1 : previousState)} />
				</CustomIcon>
			</PageNavigator>
		);
	};

	const showResults = () => {

		const responseArray = searchText.length > 0 ? autoFindUsers : users;

		if (responseArray && responseArray.length > 0) {
			return (
				<CardContainer theme={currentTheme}>
					<ResultTable
						result={responseArray}
						onDeleteRequest={(selectedUser) => {
							openDeleteUserModal();
							setSelectedUser(selectedUser);
						}}
						onResetPasswordRequest={(mobileUser) => onResetPasswordRequested(mobileUser.email)}
						onSendEmailConfirmationRequest={(mobileUser) => onSendEmailConfirmationRequested(mobileUser.email)}
					/>
					{searchText.length <= 0 && pageNavigator()}
				</CardContainer>
			);
		} else if (responseArray?.length === 0) {
			return (
				<CardContainer theme={currentTheme}>
					<p style={{padding: "20px"}}>Nenhum usuário encontrado</p>
				</CardContainer>
			);
		}
		return (
			<CardContainer theme={currentTheme}>
				<Loader />
			</CardContainer>
		);
	};


	return (
		<div>
			<DeleteUserModal isOpen={deleteModalVisible} onDeleteRequest={onDeleteRequested} onCancel={() => setDeleteModalVisible(false)} />
			<StatusModal isOpen={statusModalInfo.isOpen} title={statusModalInfo.title} description={statusModalInfo.description} success={statusModalInfo.success} />
			<SearchInput
				textValue={searchText}
				placeholder={MESSAGES.PT_BR.USER_MESSAGES.SEARCH_PLACEHOLDER}
				onChangeText={(value) => {setSearchText(value); autoSearchUsers(value);}}
				onSearch={() => console.log("search")}
			/>
			{showResults()}
		</div>
	);
};

export default Users;