import React, { createContext, useContext } from "react";
import { Api } from "../services";
import { FIRMWARE, Firmware, Firmware3in1, FirmwareSequential, Files3in1 } from "../types/Firmware";
import { createMd5Hash } from "../helpers/md5";

interface IFirmwareContextData {
	getFirmwareList: (appCode: number, deviceSlug: string) => Promise<FIRMWARE[] | null>;
	createNewFirmware: (firmware: FIRMWARE, files: File | File[] | Files3in1, appCode: number, deviceSlug: string) => Promise<void>;
	updateFirmware: (firmware: Firmware | Firmware3in1, appCode: number) => Promise<void>;
	deleteFirmware: (firmware: FIRMWARE, appCode: number) => Promise<void>;
}

interface FirmwareProviderProps {
	children: React.ReactNode;
}

export const FirmwareContext = createContext({} as IFirmwareContextData);

const FirmwareProvider: React.FC<FirmwareProviderProps> = ({ children }) => {

	const S3_FOLDER = "firmwares";

	const getFirmwareList = (appCode: number, deviceSlug: string) => {
		return Api.Firmware.getFirmwareList(appCode, deviceSlug);
	};

	const createNewFirmware = (firmware: FIRMWARE, files: File | File[] | Files3in1, appCode: number, deviceSlug: string) => {
		if (firmware.isSequential) {
			return _createNewFirmwareSequential(firmware as FirmwareSequential, files as File[], appCode, deviceSlug);
		}
		switch (firmware.numOfFiles) {
		case 1:
			return _createNewFirmwareSingleFile(firmware as Firmware, files as File, appCode, deviceSlug);
		case 3:
			return _createNewFirmware3in1(firmware as Firmware3in1, files as Files3in1, appCode, deviceSlug);
		default:
			return Promise.reject("specify the type of firmware");
		}
	};

	const updateFirmware = (firmware: Firmware | Firmware3in1, appCode: number) => {
		switch (firmware.numOfFiles) {
		case 1:
			return Api.Firmware.updateFirmware(firmware as Firmware, appCode);
		case 3:
			return Api.Firmware.updateFirmware3in1(firmware as Firmware3in1, appCode);
		default:
			return Promise.reject("specify the type of firmware");
		}
	};

	const deleteFirmware = (firmware: FIRMWARE, appCode: number) => {
		//Apenas firmwares sequenciais possuem a propriedade files
		if (Object.prototype.hasOwnProperty.call(firmware, "files")) {
			return Api.Firmware.deleteFirmwareSequential(firmware.id, appCode);
		}
		switch (firmware.numOfFiles) {
		case 1:
			return Api.Firmware.deleteFirmware(firmware.id, appCode);
		case 3:
			return Api.Firmware.deleteFirmware3in1(firmware.id, appCode);
		default:
			return Promise.reject("specify the type of firmware");
		}

	};

	const _createNewFirmwareSingleFile = (firmware: Firmware, file: File, appCode: number, deviceSlug: string) => {
		return new Promise<void>((resolve, reject) => {
			_getMd5HashAndAwsUrl(appCode, deviceSlug, file).then(([md5, s3File]) => {
				firmware.size = file.size;
				firmware.md5 = md5;
				firmware.file = s3File;
				resolve(Api.Firmware.createNewFirmware(firmware, appCode, deviceSlug));
			}).catch(error => reject(error));
		});
	};

	const _createNewFirmware3in1 = (firmware: Firmware3in1, files: Files3in1, appCode: number, deviceSlug: string) => {
		return new Promise<void>((resolve, reject) => {
			const dataTranPromise = _getMd5HashAndAwsUrl(appCode, deviceSlug, files.DATA_TRAN);
			const fontFilePromise = _getMd5HashAndAwsUrl(appCode, deviceSlug, files.FONT_FILE);
			const dfuPromise = _getMd5HashAndAwsUrl(appCode, deviceSlug, files.DFU);
			Promise.all([dataTranPromise, fontFilePromise, dfuPromise]).then(([dataTranMd5AndUrl, fontFileMd5AndUrl, dfuMd5AndUrl]) => {
				firmware.DATA_TRAN = { size: files.DATA_TRAN.size, md5: dataTranMd5AndUrl[0], file: dataTranMd5AndUrl[1] };
				firmware.FONT_FILE = { size: files.FONT_FILE.size, md5: fontFileMd5AndUrl[0], file: fontFileMd5AndUrl[1] };
				firmware.DFU = { size: files.DFU.size, md5: dfuMd5AndUrl[0], file: dfuMd5AndUrl[1] };
				resolve(Api.Firmware.createNewFirmware3in1(firmware, appCode, deviceSlug));
			}).catch(error => reject(error));
		});
	};

	const _createNewFirmwareSequential = (firmware: FirmwareSequential, files: File[], appCode: number, deviceSlug: string) => {
		return new Promise<void>((resolve, reject) => {
			const promisesList = files.map(file => _getMd5HashAndAwsUrl(appCode, deviceSlug, file));
			Promise.all(promisesList).then((md5AndUrlList) => {
				firmware.files = md5AndUrlList.map((md5AndUrl, index) =>  {
					return { size: files[index].size, md5: md5AndUrl[0], file: md5AndUrl[1] };
				});
				resolve(Api.Firmware.createNewFirmwareSequential(firmware, appCode, deviceSlug));
			}).catch(error => reject(error));
			resolve();
		});
	};

	const _getMd5HashAndAwsUrl = (appCode: number, deviceSlug: string, file: File) => {
		const Md5Promise = createMd5Hash(file);
		const AwsPromise = Api.AWS.sendOrUpdateFileToS3(appCode, file, `${S3_FOLDER}/${deviceSlug}`);
		return new Promise<[string, string]>((resolve, reject) => {
			Promise.all([Md5Promise, AwsPromise])
				.then(md5AndUrl => resolve(md5AndUrl))
				.catch(error => reject(error));
		});
	};

	return (
		<FirmwareContext.Provider
			value={{
				getFirmwareList,
				deleteFirmware,
				createNewFirmware,
				updateFirmware,
			}}>
			{children}
		</FirmwareContext.Provider>
	);
};

const useFirmware = () => {
	const context = useContext(FirmwareContext);

	return context;
};

export { FirmwareProvider, useFirmware };
