import styled from "styled-components";
import { Theme } from "../../../../../../../../types/Theme";

interface ContainerProps {
	theme: Theme
}

export const TableItemStyled = styled.tr<ContainerProps>`
	td {
		height: 70px;
		color: ${props => props.theme.textColor};
		font-weight: 400;
		font-size: 20px;
		font-family: "Normal";
		text-align: center;
	}
`;

export const RowContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: center;

	h1 {
		margin: 10px;
	}
`;

