import React, { useState } from "react";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { useTheme } from "../../../../../../contexts/theme";
import { MESSAGES } from "../../../../../../constants/messages";
import { MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { ImageInputWithPreview } from "../../../../../../components/Inputs";
import { ModalBody, ModalContainer, ModalContent, ModalHeader } from "./styles";

interface ModalProps {
	isOpen: boolean;
	onCancel: () => void;
	handleCreateApp: (name: string, image: string) => void;
}

const NewAppModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();

	const [deviceImage, setDeviceImage] = useState<string>("");
	const [appName, setAppName] = useState<string>("");
	const [appNameError, setAppNameError] = useState<string>("");

	const onSelectImage = (file: File) => {
		setDeviceImage(URL.createObjectURL(file));
	};

	const handleSaveNewDevice = () => {
		if (appName.length > 0) {
			setAppNameError("");
			props.handleCreateApp(appName, deviceImage);
			setAppName("");
			setDeviceImage("");
		} else {
			setAppNameError(MESSAGES.PT_BR.SUPPORT_MESSAGES.SUBJECT_ERROR);
		}
	};

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.APP_MODAL.NEW_APP}</h1>
					<div>
						<CloseIcon onClick={props.onCancel} />
					</div>
				</ModalHeader>
				<ModalBody theme={currentTheme}>
					<ImageInputWithPreview
						onSelectImage={onSelectImage}
						image={deviceImage}
						defaultImageComponent={<IoPhonePortraitOutline color={currentTheme.textColor} size={"60%"} />}
					/>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.APP_MODAL.APP_NAME}
						placeholder={MESSAGES.PT_BR.APP_MODAL.APP_NAME_PLACEHOLDER}
						value={appName}
						errorText={appNameError}
						onChange={setAppName}
					/>
					<MainButton enabled onClick={handleSaveNewDevice} text={MESSAGES.PT_BR.APP_MODAL.ADD_BTN} />
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);

};

export default NewAppModal;