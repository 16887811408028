import React, { useEffect, useState } from "react";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader } from "./styles";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/closeIcon.svg";
import { MainInput } from "../../../../../../components/Inputs";
import { MainButton } from "../../../../../../components/Buttons";
import { Sleep } from "../../../../../../types/Sleep";


interface Props {
	isOpen: boolean;
	onClose: () => void;
	currentSleep: Sleep;
	onUpdateSleep: (updatedSleep: Sleep) => void;
}

const EditSleepModal = (props: Props) => {

	const { currentTheme } = useTheme();
	const [currentSleep, setCurrentSleep] = useState<Sleep>(props.currentSleep);

	useEffect(() => {
		setCurrentSleep(props.currentSleep);
	}, [props.isOpen]);


	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					<h1>{MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.EDIT_SLEEP_SCORE}</h1>
					<div>
						<CloseIcon onClick={props.onClose} />
					</div>
				</ModalHeader>
				<ModalBody>
					<MainInput
						onChange={(newValue) => setCurrentSleep({ ...currentSleep, title: newValue })}
						value={currentSleep.title}
						type="text"
						placeholder={MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.TITLE_PLACEHOLDER}
						title={MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.TITLE}
					/>
					<MainInput
						onChange={(newValue) => setCurrentSleep({ ...currentSleep, description: newValue })}
						value={currentSleep.description}
						type="text"
						placeholder={MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.TITLE_PLACEHOLDER}
						title={MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.DESCRIPTION}
						rowsNumber={5}
					/>
					<ModalFooter theme={currentTheme}>
						<MainButton enabled onClick={() => props.onUpdateSleep(currentSleep)} text={MESSAGES.PT_BR.SLEEP_SCORE_MESSAGES.SAVE} />
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default EditSleepModal;
