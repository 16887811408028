import { getApi } from ".";
import { Device } from "../../types/Device";
import { DeviceMapper } from "../../types/Mapper/DeviceMapper";


export const getDeviceList = (appCode: number) => {
	return new Promise<Device[]>((resolve, reject) => {
		getApi(appCode).get("/smartWatch").then((response) => {
			const deviceList = response.data.map((device) => DeviceMapper.fromApiToModel(device));
			resolve(deviceList);
		}).catch((error) => {
			if(error.response.status === 401){
				reject("Erro ao recuperar lista de dispositivos: " + error);
			}
			reject("Erro ao recuperar lista de dispositivos: " + error?.title);
		});
	});
};

export const createNewDevice = (newDevice: Device, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/smartWatch", DeviceMapper.fromModelToApi(newDevice))
			.then(() => {
				resolve();
			}).catch((error) => {
				if(error.response.status == 409){
					reject("Já existe um dispositivo com esse slug");
				}else{
					reject(error?.response?.data?.title);
				}
			});
	});
};

export const updateDevice = (updatedDevice: Device, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put(`/smartWatch?slug=${updatedDevice.slug}`, DeviceMapper.fromModelToApi(updatedDevice))
			.then(() => {
				resolve();
			}).catch((error) => {
				console.log("Error: ", JSON.stringify(error));
				reject(error?.response?.data?.title);
			});
	});
};

export const deleteDevice = (device: Device, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete(`/smartWatch?slug=${device.slug}`)
			.then(() => {
				resolve();
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};