import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
`;

export const ContentFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 32px;

    button {
        width: 30%;
        max-width: 300px;
    }
`;
