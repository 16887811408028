import React, { useState } from "react";
import { Container, DropdownItem, ItemsContainer, Spacing, StackContainer, Placeholder } from "./styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useTheme } from "../../../contexts/theme";

interface TextInputProps {
	items: (string | null)[] | undefined;
	selected: string | null;
	onChange: (value: string) => void;
	title?: string;
	placeholder?: string;
	style?: React.CSSProperties;
	disabled?: boolean;
}

const Dropdown = (props: TextInputProps) => {

	const { currentTheme } = useTheme();
	const [isOpen, setIsOpen] = useState(false);

	return (
		<StackContainer theme={currentTheme} onMouseLeave={() => { setIsOpen(false); }} style={props?.style} disabled={props.disabled ?? false}>
			{props.title && <h5>{props.title}</h5>}
			<Container theme={currentTheme} onClick={() => {!props.disabled && setIsOpen(!isOpen);}}>
				<div />
				{props.selected === null ?
					<Placeholder>{props.placeholder}</Placeholder> 
					:
					<p>{props.selected}</p>
				}
				<div>
					{isOpen ?
						<IoIosArrowUp color={currentTheme.secondaryColor} fontSize="1.5em" /> :
						<IoIosArrowDown color={currentTheme.secondaryColor} fontSize="1.5em" />
					}
				</div>
			</Container>
			{isOpen &&
				<Spacing>
					<ItemsContainer theme={currentTheme}>
						{props.items?.map((item, index) => {
							return (
								<DropdownItem key={index} onClick={() => {
									props.onChange(item ?? "");
									setIsOpen(false);
								}} theme={currentTheme} >
									<p>{item}</p>
								</DropdownItem>
							);
						})}
					</ItemsContainer>
				</Spacing>
			}
		</StackContainer>
	);
};

export default Dropdown;