import { Theme } from "../../types/Theme";

const COLORS = {
	LIGHT: {
		type: "light",
		backgroundPage: "#E4E4E4",
		backgroundCard: "#F5F6F7",
		backgroundCardSecondary: "#EAEAEA",
		backgroundCardSurface: "#D1D2D3",
		backgroundCardSurfaceSecondary: "#C1D2D3",
		inputColor: "#D1D2D3",
		primaryColor: "#BD1823",
		secondaryColor: "#343A40",
		textColor: "#222222",
		placeholderColor: "#5E5E5E",
		secondaryTextColor: "#FFFFFF",
		borderColor: "#9D9E9F",
	} as Theme,
	DARK: {
		type: "dark",
		backgroundPage: "#1c1a1a",
		backgroundCard: "#303030",
		backgroundCardSecondary: "#414141",
		backgroundCardSurface: "#2F2F2F",
		backgroundCardSurfaceSecondary: "#5E5E5F",
		inputColor: "#5E5E5E",
		primaryColor: "#BD1823",
		secondaryColor: "#F5F6F7",
		textColor: "#F5F6F7",
		placeholderColor: "#D1D2D3",
		secondaryTextColor: "#000000",
		borderColor: "#9D9E9F",
	} as Theme,

	DEFAULT_COLORS: {
		TEXT_ERROR: "#BD1823",
		INPUT: "#D1D2D3",
		FIRST_BUTTON: "#BD1823",
		SECOND_BUTTON: "#343A40",
		WHITE: "#FFFFFF",
		WHITE90: "#F5F6F7",
		DARK90: "#222222",
		BACKGROUND_PRIMARY: "#DE3E49",
		BACKGROUND_SECONDARY: "#EAEAEA",
	}
};

export default COLORS;
