import styled from "styled-components";
import ReactSelect from "react-select/creatable";

import { COLORS } from "../../../assets";
import { Theme } from "../../../types/Theme";

interface ITagInputProps {
    theme: Theme,
    isError?: boolean
    hasTheme?: boolean
}

const optionValidationBackgroundColor = (isDisabled: boolean, isSelected: boolean, isFocused: boolean) => {
	if (isDisabled) {
		return undefined;
	} else if (isSelected) {
		return COLORS.DEFAULT_COLORS.INPUT;
	} else if (isFocused) {
		return COLORS.DEFAULT_COLORS.INPUT;
	}

	return undefined;
};

const optionValidationColor = (isDisabled: boolean, isSelected: boolean, theme: Theme) => {
	if (isDisabled) {
		return theme.backgroundCardSecondary;
	} else if (isSelected) {
		return "";
	}

	return theme.textColor;
};

const optionValidationActive = (isDisabled: boolean, isSelected: boolean) => {
	if (!isDisabled && isSelected) {
		return COLORS.DEFAULT_COLORS.INPUT;
	}

	return undefined;
};

export const StackContainer = styled.div<ITagInputProps>`
    display: flex;
    flex-direction: column;
    width: 100%;

    h1{
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
        color: ${props => props.theme.textColor};
    }

    span {
        color: ${props => props.theme.primaryColor};
        font-size: 12px;
        margin-left: 8px;
        margin-top: 5px;
        font-family: "Normal";
    }
`;

export const InputContainer = styled.div<ITagInputProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;
    width: 100%;
    background-color: ${props => props.theme.backgroundCardSecondary};
    border-radius: 16px;
    border: ${props => props.isError ? `1px solid ${props.theme.primaryColor}` : "none"};

    .custom-select {
        width: 100%;

		.select__single-value {
			color: ${props => props.theme.textColor};
		}

        .select__menu {
            background-color: ${props => props.theme.backgroundCardSecondary};
            border: 1px solid ${props => props.theme.borderColor};
            border-radius: 16px;
            padding-left: 4px;
            padding-right: 4px;
        }
    }

    p {
        white-space: nowrap;
        padding: 0 ${props => props.hasTheme ? "10px" : "0"};
        font-family: "Normal";
        color: ${props => props.theme.textColor}
    }
`;

export const Select = styled(ReactSelect).attrs(props => ({
	styles: {
		input: (styles) => ({
			...styles,
			visibility: "none",
			color: props.theme.textColor
		}),
		control: () => ({ 
			backgroundColor: props.theme.backgroundCardSecondary,
			display: "flex",
			flex: 1,
			height: 40,
			borderRadius: 16,
			fontSize: 16,
			fontFamily: "Normal",
			color: props.theme.textColor,
		}),
		option: (styles, { isDisabled, isFocused, isSelected }) => ({
			...styles,
			borderRadius: 16,
			marginTop: 2.5,
			marginBottom: 2.5,
			fontSize: 16,
			fontFamily: "Normal",
			backgroundColor: optionValidationBackgroundColor(isDisabled, isFocused, isSelected),
			color: optionValidationColor(isDisabled, isSelected, props.theme),
			cursor: isDisabled ? "not-allowed" : "default",
    
			":active": {
				color: props.theme.textColor,
				backgroundColor: optionValidationActive(isDisabled, isSelected),
			},
		}),
		multiValue: (styles) => ({
			...styles,
			backgroundColor: props.theme.backgroundCard,
			borderRadius: 8,
			color: props.theme.textColor,
		}),
		multiValueLabel: (styles) => ({
			...styles,
			color: props.theme.textColor,
		}),
		multiValueRemove: (styles) => ({
			...styles,
			flex: 1,
			color: props.theme.primaryColor,
			":hover": {
				backgroundColor: props.theme.backgroundCard,
				color: props.theme.inputColor,
			},
		}),
		placeholder: (styles) => ({ ...styles, color: props.theme.textColor, fontFamily: "Normal" })
	}
}))``;