import styled from "styled-components";

interface ContainerProps {
    disabled?: boolean;
}

export const StackContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    opacity: ${props => props.disabled? 0.5 : 1};
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    flex-direction: row;
    border: 2px solid ${props => props.theme.secondaryColor};
    border-radius: 15px;
    height: 50px;
    width: 220px;
    padding: 0 10px;

    &:hover {
        cursor: pointer;
    }

    img {
        height: 30px;
        width: 30px;
        object-fit: cover;
        border-radius: 5px;
    }

    > div {
        display: flex;
        width: 15%;
    }
`;

export const DeviceNameStyled = styled.p<DeviceNameProps>`
    color: ${props => props.theme.secondaryColor};
    font-size: ${props => props.textLength > 15 ? "12px" : "16px"};
    padding: 0 10px;
`;


export const SpacingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: 53px;
    width: 240px;
    padding-top: 10px;
`; 

export const OpenContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.backgroundCard};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    width: 100%;
    border-radius: 15px;
    padding: 5px;
    box-sizing: border-box;
    max-height: 350px;
    overflow-y: scroll;
`;

interface DeviceItemProps {
    enabled: boolean;
}

export const DeviceItem = styled.div<DeviceItemProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    opacity: ${props => props.enabled ? 1 : 0.5};

    div {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        height: 25px;
        width: 25px;
        margin-left: 5px;
    }

    h4 {
        color: ${props => props.theme.secondaryColor};
        font-size: 18px;
        font-weight: 400;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: left;
        margin-left: 15px;
    }

    &:hover {
        cursor: pointer;
        opacity: 0.8;
        background-color: ${props => props.theme.backgroundCardSecondary};
    }

`;

export const IconContainer = styled.div`
    display: flex;
    padding: 3px;
    box-sizing: border-box;
`;

interface DeviceNameProps {
    textLength: number;
}