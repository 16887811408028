import styled from "styled-components";
import { Theme } from "../../../../../../../../types/Theme";

interface TableProps {
  theme: Theme;
  disabled?: boolean;
}

export const TableContainer = styled.div<TableProps>`
  display: flex;
  flex-direction: column;
  padding: 2%;
  box-sizing: border-box;

  th {
    color: ${(props) => props.theme.textColor};
  }
`;

export const TableLine = styled.tr<TableProps>`
  td {
    height: 70px;
    color: ${(props) => props.theme.textColor};
    font-weight: 400;
    font-size: 16px;
    font-family: "Normal";
    text-align: center;
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10%;
  box-sizing: border-box;
  justify-content: space-evenly;

  p {
    color: white;
  }
`;

export const DescriptionStyled = styled.td`
  width: 60%;
  overflow: hidden;
`;
