import React, { useState } from "react";
import { ImgContainer, ItemContainer, StackContainer, TextContainer } from "./styles";
import { ReactComponent as ArrowForwardDark } from "../../../../../../assets/images/dark/arrowForwardIcon.svg";
import { ReactComponent as ArrowForwardLight } from "../../../../../../assets/images/light/arrowForwardIcon.svg";
import { ReactComponent as DndIconLight } from "../../../../../../assets/images/light/dndIcon.svg";
import { ReactComponent as DndIconDark } from "../../../../../../assets/images/dark/dndIcon.svg";
import { useTheme } from "../../../../../../contexts/theme";
import { EditWorkoutModal, ModalType } from "./components";
import { THEME_TYPES } from "../../../../../../constants";
import { WorkoutSection } from "../../../../../../types/Training";
import DeleteItemModal from "../DeleteItemModal";
import { DeleteButton, EditButton } from "../../../../../../components/Buttons";


interface WorkoutItemProps {
	currentWorkout: WorkoutSection;
	onSelectedWorkout: (workout: WorkoutSection) => void;
	onReloadRequested: () => void;
}

const WorkoutSectionItem = (props: WorkoutItemProps) => {

	const { currentTheme } = useTheme();
	const [isDragging, setIsDragging] = useState(false);

	const [editWorkoutModalVisible, setEditWorkoutModalVisible] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);

	return (
		<>
			{editWorkoutModalVisible &&
				<EditWorkoutModal onClose={() => { setEditWorkoutModalVisible(false); props.onReloadRequested(); }} selectedWorkout={props.currentWorkout} />
			}
			{deleteModalVisible &&
				<DeleteItemModal type={ModalType.WORKOUT_SECTION} onClose={() => { setDeleteModalVisible(false); props.onReloadRequested(); }} workoutSection={props.currentWorkout} />
			}
			<StackContainer theme={currentTheme} isDragging={isDragging}>
				<ItemContainer
					onMouseDownCapture={() => setIsDragging(true)}
					onMouseOverCapture={() => setIsDragging(false)}>
					<ImgContainer>
						{currentTheme.type == THEME_TYPES.DARK ? <DndIconDark /> : <DndIconLight />}
					</ImgContainer>
					<TextContainer theme={currentTheme}>
						<p>{props.currentWorkout.name}</p>
					</TextContainer>
					<ImgContainer >
						<EditButton onClick={() => setEditWorkoutModalVisible(true)} />
					</ImgContainer>
					<ImgContainer >
						<DeleteButton onClick={() => setDeleteModalVisible(true)} />
					</ImgContainer>
					<ImgContainer>
						{currentTheme.type == THEME_TYPES.DARK ? <ArrowForwardDark onClick={() => props.onSelectedWorkout(props.currentWorkout)} /> : <ArrowForwardLight onClick={() => props.onSelectedWorkout(props.currentWorkout)} />}
					</ImgContainer>
				</ItemContainer>
			</StackContainer>
		</>
	);
};

export default WorkoutSectionItem;