import React, { useEffect, useRef, useState } from "react";
import { BsCloudArrowDown } from "react-icons/bs";
import { Loader, StatusModal } from "../../../../components";
import { TextIconButton } from "../../../../components/Buttons";
import { MESSAGES } from "../../../../constants/messages";
import { FIRMWARE, Firmware, Firmware3in1, Files3in1 } from "../../../../types/Firmware";
import { useTheme } from "../../../../contexts/theme";
import { useFirmware } from "../../../../contexts/firmware";
import { ReactComponent as PlusIcon } from "../../../../assets/images/plusIcon.svg";
import { ResultTable, AddFirmwareModal, EditFirmwareModal, AddFirmwareSequentialModal } from "./components";
import { MainContainer, Header, CardContainer } from "./styles";
import { useMobileApp } from "../../../../contexts/mobileApp";
import { useDevice } from "../../../../contexts/device";
import { toast } from "react-hot-toast";
import DeleteFirmwareModal from "./components/DeleteFirmwareModal";
import { FirmwareType } from "../../../../types/Device";


const Firmwares = () => {

	const { currentTheme } = useTheme();
	const { selectedApp } = useMobileApp();
	const { currentDevice } = useDevice();
	const { getFirmwareList, createNewFirmware, updateFirmware, deleteFirmware } = useFirmware();

	const [firmwareList, setFirmwareList] = useState<FIRMWARE[] | null>(null);
	const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
	const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
	const [modalStatus, setModalStatus] = useState({ isOpen: false, title: "", description: "", success: false });
	const selectedFirmwareToEdit = useRef<Firmware | Firmware3in1>({} as Firmware | Firmware3in1);
	const selectedFirmwareToDel = useRef<FIRMWARE>({} as FIRMWARE);

	useEffect(() => {
		refreshFirmwareList();
	}, []);

	const refreshFirmwareList = () => {
		getFirmwareList(selectedApp.appCode, currentDevice?.slug || "").then((list) => {
			if (list) setFirmwareList(list);
		}).catch(() => {
			setFirmwareList([]);
			toast.error(MESSAGES.PT_BR.FIRMWARE.GET_ERROR);
		});
	};

	const handleNewFirmware = (firmware: FIRMWARE, files: File | File[] | Files3in1) => {
		setAddModalVisible(false);

		if (currentDevice != null) {
			const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
			createNewFirmware(firmware, files, selectedApp.appCode, currentDevice?.slug)
				.then(() => {
					refreshFirmwareList();
					setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.FIRMWARE.SUCCESS_TITLE, description: MESSAGES.PT_BR.FIRMWARE.SUCCESS_DESCRIPTION, success: true });
				})
				.catch((error) => {
					setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.FIRMWARE.DEFAULT_ERROR_TITLE, description: MESSAGES.PT_BR.FIRMWARE.DEFAULT_ERROR_DESCRIPTION + error, success: false });
				})
				.finally(() => {
					toast.dismiss(t);
					setTimeout(() => {
						setModalStatus({ ...modalStatus, isOpen: false });
					}, 3000);
				});
		}
	};

	const handleEditFirmware = (firmware: Firmware|Firmware3in1) => {
		setEditModalVisible(false);

		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		updateFirmware(firmware, selectedApp.appCode)
			.then(() => {
				refreshFirmwareList();
				setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.FIRMWARE.EDIT_SUCCESS_TITLE, description: MESSAGES.PT_BR.FIRMWARE.EDIT_SUCCESS_DESCRIPTION, success: true });
			})
			.catch((error) => {
				setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.FIRMWARE.DEFAULT_ERROR_TITLE, description: MESSAGES.PT_BR.FIRMWARE.DEFAULT_ERROR_DESCRIPTION + error, success: false });
			})
			.finally(() => {
				toast.dismiss(t);
				setTimeout(() => {
					setModalStatus({ ...modalStatus, isOpen: false });
				}, 3000);
			});
	};

	const handleDeleteFirmware = (firmware: FIRMWARE) => {
		setDeleteModalVisible(false);
		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		deleteFirmware(firmware, selectedApp.appCode).then(() => {
			setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.FIRMWARE.DELETE_SUCCESS_TITLE, description: MESSAGES.PT_BR.FIRMWARE.DELETE_SUCCESS_DESCRIPTION, success: true });
		}).catch((error) => {
			setModalStatus({ isOpen: true, title: MESSAGES.PT_BR.FIRMWARE.DELETE_ERROR_TITLE, description: MESSAGES.PT_BR.FIRMWARE.DELETE_ERROR_DESCRIPTION + error, success: false });
		}).finally(() => {
			refreshFirmwareList();
			toast.dismiss(t);
			setTimeout(() => {
				setModalStatus({ ...modalStatus, isOpen: false });
			}, 3000);
		});
	};

	const showResults = () => {
		if (firmwareList && firmwareList.length > 0) {
			return <ResultTable 
				result={firmwareList.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())} 
				onDeleteRequested={(firmware) => {
					selectedFirmwareToDel.current = firmware;
					setDeleteModalVisible(true);
				}}
				onEditRequested={(firmware) => {
					if (firmware.isSequential) {
						return;
					}
					if (firmware.numOfFiles === 3) {
						selectedFirmwareToEdit.current = firmware as Firmware3in1;
					}else{
						selectedFirmwareToEdit.current = firmware as Firmware;
					}
					setEditModalVisible(true);
				}}
			/>;
		} else if (firmwareList?.length === 0) {
			return (
				<CardContainer>
					<BsCloudArrowDown color={currentTheme.textColor} size={"20%"} />
					<p>{MESSAGES.PT_BR.FIRMWARE.FIRMWARE_EMPTY}</p>
				</CardContainer>
			);
		}
		return (
			<CardContainer>
				<Loader />
			</CardContainer>
		);
	};

	return (
		<MainContainer>
			<StatusModal isOpen={modalStatus.isOpen} title={modalStatus.title} description={modalStatus.description} success={modalStatus.success} />
			{ currentDevice?.firmwareType !== FirmwareType.SEQUENTIAL && <AddFirmwareModal isOpen={addModalVisible} onCancel={() => setAddModalVisible(false)} handleNewFirmware={handleNewFirmware} firmwareList={firmwareList}/>}
			{ currentDevice?.firmwareType === FirmwareType.SEQUENTIAL && <AddFirmwareSequentialModal isOpen={addModalVisible} onCancel={() => setAddModalVisible(false)} handleNewFirmware={handleNewFirmware} firmwareList={firmwareList}/>}
			<EditFirmwareModal isOpen={editModalVisible} onCancel={() => setEditModalVisible(false)} onEditRequested={handleEditFirmware} firmwareList={firmwareList} selectedFirmwareToEdit={selectedFirmwareToEdit.current} />
			<DeleteFirmwareModal isOpen={deleteModalVisible} onCancel={() => setDeleteModalVisible(false)} onDeleteRequested={handleDeleteFirmware} selectedFirmwareToDel={selectedFirmwareToDel.current} />
			<Header>
				<h1>{MESSAGES.PT_BR.FIRMWARE.FIRMWARE_TITLE}</h1>
				<TextIconButton
					text={MESSAGES.PT_BR.FIRMWARE.ADD_BTN}
					onClick={() => setAddModalVisible(true)}
					svgImage={<PlusIcon style={{ "width": "100%", "height": "100%" }} />}
					enabled
				/>
			</Header>
			{showResults()}
		</MainContainer>
	);
};

export default Firmwares;