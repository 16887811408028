import { MobileApp } from "../../types/MobileApp";
import defaultLogo from "../../assets/images/technosLogo.svg";

export const createNewMobileApp = (name: string, image: string) => {
	return new Promise<MobileApp>((resolve) => {
		setTimeout(() => {
			const device = {
				name: name,
				appCode: -1,
				image: image || defaultLogo,
			} as MobileApp;
			resolve(device);
		}, 1000);
	});
};

export const updateMobileApp = (app: MobileApp) => {
	// TODO
	console.log("App", app);
};

export const deleteMobileApp = (app: MobileApp) => {
	// TODO
	console.log("App", app);
};