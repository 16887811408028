import React from "react";
import { toast } from "react-hot-toast";
import { COLORS } from "../../../../../../assets";
import { MainButton } from "../../../../../../components/Buttons";
import { MESSAGES } from "../../../../../../constants/messages";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { useTheme } from "../../../../../../contexts/theme";
import { useWatchFace } from "../../../../../../contexts/watchFaces";
import { ModalBody, ModalContainer, ModalContent, ModalHeader } from "./styles";

interface ModalProps {
	isOpen: boolean;
    itemId: number;
	onClose: (feedback) => void;
	type: ModalType
}

export enum ModalType {
	WATCH_FACE,
	SECTION
}


const DeleteItemModal = (props: ModalProps) => {

	const { currentTheme } = useTheme();
	const { deleteWatchFaceSection, deleteWatchFace } = useWatchFace();
	const { selectedApp } = useMobileApp();

	const handleDelete = () => {
		const t = toast.loading(MESSAGES.PT_BR.WATCH_FACE.WATCH_FACES_MESSAGES.LOADING);
		if (props.type === ModalType.SECTION) {
			deleteWatchFaceSection(selectedApp.appCode ?? 0, props.itemId)
				.then(() => {
					props.onClose({ isOpen: true, title: MESSAGES.PT_BR.WATCH_FACE.DELETE_SECTION_FEEDBACK_MESSAGES.SUCCESS_DELETE_TITLE, description: MESSAGES.PT_BR.WATCH_FACE.DELETE_SECTION_FEEDBACK_MESSAGES.SUCCESS_DELETE_DESCRIPTION, success: true });
				}).catch((error) => {
					props.onClose({ isOpen: true, title: MESSAGES.PT_BR.WATCH_FACE.DELETE_SECTION_FEEDBACK_MESSAGES.ERROR_DELETE_TITLE, description: MESSAGES.PT_BR.WATCH_FACE.DELETE_SECTION_FEEDBACK_MESSAGES.ERROR_DELETE_DESCRIPTION + error, success: false });
				}).finally(() => {
					toast.dismiss(t);
				});
		} else {
			deleteWatchFace(selectedApp.appCode ?? 0, props.itemId)
				.then(() => {
					props.onClose({ isOpen: true, title: MESSAGES.PT_BR.WATCH_FACE.DELETE_WF_FEEDBACK_MESSAGES.SUCCESS_DELETE_TITLE, description:  MESSAGES.PT_BR.WATCH_FACE.DELETE_WF_FEEDBACK_MESSAGES.SUCCESS_DELETE_DESCRIPTION, success: true });
				}).catch((error) => {
					props.onClose({ isOpen: true, title: MESSAGES.PT_BR.WATCH_FACE.DELETE_WF_FEEDBACK_MESSAGES.ERROR_DELETE_TITLE, description:  MESSAGES.PT_BR.WATCH_FACE.DELETE_WF_FEEDBACK_MESSAGES.ERROR_DELETE_DESCRIPTION + error, success: false });
				}).finally(() => {
					toast.dismiss(t);
				});
		}
	};

	const getContent = () => {
		if (props.type === ModalType.SECTION) {
			return (
				<p>{MESSAGES.PT_BR.WATCH_FACE.DELETE_SECTION_FEEDBACK_MESSAGES.DESCRIPTION_1}
					<b>{MESSAGES.PT_BR.WATCH_FACE.DELETE_SECTION_FEEDBACK_MESSAGES.DESCRIPTION_2}</b>
					{MESSAGES.PT_BR.WATCH_FACE.DELETE_SECTION_FEEDBACK_MESSAGES.DESCRIPTION_3}
					<b>{MESSAGES.PT_BR.WATCH_FACE.DELETE_SECTION_FEEDBACK_MESSAGES.DESCRIPTION_4}</b>
					{MESSAGES.PT_BR.WATCH_FACE.DELETE_SECTION_FEEDBACK_MESSAGES.DESCRIPTION_5}</p>
			);
		}

		return (
			<p>{MESSAGES.PT_BR.WATCH_FACE.DELETE_WF_FEEDBACK_MESSAGES.DESCRIPTION_1}
				<b>{MESSAGES.PT_BR.WATCH_FACE.DELETE_WF_FEEDBACK_MESSAGES.DESCRIPTION_2}</b>
				{MESSAGES.PT_BR.WATCH_FACE.DELETE_WF_FEEDBACK_MESSAGES.DESCRIPTION_3}</p>
		);
	};

	return (
		<ModalContainer isOpen={props.isOpen} theme={currentTheme}>
			<ModalContent theme={currentTheme}>
				<ModalHeader theme={currentTheme}>
					{props.type === ModalType.SECTION ?
						<h3>{MESSAGES.PT_BR.WATCH_FACE.DELETE_SECTION_FEEDBACK_MESSAGES.TITLE}</h3>
						:
						<h3>{MESSAGES.PT_BR.WATCH_FACE.DELETE_WF_FEEDBACK_MESSAGES.TITLE}</h3>
					}
				</ModalHeader>
				<ModalBody theme={currentTheme}>
					{getContent()}
					<div>
						<MainButton enabled
							onClick={() => props.onClose(null)}
							text={MESSAGES.PT_BR.FAQ_MESSAGES.CANCEL_BTN}
							lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
							darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
						/>
						<h5></h5>
						<MainButton enabled
							onClick={handleDelete}
							text={MESSAGES.PT_BR.FAQ_MESSAGES.CONFIRM_DELETE}
							lightColor={currentTheme.primaryColor}
							darkColor={currentTheme.primaryColor}
						/>
					</div>
				</ModalBody>
			</ModalContent>
		</ModalContainer >
	);

};

export default DeleteItemModal;