import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${ props => props.theme.backgroundCard };
    padding: 32px;
    box-sizing: border-box;
    border-radius: 16px;
    text-align: center;
    margin: 0 32px 0 32px;

    p {
        margin-top: 16px;
    }
`;