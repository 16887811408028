import React from "react";
import { MESSAGES } from "../../../../../../constants/messages";
import { useTheme } from "../../../../../../contexts/theme";
import { ModalBody, ModalContainer, ModalContent, ModalHeader } from "./styles";
import { MainButton } from "../../../../../../components/Buttons";
import { COLORS } from "../../../../../../assets";
import { ShareExercise } from "../../../../../../types/ShareExercise";

interface ModalProps {
	onClose: () => void;
	selectedShareExercise: ShareExercise;
	handleDelete: () => void;
}
const DeleteShareExerciseModal = (props: ModalProps) => {
	const { currentTheme } = useTheme();

	return (
		<ModalContainer>
			<ModalContent>
				<ModalHeader>
					<h3>{MESSAGES.PT_BR.SHARE_EXERCISE.DELETE_TITLE}</h3>
				</ModalHeader>
				<ModalBody>
					<p>{MESSAGES.PT_BR.SHARE_EXERCISE.DELETE_DESCRIPTION}</p>
					<div>
						<MainButton enabled
							onClick={props.onClose}
							text={MESSAGES.PT_BR.SHARE_EXERCISE.CANCEL_BTN}
							lightColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
							darkColor={COLORS.DEFAULT_COLORS.SECOND_BUTTON}
						/>
						<h5></h5>
						<MainButton enabled
							onClick={() => {
								props.onClose();
								props.handleDelete();
							}}
							text={MESSAGES.PT_BR.SHARE_EXERCISE.DELETE_BTN}
							lightColor={currentTheme.primaryColor}
							darkColor={currentTheme.primaryColor}
						/>
					</div>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default DeleteShareExerciseModal;