import React from "react";
import { MESSAGES } from "../../../../../../../../constants/messages";
import { useTheme } from "../../../../../../../../contexts/theme";
import { TitleInfoBoxContainer } from "./styles";

interface Props {
	withImageCharacters: number;
	withoutImageCharacters: number;
}

const InfoBox = (props:Props) => {

	const { currentTheme } = useTheme();

	return (
		<TitleInfoBoxContainer theme={currentTheme}>
			<p>{MESSAGES.PT_BR.NOTIFICATIONS.INFO_TITLE}</p>
			<div>
				<p><strong>{MESSAGES.PT_BR.NOTIFICATIONS.WITH_IMAGE}</strong> { props.withImageCharacters +  " " + MESSAGES.PT_BR.NOTIFICATIONS.CHARACTERS}</p>
				<p><strong>{MESSAGES.PT_BR.NOTIFICATIONS.WITHOUT_IMAGE}</strong> {  props.withoutImageCharacters + " " + MESSAGES.PT_BR.NOTIFICATIONS.CHARACTERS}</p>
			</div>
		</TitleInfoBoxContainer>
	);
};

export default InfoBox;