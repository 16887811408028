import styled from "styled-components";

interface IProps {
	hasManualAttached?: boolean,
}

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${props => props.theme.backgroundCard};
	border-radius: 24px;
	padding: 32px;
	margin-top: 32px;
	margin-bottom: 32px;
	height: 100%;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
`;

export const Preview = styled.div<IProps>`
	display: flex;
	background-color: ${props => props.theme.backgroundCardSecondary};
	border-radius: 16px;
	flex-direction: column;
	padding: 20px;
	height: ${props => props.hasManualAttached ? "800px" : "100px"};

	h2 {
		font-size: 24px;
    	font-weight: 500;
		color: ${props => props.theme.textColor};
		padding-bottom:10px;
	}

	div {
		align-self: center;
		width: 100%;
		height: 100%;
	}
`;

export const ContentFooter = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 20px;

	button {
		width: 30%;
		max-width: 250px;
		align-self: flex-end;
	}
`;
