export const SLEEP_SCORE_MESSAGES = {
	SLEEP_SCORE: "Pontuação do sono",
	TITLE: "Título",
	DESCRIPTION: "Descrição",
	MIN: "Mínimo",
	MAX: "Máximo",
	ACTION: "Ação",
	SAVE: "Salvar",
	TITLE_PLACEHOLDER: "Digite o título da pontuação.",
	DESCRIPTION_PLACEHOLDER: "Descrição da pontuação.",
	EDIT_SLEEP_SCORE: "Editar pontuação",
	UPDATING: "Atualizando...",

	FEEDBACK_SUCCESS: "Sono atualizado com sucesso!",
	FEEDBACK_ERROR: "Erro ao atualizar o sono.",
	FEEDBACK_ERROR_DESCRIPTION: "Ocorreu um erro ao atualizar o sono. Tente novamente mais tarde. Erro:",
	FEEDBACK_SUCCESS_DESCRIPTION: "O registro de sono foi atualizado com sucesso.",
};