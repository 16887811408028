import React, { createContext, useContext, useState } from "react";
import _ from "lodash";
import { Api } from "../services";
import { MobileApp } from "../types/MobileApp";
import { AVAILABLE_APPS } from "../constants";

interface IMobileAppContextData {
	selectedApp: MobileApp;
	appList: MobileApp[];
	setSelectedApp: (app: MobileApp) => void;
	createNewMobileApp: (name: string, image: string) => Promise<void>;
	editMobileApps: (apps: MobileApp[]) => void;
	deleteMobileApp: (app: MobileApp, deletingIndex: number) => void;
}

interface MobileAppProviderProps {
	children: React.ReactNode;
}

export const MobileAppContext = createContext({} as IMobileAppContextData);

const MobileAppProvider: React.FC<MobileAppProviderProps> = ({ children }) => {

	const [selectedApp, setSelectedApp] = useState<MobileApp>(AVAILABLE_APPS[0]);
	const [appList, setAppList] = useState<MobileApp[]>(AVAILABLE_APPS);

	const createNewMobileApp = (name: string, image: string) => {
		return new Promise<void>((resolve, reject) => {
			Api.MobileApp.createNewMobileApp(name, image)
				.then((appCreated) => {
					setAppList([...appList, appCreated]);
					resolve();
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const editMobileApps = (apps: MobileApp[]) => {
		// call the API update only for the apps that had some change
		apps.forEach((editedMobileApp, index) => {
			if (!_.isEqual(appList[index], editedMobileApp)) Api.MobileApp.updateMobileApp(editedMobileApp);
		});

		// TODO: set appList according to api response
		setAppList(apps);

	};

	const deleteMobileApp = (app: MobileApp, deletingIndex: number) => {
		Api.MobileApp.deleteMobileApp(app);

		const appListCopy = structuredClone(appList);
		appListCopy.splice(deletingIndex, 1);
		setAppList(appListCopy);
	};

	return (
		<MobileAppContext.Provider
			value={{
				selectedApp,
				appList,
				setSelectedApp,
				createNewMobileApp,
				editMobileApps,
				deleteMobileApp
			}}>
			{children}
		</MobileAppContext.Provider>
	);
};

const useMobileApp = () => {
	const context = useContext(MobileAppContext);

	return context;
};

export { MobileAppProvider, useMobileApp };
