import React, { createContext, useContext } from "react";
import { Api } from "../services";
import { AGPS, EPO } from "../types/Gps";

interface IGpsContextData {
    getAgpsInfos: (appCode: number, deviceSlug: string) => Promise<AGPS>;
    saveAgpsInfos: (agpsInfos: AGPS, appCode: number,  deviceSlug: string) => Promise<void>;
    getEpoInfos: (appCode: number, deviceSlug: string) => Promise<EPO>;
    saveEpoInfos: (agpsInfos: EPO, appCode: number,  deviceSlug: string) => Promise<void>;
}

interface GpsProviderProps {
    children: React.ReactNode;
}

export const GpsContext = createContext({} as IGpsContextData);

const GpsProvider: React.FC<GpsProviderProps> = ({ children }) => {

	const getAgpsInfos = (appCode: number, deviceSlug: string) => {
		return Api.Gps.getAgpsInfos(appCode, deviceSlug);
	};

	const saveAgpsInfos = (agpsInfos: AGPS, appCode: number, deviceSlug: string) => {
		return Api.Gps.saveAgpsInfos(agpsInfos, appCode, deviceSlug);
	};

	const getEpoInfos = (appCode: number, deviceSlug: string) => {
		return Api.Gps.getEpoInfos(appCode, deviceSlug);
	};

	const saveEpoInfos = (epoInfos: EPO, appCode: number, deviceSlug: string) => {
		return Api.Gps.saveEpoInfos(epoInfos, appCode, deviceSlug);
	};

	return (
		<GpsContext.Provider
			value={{
				getAgpsInfos,
				saveAgpsInfos,
				getEpoInfos,
				saveEpoInfos
			}}>
			{children}
		</GpsContext.Provider>
	);
};

const useGps = () => {
	const context = useContext(GpsContext);

	return context;
};

export { GpsProvider, useGps };
