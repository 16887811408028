import React from "react";
import { ModalContainer, ModalContent } from "./styles";

interface Props {
    isOpen: boolean;
    title: string;
	description: string;
	success: boolean;
}

const StatusModal = (props: Props) => {

	if (!props.isOpen) return null;

	return (
		<ModalContainer>
			<ModalContent>
				<h4>{props.title}</h4>
				<p>{props.description}</p>
				<img src={props.success ? "/images/gifCheck.gif" : "/images/erro.gif" } alt="status image" />
			</ModalContent>
		</ModalContainer>
	);
};

export default StatusModal;
