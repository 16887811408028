import React, { useEffect } from "react";
import { useDevice } from "./contexts/device";
import { useMobileApp } from "./contexts/mobileApp";
import { useAdminUser } from "./contexts/adminUser";
import Routes from "./routes";
import { configApiInterceptors } from "./services/api";
import { useTheme } from "./contexts/theme";
import { ThemeProvider } from "styled-components";


function App() {

	const deviceStore = useDevice();
	const mobileAppStore = useMobileApp();
	const adminUserStore = useAdminUser();
	const { setLastTheme } = useTheme();
	const {currentTheme} = useTheme();

	useEffect(() => {
		if (adminUserStore.currentToken) {
			deviceStore.getDeviceList(mobileAppStore.selectedApp?.appCode ?? 0);
			setLastTheme();
		}
	}, [adminUserStore.currentToken]);

	useEffect(() => {
		configApiInterceptors();
	}, []);

	return (
		<ThemeProvider theme={currentTheme}>
			<Routes />
		</ThemeProvider>
	);
}

export default App;
