import React, { createContext, useContext } from "react";
import { Api } from "../services";
import { StravaKeys } from "../types/ApiKey";

interface IApiKeysContextData {
	getStravaInfos: (appCode: number) => Promise<StravaKeys>;
	saveStravaInfos: (stravaKeys: StravaKeys, appCode: number) => Promise<void>;
}

interface ApiKeysProviderProps {
	children: React.ReactNode;
}

export const ApiKeysContext = createContext({} as IApiKeysContextData);

const ApiKeysProvider: React.FC<ApiKeysProviderProps> = ({ children }) => {

	const getStravaInfos = (appCode: number) => {
		return new Promise<StravaKeys>((resolve, reject) => {
			Api.ApiKeys.getStravaInfos(appCode)
				.then((response) => {
					resolve(response);
				}).catch((error) => {
					reject(error);
				});
		});
	};

	const saveStravaInfos = (stravaKeys: StravaKeys, appCode: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.ApiKeys.saveStravaInfos(stravaKeys, appCode)
				.then(() => {
					resolve();
				}).catch((error) => {
					reject(error);
				});
		});
	};

	return (
		<ApiKeysContext.Provider
			value={{
				getStravaInfos,
				saveStravaInfos,
			}}>
			{children}
		</ApiKeysContext.Provider>
	);
};

const useApiKeys = () => {
	const context = useContext(ApiKeysContext);

	return context;
};

export { ApiKeysProvider, useApiKeys };
