export const GPS = {
	AGPS: "AGPS",
	EPO: "EPO",
	AGPS_OFFLINE_URL: "URL do arquivo offline",
	AGPS_ONLINE_URL: "URL do arquivo online",
	AGPS_OFFLINE_URL_PLACEHOLDER: "Cole aqui link do arquivo offline",
	AGPS_ONLINE_URL_PLACEHOLDER: "Cole aqui link do arquivo online",

	EPO_C3: "Url do arquivo EPO C3",
	EPO_E3: "Url do arquivo EPO E3",
	EPO_G3: "Url do arquivo EPO G3",
	EPO_J3: "Url do arquivo EPO J3",
	EPO_C3_PLACEHOLDER: "Cole aqui link do arquivo EPO C3",
	EPO_E3_PLACEHOLDER: "Cole aqui link do arquivo EPO E3",
	EPO_G3_PLACEHOLDER: "Cole aqui link do arquivo EPO G3",
	EPO_J3_PLACEHOLDER: "Cole aqui link do arquivo EPO J3",

	SAVE: "Salvar",
	SAVE_FEEDBACK: "Salvo com sucesso!",
	AGPS_FEEDBACK: "Os links do AGPS foram salvos com sucesso!",
	EOP_FEEDBACK: "Os links do EOP foram salvos com sucesso!",

	MANDATORY_FIELD: "Este campo é obrigatório",
	GENERIC_ERROR: "Erro ao salvar informações",
};
