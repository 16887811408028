import styled from "styled-components";
import { Theme } from "../../../types/Theme";

interface cardProps {
    theme: Theme;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

export const Card = styled.div<cardProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    min-width: 400px;
    height: 40%;
    min-height: fit-content;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 20px;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    img {
        width: 40%;
        object-fit: cover;
    }

    hr {
        width: 90%;
    }

    h1 {
        font-size : clamp(2rem, 6rem, 5rem);
        color: ${props => props.theme.primaryColor};
    }

    p {
        font-size : clamp(1rem,4rem, 2rem);
        color: ${props => props.theme.textColor};
    }


`;

export const ColorContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`;
