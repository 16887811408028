import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { COLORS } from "../../../../../../assets";
import { StatusModal } from "../../../../../../components";
import { MainButton } from "../../../../../../components/Buttons";
import { MainInput } from "../../../../../../components/Inputs";
import { MESSAGES } from "../../../../../../constants/messages";
import { useMobileApp } from "../../../../../../contexts/mobileApp";
import { useSupport } from "../../../../../../contexts/support";
import { Helpers } from "../../../../../../helpers";
import { ContentFooter } from "./styles";

const WhatsAppContent = () => {

	const [wppNumber, setWppNumber] = useState<string | null>(null);
	const [wppNumberError, setWppNumberError] = useState("");
	const [modalStatus, setModalStatus] = useState({ isOpen: false, title: MESSAGES.PT_BR.SUPPORT_MESSAGES.SAVE_FEEDBACK, description: MESSAGES.PT_BR.SUPPORT_MESSAGES.WPP_FEEDBACK, success: true });
	const { getWppNumber, saveWppNumber } = useSupport();
	const { selectedApp } = useMobileApp();

	useEffect(() => {
		setWppNumber(null);
		getWppNumber(selectedApp?.appCode).then((res) => {
			setWppNumber(res);
		}).catch((err) => {
			setWppNumber("");
			toast.error(err);
		});
	}, [selectedApp]);

	const handleChangeNumber = (newNumber: string) => {
		if (Helpers.Validators.isNumber(newNumber)) {
			setWppNumberError("");
			setWppNumber(newNumber);
		}
	};


	const handleSaveWpp = () => {
		if ( wppNumber != null && wppNumber.length > 0 && Helpers.Validators.isNumber(wppNumber)) {
			setWppNumberError("");
		} else {
			setWppNumberError(MESSAGES.PT_BR.SUPPORT_MESSAGES.WPP_ERROR);
			return;
		}

		const t = toast.loading(MESSAGES.PT_BR.LOGIN.LOADING);
		saveWppNumber(wppNumber, selectedApp?.appCode)
			.then(() => {
				setModalStatus({...modalStatus, isOpen: true});

				setTimeout(() => {
					setModalStatus({ ...modalStatus, isOpen: false });
				}, 3000);

			}).catch((err) => {
				toast.error(err);
			}).finally(() => {
				toast.dismiss(t);
			});

	};


	return (
		<div>
			<StatusModal
				isOpen={modalStatus.isOpen}
				description={modalStatus.description}
				success={modalStatus.success}
				title={modalStatus.title}
			/>
			<MainInput
				onChange={(newNumber) => { handleChangeNumber(newNumber); }}
				placeholder={MESSAGES.PT_BR.SUPPORT_MESSAGES.WPP_NUMBER_PLACEHOLDER}
				value={wppNumber ?? ""}
				type="number"
				title={MESSAGES.PT_BR.SUPPORT_MESSAGES.WPP_NUMBER}
				errorText={wppNumberError}
				isLoading={wppNumber === null}
			/>
			<ContentFooter>
				<MainButton
					enabled
					onClick={() => { handleSaveWpp(); }}
					text={MESSAGES.PT_BR.SUPPORT_MESSAGES.SAVE_BTN}
					darkColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
					lightColor={COLORS.DEFAULT_COLORS.FIRST_BUTTON}
				/>
			</ContentFooter>
		</div>
	);
};

export default WhatsAppContent;