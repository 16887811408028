import React from "react";
import { useTheme } from "../../../contexts/theme";
import { ButtonStyled } from "./styles";

interface MainButtonProps {
    text: string;
    onClick: () => void;
	enabled: boolean;
	darkColor?: string;
	lightColor?: string;
    selected: boolean;
}

const OutlineButton = (props: MainButtonProps) => {

	const { currentTheme } = useTheme();

	return (
		<ButtonStyled
			onClick={() => props.enabled ? props.onClick() : null}
			theme={currentTheme}
			enabled={props.enabled}
			darkColor={props?.darkColor}
			lightColor={props?.lightColor}
			selected={props.selected}
		>
			{props.text}
		</ButtonStyled>
	);
};

export default OutlineButton;