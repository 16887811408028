/**
 * 
	API Object
	{
		"offline_url": "string",
		"online_url": "string"
	}
 */

/**
 * APP Object
interface AGPS {
	offlineFile: string;
	onlineFile: string;
}
 */

const fromAgpsModelToApi = (agpsModel, smartWatchSlug) => {
	return {
		offlineUrl: agpsModel.offlineFile,
		onlineUrl: agpsModel.onlineFile,
		smartWatchSlug
	};
};

const fromApiToAgpsModel = (apiModel) => {
	return {
		offlineFile: apiModel.offlineUrl,
		onlineFile: apiModel.onlineUrl,
	};
};


/**
 * 
	API Object
	{
        c3FileUrl: string;
        g3FileUrl: string;
        e3FileUrl: string;
        j3FileUrl: string;
        smartWatchSlug: string;
	}
 */

/**
 * APP Object
interface EPO {
	c3File: string;
    g3File: string;
    e3File: string;
    j3File: string;
}
 */

const fromEpoModelToApi = (epoModel, smartWatchSlug) => {
	return {
		c3FileUrl: epoModel.c3File,
		g3FileUrl: epoModel.g3File,
		e3FileUrl: epoModel.e3File,
		j3FileUrl: epoModel.j3File,
		smartWatchSlug
	};
};

const fromApiToEpoModel = (epoModel) => {
	return {
		c3File: epoModel.c3FileUrl,
		g3File: epoModel.g3FileUrl,
		e3File: epoModel.e3FileUrl,
		j3File: epoModel.j3FileUrl,
	};
};


export const GpsMapper = {
	fromAgpsModelToApi,
	fromApiToAgpsModel,
	fromEpoModelToApi,
	fromApiToEpoModel
};