import styled from "styled-components";

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    transition: visibility 0s, opacity 0.3s linear;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    min-width: 800px;
    height: 700px;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 24px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
`;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h1 {
        font-size: 28px;
        text-align: center;
        font-weight: 500;
        line-height: 30px;
        color: ${props => props.theme.primaryColor};
    }

    div {
        &:hover {
            cursor: pointer;
        }
    }
`;

export const Subtitle = styled.h3`
    font-size: 22px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;
    color: ${props => props.theme.textColor};
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 100%;
    min-height: 400px;
`;

export const ScrollContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 30px;
    overflow-y: scroll;

    /* width */
    ::-webkit-scrollbar {
    width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;

    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;

    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555;
    }
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    h5 {
        margin: 1%;
    }
`;

export const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
        width: 30%;
        margin-top: 0;
        margin-left: 20px;
        border-radius: 15px;
        background-color: ${props => props.theme.primaryColor};
    }

    p {
        text-decoration: underline;
        color: ${props => props.theme.textColor};

        &:hover {
            cursor: pointer;
        }
    }
`;

export const GroupContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 40%;
    justify-content: flex-end;

    p {
        white-space: nowrap;
    }
`;

export const StackContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
    margin-left: 10px;

    h1 {
        height: 3%;
    }

    > button {
        margin: 0;
        margin-top: 20px;
        width: 50%;
    }
`;


