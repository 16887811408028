import React, { createContext, useState, useContext } from "react";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorage";
import { Api, Storage } from "../services";
import { setToken } from "../services/api";
import { AdminUser } from "../types/AdminUser";

interface IAdminUserContextData {
    currentUser: AdminUser;
	currentToken: string;
	updateCurrentToken: (token: string) => Promise<void>;
    setCurrentUser: (user: AdminUser) => void;
    getCurrentUser: (appCode: number) => Promise<AdminUser | null>;
    getAdminUsers: (appCode: number) => Promise<AdminUser[] | null>;
	addAdminUsers: (appCode: number, newUser : AdminUser) => Promise<void>;
	deleteAdminUser: (appCode: number , id : string) => Promise<void>;
	updateAdminUser: (appCode: number, id: string, newRole: string) => Promise<void>;
	logout: () => void;
	sendResetPasswordEmail: (email: string) => Promise<void>;
	updateCurrentUser: (appCode: number, user: AdminUser, newPhotoFile: File) => Promise<void>;
	updatePassword: (appCode: number, oldPassword: string, newPassword: string) => Promise<void>;
}

interface AdminUserProviderProps {
    children: React.ReactNode;
}

export const AdminUserContext = createContext({} as IAdminUserContextData);

const AdminUserProvider: React.FC<AdminUserProviderProps> = ({ children }) => {

	const S3_FOLDER = "adminProfile";

	const [currentUser, setCurrentUser] = useState<AdminUser>({} as AdminUser);
	const [currentToken, setCurrentToken] = useState<string>("");

	const getCurrentUser = (appCode: number) => {
		return new Promise<AdminUser | null>((resolve, reject) => {
			Api.AdminUser.getCurrentUser(appCode)
				.then((user) => {
					setCurrentUser(user);
					resolve(user);
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const updateCurrentToken = (token: string) => {
		setCurrentToken(token);
		setToken(token);
		Storage.LocalStorage.storeLocalData(LOCAL_STORAGE_KEYS.TOKEN, token);
		return new Promise<void>((resolve) => setTimeout(resolve, 500));
	};

	const getAdminUsers = (appCode: number) => {
		return new Promise<AdminUser[] | null>((resolve, reject) => {
			Api.AdminUser.getAdminUsers(appCode)
				.then((users) => {
					resolve(users);
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};
	const deleteAdminUser = (appCode: number , id: string) => {
		return new Promise<void>((resolve, reject) => {
			Api.AdminUser.deleteAdminUser(appCode,id)
				.then(() => {
					resolve();
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const updateAdminUser = (appCode: number , id: string, newRole : string) => {
		return new Promise<void>((resolve, reject) => {
			Api.AdminUser.updateAdminUser(appCode,id, newRole)
				.then((id) => {
					resolve(id);
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const updateCurrentUser = (appCode: number, user: AdminUser, newPhotoFile: File) => {
		let photoPromise: Promise<string>;
		return new Promise<void>((resolve, reject) => {
			if (newPhotoFile.size) {
				photoPromise = Api.AWS.sendOrUpdateFileToS3(appCode, newPhotoFile, S3_FOLDER, user.photo);
			}
			
			Promise.all([photoPromise]).then(values => {
				if (values[0]) user.photo = values[0];
				Api.AdminUser.updateCurrentUser(appCode, user)
					.then(() => {
						setCurrentUser(user);
						Storage.LocalStorage.storeLocalData(LOCAL_STORAGE_KEYS.CURRENT_USER, JSON.stringify(user));
						resolve();
					}).catch((errorMessage) => {
						reject(errorMessage);
					});
			}).catch(s3Error => reject(s3Error));
		});
	};

	const sendResetPasswordEmail = (email: string) => {
		return new Promise<void>((resolve, reject) => {
			Api.AdminUser.sendAdminResetPasswordEmail(email)
				.then(() => {
					resolve();
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const logout = () => {
		setCurrentUser({} as AdminUser);
		Storage.LocalStorage.clearLocalStorage();
	};
 
	const addAdminUsers = (appCode:number, newUser : AdminUser) => {
		return Api.AdminUser.postAdminUsers(appCode,newUser);		
	};

	const updatePassword = (appCode: number, currentPassword: string, newPassword: string) => {
		return Api.AdminUser.updatePassword(appCode, currentPassword, newPassword);
	};

	return (
		<AdminUserContext.Provider
			value={{
				currentUser,
				currentToken,
				updateCurrentToken,
				setCurrentUser,
				getCurrentUser,
				getAdminUsers,
				addAdminUsers,
				deleteAdminUser,
				updateAdminUser,
				logout,
				sendResetPasswordEmail,
				updateCurrentUser,
				updatePassword
			}}>
			{children}
		</AdminUserContext.Provider>
	);
};

const useAdminUser = () => {
	const context = useContext(AdminUserContext);

	return context;
};

export { AdminUserProvider, useAdminUser };
