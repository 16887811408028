import React, { useState, useEffect } from "react";
import { MESSAGES } from "../../../../../constants/messages";
import { ReactComponent as CloseIcon } from "../../../../../assets/images/closeIcon.svg";
import { MainInput } from "../../../../../components/Inputs";
import { MainButton } from "../../../../../components/Buttons";
import Dropdown from "../../../../../components/Dropdown/MainDropdown";
import { AdminUser, Role, RolesList } from "../../../../../types/AdminUser";
import { ModalBody, ModalContainer, ModalContent, ModalFooter, ModalHeader } from "./styles";
import { emailIsValid } from "../../../../../helpers/validators";
import { useAdminUser } from "../../../../../contexts/adminUser";
import { toast } from "react-hot-toast";
import { useMobileApp } from "../../../../../contexts/mobileApp";

interface Props {
	isOpen: boolean;
	onCancel: () => void;
	onFeedbackRequested: (feedback) => void;
}

const AddUserModal = (props: Props) => {

	const { addAdminUsers, currentUser } = useAdminUser();
	const { selectedApp } = useMobileApp();
	const [nameError, setNameError] = useState<string>("");
	const [emailError, setEmailError] = useState<string>("");
	const [newUser, setNewUser] = useState<AdminUser>({
		id: "-1",
		email: "",
		photo: null,
		name: "",
		role: Role.DEVELOPER
	});


	useEffect(() => {
		resetState();
	}, []);

	const resetState = () => {
		setNewUser({
			id: "-1",
			email: "",
			photo: null,
			name: "",
			role: Role.DEVELOPER
		});
		setNameError("");
		setEmailError("");
	};


	const handleCreateUser = (keepAdding = false) => {
		let allRight = true;

		if (newUser.name.length <= 0) {
			allRight = false;
			setNameError(MESSAGES.PT_BR.USER_MESSAGES.MANDATORY_FIELD);
		} else {
			setNameError("");
		}

		if (!emailIsValid(newUser.email)) {
			allRight = false;
			setEmailError(MESSAGES.PT_BR.USER_MESSAGES.EMAIL_ADMIN_USER);
		} else {
			setEmailError("");
		}

		if (allRight) {
			const t = toast.loading(MESSAGES.PT_BR.USER_MESSAGES.LOADING);
			addAdminUsers(selectedApp.appCode, newUser)
				.then(() => {
					if (!keepAdding) {
						props.onFeedbackRequested({
							isOpen: true,
							description: MESSAGES.PT_BR.USER_MESSAGES.NEW_USER_FEEDBACK_DESCRIPTION,
							success: true,
							title: MESSAGES.PT_BR.USER_MESSAGES.NEW_USER_FEEDBACK_TITLE
						});
						props.onCancel();
					} else { 
						resetState();
						toast.success("Usuário adicionado");
					}
				})
				.catch((err) => {
					props.onFeedbackRequested({
						isOpen: true,
						description: MESSAGES.PT_BR.USER_MESSAGES.NEW_USER_FEEDBACK_DESCRIPTION_ERROR + err,
						success: false,
						title: MESSAGES.PT_BR.USER_MESSAGES.NEW_USER_FEEDBACK_TITLE_ERROR
					});
					props.onCancel();
				})
				.finally(() => {
					toast.dismiss(t);
				});
		}
	};

	return (
		<ModalContainer isOpen={props.isOpen} >
			<ModalContent >
				<ModalHeader >
					<h1>{MESSAGES.PT_BR.USER_MESSAGES.ADD_USER_MODAL_TITLE}</h1>
					<div>
						<CloseIcon onClick={props.onCancel} />
					</div>
				</ModalHeader>
				<ModalBody>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.USER_MESSAGES.NAME}
						value={newUser.name}
						placeholder={MESSAGES.PT_BR.USER_MESSAGES.NAME_PLACEHOLDER}
						onChange={(newName) => setNewUser({ ...newUser, name: newName })}
						style={{ marginBottom: "10px" }}
						errorText={nameError}
					/>
					<MainInput
						type="text"
						title={MESSAGES.PT_BR.USER_MESSAGES.EMAIL}
						value={newUser.email}
						placeholder={MESSAGES.PT_BR.USER_MESSAGES.EMAIL_PLACEHOLDER}
						onChange={(newEmail) => setNewUser({ ...newUser, email: newEmail })}
						style={{ marginBottom: "10px" }}
						errorText={emailError}
					/>
					<Dropdown
						title={MESSAGES.PT_BR.USER_MESSAGES.ROLE}
						items={ currentUser.role === Role.OWNER ? RolesList : RolesList.filter((role) => role !== Role.OWNER) } //Apenas Owner pode adicionar Owner
						onChange={(newRole) =>{ 
							setNewUser({ ...newUser, role: newRole as Role });							
						}}
						selected={newUser.role}
					/>
					<ModalFooter >
						<p onClick={() => handleCreateUser(true)} >{MESSAGES.PT_BR.USER_MESSAGES.ADD_AND_NEW_USER_BTN}</p>
						<MainButton enabled onClick={() => handleCreateUser(false)} text={MESSAGES.PT_BR.USER_MESSAGES.ADD_USER_BTN} />
					</ModalFooter>
				</ModalBody>
			</ModalContent>
		</ModalContainer>
	);
};

export default AddUserModal;
