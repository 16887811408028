import React from "react";
import { useTheme } from "../../../contexts/theme";
import { ButtonStyled } from "./styles";

interface MainButtonProps {
    text: string;
    onClick: () => void;
    enabled?: boolean;
    darkColor?: string;
    lightColor?: string;
    svgImage?: React.ReactNode;
}

const TextIconButton = (props: MainButtonProps) => {

	const { currentTheme } = useTheme();

	return (
		<ButtonStyled
			onClick={() => props?.enabled ? props.onClick() : null}
			theme={currentTheme}
			enabled={props.enabled}
			darkColor={props?.darkColor}
			lightColor={props?.lightColor}
		>
			{props?.svgImage &&
                <div>{props.svgImage}</div>
			}
			{props.text}
		</ButtonStyled>
	);
};

export default TextIconButton;