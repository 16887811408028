const fromApiToModel = (shareExercise) => {
	return {
		id: shareExercise.id,
		name: shareExercise.name,
		image: shareExercise.image,
		slugs : shareExercise.slugs,
		index: shareExercise.index,
		showApp: shareExercise.available
	};
};

const fromModelToApi = (shareExercise) => {
	return {
		name: shareExercise.name,
		image: shareExercise.image,
		available: shareExercise.showApp,
		slugs: shareExercise.slugs,
		index: shareExercise.index
	};
};

export const ShareExerciseMapper = {
	fromApiToModel,
	fromModelToApi
};
