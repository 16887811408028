import React, { Dispatch, SetStateAction } from "react";
import { MESSAGES } from "../../../../../../constants/messages";
import { Firmware, Firmware3in1, Operator, OperatorsList } from "../../../../../../types/Firmware";
import { MainInput } from "../../../../../../components/Inputs";
import Dropdown from "../../../../../../components/Dropdown/MainDropdown";
import { translateOperator, getOperatorByTranslation } from "../../../../../../helpers/firmware";
import { RowContainer, VersionContainer } from "./styles";

interface Props {
	firmware: Firmware | Firmware3in1;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFirmware: Dispatch<SetStateAction<any>>;
	deviceVersionError: string;
	deviceVersionEndError: string;
}

const DeviceVersionDropdown = ({
	firmware,
	setFirmware,
	deviceVersionError,
	deviceVersionEndError,
}: Props) => {
	
	return (
		<RowContainer>
			<Dropdown
				title={MESSAGES.PT_BR.FIRMWARE.HEADER_DEVICE_VERSION}
				placeholder={MESSAGES.PT_BR.FIRMWARE.DEVICE_VERSION_PLACEHOLDER}
				items={OperatorsList.map((value) => translateOperator(Operator[value]))}
				selected={translateOperator(firmware.deviceVersionOperator)}
				onChange={(newValue) => setFirmware({ ...firmware, deviceVersionOperator: getOperatorByTranslation(newValue)})}
				style={{ marginBottom: "1%" }}
			/>
			{firmware.deviceVersionOperator ?
				<VersionContainer>
					<MainInput
						type="text"
						title={translateOperator(firmware.deviceVersionOperator) ?? ""}
						value={firmware.deviceVersion ?? ""}
						placeholder={MESSAGES.PT_BR.FIRMWARE.VERSION_PLACEHOLDER}
						onChange={(newValue) => setFirmware({ ...firmware, deviceVersion: newValue })}
						errorText={deviceVersionError}
						inputStyle={{ marginBottom: 0 }}
					/>
					{firmware.deviceVersionOperator === Operator.InBetween &&
						<MainInput
							type="text"
							title={"e"}
							value={firmware.deviceVersionEnd ?? ""}
							placeholder={MESSAGES.PT_BR.FIRMWARE.VERSION_PLACEHOLDER}
							onChange={(newValue) => setFirmware({ ...firmware, deviceVersionEnd: newValue })}
							errorText={deviceVersionEndError}
							inputStyle={{ marginBottom: 0, marginLeft: "2%" }}
						/>
					}
				</VersionContainer>
				:
				null
			}
		</RowContainer>
	);
};

export default DeviceVersionDropdown;
