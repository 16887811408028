import React from "react";
import { useTheme } from "../../../../contexts/theme";
import { IconType } from "react-icons";
import { SidebarItemStyled } from "./styles";
import { IoIosArrowForward } from "react-icons/io";
import { Feature } from "../../../../types/Feature";
import { canSee } from "../../../../services/auth/PermissionGate";
import { useAdminUser } from "../../../../contexts/adminUser";
import { Helpers } from "../../../../helpers";


interface Props {
	requestedFeature: Feature;
	icon: IconType;
	selected?: boolean;
	onClick: () => void;
}

const SidebarItem = (props: Props) => {

	const { currentTheme } = useTheme();
	const { currentUser } = useAdminUser();
	const canAccessFeature = !props.requestedFeature.feature || canSee(currentUser.role, props.requestedFeature.feature);

	return (
		<SidebarItemStyled
			enabled={canAccessFeature}
			selected={props.selected}
			onClick={() => canAccessFeature && props.onClick()}
		>
			<div>
				<props.icon color={props?.selected ?currentTheme.primaryColor : Helpers.Hex.hex2rgba(currentTheme.secondaryColor, canAccessFeature ? 1 : 0.5)} fontSize="1.5em" />
				<span>{props.requestedFeature.name}</span>
			</div>
			<IoIosArrowForward color={props?.selected ? currentTheme.primaryColor : Helpers.Hex.hex2rgba(currentTheme.secondaryColor, canAccessFeature ? 1 : 0.5)} fontSize="1.5em" />
		</SidebarItemStyled>
	);
};

export default SidebarItem;