import React from "react";
import { MESSAGES } from "../../constants/messages";
import { useTheme } from "../../contexts/theme";
import { TitleInfoBoxContainer } from "./styles";

interface Props {
	format: string;
	maximumSize?: string;
}

const PhotoBox = (props: Props) => {

	const { currentTheme } = useTheme();

	return (
		<TitleInfoBoxContainer theme={currentTheme}>
			{props.maximumSize &&
				<div>
					<p><strong>{MESSAGES.PT_BR.PHOTO_BOX.MAX_TAM_IMG}</strong></p>
					<p>{props.maximumSize}</p>
				</div>
			}

			<div>
				<p><strong>{MESSAGES.PT_BR.PHOTO_BOX.IMG_FORMAT}</strong></p>
				<p>{props.format}</p>
			</div>
		</TitleInfoBoxContainer>
	);
};

export default PhotoBox;