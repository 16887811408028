import styled from "styled-components";
import { Theme } from "../../../../../../../../types/Theme";

interface ContainerProps {
    theme: Theme,
    selected?: boolean,
}

export const PreviewContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    max-width: 350px;
    /* min-height: 500px; */
    border-radius: 16px;
    background-color: ${props => props.theme.backgroundCardSecondary};
    padding: 10px;
`;

export const PreviewHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 350px;
    margin-top: 10px;
    margin-bottom: 20px;

    button {
        margin: 0;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 8px;
        height: 45px;
        font-size: 16px;
    }
`;

interface PreviewTemplate {
    backgroundImage: string;
    theme: Theme;
}

export const TemplatesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;


export const AndroidPreview = styled.div<PreviewTemplate>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${props => props.backgroundImage});
    width: 90%;
    height: 180px;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 45px;
    box-sizing: border-box;

    h4 {
        margin-top: 10px;
        color: ${props => props.theme.textColor};
    }
`;

export const IOSPreview = styled.div<PreviewTemplate>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${props => props.backgroundImage});
    width: 90%;
    height: 160px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 20px;
    padding-top: 10px;

    h4 {
        margin-top: 15px;
        color: ${props => props.theme.textColor};
    }
`;


export const AndroidNotification = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 270px;
    height: 40%;
    background-color: ${props => props.theme.backgroundCardSurface};
    border-radius: 2px;
    padding: 5px;
    box-sizing: border-box;

    h3 {
        font-size: 14px;
        color: ${props => props.theme.textColor};
    }

    p {
        font-family: "Normal";
        font-size: 12px;
        color: ${props => props.theme.textColor};
        max-width: 260px;
        max-height: 35px;
        word-wrap: break-word;
    }
`;

export const IosNotification = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 87%;
    height: 40%;
    background-color: ${props => props.theme.backgroundCardSurface};
    border-radius: 8px;
    padding: 5px;
    box-sizing: border-box;

    h3 {
        font-size: 14px;
        color: ${props => props.theme.textColor};
    }

    p {
        font-family: "Normal";
        font-size: 12px;
        color: ${props => props.theme.textColor};
        max-width: 260px;
        max-height: 35px;
        word-wrap: break-word;
    }
`;


export const CentralNotificationPreview = styled.div<PreviewTemplate>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(${props => props.backgroundImage});
    width: 90%;
    height: 340px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 20px;
    padding-top: 10px;
    background-position: center;

    h4 {
        margin-top: 40px;
        margin-bottom: 10px;
        color: ${props => props.theme.textColor};
    }
`;


export const CentralNotification = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 87%;
    height: 30%;
    background-color: ${props => props.theme.backgroundCardSurface};
    border-radius: 8px;
    padding: 5px;
    box-sizing: border-box;
`;

export const CentralNotificationContent = styled.div<ContainerProps>`
    width: 100%;

    h3 {
        font-size: 14px;
        color: ${props => props.theme.textColor};
    }

    p {
        font-family: "Normal";
        font-size: 12px;
        color: ${props => props.theme.textColor};
        max-width: 180px;
        max-height: 35px;
        word-wrap: break-word;
    }
`;


export const ImgContainer = styled.div<ContainerProps>`
    display: flex;
    width: 100px;
    height: 70%;
    margin-top: 5%;
    margin-left: 10px;
    margin-right: 3px;
    margin-bottom: 5%;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.backgroundPage};
    border-radius: 8px;

    img {
        width: 100%;
        object-fit: contain;
        border-radius: 8px;
    }
`;