export const EXERCISE_TYPE = [
	{ type: 1, name: "Caminhada" },
	{ type: 2, name: "Corrida" },
	{ type: 3, name: "Ciclismo" },
	// { type: 4, name: "A pé" },
	{ type: 6, name: "Escalada" },
	{ type: 8, name: "Outro" },
	{ type: 9, name: "Academia" },
	{ type: 10, name: "Spinning" },
	{ type: 12, name: "Esteira" },
	{ type: 18, name: "Yoga" },
	{ type: 21, name: "Basquete" },
	{ type: 22, name: "Futebol" },
	{ type: 23, name: "Vôlei" },
	{ type: 24, name: "Tênis" },
	{ type: 29, name: "Dança" },
	{ type: 54, name: "Natação em piscina" },
	{ type: 55, name: "Natação no mar" },
	{ type: 75, name: "Beach Tennis" },
	{ type: 170, name: "Surf" },
	{ type: 187, name: "Futevôlei" },
	{ type: 188, name: "SUP" },
	{ type: 198, name: "Crossfit" },
];