import styled from "styled-components";
import { THEME_TYPES } from "../../../constants";
import { COLORS } from "../../../assets";


export const EditorContainer = styled.div`

    & .toolbarStyle {
        background-color: ${props => props.theme.backgroundCardSecondary};
        border: none;
        border-radius: 16px;
        margin-bottom: 16px;
        padding: 10px;
    }

    & .editorStyle {
        background-color: ${COLORS.DEFAULT_COLORS.BACKGROUND_SECONDARY};
        border-radius: 16px;
        height: 40vh;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        font-family: initial !important;
        font-size: initial !important;
    }

    & .wrapperStyle {
        /* background-color: blue; */
    }

    & .iconStyle {
        background-color: transparent;
        border: none;
        filter: ${props => props.theme.type === THEME_TYPES.DARK ? "invert(1)" : "invert(0)"};
        border-radius: 8px;
    }

    & .removeClass {
        display: none;
    }

`;