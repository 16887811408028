import styled from "styled-components";
import { Theme } from "../../../../../../types/Theme";

interface ContainerProps {
    theme: Theme,
    isDragging?: boolean
}

export const StackContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${props => props.theme.backgroundCardSecondary};
    box-shadow:  ${props => props.isDragging ? "0px 0px 10px 0px rgba(0,0,0,0.75)" : "none"};
    border-radius: 15px;
    margin-top: 10px;
`;

export const ItemContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
`;

export const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    &:hover {
        cursor: pointer;
    }
`;

export const TextContainer = styled.div<ContainerProps>`
    display: flex;
    width: 100%;
    align-items: center;

    p {
        color: ${props => props.theme.textColor};
        font-size: 18px;
        font-weight: 520;
        margin-left: 1%;
    }
`;

export const ExpandedContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    box-sizing: border-box;
`;

export const TopicContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 5%;
    padding-right: 5%;
    height: 60px;

    p {
        font-size: 18px;
        font-weight: 100;
        color: ${props => props.theme.textColor};
        font-family: "Normal";
    }

    div {
        display: flex;
        flex-direction: row;
    }
`;
