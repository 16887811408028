import React from "react";
import { Container } from "./styles";
import { MESSAGES } from "../../constants/messages";

const WithoutPermissionBox = () => {

	return (
		<Container>
			<h2>{MESSAGES.PT_BR.ERROR.WITHOUT_PERMISSION_TITLE}</h2>
			<p>{MESSAGES.PT_BR.ERROR.WITHOUT_PERMISSION_DESCRIPTION}</p>
		</Container>
	);

};

export default WithoutPermissionBox;