import React, { createContext, useContext, useState } from "react";
import { DEFAULT_DEVICE } from "../constants";
import { Api } from "../services";
import { Device } from "../types/Device";

interface IDeviceContextData {
	currentDevice: Device | null;
	deviceList: Device[];
	deviceListWithoutDefault: Device[];
	addDeviceModalVisible: boolean;
	editDeviceModalVisible: boolean;
	getDeviceList: (appCode: number) => Promise<Device[] | null>;
	createNewDevice: (device: Device, imageFile: File, appCode: number) => Promise<void>;
	updateDevice: (updatedDevice: Device, newFile: File, appCode: number, updateFileType: "deviceImage" | "manual") => Promise<void>;
	deleteDevice: (device: Device, appCode: number) => Promise<void>;
	setCurrentDevice: (device: Device) => void;
	setAddDeviceModalVisible: (visible: boolean) => void;
	setEditDeviceModalVisible: (visible: boolean) => void;
}

interface DeviceProviderProps {
	children: React.ReactNode;
}

export const DeviceContext = createContext({} as IDeviceContextData);

const DeviceProvider: React.FC<DeviceProviderProps> = ({ children }) => {

	const S3_FOLDER_IMAGE = "deviceImages";
	const S3_FOLDER_MANUAL = "manuals";

	const [currentDevice, setCurrentDevice] = useState<Device | null>(null);
	const [deviceList, setDeviceList] = useState<Device[]>([]);
	const [deviceListWithoutDefault, setDeviceListWithoutDefault] = useState<Device[]>([]);

	const [addDeviceModalVisible, setAddDeviceModalVisible] = useState(false);
	const [editDeviceModalVisible, setEditDeviceModalVisible] = useState(false);

	const getDeviceList = (appCode: number) => {
		setCurrentDevice(null);
		return new Promise<Device[] | null>((resolve, reject) => {
			Api.Device.getDeviceList(appCode)
				.then((devices) => {
					setDeviceListWithoutDefault(devices);
					setDeviceList([DEFAULT_DEVICE].concat(devices));
					setCurrentDevice(DEFAULT_DEVICE);
					resolve(devices);
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	const createNewDevice = (newDevice: Device, imageFile: File, appCode: number) => {
		let filePromise: Promise<string>;
		return new Promise<void>((resolve, reject) => {
			if (imageFile.size) filePromise = Api.AWS.sendOrUpdateFileToS3(appCode, imageFile, S3_FOLDER_IMAGE);
			Promise.all([filePromise]).then(values => {
				if (values[0]) newDevice.image = values[0];
				else newDevice.image = "https://cdn.dooca.store/34629/brands/technos.jpg?v=1662122692&webp=0"; //Default Image
				Api.Device.createNewDevice(newDevice, appCode).then(() => {
					setDeviceListWithoutDefault([...deviceListWithoutDefault, newDevice]);
					setDeviceList([...deviceList, newDevice]);
					resolve();
				}).catch(errorMessage => reject(errorMessage));
			}).catch(s3Error => reject(s3Error));
		});
	};

	const updateDevice = (updatedDevice: Device, newFile: File, appCode: number, updateFileType: "deviceImage" | "manual") => {
		let filePromise: Promise<string>;
		return new Promise<void>((resolve, reject) => {
			if (newFile.size) {
				if (updateFileType === "deviceImage") filePromise = Api.AWS.sendOrUpdateFileToS3(appCode, newFile, S3_FOLDER_IMAGE, updatedDevice.image);
				if (updateFileType === "manual") filePromise = Api.AWS.sendOrUpdateFileToS3(appCode, newFile, S3_FOLDER_MANUAL, updatedDevice.linkManual);
			}
			Promise.all([filePromise]).then(values => {
				if (values[0]) {
					if (updateFileType === "deviceImage") updatedDevice.image = values[0];
					if (updateFileType === "manual") updatedDevice.linkManual = values[0];
				}
				Api.Device.updateDevice(updatedDevice, appCode).then(() => {
					const newList = deviceListWithoutDefault.filter((d) => d.slug !== updatedDevice.slug);
					newList.push(updatedDevice);
					setDeviceListWithoutDefault(newList);
					setDeviceList([DEFAULT_DEVICE].concat(newList));
					setCurrentDevice(updatedDevice);
					resolve();
				}).catch(errorMessage => reject(errorMessage));
			}).catch(s3Error => reject(s3Error));
		});
	};

	const deleteDevice = (device: Device, appNumber: number) => {
		return new Promise<void>((resolve, reject) => {
			Api.Device.deleteDevice(device, appNumber)
				.then(() => {
					const newList = deviceListWithoutDefault.filter((d) => d.slug !== device.slug);
					setDeviceListWithoutDefault(newList);
					setDeviceList([DEFAULT_DEVICE].concat(newList));
					resolve();
				})
				.catch((errorMessage) => {
					reject(errorMessage);
				});
		});
	};

	return (
		<DeviceContext.Provider
			value={{
				currentDevice,
				deviceList,
				deviceListWithoutDefault,
				addDeviceModalVisible,
				editDeviceModalVisible,
				setAddDeviceModalVisible,
				setEditDeviceModalVisible,
				getDeviceList,
				createNewDevice,
				updateDevice,
				deleteDevice,
				setCurrentDevice
			}}>
			{children}
		</DeviceContext.Provider>
	);
};

const useDevice = () => {
	const context = useContext(DeviceContext);

	return context;
};

export { DeviceProvider, useDevice };
