import React from "react";
import { StackContainer } from "./styles";
import { ReactComponent as EditIconLight } from "../../../assets/images/light/editIcon.svg";
import { ReactComponent as EditIconDark } from "../../../assets/images/dark/editIcon.svg";
import { useTheme } from "../../../contexts/theme";
import { MESSAGES } from "../../../constants/messages";
import { THEME_TYPES } from "../../../constants";
import { COLORS } from "../../../assets";
import { Tooltip } from "../../Tooltip";

interface EditButtonProps {
	onClick: () => void;
	disabled?: boolean;
}

const EditButton = (props: EditButtonProps) => {
	const tooltipId = "edit-tooltip";
	const { currentTheme } = useTheme();

	return (
		<StackContainer disabled={props.disabled} onClick={() => !props.disabled && props.onClick()} data-tooltip-id={tooltipId} data-tooltip-content={MESSAGES.PT_BR.BUTTON_MESSAGES.EDIT}>
			{currentTheme.type === THEME_TYPES.DARK ? <EditIconDark /> : <EditIconLight />}

			<Tooltip id={tooltipId} color={COLORS.DEFAULT_COLORS.DARK90} position="bottom" />
		</StackContainer>
	);
};

export default EditButton;
