import { getApi } from ".";
import { Exercise } from "../../types/Exercise";

export const getCustomExerciseList = (appCode: number) => {
	return new Promise<Exercise[]>((resolve, reject) => {
		getApi(appCode).get("/exercises_custom_names")
			.then((response) => {
				resolve(response.data.map((apiExercise) => { return { name: apiExercise?.name, id: apiExercise?.id } as Exercise; }));
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};


export const createNewCustomExercise = (newExercise: Exercise, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).post("/exercises_custom_names", { "custom_name": newExercise.name })
			.then(() => {
				resolve();
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};

export const updateCustomExercise = (lastExerciseName: string, updatedExercise: Exercise, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).put("/exercises_custom_names", { "current_custom_name": lastExerciseName, "new_custom_name": updatedExercise.name })
			.then(() => {
				resolve();
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};

export const deleteCustomExercise = (exercise: Exercise, appCode: number) => {
	return new Promise<void>((resolve, reject) => {
		getApi(appCode).delete("/exercises_custom_names", {data: {custom_name: exercise.name}})
			.then(() => {
				resolve();
			}).catch((error) => {
				reject(error?.response?.data?.title);
			});
	});
};