/**
 * Classe que define o objeto de usuário do webAdmin.
 */

export interface AdminUser {
	id: string,
	email: string;
	name: string;
	photo: string | null;
	role: Role
}

export enum Role {
	OWNER = "owner",
	ADMIN = "admin",
	DEVELOPER = "developer",
	EDITOR = "editor",
	POST_OFFICER = "post-officer",
	DESIGNER = "designer",
	NONE = "",
}

export const RolesList = [
	Role.OWNER,
	Role.ADMIN,
	Role.DEVELOPER,
	Role.EDITOR,
	Role.POST_OFFICER,
	Role.DESIGNER,
];
