import styled from "styled-components";
import { Theme } from "../../../../types/Theme";

interface ContainerProps {
    theme: Theme;
}

export const CardContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${props => props.theme.backgroundCard};
    border-radius: 15px;
    margin-top: 40px;
    box-sizing: border-box;

    p {
        color: ${props => props.theme.textColor};
    }
`;

export const PageNavigator = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 20px;
`;

interface IconDisabled {
    disabled?: boolean;
}

export const CustomIcon = styled.div<IconDisabled>`
    opacity: ${props => props.disabled ? "0.5" : "1"};
    &:hover {
        cursor: pointer;
    }
`;
