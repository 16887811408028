import styled from "styled-components";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css"; // melhor forma?
import "react-calendar/dist/Calendar.css";
import { COLORS } from "../../../assets";
// import { Theme } from "../../../types/Theme";
// import { THEME_TYPES } from "../../../constants";

export interface StackContainerProps {
    active: boolean;
}

export const StackContainer = styled.div<StackContainerProps>`    
    height: 50px;
    border-radius: 15px;
    box-sizing: border-box;
    
    font-family: "Normal";
    font-size: 20px;
    font-weight: 500;

    border: ${(props) => props.active ? "none" : `3px solid ${props.theme.textColor}` };
    background-color: ${(props) => props.active ? props.theme.primaryColor : "transparent" };
    color: ${(props) => props.active ? COLORS.DEFAULT_COLORS.WHITE90 : props.theme.textColor };
`;

export const DateRangePickerStyled = styled(DateRangePicker)`
    z-index: 5;
    height: 100%;

    .react-daterange-picker__wrapper {
        border: none;
        /* width: 400px; */
    }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now {
        background: grey;
    }

    .react-calendar__tile--active {
        background: rgba(189, 24, 35, 0.3);
    }

    .react-calendar--selectRange .react-calendar__tile--hover {
        background-color: rgba(189, 24, 35, 0.3);
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: rgba(189, 24, 35);
    }

    .react-daterange-picker__range-divider {
        white-space: pre;
    }

`;
